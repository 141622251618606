import React, { useState } from "react";

import { RootState } from "../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import { SaveBookingCRS } from "../../Redux/actions/BookingsAction";

import Button from "../../Views/buttons/Button";
import { toast } from "react-toastify";
import { CATCH_MESSAGE, getImage } from "../../UtilityFunctions";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";

export interface ICancelBookingProps {
  onClose: () => void;
  refreshData: () => void;
  sliderState: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelBooking: React.FC<ICancelBookingProps> = ({
  onClose,
  sliderState,
  refreshData,
}) => {
  const dispatch = useDispatch();

  const { cancelBookings, bookingDetailsCRSGet } = endPoints.BOOKINGS;

  const { booking_details } = useSelector((state: RootState) => state.bookings);
  const { auth_token } = useSelector((state: RootState) => state.auth);
  const [loader, setLoader] = useState<boolean>(false);

  const onCancelBooking = async () => {
    setLoader(true);
    try {
      const response = await beApi.post(`${cancelBookings}`, {
        invoice_id: booking_details?.bookingid,
      });

      if (response.data.status === 1) {
        setLoader(false);
        sliderState(false);
        refreshData();
        getBookingDetailsCRS();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
    }
    setLoader(false);
  };

  const getBookingDetailsCRS = async () => {
    try {
      const response = await beApi.post(
        `${bookingDetailsCRSGet}`,
        {
          booking_id: booking_details?.bookingid,
          booking_source: booking_details?.booking_source,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        dispatch(SaveBookingCRS(response.data.data));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
    }
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i id="close-slider" onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Cancel Booking</div>
        <div className="side__slider__sub__heading me-3">
          ID : {booking_details?.bookingid}
        </div>
      </div>
      <div className="height-64px"></div>

      <div className="booking__details__guest__wrapper">
        <div className="guest__detail__card">
          <div className="guest__name__wrapper">
            <div className="guest__name">{booking_details?.guest_name}</div>
            <div className="booking__channel__icon">
              <img
                className="booking__source__image"
                src={booking_details?.channel_logo}
                alt="Booking Source Icon"
              />
            </div>
          </div>

          <div className="guest__booking__date mt-2">
            Booking Date - {booking_details?.booking_date}
          </div>
          <div className="guest__email__contact__details mt-2">
            <div className="guest__number">
              <i className="bi bi-telephone"></i> {booking_details?.mobile}{" "}
            </div>
            <div className="guest__email__id">
              <i className="bi bi-envelope"></i> {booking_details?.email_id}
            </div>
          </div>

          <div className="checkin__checkout__wrapper mt-3">
            <div className="checkinout__content">
              <div className="check__inout__details check-in-border">
                <div className="check__inout__icon check-in-color">
                  <i className="bi bi-arrow-down-right-circle"></i>
                </div>
                <div className="check__inout__date">
                  <div className="check__inout check-in-color">
                    {booking_details?.checkin_at}
                  </div>
                  <div className="check__inout__label">Check-in</div>
                </div>
              </div>
            </div>

            <div className="number__of__nights__wrapper">
              <div className="night__icon">
                <i className="bi bi-moon-fill"></i>
              </div>
              <div className="night__number">{booking_details?.nights}</div>
            </div>

            <div className="checkinout__content">
              <div className="check__inout__details check-out-border">
                <div className="check__inout__icon check-out-color">
                  <i className="bi bi-arrow-up-left-circle"></i>
                </div>
                <div className="check__inout__date">
                  <div className="check__inout check-out-color">
                    {booking_details?.checkout_at}
                  </div>
                  <div className="check__inout__label">Check-out</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="standard-page--label my-3">Room Details</div>

        {booking_details?.room_data?.map((room: any, i: number) => {
          return (
            <div key={i} className="booking__room__detail__wrapper mb-4">
              <div className="booking__room__image">
                <img src={getImage(room.room_image)} alt={room.room_type} />
              </div>
              <div className="booking__room__details">
                <div className="room__type__meal__plan mb-3">
                  <div className="room__type__name">
                    <i className="bi bi-door-open me-2"></i>
                    {room.room_type}
                  </div>
                  <div className="room__type__meal__plan">
                    <i className="bi bi-cup-straw"></i> {room.plan_type}
                  </div>
                </div>
                <div className="booking__room__type__rate mb-3">
                  <i className="bi bi-people me-2"></i>{" "}
                  <div className="adult__child__wrapper">
                    <div className="adult__wrapper me-2">
                      {room.adult} Adult
                    </div>
                    <div className="child__wrrapper">{room.child} Child</div>
                  </div>
                </div>
                <div className="booking__room__nights mb-3">
                  <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                  {room.no_of_rooms}
                </div>
              </div>
            </div>
          );
        })}

        <div className="booking__details__price__wrapper">
          <div className="standard-page--label my-3">Price Breakup</div>
          <div className="booking__details__price__card">
            <div className="booking__detaila__price__input__wrapper me-3">
              <div>
                <div className="price__input__label mb-2">Total Price</div>
                <div className="price__input">
                  <div className="price__label">
                    {booking_details?.currency_name}
                  </div>
                  <div className="price__amount">
                    {booking_details?.price ?? " "}
                  </div>
                </div>
              </div>
            </div>

            <div className="booking__detaila__price__input__wrapper">
              <div>
                <div className="price__input__label mb-2">Tax</div>
                <div className="price__input">
                  <div className="price__label">{booking_details?.tax}</div>
                  <div className="price__amount">
                    {booking_details?.tax_amount ?? " "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="cancel__booking__btn__wrapper">
          <Button handleClick={onCancelBooking} loader={loader} className={loader ? "modify_cancel_booking_btn" : ""}>Cancel booking</Button>
        </div>
      </div>
    </>
  );
};

export default CancelBooking;
