import { BE_URL } from "../actionTypes";

const initialState = {
  be_url: "",
};

const BEUrlReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BE_URL:
      return {
        ...state,
        be_url: action.payload,
      };

    default:
      return state;
  }
};

export default BEUrlReducer;
