import React, { useState, useEffect } from "react";
import Button from "../../Views/buttons/Button";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import SlidingPane from "react-sliding-pane";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import AddPartnerSliderContent from "./AddPartnerSliderContent";
import { useNavigate } from "react-router-dom";
import beApi from "../../API/beApi";
import { toast } from "react-toastify";
import CATCH_MSG from "../../Constants/CatchMsg";
import Loader from "../../Views/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Capitalize } from "../../UtilityFunctions";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../Redux/actions/PromptAction";
import EditPartnerSliderContent from "./EditPartnerSliderContent";
import { Tooltip } from "@mui/material";
import { PartnerBulkUpload } from "./PartnerBulkUpload";

const Partner = () => {
  const [addSliderOpen, setAddSliderOpen] = useState(false);
  const [editSliderOpen, setEditSliderOpen] = useState(false);
  const [organisationList, setOrganisationList] = useState([]);
  const [selectedOrgList, setSelectedOrgList] = useState([]);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [customPageRange, setCustomPageRange] = useState<any[]>([]);

  const [checkAll, setCheckAll] = useState(true);
  const [checkAgent, setCheckAgent] = useState(false);
  const [checkCorporate, setCheckCorporate] = useState(false);

  const [partnerSearchInput, setPartnerSearchInput] = useState("");

  const [display, setDisplay] = useState(false);
  const [index, setIndex] = useState<any>(null);
  const [loader, setLoader] = useState(true);
  const [datafilter, setDataFilter] = useState(1);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [defaultMsg, setDefaultMsg] = useState("No data found");

  const { company_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const pageSize = [10, 15, 20, 25, 30];
  // const pageSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const [editPartner, setEditPartner] = useState<any>(null);

  // Handler function to construct custom Page Range-- Pagination
  useEffect(() => {
    // setIsLoading(true);
    if (noOfPages < 7) {
      let arr: number[] = [];
      for (let i = 0; i < noOfPages; i++) {
        arr.push(i + 1);
      }
      setCustomPageRange(arr);
    } else {
      if (currentPageNo < 4) {
        let arr: any[] = [1, 2, 3, 4, 5, "z", noOfPages];
        setCustomPageRange(arr);
      } else if (currentPageNo > noOfPages - 3) {
        let arr: any[] = [
          1,
          "y",
          noOfPages - 4,
          noOfPages - 3,
          noOfPages - 2,
          noOfPages - 1,
          noOfPages,
        ];
        setCustomPageRange(arr);
      } else {
        let arr: any[] = [
          1,
          "y",
          currentPageNo - 1,
          currentPageNo,
          currentPageNo + 1,
          "z",
          noOfPages,
        ];
        setCustomPageRange(arr);
      }
    }
    // setIsLoading(false);
  }, [currentPageNo, noOfPages]);

  // Current page from custom page range
  const currentPageNoFromCustom = (item: any) => {
    if (item === "y") {
      setCurrentPageNo(currentPageNo - 3);
    } else if (item === "z") {
      setCurrentPageNo(currentPageNo + 3);
    } else {
      setCurrentPageNo(item);
    }
  };

  useEffect(() => {
    getPartners();
  }, [datafilter]);

  const getPartners = async () => {
    setLoader(true);
    try {
      const res = await beApi.get(`select-all-partner/${hotel_id}`);
      if (res.status === 200) {
        setLoader(false);
        // setOrganisationList(res.data);

        const data = await res.data.filter((el) => el.is_active == datafilter);

        setSelectedOrgList(data);
        setNoOfPages(Math.ceil(data.length / selectPageSize));
      } else {
        setLoader(false);
        setOrganisationList([]);
        setSelectedOrgList([]);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MSG);
    }
  };

  useEffect(() => {
    setNoOfPages(Math.ceil(selectedOrgList.length / selectPageSize));
    setCurrentPageNo(1);
  }, [selectedOrgList]);

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const setInputType = (e: any) => {
    if (e.target.value === "all") {
      setCheckAll(true);
      setCheckAgent(false);
      setCheckCorporate(false);
    } else if (e.target.value === "agent") {
      setCheckAll(false);
      setCheckAgent(true);
      setCheckCorporate(false);
    } else if (e.target.value === "corporate") {
      setCheckAll(false);
      setCheckAgent(false);
      setCheckCorporate(true);
    }
  };

  const deleteSelectedPartnerData = async (ind: number, Iactive: number) => {
    dispatch(CancelConfirmationPrompt());
    try {
      const res = await beApi.get(
        `${Iactive ? "remove-partner" : "active-partner"}/${hotel_id}/${ind}`
      );
      if (res.data.status === 1) toast.success(res.data.message);
      else toast.error(res.data.message);
      getPartners();
    } catch {
      toast.error(CATCH_MSG);
    }
  };

  const openTheConfirmationPopup = (Id: any, Iactive: number) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure you want to ${
          Iactive ? "deactivate" : "activate"
        } the partner?`,
        `${Iactive ? "DELETE" : "UPDATE"}`,
        () => deleteSelectedPartnerData(Id, Iactive),
        ["No", "Yes"]
      )
    );
  };
  // =======================Bulk Upload============================================================
  const [editBulkUpload, setEditBulkUpload] = useState<any>(null);
  const [bulkUploadSliderOpen, setBulkUploadSliderOpen] = useState(false);
  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs content={[{ label: "Partner" }]} />
        <div className="manage__bookings__action__right manage__actions__wrapper">
          <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Tooltip title={"Active"} placement="top" arrow>
                    <a
                      onClick={() => setDataFilter(1)}
                      className="nav-link active"
                      id="list-tab"
                      data-bs-toggle="tab"
                      data-bs-target="list"
                      aria-controls="list"
                      role="tab"
                      aria-selected="true"
                    >
                      Active
                    </a>
                  </Tooltip>
                </li>
                <li className="nav-item" role="presentation">
                  <Tooltip title={"Inactive"} placement="top" arrow>
                    <a
                      onClick={() => setDataFilter(0)}
                      className="nav-link"
                      id="crs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="crs"
                      aria-controls="crs"
                      role="tab"
                      aria-selected="true"
                    >
                      Inactive
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
          {/* ----- */}
        </div>
      </div>

      <div className="hr--line"></div>
      <div
        style={{ display: "flex", justifyContent: "end" }}
        className="new__booking__btn__wrapper"
      >
        <div style={{ width: "140px", marginRight: "10px" }}>
          <Button
            className="new__bookings"
            // icon="bi bi-plus-lg"
            handleClick={() => {
              setEditBulkUpload(null);
              setBulkUploadSliderOpen(true);
            }}
          >
            Bulk Upload
          </Button>
        </div>

        <div style={{ width: "100px" }}>
          <Button
            className="new__bookings"
            icon="bi bi-plus-lg"
            handleClick={() => {
              setEditPartner(null);
              setAddSliderOpen(true);
            }}
          >
            Add
          </Button>
        </div>
      </div>

      {loader ? (
        <div style={styles.emptyDivStyles}>
          <Loader />
        </div>
      ) : selectedOrgList.length ? (
        <>
          <div className="list-view-table-wrapper mb-4">
            {/* -------------Organisations Mapping--------------- */}
            <table className="table table-borderless">
              <thead>
                <tr className="listview__table__heading">
                  <th className="ms-3">Type</th>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Phone No</th>
                  <th>Email</th>
                  <th>GST</th>
                  <th style={{ textAlign: "center" }}>Rate Mapping</th>
                  <th style={{ textAlign: "center" }}>Policy Mapping</th>
                  <th style={{ textAlign: "center" }}>Edit</th>
                  {/* <th>Remove</th> */}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrgList
                  ?.slice(
                    selectPageSize * (currentPageNo - 1),
                    selectPageSize * currentPageNo
                  )
                  // .reverse()
                  .map((item: any, i: number) => (
                    <React.Fragment key={i}>
                      <tr
                        className="listview__table__data"
                        onClick={(e: any) => {
                          if (
                            e.target.className.includes(
                              "bi-three-dots-vertical"
                            )
                          ) {
                            setIndex(i);
                            setDisplay(!display);
                          }
                          // else {
                          //     setIndex(null)
                          //     setDisplay(false)
                          // }
                          window.addEventListener("click", function (e: any) {
                            if (
                              !e.target.className.includes(
                                "bi-three-dots-vertical"
                              )
                            ) {
                              setIndex(null);
                              setDisplay(false);
                            }
                          });
                        }}
                      >
                        <td>
                          <div className="customer-details">
                            {Capitalize(item.partner_type)}
                          </div>
                        </td>
                       
                        <td>
                          <div className="customer-details">
                            <Tooltip
                              title={item.partner_name}
                              placement="top"
                              arrow
                            >
                              <span>
                                {item.partner_name.length <= 15
                                  ? Capitalize(item.partner_name)
                                  : Capitalize(item.partner_name).slice(0, 15) +
                                    "..."}
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {Capitalize(item?.company_name)}
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {item.contact_no}
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">
                            <Tooltip
                              title={item.partner_name}
                              placement="top"
                              arrow
                            >
                              <span>
                                {item.email_id.length <= 20
                                  ? item.email_id
                                  : item.email_id.slice(0, 20) + "..."}
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {item.gstin ? item.gstin : "N.A"}
                          </div>
                        </td>
                        {/* <td style={{ position: 'relative' }}>
                                                            <div className="customer-details max-width">
                                                                {item.gstin ? item.gstin : 'N.A'}
                                                                <i className="bi bi-three-dots-vertical" style={{ position: 'absolute', right: "0", top: '0', transform: "translate(0%,40%)" }} ></i>

                                                                <div className="otherpOtions" style={{ display: display && i === index ? "flex" : 'none' }}>
                                                                    <div onClick={() => navigate('/bookings')}>New Booking</div>
                                                                    <div onClick={() => navigate(`/partner-rate-mapping/${item.id}`)}>Rate Mapping</div>
                                                                    <div onClick={() => openTheConfirmationPopup(item.id)}>Remove</div>
                                                                </div>
                                                            </div>
                                                        </td> */}
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customer-details"
                          >
                            <button
                              onClick={() =>
                                navigate(
                                  `/partner-rate-mapping/${item.id}/${item.partner_name}`
                                )
                              }
                              style={{
                                color: "white",
                                backgroundColor: "#223189",
                                marginTop: "0.5rem",
                                width: "fit-content",
                                padding: "0.4rem",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                borderRadius: "10px",
                              }}
                            >
                              <i className="bi bi-clipboard-data"></i>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customer-details"
                          >
                            <button
                              onClick={() =>
                                navigate(
                                  `/partner-policy-mapping/${item.id}/${item.partner_name}`
                                )
                              }
                              style={{
                                color: "white",
                                backgroundColor: "#223189",
                                marginTop: "0.5rem",
                                width: "fit-content",
                                padding: "0.4rem",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                borderRadius: "10px",
                              }}
                            >
                              <i className="bi bi-card-checklist"></i>
                            </button>
                          </div>
                        </td>

                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customer-details"
                          >
                            <button
                              onClick={() => {
                                setEditPartner(item);
                                setEditSliderOpen(true);
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "#4AD933",
                                marginTop: "0.5rem",
                                width: "fit-content",
                                padding: "0.4rem",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                borderRadius: "10px",
                              }}
                            >
                              {/* <i className="bi bi-pencil-square"></i> */}
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          </div>
                        </td>
                        {/* <td>
                                                            <div className="customer-details">
                                                                <h4 onClick={() => openTheConfirmationPopup(item.id)} style={{ color: "red" }}><i className="bi bi-trash3"></i></h4>
                                                            </div>
                                                        </td> */}
                        <td>
                          <div
                            className="customer-details"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="toogle__switch">
                              <div className="form-check form-switch custom-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={item?.is_active}
                                  onChange={() =>
                                    openTheConfirmationPopup(
                                      item.id,
                                      item?.is_active
                                    )
                                  }
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            <div className="list--view--pagination">
              <div className="pagination__left">
                <div className="pagination__dropdown">
                  <InputSelectField
                    label="Select"
                    value={selectPageSize}
                    options={pageSize}
                    handleChange={(e) => {
                      setSelectPageSize(e.target.value);
                      setNoOfPages(
                        Math.ceil(selectedOrgList.length / e.target.value)
                      );
                      setCurrentPageNo(1);
                    }}
                  />
                </div>
                <ul style={{ alignItems: "center" }}>
                  {/* {Array(noOfPages)
                                            .fill(0)
                                            .map((p, i) => {
                                                const num = i + 1
                                                return (
                                                    <>
                                                        <li
                                                            key={i}
                                                            onClick={() => setCurrentPageNo(num)}
                                                            className={`${num === currentPageNo
                                                                ? "active__page"
                                                                : "showing__page__number"
                                                                }`}
                                                            style={(num > currentPageNo + 1 && num < (Array(noOfPages).length - 1)) || num < currentPageNo ? { display: 'none' } : {}}
                                                        >
                                                            {num}
                                                        </li>
                                                        <div style={num === currentPageNo + 1 && num < (Array(noOfPages).length - 1) ? { marginLeft: "0.9rem" } : { display: "none" }}>.....</div>
                                                    </>
                                                );
                                            })} */}
                  {customPageRange.map((item, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => currentPageNoFromCustom(item)}
                        className={`${
                          item === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                        }`}
                      >
                        {typeof item === "string" ? "..." : item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="pagination__right">
                <div className="arrow__container me-3" onClick={leftArrowClick}>
                  <i className="bi bi-chevron-left font--weight"></i>
                </div>
                <div className="arrow__container" onClick={rightArrowClick}>
                  <i className="bi bi-chevron-right font--weight"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={styles.emptyDivStyles}>{defaultMsg}</div>
      )}

      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={addSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddSliderOpen(false)}
      >
        <AddPartnerSliderContent
          onClose={() => {
            setAddSliderOpen(false);
          }}
          runTheFunc={() => {
            getPartners();
          }}
          // editPartner={editPartner}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={bulkUploadSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setBulkUploadSliderOpen(false)}
      >
        <PartnerBulkUpload
          onClose={() => {
            setBulkUploadSliderOpen(false);
          }}
          // runTheFunc={() => { getPartners() }}
          // editPartner={editPartner}
        />
      </SlidingPane>
      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={editSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditSliderOpen(false)}
      >
        <EditPartnerSliderContent
          onClose={() => {
            setEditSliderOpen(false);
          }}
          runTheFunc={() => {
            getPartners();
          }}
          editPartner={editPartner}
        />
      </SlidingPane>
    </>
  );
};

const styles = {
  emptyDivStyles: {
    height: "500px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "lightgray",
  },
};

export default Partner;
