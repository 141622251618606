import React, { useEffect, useState } from "react";
import { RootState } from "../../Redux/store";
import { useDispatch, useSelector } from "react-redux";
import kernelApi from "../../API/kernelApi";
import { Logout } from "../../UtilityFunctions";

const ManagePropertyDropdown = () => {
  const { company_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  const [showManageDropdown, setShowManageDropdown] = useState(false);
  const [hotelName, setHotelName] = useState<string>("");

  useEffect(() => {
    getHotelName(auth_token, company_id, hotel_id);
  }, [auth_token, company_id, hotel_id]);

  // Api To Get Hotel Name
  const getHotelName = async (
    auth_token: any,
    company_id: any,
    hotel_id: any
  ) => {
    try {
      const resp = await kernelApi.get(
        `superadmin-dashboard/retrive-details/${company_id}`,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );
      const hotelName = resp.data.details.filter((obj: any) => {
        if (obj.hotel_id == hotel_id) return obj;
      })[0].hotel_name;
      setHotelName(hotelName);
    } catch (error: any) {
      // console.log(error);
      if (error.response.status === 401) {
        Logout();
      }
    }
  };

  document.addEventListener("mouseup", function (e: any) {
    let trigger = document.getElementById("manage-dropdown-trigger");
    var container = document.getElementById("manage-dropdown");
    if (
      container &&
      !container.contains(e.target) &&
      trigger &&
      !trigger.contains(e.target)
    ) {
      setShowManageDropdown(false);
    }
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.5rem 1rem",
          borderRadius: "0.5rem",
        }}
      >
        {hotel_id ? (
          <>
            <i
              className="bi bi-building"
              style={{ color: "#223189", marginRight: "0.75rem" }}
            ></i>
            <div
              style={{
                color: "#223189",
                fontWeight: "700",
                fontSize: "1rem",
              }}
            >
              {hotelName ? hotelName : "HotelName"}
            </div>
            <div
              style={{
                color: "#223189",
                fontWeight: "700",
                fontSize: "1rem",
              }}
            >
              {` (${hotel_id})`}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ManagePropertyDropdown;
