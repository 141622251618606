import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment, { weekdays } from "moment";
import { calendarPickerClasses } from "@mui/lab";
import beApi from "../../API/beApi";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import endPoints from "../../API/endPoints";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import bedIconBlack from "../../../src/assets/image/bed-icon-black.svg";
import bedIconWhite from "../../../src/assets/image/bed-icon-white.svg";

// import CalendarSkeleton from "../../Views/loader/CalendarSkeleton";

const OccupancyBaseCalender = () => {
  const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
  const { occupancyPercentageApi } = endPoints.DASHBOARD;
  const [currentMonth, setCurrentMonth] = useState<any>(moment()); // Past seven days
  const [nextMonth, setNextMonth] = useState<any>(moment().add(1, "months"));
  const [calendar, setCalendar] = useState<any[]>([]);
  const [nextCalendar, setNextCalendar] = useState<any[]>([]);
  const [isSelectedDate, setIsSelectedDate] = useState<any>(moment());
  const [occupancyPercentage, setOccupancyPercentage] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);



  // Function To Build Calendar Data Array
  const buildCalender = (value: any) => {
    const startDay = value
      .clone()
      .startOf("month")
      // .startOf("week")
      .isoWeekday(1);
    const endDay = value.clone().endOf("month").endOf("week").isoWeekday(6);
    let calendar: any[] = [];
    let date = startDay.clone().subtract(1, "day");
    while (date.isBefore(endDay, "day"))
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => date.add(1, "day").clone())
      );
    return calendar;
  };

  useEffect(() => {
    setCalendar(buildCalender(currentMonth));
  }, [currentMonth]);
  useEffect(() => {
    setNextCalendar(buildCalender(nextMonth));
  }, [nextMonth]);



  //Helper Function For Conditional Styling of Same and Before Day and Next Month Day
  const isSelected = (day: any, val: any) => {
    return val.isSame(day, "day");
  };
  const beforeAfterMonth = (day: any, val: any) => {
    if (Number(val.format("M")) === Number(day.format("M"))) {
      // return day.isBefore(new Date(), "day");
      return false;
    } else {
      return true;
    }
  };
  const today = (day: any) => {
    return day.isSame(new Date(), "day");
  };
  const isBeforToday = (day: any, val: any) => {
    // Moment Object Check that the 1st value is after the 2nd value and return true or else return false
    if (moment().isAfter(day) && !day.isSame(new Date(), "day")) {
      return true;
    } else {
      return false;
    }

  }
  const dayStyles = (day: any, val: any) => {
    if (beforeAfterMonth(day, val)) return "before";
    // if (isSelected(day, val)) return "selected";
    // if (today(day)) return "today";
    return "";
  };

  // Helper Function To Change Month on Arrow Selection
  const prevMonthHandler = () => {
    setCurrentMonth(currentMonth.clone().subtract(2, "month"));
    setNextMonth(currentMonth.clone().subtract(1, "month"));
  };
  const nextMonthHandler = () => {
    setCurrentMonth(currentMonth.clone().add(2, "month"));
    setNextMonth(currentMonth.clone().add(3, "month"));
  };

  // Api To Get Calendar Occupancy Percentage
  useEffect(() => {
    getOccupancyPercentage();
  }, [hotel_id, currentMonth, nextMonth]);

  const getOccupancyPercentage = async () => {
    setLoader(true);
    try {
      let percentageRes = await beApi.get(
        `${occupancyPercentageApi}/${hotel_id}/${currentMonth
          .startOf("month")
          .format("DD-MM-YYYY")}/${nextMonth
            .endOf("month")
            .format("DD-MM-YYYY")}`
      );

      if (percentageRes.data.status === 1) {
        setOccupancyPercentage(percentageRes.data.data);
        setLoader(false);
      } else {
        setOccupancyPercentage([]);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  // Checking This Month Is Not Prev of Current Month For Arrow Rendering
  const thisMonth = () => {
    return currentMonth.isSame(new Date(), "month");
  };

  return (
    <>
      <div className="dashboard__standard__header pt-1 mb-4 mt-4">
        <div className="dashboard__standard__label">
          Availability Calendar
        </div>
        <div className="pagination__right">
          {
            !thisMonth() ? (
              <div className="arrow__container me-3" onClick={() => prevMonthHandler()}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
            ) : (null)
          }

          <div className="arrow__container" onClick={() => nextMonthHandler()}>
            <i className="bi bi-chevron-right font--weight"></i>
          </div>
        </div>

      </div>
      {loader ? (
        <Skeleton count={20} />
      ) : (
        <div className="occupancy_calender_wrapper mb-5">
          <div className="calender_left_month_wrapper">
            <div className="calender_month_name mb-3">
              {currentMonth.format("MMM")} {currentMonth.format("YYYY")}
            </div>
            <table className="calender_day_date_wrapper">
              <thead className="calender_day_wrapper">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day, idx) => {
                    return (
                      <tr key={idx} className="calendar_day_row">
                        <th>{day}</th>
                      </tr>
                    );
                  }
                )}
              </thead>
              <tbody className="calender_date_wrapper">
                {calendar.map((week) => (
                  <tr className="date_wrapper">
                    {week.map((day: any) => {
                      let data = occupancyPercentage?.length && occupancyPercentage?.filter(
                        (date: any) =>
                          date.currentDate ===
                          day.format("YYYY-MM-DD")
                      )[0];
                      return (
                        <th
                          className={`calender_date_th ${dayStyles(day, currentMonth) !== "before" &&
                            isSelectedDate.isSame(day, "day")
                            ? "selected"
                            : ""
                            }`}
                          onClick={() => {
                            if (dayStyles(day, currentMonth) !== "before" && !isBeforToday(day, currentMonth)) {
                              setIsSelectedDate(day);
                            }
                          }}
                        >

                          <div className={`${dayStyles(day, currentMonth)} calender_date_div_wrapper`} style={{
                            backgroundColor: `${dayStyles(day, currentMonth) !== "before" ? data?.color_code : ""}`,
                            color: `${data?.color_code === "#E64467" ? "#ffffff" : "#000000"}`,
                            display: `${(isBeforToday(day, currentMonth)) && "none"}`
                          }}>
                            <div
                              className={`${dayStyles(day, currentMonth)} day`} style={{ color: `${data?.color_code === "#E64467" ? "#ffffff" : "#000000"}` }}
                            >
                              {day.format("D").toString()}
                            </div>
                            <div
                              className={`${dayStyles(day, currentMonth)} available_room_price`}
                            >
                              <div className="available_room">
                                <i className="bi bi-house me-1"></i>
                                {data?.available_rooms}

                              </div>
                              <div className="min_price">
                                {String.fromCharCode(
                                  parseInt(data?.currency_symbol, 16)
                                )}
                                {data?.minimum_rates}
                              </div>

                            </div>
                          </div>

                        </th>
                      )
                    }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="vertical_line"></div>

          <div className="calender_right_month_wrapper">
            <div className="calender_month_wrapper mb-3">
              <div className="calender_month_name">
                {nextMonth.format("MMM")} {nextMonth.format("YYYY")}
              </div>

            </div>
            <table className="calender_day_date_wrapper">
              <thead className="calender_day_wrapper">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day, idx) => {
                    return (
                      <tr key={idx} className="calendar_day_row">
                        <th>{day}</th>
                      </tr>
                    );
                  }
                )}
              </thead>
              <tbody className="calender_date_wrapper">
                {nextCalendar.map((week) => (
                  <tr className="date_wrapper">
                    {week.map((day: any) => {
                      let data = occupancyPercentage?.length && occupancyPercentage?.filter(
                        (date: any) =>
                          date.currentDate ===
                          day.format("YYYY-MM-DD")
                      )[0];

                      return (
                        <th
                          className={`calender_date_th ${isSelectedDate.isSame(day, "day") ? "selected" : ""
                            } `}
                          onClick={() => {
                            if (dayStyles(day, nextMonth) !== "before") {
                              setIsSelectedDate(day);
                            }
                          }}
                        >
                          <div className={`${dayStyles(day, nextMonth)} calender_date_div_wrapper`} style={{
                            backgroundColor: `${dayStyles(day, nextMonth) !== "before" ? data?.color_code : ""}`,
                            color: `${data?.color_code === "#E64467" ? "#ffffff" : "#000000"}`,

                          }}>
                            <div
                              className={`${dayStyles(day, nextMonth)} day`} style={{ color: `${data?.color_code === "#E64467" ? "#ffffff" : "#000000"}` }}
                            >
                              {day.format("D").toString()}
                            </div>
                            <div
                              className={`${dayStyles(day, nextMonth)} available_room_price`}>
                              <div className="available_room">
                                <i className="bi bi-house me-1"></i>

                                {data?.available_rooms}
                              </div>
                              <div className="min_price">
                                {String.fromCharCode(
                                  parseInt(data?.currency_symbol, 16)
                                )}
                                {data?.minimum_rates}
                              </div>
                            </div>
                          </div>

                        </th>
                      )
                    }
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
      }
    </>
  );
};

export default OccupancyBaseCalender;
