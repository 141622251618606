import React from 'react'

export interface ICalendarSkeletonProps {
    rows?: number
    cols?: number
}

const CalendarSkeleton: React.FC<ICalendarSkeletonProps> = ({ rows, cols }) => {
    return (
        <div className="rates__data__row rates__data__flex rates__border__bottom">
            {Array(rows ? rows : 4).fill(0).map((_, i) => {
                return (
                    <div key={i} className={`all__channel__prices ${i === (rows ? rows - 1 : 3) ? "" : "rates__border__bottom"}`}>
                        <div className="rates__image__header rates__border__right">
                            <div className="loader__line__container">
                                <div className="content__line__loader skeleton"></div>
                            </div>
                        </div>
                        <div className="rates__label__datas">
                            {Array(cols ? cols : 10).fill(0).map((_, j) => {
                                return (
                                    <div key={j} className="rates__dates__wrapper rates__border__right">
                                        <div className="loader__line__container">
                                            <div className="content__line__loader skeleton"></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default CalendarSkeleton