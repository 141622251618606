import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import { RootState } from "../../Redux/store";
import Card from "../../Views/cardwrapper/Card";

const RecentBookings = () => {
  const { crsRecentBooking } = endPoints.DASHBOARD;
  const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
  const [recentBookkings, setRecentBookings] = useState<any[]>([]);
  const [noRecentBookings, setNoRecentBookings] = useState<string>("");

  useEffect(() => {
    getRecentBookings();
  }, [hotel_id]);

  // Api To Get Recent Bookings
  const getRecentBookings = async () => {
    try {
      let res = await beApi.get(`${crsRecentBooking}/${hotel_id}`);
      if (res.data.status === 1) {
        setRecentBookings(res.data.data);
      } else {
        setRecentBookings([]);
        setNoRecentBookings(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="dashboard__standard__header pt-1 mb-2 mt-1">
        <div className="dashboard__standard__label">Recent Bookings</div>
      </div>
      <div className="dashboard__rooms__cards">
        <Card className="dashboard__recent_bookings__card__wrapper">
          {recentBookkings?.length > 0 ?
            recentBookkings?.map((iteam, idx) => {
              return (
                <div className="dashboard__recent_bookings__card" key={idx}>
                  <div className="recent_bookings__date__wrapper">
                    <div className="recent_bookings__booking_date">
                      <i className="bi bi-calendar-week me-2"></i>
                      {iteam.check_in_out_at}
                    </div>
                    <div className="recent_bookings__today_wrapper">
                      <span className="recent_bookings_today">
                        {iteam.booking_days}
                      </span>
                      <span className="recent_bookings_today_date">
                        {iteam.display_booking_date}
                      </span>
                    </div>
                  </div>
                  <div className="recent_bookings_agent_corporate_name">
                    <i className="bi bi-file-earmark-person me-2"></i>
                    {iteam.partner_name}
                  </div>
                  <div className="recent-bookings-name">
                    <i className="bi bi-person me-2"></i>
                    {iteam.customer_name}
                  </div>
                  <div className="recent-bookings-number-wrapper">
                    <div className="recent-bookings-number">
                      <i className="bi bi-telephone me-2"></i>
                      {iteam.customer_phone}
                    </div>
                    <div className="recent-bookings-price">
                      {iteam.total_amount}
                    </div>
                  </div>
                </div>
              );
            }) : (
              <div className="no-data-label-container">
                <div className="icon-container">
                  <i className="bi bi-x-circle mb-3"></i>
                </div>
                <div className="no-data-label">
                  {noRecentBookings}
                </div>
              </div>
            )}
        </Card>
      </div>
    </>
  );
};

export default RecentBookings;
