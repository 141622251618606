export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHOOSE_PROPERTY = "CHOOSE_PROPERTY";
export const GET_PROPERTIES = "GET_PROPERTIES";

export const ACTIVE_SIDEBAR_ITEM = "ACTIVE_SIDEBAR_ITEM";
export const ACTIVE_SIDEBAR_SUBITEM = "ACTIVE_SIDEBAR_SUBITEM";
export const UPDATE_BREADCRUMBS = "UPDATE_BREADCRUMBS";
export const CANCEL_CONFIRMATION_PROMPT = "CANCEL_CONFIRMATION_PROMPT";
export const DISABLE_SWITCH_PROPERTY_SCREEN = "DISABLE_SWITCH_PROPERTY_SCREEN";
export const ENABLE_CONFIRMATION_PROMPT = "ENABLE_CONFIRMATION_PROMPT";
export const ENABLE_SWITCH_PROPERTY_SCREEN = "ENABLE_SWITCH_PROPERTY_SCREEN";

export const UPDATE_INV_BE_LOADER = "UPDATE_INV_BE_LOADER";
export const UPDATE_INV_CM_LOADER = "UPDATE_INV_CM_LOADER";
export const UPDATE_INV_BE_LOADER_DISABLE = "UPDATE_INV_BE_LOADER_DISABLE";
export const UPDATE_INV_CM_LOADER_DISABLE = "UPDATE_INV_CM_LOADER_DISABLE";

export const CUSTOMER_ID = "CUSTOMER_ID";
export const CUSTOMER_LIST = "CUSTOMER_LIST";

export const ORGANISATION_LIST = "ORGANISATION_LIST";

export const GROUP_LIST = "GROUP_LIST";

export const STATE_UPDATE_CUSTOMER_LIST = "STATE_UPDATE_CUSTOMER_LIST"

export const CUSTOMER_EMPTY = "CUSTOMER_EMPTY";
export const UPDATE_MEMBERS_ARRAY = "UPDATE_MEMBERS_ARRAY";


export const ORGANISATION_ADDED = "ORGANISATION_ADDED";


export const EDITGROUP = "EDITGROUP"

export const LOGIN_TYPE = 'LOGIN_TYPE'

export const USER_ACCESS_DATA = "USER_ACCESS_DATA";
export const USER_ADMIN_ACCESS = "USER_ADMIN_ACCESS";
export const USER_FULL_NAME_ACCESS = "USER_FULL_NAME_ACCESS";


// -------------------------------------------

// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_CREDENTAILS = "LOGIN_CREDENTAILS";
export const SINGLE_SIGN_ON = "SINGLE_SIGN_ON";
export const SAVE_SIGNUP_INFO = "SAVE_SIGNUP_INFO";
export const SAVE_SUBSCRPTION_PLAN = "SAVE_SUBSCRPTION_PLAN";

export const SAVE_PROPERTY_INFO = "SAVE_PROPERTY_INFO";

export const ADD_PROPERTY_TYPE = "ADD_PROPERTY_TYPE";
export const ADD_PROPERTY_SUBTYPE = "ADD_PROPERTY_SUBTYPE";
export const ADD_PROPERTY_DETAILS = "ADD_PROPERTY_DETAILS";
export const ADD_PROPERTY_ADDRESS = "ADD_PROPERTY_ADDRESS";
export const ADD_PROPERTY_LOCATION = "ADD_PROPERTY_LOCATION";
export const ADD_PROPERTY_AMENITIES = "ADD_PROPERTY_AMENITIES";
export const ADD_PROPERTY_IMAGES = "ADD_PROPERTY_IMAGES";
export const ADD_PROPERTY_RESET = "ADD_PROPERTY_RESET";
export const ADD_ROOM_TYPE_BASIC_DETAILS = "ADD_ROOM_TYPE_BASIC_DETAILS";
export const ADD_ROOM_TYPE_OCCUPANCY = "ADD_ROOM_TYPE_OCCUPANCY";
export const ADD_ROOM_TYPE_PRICE = "ADD_ROOM_TYPE_PRICE";
export const ADD_ROOM_TYPE_MEAL_PLAN = "ADD_ROOM_TYPE_MEAL_PLAN";
export const ADD_ROOM_TYPE_EP_PRICE_UPDATE = "ADD_ROOM_TYPE_EP_PRICE_UPDATE";
export const ADD_ROOM_TYPE_IMAGES = "ADD_ROOM_TYPE_IMAGES";
export const ADD_ROOM_TYPE_RESET = "ADD_ROOM_TYPE_RESET";
export const ADD_NUMBER_OF_FLOORS = "ADD_NUMBER_OF_FLOORS";
export const ADD_SERVICEABLE_FLOORS = "ADD_SERVICEABLE_FLOORS";
export const RESET_FLOOR_SELECTION = "RESET_FLOOR_SELECTION";


export const SAVE_BOOKING_DETAILS = "SAVE_BOOKING_DETAILS";
export const SAVE_BOOKING_CRS = "SAVE_BOOKING_CRS";
export const SAVE_STAY_DETAILS = "SAVE_STAY_DETAILS";
export const SAVE_BOOKING_INFO = "SAVE_BOOKING_INFO";
export const SAVE_BILLING_DETAILS = "SAVE_BILLING_DETAILS";
export const SAVE_BOOKING_ROOM_DETAILS = "SAVE_BOOKING_ROOM_DETAILS";
export const REMOVE_BOOKING_DETAILS = "REMOVE_BOOKING_DETAILS";

// Manage Channels
export const BE_URL = "BE_URL";
export const ALL_CHANNELS = "ALL_CHANNELS";
export const COUPON_ID = "COUPON_ID";
export const ALL_ROOM_TYPES = "ALL_ROOM_TYPES";
export const OTA_MANAGE = "OTA_MANAGE";

// Dashboard
export const DASHBOARD_HOTILER_DATA_TODAY = "DASHBOARD_HOTILER_DATA_TODAY";
export const DASHBOARD_HOTILER_DATA_MTD = "DASHBOARD_HOTILER_DATA_MTD";
export const DASHBOARD_HOTILER_DATA_YTD = "DASHBOARD_HOTILER_DATA_YTD";

// User Access
// export const USER_ACCESS_DATA = "USER_ACCESS_DATA";
// export const USER_ADMIN_ACCESS = "USER_ADMIN_ACCESS";

//Payment Gateway

export const SET_CURRENT_PAYMENT_GATEWAY_DETAILS =
    "SET_CURRENT_PAYMENT_GATEWAY_DETAILS";
export const SET_PAYMENT_ACTIVE = "SET_PAYMENT_ACTIVE";
export const SET_PAYMENT_INACTIVE = "SET_PAYMENT_INACTIVE";

export const SHOW_SETUP_PAYMENT_SLIDER = "SHOW_SETUP_PAYMENT_SLIDER";
export const HIDE_SETUP_PAYMENT_SLIDER = "HIDE_SETUP_PAYMENT_SLIDER";
export const SHOW_ADD_PAYMENT_SLIDER = "SHOW_ADD_PAYMENT_SLIDER";
export const HIDE_ADD_PAYMENT_SLIDER = "HIDE_ADD_PAYMENT_SLIDER";

export const SET_NEW_PAYMENT_MODE_ADDED = "SET_NEW_PAYMENT_MODE_ADDED";
export const SET_PAYMENT_MODE_TO_ACTIVE = "SET_PAYMENT_MODE_TO_ACTIVE";
export const SET_PAYMENT_MODE_TO_INACTIVE = "SET_PAYMENT_MODE_TO_INACTIVE";

// Preview Box
export const SHOW_PREVIEW_BOX = "SHOW_PREVIEW_BOX";
export const HIDE_PREVIEW_BOX = "HIDE_PREVIEW_BOX";

// Previe Box
export const UPDATED_ROOM_NOS = "UPDATED_ROOM_NOS";

// Get Client Image and details
export const GET_CLIENT_SECTION_DATA = "GET_CLIENT_SECTION_DATA";

// Save Full Name

// Save Chargeebee subscrtiption id
export const GET_CHARGEBEE_SUBSCRIPTION_ID = "GET_CHARGEBEE_SUBSCRIPTION_ID";


export const GET_PROPERTY_EMAILS = 'GET_PROPERTY_EMAILS'
export const GET_PROPERTY_MOBILES = 'GET_PROPERTY_MOBILES'

export const SAVE_PROPERTY_NEW_EMAILS = 'SAVE_PROPERTY_NEW_EMAILS'
export const SAVE_PROPERTY_NEW_MOBILES = 'SAVE_PROPERTY_NEW_MOBILES'



export const GET_HOTEL_NAME = 'GET_HOTEL_NAME'
