import React, { useEffect, useState } from "react";
import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../Views/buttons/Button";

import { useNavigate } from "react-router";
import { RootState } from "../../Redux/store";

import moment from "moment";
import beApi from "../../API/beApi";

import { useDispatch, useSelector } from "react-redux";
import {
  SaveBooking,
  SaveBookingCRS,
  SaveBookingDetailedInfo,
} from "../../Redux/actions/BookingsAction";

import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../Views/inputtextfield/InputMultiSelectField";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import gemsApi from "../../API/gemsApi";
import { toast } from "react-toastify";

import ModifyBooking from "./ModifyBooking";
import CancelBooking from "./CancelBooking";
import CalendarSkeleton from "../../Views/loader/CalendarSkeleton";
import NewBookings from "./NewBookings";
import endPoints from "../../API/endPoints";
import FetchBooking from "./FetchBookings";
import DataLoader from "../../Views/loader/DataLoader";
import Loader from "../../Views/loader/Loader";
import { Tooltip } from "@mui/material";
import ModifyCrsBooking from "./ModifyCrsBooking";
import DayBooking from "./DayBooking/DayBooking";

export const RoomBookingsList = (refreshData) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Bookings", "List View");

  const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
  const [bookingTab, setBookingTab] = useState("roombooking");
  const [booking_details, setBooking_details] = useState<any>();

  const [bookingDetails, setBookingDetails] = useState<any[]>([]);
  const [allSource, setAllSource] = useState<any[]>([]);
  const [allSourcesName, setAllSourcesName] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState<any[]>([]);

  const [searchInput, setSearchInput] = useState("");
  const [statusInput, setStatusInput] = useState("all");
  const [dateType, setDateType] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [startDate, setStartDate] = useState<any>(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState<any>(moment());
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [sortingOrder, setSortingOrder] = useState("ASC");
  const [isLoading, setIsLoading] = useState(false);
  const [isGems, setIsGems] = useState<boolean>(false);
  const [noDataFound, setNoDataFound] = useState(true);
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  const [openBookingDetailsSlider, setopenBookingDetailsSlider] =
    useState(false);
  const [openModifyBookingSlider, setopenModifyBookingSlider] = useState(false);
  const [openCancelBookingSlider, setopenCancelBookingSlider] = useState(false);
  const [openSlideNewBooking, setOpenSlideNewBooking] = useState(false);
  const [openSliderDayBooking, setOpenSlideDayBooking] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(true);
  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectCheckinDate, setSelectCheckinDate] = useState("");
  const [newSubTab, setNewSubTab] = useState<boolean>(false);
  const [refreshDataNewBooking, setRefreshDataNewBooking] = useState(true);

  const queryType = {
    "Booking Date": 1,
    "Check-in Date": 2,
    "Check-out Date": 3,
  };

  const pageSize = [10, 15, 20, 25, 30];
  const { auth_token, role_id } = useSelector((state: RootState) => state.auth);

  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);
  const {
    isgemsSubscribed,
    listViewBooking,
    bookingDetailsGet,
    bookingDetailsCRSGet,
    allSources,
    stayDetailsByBookingIdGetV2,
    downloadCrsVoucher,
  } = endPoints.BOOKINGS;

  useEffect(() => {
    const status = accessData.filter((iteam: any) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
      ? setAccess(true)
      : setAccess(false);
  }, [accessData]);

  useEffect(() => {
    bookingListView();
  }, [
    hotel_id,
    startDate,
    endDate,
    dateType,
    selectedSource,
    statusInput,
    refreshTrigger,
    refreshDataNewBooking,
    // refreshData
  ]);

  useEffect(() => {
    isGemsSubscribed();
  }, [hotel_id]);
  // Api To Check Is Gems Subscribed Or Note
  const isGemsSubscribed = async () => {
    try {
      let gemsRes = await gemsApi.get(`${isgemsSubscribed}/${hotel_id}`);

      if (gemsRes?.data?.status === 1) {
        gemsRes?.data?.message.toLowerCase() === "subscribed" &&
          setIsGems(true);
      } else {
        setIsGems(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSource();
  }, [hotel_id]);

  useEffect(() => {
    setNoOfPages(
      Math.ceil(listViewFilter(bookingDetails).length / selectPageSize)
    );
    if (noOfPages === 0) {
      setNoDataFound(true);
    } else if (noOfPages > 0) {
      setNoDataFound(false);
    }
  }, [selectedSource, searchInput, statusInput, noOfPages]);

  // API call fot getting the booking details
  const bookingListView = async () => {
    setIsLoading(true);
    setNoDataFound(false);
    let arr: any[] = [];
    allSource.forEach((source) => {
      if (selectedSource.includes(source.ota_name)) {
        arr.push(source.ota_id);
      }
    });

    try {
      let response = await beApi.post(`${listViewBooking}`, {
        from_date: moment(startDate).format("YYYY-MM-DD"),
        to_date: moment(endDate).format("YYYY-MM-DD"),
        date_type: dateType.toString(),
        booking_status: statusInput,
        hotel_id: hotel_id.toString(),
        booking_id: "",
        sales_executive_id: 0,
        admin_id: admin_id,
      });

      if (response.data.status === 1) {
        let res = response.data.data;
        setBookingDetails(res);
        setNoOfPages(Math.ceil(res.length / selectPageSize));
        setIsLoading(false);
        setNoDataFound(false);
        setLoader(false);
      } else if (response.data.status === 0) {
        setNoDataFound(true);
        setIsLoading(false);
        setBookingDetails([]);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    setNoDataFound(false);
  };

  const getBookingDetails = async (bookingId: string) => {
    try {
      const response = await gemsApi.post(`${bookingDetailsGet}`, {
        hotel_id: hotel_id,
        user_id: admin_id,
        booking_id: bookingId,
      });

      if (response.data.status === 1) {
        dispatch(SaveBooking(response.data));
        setTimeout(() => {
          navigate("/bookings/check-in");
        }, 250);
      }
    } catch (error) {
      toast("Booking details not found", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const getBookingDetailsCRS = async (booking: any) => {
    try {
      const response = await beApi.post(
        `${bookingDetailsCRSGet}`,
        {
          booking_id: booking.unique_id,
          booking_source: booking.channel_name,
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        setBooking_details(response.data.data);
        dispatch(SaveBookingCRS(response.data.data));
        setopenBookingDetailsSlider(true);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  // API call for getting all the source
  const getAllSource = async () => {
    let res = await beApi.get(`${allSources}/${hotel_id}`);
    if (res.data.status === 1) {
      let sources = res.data.data;
      setAllSource(sources);

      let otaname: string[] = [];
      let otaid: number[] = [];
      sources.forEach((source: any) => {
        if (source.ota_name === "CRS") {
          otaname.push(source.ota_name);
        }
        otaid.push(source.ota_id);
      });

      setAllSourcesName(otaname);
      setSelectedSource(otaname);
    }
  };

  const searchOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === "" && noOfPages > 0) {
      setNoDataFound(false);
    }
    setSearchInput(val);
  };

  // selected sources values
  const isAllSelected =
    allSourcesName.length > 0 &&
    selectedSource.length === allSourcesName.length;

  const sourceOnchange = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedSource(
        selectedSource.length === allSourcesName.length ? [] : allSourcesName
      );
      return;
    }
    setSelectedSource(value);
  };

  // Filter function
  const listViewFilter = (data: any[]) => {
    return data?.filter((book: any) => {
      return (
        book.unique_id.toString().includes(searchInput) ||
        book.customer_details
          .split(",")[0]
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
  };

  // Sorting
  const sortByClick = (date: string) => {
    if (sortingOrder === "ASC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate > newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("DEC");
    } else if (sortingOrder === "DEC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate < newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("ASC");
    }
  };

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };

  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const getBookingDetailsById = async (bookingId: string) => {
    // dispatch(RemoveBooking())
    try {
      const response = await gemsApi.post(`${stayDetailsByBookingIdGetV2}`, {
        user_id: admin_id,
        booking_id: bookingId,
        hotel_id: hotel_id,
      });

      if (response.data.status) {
        dispatch(SaveBookingDetailedInfo(response.data));
      }
    } catch (error) {
      toast("Booking details not found", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const onModifyBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenModifyBookingSlider(true);
  };

  const onCheckinClick = () => {
    setopenBookingDetailsSlider(false);
    getBookingDetails(booking_details?.bookingid);
    getBookingDetailsById(booking_details?.bookingid);
  };

  const onCancelBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenCancelBookingSlider(true);
  };

  const onGenerateInvoice = async () => {};

  //this function modifies MUI styling issue
  // const modifyInputBoxStyle = () => {
  //   const boxes = document.querySelectorAll<HTMLElement>(
  //     ".MuiOutlinedInput-notchedOutline"
  //   );
  //   for (let i = 0; i < 3; i++) {
  //     boxes[i]?.classList.add(`crs-view-inputBox-${i}`);
  //   }

  //   setTimeout(() => {
  //     const box1 = document.querySelector<HTMLElement>(`.crs-view-inputBox-0`)!;
  //     const box2 = document.querySelector<HTMLElement>(`.crs-view-inputBox-1`)!;
  //     const box3 = document.querySelector<HTMLElement>(`.crs-view-inputBox-2`)!;

  //     box1.style.padding = "0 90px";
  //     box2.style.padding = "0 70px";
  //     box3.style.padding = "0 60px";
  //   }, 1000);
  // };
  // modifyInputBoxStyle();
  // --------------------------------------

  const downloadBookingVoucher = async () => {
    setIsPDFLoading(true);
    try {
      const response = await beApi.get(
        `${downloadCrsVoucher}${booking_details?.bookingid}`
      );

      if (response.data.status === 1) {
        // Parse the 'data' key value and extract the PDF base64 string
        let pdfData = JSON.parse(response.data.data).pdf;

        // Decode base64 string
        let byteCharacters = atob(pdfData);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);

        // Make a Blob from the bytes
        let blob = new Blob([byteArray], { type: "application/pdf" });

        // Create a URL for the Blob
        let url = window.URL.createObjectURL(blob);

        // Create a hidden anchor (<a>) tag, set the href to the Blob URL, and simulate clicking it.
        var link = document.createElement("a");
        link.href = url;
        link.download = `${booking_details?.bookingid}.pdf`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsPDFLoading(false);
    } catch (error) {
      console.log(error);
      setIsPDFLoading(false);
    }
  };
  return (
    <div>
      <div className="calendar__filter__wrapper">
        <div className="search__booking__wrapper">
          <i className="bi bi-search"></i>
          <input
            placeholder="Search Bookings"
            value={searchInput}
            onChange={(e) => searchOnchange(e)}
          />
        </div>
        <div className="listview__datepicker__wrapper me-3">
          <div className="date__dropdown__wrapper mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={dateType}
              handleChange={(e) => setDateType(e.target.value)}
              options={Object.keys(queryType)}
              values={Object.values(queryType)}
            />
          </div>
        </div>

        <div
          className="listview__select__date__wrapper"
          onClick={() => setFocusedInput("startDate")}
        >
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            minimumNights={0}
            // preventPastDates={true}
          />
        </div>

        <div className="dropdown__right__wrapper">
          <div className="status__dropdown__wrapper me-3 custom--width mui__input__fieldset__legends__unset">
            <InputMultiSelectField
              label=""
              value={selectedSource}
              handleChange={(evnt) => sourceOnchange(evnt)}
              options={allSourcesName}
              isAllSelected={isAllSelected}
            />
          </div>
          <div className="status__dropdown__wrapper mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={statusInput}
              handleChange={(e) => setStatusInput(e.target.value)}
              options={["All Status", "Confirmed", "Cancelled"]}
              values={["all", "confirm", "cancelled"]}
            />
          </div>

          {/* <div className="change__calendar__filter"></div> */}
        </div>
        <div className="manage__bookings__action__right">
          {role_id != 13 && (
            <div className="calendar__view__wrapper">
              <div className="calendar__view__tab">
                <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Tooltip title={"List View"} placement="top" arrow>
                      <a
                        className="nav-link active"
                        id="list-tab"
                        data-bs-toggle="tab"
                        data-bs-target="list"
                        aria-controls="list"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="bi bi-grid-3x3-gap"></i>
                      </a>
                    </Tooltip>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Tooltip title={"Calendar View"} placement="top" arrow>
                      <a
                        onClick={() => navigate("/bookings/crs-view")}
                        className="nav-link"
                        id="crs-tab"
                        data-bs-toggle="tab"
                        data-bs-target="crs"
                        aria-controls="crs"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="bi bi-calendar2-range"></i>
                      </a>
                    </Tooltip>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                  <a
                    onClick={() =>
                      navigate(
                        `/bookings/${isGems ? "gems/frontoffice-view" : "frontoffice-view"
                        }`
                      )
                    }
                    className="nav-link"
                    id="pms-tab"
                    data-bs-toggle="tab"
                    data-bs-target="pms"
                    aria-controls="pms"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="bi bi-calendar-week"></i>
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="list-view-table-wrapper">
        {/* <table className="table table-borderless"> */}
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Loader />
          </div>
        ) : noDataFound ? (
          <div className="table">
            <div className="empty__room__div mt-5">
              <i className="bi bi-x-circle mb-3"></i>
              <div>You don't have any bookings yet.</div>
            </div>
          </div>
        ) : (
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>Booking ID</th>
                <th>Source</th>
                <th onClick={() => sortByClick("booking_date")}>
                  <div className="poniter">
                    Booking Date
                    <i
                      className={`bi bi-caret-${
                        sortingOrder === "ASC" ? "down" : "up"
                      }-fill`}
                    ></i>
                  </div>
                </th>
                <th>Booker Details</th>
                <th>Nights</th>
                <th>Rooms</th>
                <th>Check-in/out</th>
                <th>Payment Status</th>
                <th>Booking Status</th>
              </tr>
            </thead>
            <tbody>
              {listViewFilter(bookingDetails)
                ?.slice(
                  selectPageSize * (currentPageNo - 1),
                  selectPageSize * currentPageNo
                )
                .map((item: any, id: number) => (
                  <tr key={id} className="listview__table__data">
                    <td>
                      <div
                        onClick={() => getBookingDetailsCRS(item)}
                        className="booking__id d-flex align-items-center justify-content-start"
                      >
                        {item.unique_id}
                        {item.business_booking === 1 && (
                          <span className="business__booking__wrapper ms-2">
                            <i className="bi bi-buildings"></i>
                          </span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="listview__image">
                        <img src={item.logo} />
                      </div>
                    </td>
                    <td>
                      <div className="booking__date ps-0">
                        {item.display_booking_date}
                      </div>
                    </td>
                    <td>
                      <div className="guest">
                        {item.customer_details.split(",")[0]}
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <i className="bi bi-moon-fill"></i>
                        <span className="ms-2">{item.nights}</span>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <span className="ms-2">{item.no_of_rooms}</span>
                      </div>
                    </td>
                    <td>
                      <div className="check__in__out">{`${moment(
                        item.checkin_at
                      ).format("DD MMM")}-${moment(item.checkout_at).format(
                        "DD MMM YY"
                      )}`}</div>
                    </td>
                    <td>
                      <div className={`${item?.payment_status_color}`}>
                        {`${item?.payment_status}`}
                      </div>
                    </td>
                    <td>
                      <div></div>
                      <div
                        className={`${
                          item.btn_status === "Confirmed"
                            ? "confirmed__label"
                            : "cancelled"
                        }`}
                      >
                        {item.btn_status}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!isLoading && !noDataFound && noOfPages !== 0 && (
          <div className="list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label=""
                  value={selectPageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setCurrentPageNo(1);
                    setNoOfPages(
                      Math.ceil(bookingDetails.length / e.target.value)
                    );
                  }}
                  options={pageSize}
                />
              </div>
              <ul>
                {Array(noOfPages)
                  .fill(0)
                  .map((_, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setCurrentPageNo(i + 1)}
                        className={`${
                          i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                        }`}
                      >
                        {i + 1}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Booking Detail Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openBookingDetailsSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenBookingDetailsSlider(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setopenBookingDetailsSlider(false)}
              className="bi bi-x"
            ></i>
          </div>
          {booking_details && (
            <>
              <div className="standard-page--label">Booking Detail</div>
              <div className="side__slider__sub__heading me-3">
                ID : {booking_details.bookingid}
              </div>
            </>
          )}
        </div>
        <div className="height-64px"></div>
        {booking_details ? (
          <div className="booking__details__guest__wrapper">
            <div className="guest__detail__card">
              <div className="guest__name__wrapper">
                <div className="guest__name">{booking_details?.guest_name}</div>
                <div className="booking__channel__icon">
                  <img
                    className="booking__source__image"
                    src={booking_details?.channel_logo}
                    alt="Booking Source Icon"
                  />
                </div>
              </div>

              <div className="guest__booking__date mt-2">
                Booking Date - {booking_details?.booking_date}
              </div>
              <div className="guest__email__contact__details mt-2">
                <div className="guest__number">
                  <i className="bi bi-telephone"></i> {booking_details?.mobile}{" "}
                </div>
                <div className="guest__email__id">
                  <i className="bi bi-envelope"></i> {booking_details?.email_id}
                </div>
              </div>

              {booking_details?.booking_source === "CRS" ? (
                <>
                  <div>
                    <div className="guest__booking__date mt-2">
                      Payment Options
                    </div>
                    <div className="guest__email__contact__details">
                      {booking_details?.payment_options}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="checkin__checkout__wrapper mt-3">
                <div className="checkinout__content">
                  <div className="check__inout__details check-in-border">
                    <div className="check__inout__icon check-in-color">
                      <i className="bi bi-arrow-down-right-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-in-color">
                        {booking_details?.checkin_at}
                      </div>
                      <div className="check__inout__label">Check-in</div>
                    </div>
                  </div>
                </div>

                <div className="number__of__nights__wrapper">
                  <div className="night__icon">
                    <i className="bi bi-moon-fill"></i>
                  </div>
                  <div className="night__number">{booking_details?.nights}</div>
                </div>

                <div className="checkinout__content">
                  <div className="check__inout__details check-out-border">
                    <div className="check__inout__icon check-out-color">
                      <i className="bi bi-arrow-up-left-circle"></i>
                    </div>
                    <div className="check__inout__date">
                      <div className="check__inout check-out-color">
                        {booking_details?.checkout_at}
                      </div>
                      <div className="check__inout__label">Check-out</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="standard-page--label my-3">Room Details</div>

            {booking_details?.room_data?.map((room: any, i: number) => {
              return (
                <div key={i} className="booking__room__detail__wrapper mb-4">
                  <div className="booking__room__image">
                    <img src={getImage(room.room_image)} alt={room.room_type} />
                  </div>
                  <div className="booking__room__details">
                    <div className="room__type__meal__plan mb-3">
                      <div className="room__type__name">
                        <i className="bi bi-door-open me-2"></i>
                        {room.room_type}
                      </div>
                      <div className="room__type__meal__plan">
                        <i className="bi bi-cup-straw"></i> {room.plan_type}
                      </div>
                    </div>
                    <div className="booking__room__type__rate mb-3">
                      <i className="bi bi-people me-2"></i>{" "}
                      <div className="adult__child__wrapper">
                        <div className="adult__wrapper me-2">
                          {room.adult} Adult
                        </div>
                        <div className="child__wrrapper">
                          {room.child} Child
                        </div>
                      </div>
                    </div>
                    <div className="booking__room__nights mb-3">
                      <i className="bi bi-question-circle me-2"></i> Rooms x{" "}
                      {room.no_of_rooms}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="booking__details__price__wrapper">
              <div className="standard-page--label my-3">Price Breakup</div>
              <div className="booking__details__price__card">
                <div className="booking__detaila__price__input__wrapper me-3">
                  <div>
                    <div className="price__input__label mb-2">Total Price</div>
                    <div className="price__input">
                      <div className="price__label">
                        {booking_details?.currency_name}
                      </div>
                      <div className="price__amount">
                        {booking_details?.price ?? " "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="booking__detaila__price__input__wrapper">
                  <div>
                    <div className="price__input__label mb-2">Tax</div>
                    <div className="price__input">
                      <div className="price__label">{booking_details?.tax}</div>
                      <div className="price__amount">
                        {booking_details?.tax_amount ?? " "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="booking__details__price__wrapper">
              <div className="standard-page--label my-3">Remarks</div>
              <div className="booking__details__price__card">
                <div className="booking__detaila__price__input__wrapper me-3">
                  <div>
                    <div className="price__input__label mb-2">By Guest</div>
                    <div>{booking_details?.guest_remark}</div>
                  </div>
                </div>

                <div className="booking__detaila__price__input__wrapper me-3">
                  <div>
                    <div className="price__input__label mb-2">By Hotelier</div>
                    <div>{booking_details?.hotelier_remark}</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper booking__btn__alignment">
                {booking_details?.is_modify_crs === 1 && (
                  <Button
                    handleClick={onModifyBookingClick}
                    className="modify__bookinhg__btn"
                    isAccessible={access}
                  >
                    Modify Booking
                  </Button>
                )}
                {/* {booking_details?.is_checkin !== 0 &&
                  <Button handleClick={onCheckinClick} className="checkin__bookinhg__btn" isAccessible={access}>
                    Check-in
                  </Button>
                } */}
                {booking_details?.is_cancel !== 0 && (
                  <Button
                    handleClick={onCancelBookingClick}
                    className="cancel__bookinhg__btn"
                    isAccessible={access}
                  >
                    Cancel booking
                  </Button>
                )}
                <Button
                  handleClick={downloadBookingVoucher}
                  className="modify__bookinhg__btn"
                  loader={isPDFLoading}
                >
                  Download Voucher
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>Booking details not found</div>
          </div>
        )}
      </SlidingPane>
      {/* Modify Booking Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        className="sliding-pane-modify-booking"
        isOpen={openModifyBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenModifyBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <ModifyCrsBooking
            onClose={() => {
              setopenModifyBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            refreshData={() => setRefreshTrigger(!refreshTrigger)}
            booking_details={booking_details}
            // trigger={refreshTrigger}
            // setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openCancelBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenCancelBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {bookingDetails ? (
          <CancelBooking
            onClose={() => {
              setopenCancelBookingSlider(false);
              setopenBookingDetailsSlider(true);
            }}
            sliderState={setopenCancelBookingSlider}
            refreshData={() => setRefreshTrigger(!refreshTrigger)}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </div>
  );
};
