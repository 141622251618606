import { REMOVE_BOOKING_DETAILS, SAVE_BILLING_DETAILS, SAVE_BOOKING_CRS, SAVE_BOOKING_DETAILS, SAVE_BOOKING_INFO, SAVE_BOOKING_ROOM_DETAILS, SAVE_STAY_DETAILS } from "../actionTypes"

export const SaveBooking = (booking: any) => {
    return (
        {
            type: SAVE_BOOKING_DETAILS,
            payload: booking
        }
    )
}

export const SaveBookingCRS = (data: any) => {
    return (
        {
            type: SAVE_BOOKING_CRS,
            payload: data
        }
    )
}

export const SaveStayDetails = (booking: any) => {
    return (
        {
            type: SAVE_STAY_DETAILS,
            payload: booking
        }
    )
}

export const SaveBookingDetailedInfo = (booking: any) => {
    return (
        {
            type: SAVE_BOOKING_INFO,
            payload: booking
        }
    )
}

export const SaveBillingDetails = (billing: any) => {
    return (
        {
            type: SAVE_BILLING_DETAILS,
            payload: billing
        }
    )
}

export const SaveBookingRoomDetails = (room: any) => {
    return (
        {
            type: SAVE_BOOKING_ROOM_DETAILS,
            payload: room
        }
    )
}

export const RemoveBooking = () => {
    return (
        {
            type: REMOVE_BOOKING_DETAILS,
        }
    )
}