import {
  ACTIVE_SIDEBAR_ITEM,
  ACTIVE_SIDEBAR_SUBITEM,
  UPDATE_BREADCRUMBS,
  UPDATE_INV_CM_LOADER,
  UPDATE_INV_BE_LOADER,
  UPDATE_INV_BE_LOADER_DISABLE,
  UPDATE_INV_CM_LOADER_DISABLE,
} from '../actionTypes';

export const ActiveSidebarItem = (label: string) => {
  return {
    type: ACTIVE_SIDEBAR_ITEM,
    payload: label,
  };
};

export const ActiveSidebarSubitem = (label: string) => {
  return {
    type: ACTIVE_SIDEBAR_SUBITEM,
    payload: label,
  };
};

export const UpdateBreadcrumbs = (breadcrumbs: any) => {
  return {
    type: UPDATE_BREADCRUMBS,
    payload: breadcrumbs,
  };
};

export const Update_cm_loader = () => {
  return {
    type: UPDATE_INV_CM_LOADER,
  };
};
export const Update_be_loader = () => {
  return {
    type: UPDATE_INV_CM_LOADER,
  };
};
export const Update_cm_loader_disable = () => {
  return {
    type: UPDATE_INV_CM_LOADER_DISABLE,
  };
};
export const Update_be_loader_disable = () => {
  return {
    type: UPDATE_INV_BE_LOADER_DISABLE,
  };
};
