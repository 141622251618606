import React, { useState, useEffect } from 'react';
import InputTextField from '../../Views/inputtextfield/InputTextField';
import Button from '../../Views/buttons/Button';
import { validateEmail, validateFullName, validateMobile } from '../../UtilityFunctions';
import { toast } from 'react-toastify';
import endPoints from '../../API/endPoints';
import beApi from '../../API/beApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios';

export interface IEditPartnerSliderContent {
    onClose: () => void;
    editSliderData: any;
    refreshData: () => void;
}

const EditCacellationPolicy: React.FC<IEditPartnerSliderContent> = ({ onClose, editSliderData, refreshData }) => {
    const { auth_token, hotel_id } = useSelector((state: RootState) => state.auth);
    const { updateCancelPolicy } = endPoints.CANCELATIONPOLICY;

    // initial States
    const [cancellationPolicyId, setCancellationPolicyId] = useState("");
    const [salesExecutiveName, setSalesExecutiveName] = useState("");
    const[cancellationData,setCancelationData]=useState("")
  

    // get initial data from prop
    useEffect(() => {
        setCancellationPolicyId(editSliderData?.id);
        setSalesExecutiveName(editSliderData?.policy_name);
        setCancelationData(editSliderData?.cancel_policy)
    }, [editSliderData])

    // initial helper text
    const [salesExecutivHelperText, setSalesExecutivHelperText] = useState("");
    
    const [saveLoader, setSaveLoader] = useState<boolean>(false);

    const salesExecutiveNameOnChange = (name: any) => {
        if (!validateFullName(name) && name) {
            setSalesExecutivHelperText("Please enter a valid name");
        } else {
            setSalesExecutivHelperText("");
        }
        setSalesExecutiveName(name);
    }



    const editSalesExecutiveDetails = async () => {
        setSaveLoader(true);
        if (salesExecutiveName.length ) {
            try {
                const payload = {
                    hotel_id: hotel_id,
                    policy_name: salesExecutiveName,
                    id:cancellationPolicyId,
                   cancel_policy:cancellationData
                }
                let response = await beApi.post(`${updateCancelPolicy}`, payload,
                    {
                        headers: {
                            Authorization: `Bearer ${auth_token}`,
                        }
                    })
         
              
                    
                
                if (response.data.status === 1) {
                    setSaveLoader(false);
                    toast(response.data.message, {
                        hideProgressBar: true,
                        type: "success",
                    });
                    onClose();
                    refreshData();
                }
            } catch (error) {
                console.log(error);
                setSaveLoader(false);
            }
        } else {
            toast.error("Kindly fill all the fields");
        }
        setSaveLoader(false);
    }

    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i
                        onClick={() => {
                            onClose();
                        }}
                        className="bi bi-x"
                    ></i>
                </div>
                <div className="standard-page--label">Edit Cancellation Policy</div>
            </div>

            <div className="height-64px"></div>

            <div className="add__new__sales__executive mt-3">
                <div className="div__inputs__wrapp">
                    <div className="div--email--input">
                        <InputTextField
                            value={salesExecutiveName || ""}
                            helperText={salesExecutivHelperText}
                            label="Policy Name"
                            handleChange={(e) => salesExecutiveNameOnChange(e.target.value)}
                            handleFocus={(e) => {
                                if (!e.target.value) setSalesExecutivHelperText("");
                            }}
                        />
                    </div>
                </div>

                <p style={{color:"#414141",fontWeight:"semibold"}}>Policy Description</p>
                     <div className="cancelation__policy__wrapper">
                        <div className="manage__terms__poliy__wrapper pb-0">
                            <div id="editor">
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={ editSliderData?.cancel_policy || ""}
                                    config={{
                                        removePlugins: [
                                            "EasyImage",
                                            "ImageUpload",
                                            "MediaEmbed",
                                            "Table",
                                        ],
                                    }}
                                    onChange={(event: string, editor: any) => {
                                        const data = editor.getData();
                                        setCancelationData(data);
                                        // setCharCount({
                                        //     ...charCount,
                                        //     terms_cond: removeTags(data).length,
                                        // });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="character__count__number">
                            {/* <div className="character__count__label">
                                Used <span className='count_number'>{charCount.terms_cond}</span>
                            </div> */}
                        </div>

                        
                     
                    </div>

           
            </div>

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            >
            </div>

            <div className="side__slider__panel__btn">
                <div className="__btn__wrapper justify-content-between w-100">
                    <Button className="primary-btn" handleClick={editSalesExecutiveDetails} loader={saveLoader}>Save</Button>
                </div>
            </div>
        </>
    )
}

export default EditCacellationPolicy;