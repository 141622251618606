import React from "react";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import Error from "../Error";
import CRSView from "../pages/Bookings/CrsView";
import Company from "../pages/Partner/Partner";
import Dashboard from "../pages/Dashboard/Dashboard";
import DisplayPage from "../pages/DisplayPage";
import Loginwithoutcredential from "../pages/Loginwithoutcredential";
import Season from "../pages/Season/Season";
import PartnerRateMapping from "../pages/Partner/PartnerRateMapping";
import Reports from "../pages/Reports/Reports";
import DefaultLayout from "../layout/DefaultLayout";
import PaymentOptnReport from "../pages/Reports/PaymentOptnReport";
import CancelationPolicy from "../pages/CancellationPolicy/CancelationPolicy";
import ListView from "../pages/Bookings/ListView";
import SalesExecutive from "../pages/SalesExecutive/SalesExecutive";
import PartnerPolicyMapping from "../pages/Partner/PartnerPolicyMapping";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Navigate to="dashboard" />} />
        {/* ------------Dashboard------------ */}
        <Route path="dashboard" element={<Dashboard />} />

        {/* ------------Bookings------------ */}
        {/* <Route path="bookings" element={<Outlet />}> */}
        {/* <Route index element={<Navigate to="/bookings" />} /> */}
        {/* <Route path="gems/frontoffice-view" element={<FrontofficeViewGems />}/> */}
        {/* <Route path="frontoffice-view" element={<NoFrontOfficeView />} /> */}
        {/* <Route path="list-view" element={<ListView />} /> */}
        {/* <Route path="/bookings" element={<CRSView />} /> */}
        {/* <Route path="check-in" element={<CheckIn />} /> */}
        {/* <Route path="stay-details" element={<StayDetails />} /> */}
        {/* <Route path="check-out" element={<CheckOut />} /> */}
        {/* </Route> */}
        <Route path="bookings" element={<Outlet />}>
          <Route index element={<Navigate to="list-view" />} />
          <Route path="list-view" element={<ListView />} />
          <Route path="crs-view" element={<CRSView />} />
        </Route>

        {/* ------------Season------------ */}
        <Route path="season" element={<Season />} />
        {/* ------------Company------------ */}
        <Route path="partner" element={<Company />} />
        {/* -------------partner rate mapping------------ */}
        <Route
          path="partner-rate-mapping/:id/:name"
          element={<PartnerRateMapping />}
        />
        <Route
          path="partner-policy-mapping/:id/:name"
          element={<PartnerPolicyMapping />}
        />
        {/* ------------------Reports---------------- */}
        <Route path="reports" element={<PaymentOptnReport />} />
        {/* <Route path="payment-reports" element={<PaymentOptnReport />} /> */}

        <Route path="cancelation-policy" element={<CancelationPolicy />} />

        <Route path="sales-executive" element={<SalesExecutive />} />

        <Route path="*" element={<Error />} />
      </Route>

      <Route
        path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
        element={<Loginwithoutcredential />}
      />
      <Route path="/login" element={<Navigate to={"/dashboard"} />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default AppRoutes;
