import React, { useEffect } from "react";
import Login from "../pages/Login";
import { Routes, Route, Navigate } from "react-router-dom";
// import ResetPassword from "../pages/ResetPassword";
// import EnterNewPassword from "../pages/EnterNewPassword";
// import SignUp from "../pages/SignUp/SignUp";
// import SignUpForm from "../pages/SignUp/SignUpForm";
import Loginwithoutcredential from "../pages/Loginwithoutcredential";
// import SignUpValidation from "../pages/SignUp/SignUpValidation";
import { useDispatch } from "react-redux";

const AuthRoutes = () => {
    let dispatch = useDispatch();

    // useEffect(()=>{
    //   dispatch(handleSingleSignOn(0));
    // },[])
    return (
        <Routes>

            <Route path="/:company_id/:comp_hash/:hotel_id/:admin_id/:auth_token/:full_name/:hotel_name"
                element={<Loginwithoutcredential />} />

            <Route path="/login" element={<Login />} />

            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    );
};

export default AuthRoutes;
