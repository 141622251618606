import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar_Menu from "./SidebarMenu.json";
import Sales_sidebar_Menu from "./SalesSidbarMenu.json";
import SidebarItem from "./SidebarItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { ActiveSidebarItem } from "../../Redux/actions/SidebarAction";
import { Logout } from "../../UtilityFunctions";

export interface IMenuItemsProps {
  label: string;
  icon: string;
  dropdown?: string[];
  showDropdown?: boolean;
}

const AppSidebar: React.FC = () => {
  const dispatch = useDispatch();

  const [channels, setChannels] = useState([
    {
      label: "Website",
      alerts: 0,
    },
  ]);

  const { active_sidebar_item } = useSelector(
    (state: RootState) => state.sidebar
  );
  const { full_name, role_id } = useSelector((state: RootState) => state.auth);

  const login_type = useSelector((state: RootState) => state.loginType);

  // console.log({ login_type, full_name })

  const handleSidebarItemClick: (item: IMenuItemsProps) => void = (item) => {
    dispatch(ActiveSidebarItem(item.label));
    if (item.label === active_sidebar_item) {
      item.showDropdown = !item.showDropdown;
    }
  };

  return (
    <>
      <div className="sidebar-main-wrapper" style={{ position: "relative" }}>
        <div className="sidebar-wrapper sidebar-position">
          <div className="company__logo__wrapper">
            <Link to="/">
              <div className="company--logo"></div>
            </Link>
          </div>

          <ul className="sidebar__list__wrapper">
            {role_id == 13 ? (
              <div>
                {Sales_sidebar_Menu.map((item: any, index: number) => {
                  return (
                    <SidebarItem
                      key={index}
                      handleClick={() => handleSidebarItemClick(item)}
                      href={item.href}
                      label={item.label}
                      icon={item.icon}
                      dropdown={item.dropdown}
                      showDropdown={item.showDropdown}
                      active={active_sidebar_item === item.label}
                    />
                  );
                })}
              </div>
            ) : (
              <div>
                {Sidebar_Menu.map((item: any, index: number) => {
                  return (
                    <SidebarItem
                      key={index}
                      handleClick={() => handleSidebarItemClick(item)}
                      href={item.href}
                      label={item.label}
                      icon={item.icon}
                      dropdown={item.dropdown}
                      showDropdown={item.showDropdown}
                      active={active_sidebar_item === item.label}
                    />
                  );
                })}
              </div>
            )}
          </ul>
          <div className="app__versioning__wrapper">
            <div className="app__version">Version 1.3.3</div>
          </div>
          {/* <div className="logout" style={{
            width: "100%",
            backgroundColor: 'white',
            height: '50px',
            position: 'absolute',
            bottom: "0",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}>

            <div className="leftPart" style={{
              width: `${login_type === 'loginwithoutcred' ? '100%' : '75%'}`,
              height: '100%',
              display: 'flex', justifyContent: 'right', alignItems: 'center'
            }}>
              <div style={{
                width: "90%",
                height: '100%',
                display: "flex",
                alignItems: 'center'
              }}>
                <i className="bi bi-person-check-fill" style={{ color: "#4754c3", marginRight: '10%' }}></i>
                <div className="name" style={{ color: "#4754c3", fontWeight: '700' }}>{full_name ? full_name : 'Hotelier Name'}</div>
              </div>
            </div>

            <div className="rightPart" style={{
              width: '25%',
              height: '100%',
              cursor: 'pointer',
              background: '#ffdee5',
              display:
                // 'flex'
                `${login_type === 'logincred' ?
                  'flex'
                  : 'none'}`
              ,
              justifyContent: 'center', alignItems: 'center'
            }} onClick={Logout}>
              <i className="bi bi-box-arrow-right" style={{ color: '#d61d11' }}></i>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AppSidebar;
