import React, { useState } from "react";
import InputTextField from "../../Views/inputtextfield/InputTextField";
import Button from "../../Views/buttons/Button";
import endPoints from "../../API/endPoints";
import beApi from "../../API/beApi";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { toast } from "react-toastify";
import {
  validateEmail,
  validateMobile,
  validateFullName,
  validatePassword,
} from "../../UtilityFunctions";

export interface IEditPartnerSliderContent {
  onClose: () => void;
  hotelId: number;
  refreshData: () => void;
}

const AddSalesExecutive: React.FC<IEditPartnerSliderContent> = ({
  onClose,
  hotelId,
  refreshData,
}) => {
  const { auth_token, company_id } = useSelector(
    (state: RootState) => state.auth
  );

  const { addSalesExecutive } = endPoints.SALESEXECUTIVE;

  // initial States
  const [salesExecutiveName, setSalesExecutiveName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [password, setPassword] = useState("");
  // initial helper text
  const [salesExecutivHelperText, setSalesExecutivHelperText] = useState("");
  const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
  const [emailAddressHelperText, setEmailAddressHelperText] = useState("");
  const [whatsAppNumberHelperText, setWhatsAppNumberHelperText] = useState("");
  const [saveLoader, setSaveLoader] = useState<boolean>(false);

  const salesExecutiveNameOnChange = (name: string) => {
    if (!validateFullName(name) && name) {
      setSalesExecutivHelperText("Please enter a valid name");
    } else {
      setSalesExecutivHelperText("");
    }
    setSalesExecutiveName(name);
  };

  const phoneNumberOnChange = (phone: string) => {
    let mobileNumber = phone.slice(0, 10);
    if (!validateMobile(mobileNumber) && mobileNumber) {
      setPhoneNumberHelperText("Please enter a valid mobile number");
    } else {
      setPhoneNumberHelperText("");
    }
    setPhoneNumber(mobileNumber);
  };

  const emailAddressOnChange = (email: string) => {
    if (!validateEmail(email) && email) {
      setEmailAddressHelperText("Please enter a valid email address");
    } else {
      setEmailAddressHelperText("");
    }
    setEmailAddress(email);
  };

  const whatsAppNumberOnChange = (whatsApp: string) => {
    let whatsAppNumber = whatsApp.slice(0, 10);
    if (!validateMobile(whatsAppNumber) && whatsAppNumber) {
      setWhatsAppNumberHelperText("Please enter a valid whatsApp number");
    } else {
      setWhatsAppNumberHelperText("");
    }
    setWhatsAppNumber(whatsAppNumber);
  };

  const addSalesExecutiveDetails = async () => {
    if (
      (salesExecutiveName.length,
      phoneNumber.length,
      emailAddress.length,
      whatsAppNumber.length,
      password.length)
    ) {
      if (whatsAppNumber.length < 10) {
        toast.error("Enter valid whatsApp number");
        return;
      }
      if (password.length < 6) {
        toast.error("Password must be at least 6 characters long");
        return;
      }
      if (!validatePassword(password)) {
        toast.error("Enter valid password");
        return;
      }

      setSaveLoader(true);
      try {
        const payload = {
          hotel_id: hotelId,
          name: salesExecutiveName,
          phone: phoneNumber,
          email: emailAddress,
          whatsapp_number: whatsAppNumber,
          company_id: company_id,
          password: password,
        };
        let response = await beApi.post(`${addSalesExecutive}`, payload, {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        });
        if (response.data.status === 1) {
          setSaveLoader(false);
          toast(response.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          onClose();
          refreshData();
        } else {
          setSaveLoader(false);
          toast(response.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        setSaveLoader(false);
      }
    } else {
      toast.error("Kindly fill all the fields");
    }
    setSaveLoader(false);
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i
            onClick={() => {
              onClose();
            }}
            className="bi bi-x"
          ></i>
        </div>
        <div className="standard-page--label">Add Sales Executive</div>
      </div>

      <div className="height-64px"></div>

      <div className="add__new__sales__executive mt-3">
        <div className="div__inputs__wrapp">
          <div className="div--email--input">
            <InputTextField
              value={salesExecutiveName}
              helperText={salesExecutivHelperText}
              label="Name"
              handleChange={(e) => salesExecutiveNameOnChange(e.target.value)}
              handleFocus={(e) => {
                if (!e.target.value) setSalesExecutivHelperText("");
              }}
            />
          </div>
        </div>

        <div className="div__inputs__wrapp mt-3">
          <div className="div--email--input">
            <InputTextField
              value={phoneNumber}
              helperText={phoneNumberHelperText}
              label="Phone"
              handleChange={(e) => phoneNumberOnChange(e.target.value)}
              handleFocus={(e) => {
                if (!e.target.value) setPhoneNumberHelperText("");
              }}
            />
          </div>
        </div>

        <div className="div__inputs__wrapp mt-3">
          <div className="div--email--input">
            <InputTextField
              value={emailAddress}
              helperText={emailAddressHelperText}
              label="Email Address"
              handleChange={(e) => emailAddressOnChange(e.target.value)}
              handleFocus={(e) => {
                if (!e.target.value) setEmailAddressHelperText("");
              }}
            />
          </div>
        </div>

        <div className="div__inputs__wrapp mt-3">
          <div className="div--email--input">
            <InputTextField
              value={whatsAppNumber}
              type="number"
              helperText={whatsAppNumberHelperText}
              label="Whatsapp Number"
              handleChange={(e) => whatsAppNumberOnChange(e.target.value)}
              handleFocus={(e) => {
                if (!e.target.value) setWhatsAppNumberHelperText("");
              }}
            />
          </div>
        </div>
        <div className="div__inputs__wrapp mt-3">
          <div className="div--email--input">
            <InputTextField
              value={password}
              // helperText={whatsAppNumberHelperText}
              label="Password"
              handleChange={(e) => setPassword(e.target.value)}
              // handleFocus={(e) => {
              //     if (!e.target.value) setWhatsAppNumberHelperText("");
              // }}
            />
          </div>
        </div>
      </div>
      <p style={{ color: "red", textAlign: "center" }}>
        Note : Password Should Contain one Upper Case and one Special Character
        and Atleast 8 Number of Character
      </p>

      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></div>

      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper justify-content-between w-100">
          <Button
            className="primary-btn"
            handleClick={addSalesExecutiveDetails}
            loader={saveLoader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddSalesExecutive;
