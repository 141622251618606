import { useDispatch } from "react-redux";
import {
  ActiveSidebarItem,
  ActiveSidebarSubitem,
  UpdateBreadcrumbs,
} from "./Redux/actions/SidebarAction";

export const validateUrl = (url: string) => {
  return String(url).match(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateFullName = (fullname: string) => {
  return String(fullname).match(/^([a-zA-Z ]){2,30}$/);
}

export const validatePositiveInt = (number: string) => {
  return String(number).match(/^[0-9]*$/);
  // return String(number).match(/^[1-9][0-9]*$/);
};

export const validateMobile = (mobile: string) => {
  // return String(mobile).match(/^[0-9]{0,100}$/);
  return String(mobile).match(/^[0-9]{10}$/);
};

export const validateLandline = (number: string) => {
  return String(number).match(/^[0-9]{0,100}$/);
  // return String(number).match(/^[0-9]{6,10}$/);
};

export const validatePinCode = (pin: string) => {
  return String(pin).match(/^[0-9]{6}$/);
  // return true;
};

// Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character and must be at least 4 characters long.
export const validatePassword = (password: string) => {
  return String(password).match(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$"
  );
};

// The first four characters should be upper case alphabets,fifth character should be 0,next six characters usually numeric, but can also be alphabetic.
export const validateIfscCode = (ifsc: string) => {
  return String(ifsc).match(/^[A-Z]{4}0[A-Z0-9]{6}$/);
};

// The first six characters should be upper case alphabets,2 letters or digits,3 letters or digits, last three are optional
export const validateSwiftCode = (swift: string) => {
  return String(swift).match(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/);
};

// The first two characters should be a number,next five characters should be any upper case alphabets,next four characters should be any number,next character should be any upper case alphabet,the 13th character should be a number from 1-9 or an alphabet,14th character should be Z,the 15th character should be an alphabet or a number,
export const validateGstNo = (gstno: string) => {
  // return String(gstno).match(
  //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  // );
  var gstinformat = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
  if (gstinformat.test('18AABCU9603R1ZM')) {
    return true;
  } else {
    // console.log(gstinformat.test(gstno))
    return false
  }
};

//
export const validUrl = (url: string) => {
  return String(url).match(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );
};

export const Format12HrTime = (time: string) => {
  let val = time.replace(/\D/g, "");
  if (val === "00") {
    return "01";
  } else if (val.length === 1 && parseInt(val) > 2) {
    return "0" + val;
  } else if (val.length === 2 && parseInt(val) > 12) {
    return "12";
  } else if (val.length === 3 && parseInt(val[2]) > 5) {
    return val.slice(0, 2) + ":5";
  } else if (val.length === 4 && parseInt(val.slice(2)) > 59) {
    return val.slice(0, 2) + ":59";
  } else if (val.length > 2) {
    return val.slice(0, 2) + ":" + val.slice(2, 4);
  } else {
    return val;
  }
};

export const GetOrdinalSuffix = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const Convert12Hto24H = (time: string) => {
  let arr = time.split(":");
  if (arr[2] === "AM" && arr[0] === "12") {
    arr[0] = "00";
  } else if (arr[2] === "PM" && arr[0] !== "12") {
    arr[0] = (parseInt(arr[0]) + 12).toString();
  }
  arr[2] = "00";
  return arr[0].length === 1 ? "0" + arr.join(":") : arr.join(":");
};

export const Convert24Hto12H = (time: string) => {
  let arr = time.split(":").slice(0, 2);
  let day;
  if (arr[0].toString() === "00") {
    arr[0] = "12";
    day = "AM";
  } else if (parseInt(arr[0]) > 12) {
    arr[0] = (parseInt(arr[0]) - 12).toString();
    day = "PM";
  } else if (parseInt(arr[0]) < 11) {
    day = "AM";
  } else {
    day = "PM";
  }
  return arr[0].length === 1
    ? "0" + arr.join(":") + " " + day
    : arr.join(":") + " " + day;
};

export const Capitalize = (str: string) => {
  if (str) {
    let words = str.split(" ");
    let i = 0;
    let j = 0;
    for (i = 0; i < words.length; i++) {
      if (words[i].toLowerCase().includes("-")) {
        let subwords = words[i].split("-");
        for (j = 0; j < subwords.length; j++) {
          subwords[j] = Capitalize(subwords[j]);
        }
        words[i] = subwords.join("-");
      } else if (!words[i].includes("II")) {
        words[i] =
          words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      } else {
        words[i] = words[i].toUpperCase();
      }
    }
    return words.join(" ");
  } else {
    return "";
  }
};

export const FileToBase64 = async (file: any) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  }).then((result: any) => {
    return result;
  });
};

export const browsePDF = (pdf: string, title: string) => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = title;
  downloadLink.click();
};

export const getImage = (url: string) => {
  if (
    url?.includes("https://") ||
    url?.includes("http://") ||
    url?.includes("data:")
  ) {
    return url;
  }
  return "https://d3ki85qs1zca4t.cloudfront.net/bookingEngine/" + url;
};

export const UpdateSidebar = (menu: string, sub_menu?: string) => {
  const dispatch = useDispatch();

  let breadcrumbs: any[] = [];
  breadcrumbs.push({
    label: menu,
    href:
      menu === "Dashboard" ? "/" : "/" + menu.toLowerCase().replace(/ /g, "-"),
  });

  dispatch(ActiveSidebarItem(menu));
  if (sub_menu) {
    dispatch(ActiveSidebarSubitem(sub_menu));
    breadcrumbs.push({
      label: sub_menu,
      href:
        "/" +
        menu.toLowerCase().replace(/ /g, "-") +
        "/" +
        sub_menu.toLowerCase().replace(/ /g, "-"),
    });
  } else {
    dispatch(ActiveSidebarSubitem(""));
  }

  dispatch(UpdateBreadcrumbs(breadcrumbs));
};

// Logout function
export const Logout = () => {
  setTimeout(() => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }, 500);
};

// Api Failed Error Message
export const CATCH_MESSAGE = "Something went wrong";
