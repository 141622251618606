import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <div className="error-page-wrapper">
            <div className="error__contents">
                <div className="error--404">404</div>
                <div className="oops--message">OOPS! NOTHING WAS FOUND</div>
                <div className="opps-sub-message">Maybe this page moved ? Got deleted ? Is hiding out in quarantine ? Never existed in the first place ?</div>
                <div className="go-home-text">Let's go <Link to="/">home</Link> and try from there.</div>
            </div>
        </div>
    )
}

export default Error;
