import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../API/beApi";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import { RootState } from "../../Redux/store";
import { Capitalize, CATCH_MESSAGE } from "../../UtilityFunctions";
import Button from "../../Views/buttons/Button";
import InventoryBtn from "../../Views/buttons/Button";
import InputTextField from "../../Views/inputtextfield/InputTextField";

interface INewSeason {
    onClose: () => void;
    callTheApi: () => void;
}

const NewSeason: React.FC<INewSeason> = ({ onClose, callTheApi }) => {
    // state
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [focusedInput, setFocusedInput] = useState<any>(null);
    const [seasonName, setSeasonName] = useState<any>("");
    const [isLoading, setIsLoading] = useState(false)


    // state from redux
    const { admin_id, auth_token, hotel_id } = useSelector(
        (state: RootState) => state.auth
    );

    // Handler function to add new Season
    const addNewSeason = async () => {
        // console.log('apple')
        if (!hotel_id) {
            setIsLoading(false)
            return
        }
        else if (!seasonName || !startDate || !endDate) {
            setIsLoading(false)
            toast.error('Fill all the fields')
            return
        }
        else {
            let payload = {
                hotel_id: hotel_id,
                season_type: seasonName,
                validate_from: startDate.format("YYYY-MM-DD"),
                validate_to: endDate.format("YYYY-MM-DD"),
            }
            try {
                let res = await beApi.post(`/add-season`, payload, {
                    headers: {
                        Authorization: `Bearer ${auth_token}`,
                    },
                });

                if (res.data.status === 1) {
                    setIsLoading(false)
                    callTheApi()
                    toast.success(res.data.message);
                    onClose();
                } else {
                    setIsLoading(false)
                    toast.error(res.data.message);
                }
            }
            catch {
                setIsLoading(false)
                toast.error(CATCH_MESSAGE);
            }
        }
    }

    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Add Season</div>
            </div>
            <div className="height-64px"></div>

            <div className="text_date_picker_wrapper">
                <InputTextField
                    value={Capitalize(seasonName)}
                    label="Season"
                    handleChange={(e) => { setSeasonName(Capitalize(e.target.value)) }
                    }
                />

                <div className="date_nights_label_content_wrapper_season">
                    <div className="checkin_dates_wrapper">
                        <div
                            className="bookings__select__date__wrapper "
                            onClick={() => setFocusedInput("startDate")}
                        >
                            <div className="date__picker__icon">
                                {" "}
                                <i className="bi bi-calendar"></i>
                            </div>
                            <div className="date__label">
                                {!startDate && !endDate
                                    ? "Select Date Range"
                                    : (startDate
                                        ? moment(startDate).format("DD MMM, YYYY")
                                        : "") +
                                    " - " +
                                    (endDate ? moment(endDate).format("DD MMM, YYYY") : "")}
                            </div>
                            <CustomDateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                focusedInput={focusedInput}
                                setFocusedInput={setFocusedInput}
                                preventPastDates={true}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                {isLoading ?
                    <Button>
                        Save
                        <span
                            className="spinner-border spinner-border-sm pd-4"
                            role="status"
                            aria-hidden="true"
                            style={{ marginLeft: '0.5rem' }}
                        ></span>
                    </Button>
                    :
                    <Button
                        handleClick={() => {
                            setIsLoading(true)
                            addNewSeason()
                        }}
                    >
                        Save
                    </Button>
                }
            </div>
        </>
    )
};

export default NewSeason;