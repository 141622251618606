import React from 'react';

export interface ICardProps{
    children:React.ReactNode;
    className?:string;

}

const Card:React.FC<ICardProps> = ({children,className}) => {
  return (
    <div className={`card ${className}`}>
      {children}
    </div>
  )
}

export default Card;
