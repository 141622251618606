import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import beApi from '../../API/beApi';
import { RootState } from '../../Redux/store';
import { CATCH_MESSAGE, validateEmail, validatePositiveInt } from '../../UtilityFunctions';
import Button from '../../Views/buttons/Button';
import InputTextField from '../../Views/inputtextfield/InputTextField';
import moment from 'moment';

interface IRateSetup {
    onClose: () => void;
    selectedRatePlan: any;
    agentId: any;
    refreshFunntion: () => void;
}

const RateSetup: React.FC<IRateSetup> = ({ onClose, selectedRatePlan, agentId, refreshFunntion }) => {
    // Redux
    const { company_id, auth_token, hotel_id } = useSelector(
        (state: RootState) => state.auth
    );
    // State
    const [initialData, setInitialData] = useState<any>([]);
    const [updateFlag, setUpdateFlag] = useState<boolean>(false);
    const [barPrice, setBarPrice] = useState<any>("");
    const [extraAdult, setExtraAdult] = useState<any>("");
    const [extraChild, setExtraChild] = useState<any>("");
    const [maxPeople, setMaxPeople] = useState<number>(0);
    const [maxPeopleForPaylaod, setMaxPeopleForPaylaod] = useState<number>(0);
    const [roomInput, setRoomInput] = useState<any>();
    const [rooms, setRooms] = useState<string[]>([]);
    const [roomInputError, setRoomInputError] = useState("max");
    const [percentage, setPercentage] = useState<number>();

    const [starTrekPayload, setStarTrekPayload] = useState<any>([]);
    const [identity, setID] = useState<number | null>(null)
    // const [spacexPayload, setSpacexPayload] = useState<any>([]);

    const [saveLoader, setSaveLoader] = useState<boolean>(false);
 
    useEffect(() => {
        fetchInitialData();
    }, []);

    // Handler function to fetch initial rate plan data 
    const fetchInitialData = async () => {
        try {
            const res = await beApi.get(`select-all-partner-rate/${hotel_id}/${selectedRatePlan?.room_type_id}/${agentId}/${selectedRatePlan?.season_id}`);
            if (res.data.status === 1) {
                setAllocateroom(res.data?.Data[0]?.total_allocated_room)
                setInitialData(res.data.Data);
                let dataForPayload: any = [];
                if (res.data.Data[0]?.multiple_occupancy) {
                    dataForPayload = [...res.data.Data];
                    dataForPayload.map((obj: any, id: number) => {
                        dataForPayload[id].percent_input = 0;
                        dataForPayload[id].room_input = 0;
                        dataForPayload[id].hotel_id = hotel_id;
                        dataForPayload[id].partner_id = agentId;
                        dataForPayload[id].season_id = selectedRatePlan.season_id;
                        // dataForPayload[id].multiple_occupancy = dataForPayload[id].multiple_occupancy.length ? dataForPayload[id].multiple_occupancy : [];
                        dataForPayload[id].max_people = obj?.max_people ? obj?.max_people : 1;
                    })
                    setUpdateFlag(true);
                    setStarTrekPayload(dataForPayload);
                } else {
                    setUpdateFlag(false);
                    // dataForPayload = [...res.data.Data];
                    res.data.Data.map((obj: any, id: number) => {
                        let dataObj: any = {};
                        dataObj.bar_price = "";
                        dataObj.extra_adult = "";
                        dataObj.extra_child = "";
                        dataObj.max_people = obj?.max_people ? obj?.max_people : 1;
                        dataObj.percent_input = null;
                        dataObj.room_input = 0;
                        dataObj.multiple_occupancy = [];
                        dataObj.hotel_id = hotel_id;
                        dataObj.partner_id = agentId;
                        dataObj.plan_name = obj.plan_name;
                        dataObj.plan_type = obj.plan_type;
                        dataObj.rate_plan_id = obj.rate_plan_id;
                        dataObj.room_type = obj.room_type;
                        dataObj.room_type_id = obj.room_type_id;
                        dataObj.season_id = selectedRatePlan.season_id;
                        dataForPayload.push(dataObj);
                    })
                    setStarTrekPayload(dataForPayload);
                }
                setBarPrice(res.data.Data[0]?.bar_price ? res.data.Data[0]?.bar_price : "");
                setExtraAdult(res.data.Data[0]?.extra_adult ? res.data.Data[0]?.extra_adult : "");
                setExtraChild(res.data.Data[0]?.extra_child ? res.data.Data[0]?.extra_child : "");
                setMaxPeople(res.data.Data[0]?.max_people ? res.data.Data[0]?.max_people : 0);
                setRoomInputError(res.data.Data[0]?.max_people ? res.data.Data[0]?.max_people : res.data.Data[0]?.multiple_occupancy?.length + 1)
                setRooms(res.data.Data[0]?.multiple_occupancy ? res.data.Data[0]?.multiple_occupancy : []);
            }
        } catch {
            toast.error("Some error occurs while fetching data")
        }
    }

    // Handler function for percentage input change 
    const percentChange = (event: any, id: number) => {
        let amount = event.target.value;
        let arrMod = [...starTrekPayload];
        if(!isNaN(Number(amount))) { 
            arrMod[id].percent_input = amount;
            setStarTrekPayload(arrMod);
        }
    }
    // Handler function for percentage fetch 
    const percentFetch = async (id: number, obj: any) => {
        try {
            // ${obj?.room_type_id}/${obj.rate_plan_id}
            const res = await beApi.post(`rate-percentage-cal/${hotel_id}/${obj?.room_type_id}/${obj.rate_plan_id}`, {
                percentage: obj?.percent_input
            });
            if (res.data.status === 1) {
                let arrMod = [...starTrekPayload];
                arrMod[id].bar_price = res.data.Data.bar_price.toString();
                arrMod[id].extra_adult = res.data.Data.extra_adult_price.toString();
                arrMod[id].extra_child = res.data.Data.extra_child_price.toString();
                arrMod[id].multiple_occupancy = res.data.Data.multiple_occupancy;
                setStarTrekPayload(arrMod);
            }
        } catch {
            toast.error(CATCH_MESSAGE)
        }
    }

    // setBarPriceFunc Handler
    const setBarPriceFunc = (event: any, id: number) => {
        // setRoomInput('');
        let amount = event.target.value;
        let arrMod = [...starTrekPayload];
        arrMod[id].bar_price = amount;
        setStarTrekPayload(arrMod);
    }

    const setExtraAdultFunc = (event: any, id: number) => {
        // setRoomInput('');
        let amount = event.target.value;
        let arrMod = [...starTrekPayload];
        arrMod[id].extra_adult = amount;
        setStarTrekPayload(arrMod);
    }

    const setExtraChildFunc = (event: any, id: number) => {
        // setRoomInput('');
        let amount = event.target.value;
        let arrMod = [...starTrekPayload];
        arrMod[id].extra_child = amount;
        setStarTrekPayload(arrMod);
    }

    // Handler function for multiple occupancy
    const onRoomInputChange = (e: any, id: number) => {
        setRoomInput(e.target.value)
        if (e.target.value && e.target.value == parseInt(e.target.value)) {
            setID(id)
            let val = e.target.value;
            let arrMod = [...starTrekPayload];
            arrMod[id].room_input = Number(val);
            setStarTrekPayload(arrMod);
        }
        // else {
        //     toast.error('Please provide a valid input')
        // }
    };

    const onRoomEnterPress = (e: any, id: number, obj: any) => {
        if (e.key === "Enter") {
            if (e.target.value && e.target.value == parseInt(e.target.value)) {
                setRoomInput('')
                // let val = e.target.value

                let val = starTrekPayload[id].room_input.toString();
                let arrMod = [...starTrekPayload];
                if (arrMod[id].multiple_occupancy.length < obj.max_people-1) {
                    arrMod[id].room_input = 0;
                    // arrMod[id].multiple_occupancy.push(val);
                    arrMod[id].multiple_occupancy.push(val);
                    // setStarTrekPayload(arrMod);
                    // setRoomInput("");
                } else {
                    toast.error("Maximum multiple occupancy reached!");
                    // setRoomInput("");
                }
            }
            // else {
            //     toast.error('Please provide a valid input')
            // }
        }
    };

    const onBlurRoomInput = (e: any, id: number, obj: any) => {
        // if (e.key === "Enter") {
            if (e.target.value && e.target.value == parseInt(e.target.value)) {
                setRoomInput('')
                // let val = e.target.value

                let val = starTrekPayload[id].room_input.toString();
                let arrMod = [...starTrekPayload];
                if (arrMod[id].multiple_occupancy.length < obj.max_people-1) {
                    arrMod[id].room_input = 0;
                    // arrMod[id].multiple_occupancy.push(val);
                    arrMod[id].multiple_occupancy.push(val);
                    // setStarTrekPayload(arrMod);
                    // setRoomInput("");
                } 
                // else {
                //     toast.error("Maximum multiple occupancy reached!");
                //     // setRoomInput("");
                // }
            }
            // else {
            //     toast.error('Please provide a valid input')
            // }
        // }
    };

    const removeRoomInput = (room: string, id: any) => {
        // setRooms(rooms.filter((Room) => Room !== room));
        let arrMod = [...starTrekPayload];
        let index = arrMod[id].multiple_occupancy.indexOf(room);
        if (index > -1) {
            arrMod[id].multiple_occupancy.splice(index, 1)
        }
        setStarTrekPayload(arrMod);
    };
const [allocateroom, setAllocateroom]=useState("")
    // Handler function to save data
    const saveHandler = async () => {
        // if (!updateFlag) {
        // starTrekPayload.map(async (obj: any) => {
        //     let res = await beApi.post(`add-rate`, {
        //         hotel_id: hotel_id,
        //         partner_id: agentId,
        //         room_type_id: obj.room_type_id,
        //         season_id: obj.season_id,
        //         rate_plan_id: obj.rate_plan_id,
        //         bar_price: obj.bar_price,
        //         multiple_occupancy: obj.multiple_occupancy,
        //         extra_adult: obj.extra_adult,
        //         extra_child: obj.extra_child
        //     });
        // })
        // } else {
        setSaveLoader(true);
        const spacexPayload: any = {
            hotel_id: hotel_id,
            partner_id: agentId,
            season_id: selectedRatePlan.season_id,
            room_type_id: selectedRatePlan.room_type_id,
            allocate_room:+allocateroom,
            validate_from:selectedRatePlan.validate_from,
            validate_to:selectedRatePlan.validate_to,
        };
        const ratePlanArr: any = [];
        starTrekPayload.map((obj: any) => {
            let dataObj: any = {};
            dataObj.rate_plan_id = obj.rate_plan_id;
            dataObj.rate_plan_name = obj.plan_name;
            dataObj.bar_price = obj.bar_price;
            dataObj.multiple_occupancy = obj.multiple_occupancy;
            dataObj.extra_adult = obj.extra_adult;
            dataObj.extra_child = obj.extra_child;
            ratePlanArr.push(dataObj);
        }
        );
        spacexPayload.rate_plan = ratePlanArr;
       
        try {
            if (!updateFlag) {
                let res = await beApi.post(`add-rate`, spacexPayload);
                if (res.data.status === 1) {
                    setSaveLoader(false);
                    toast.success(res.data.message);
                    onClose();
                    refreshFunntion();
                } else {
                    setSaveLoader(false);
                    toast.success(res.data.message);
                }
            } else {
                let res = await beApi.post(`update-rate`, spacexPayload);
                if (res.data.status === 1) {
                    setSaveLoader(false);
                    toast.success(res.data.message);
                    onClose();
                    refreshFunntion();
                } else {
                    setSaveLoader(false);
                    toast.success(res.data.message);
                }
            }
        } catch {
            setSaveLoader(false);
            toast.error(CATCH_MESSAGE);
        }
    }

    return (
        <>
            <div className='side__slider__header__label__wrapper'>
                <div className='side__slider__close'>
                    <i className='bi bi-x' onClick={()=> {
                        onClose();
                        refreshFunntion();
                    }}></i>
                </div>
                <div className='standard-page--label'> {`${selectedRatePlan?.room_type_name} (${selectedRatePlan?.season_name} , ${moment(
                        selectedRatePlan?.validate_from
                      ).format("DD MMMM YYYY")} - ${moment(
                        selectedRatePlan?.validate_to
                      ).format("DD MMMM YYYY")})`}</div>
            </div>
            <div className="height-64px"></div>
            <div className="div--email--input">
                        <InputTextField
                        type="number"
                            value={allocateroom}
                            // helperText={""}
                            label="Allocate Room"
                            handleChange={(e) => {
                                validatePositiveInt(e.target.value) && setAllocateroom((e.target.value));
                            }}
                            // handleFocus={(e) => {
                            //     if (!e.target.value) setPhoneNumberHelperText("");
                            // }}
                        />
                    </div>

            {/* ----------Rate plan container wrapper--------------- */}
            {starTrekPayload.map((obj: any, id: number) => {
                const { multiple_occupancy } = obj;
                
                return (
                    <div className="partner__rate__plan__box" key={id}>               
                        <div className='partner__rate__plan__box__heading'>
                            <div className='leftHalf'>
                                <div className="rate__plan__heading">
                                    <i
                                        className="bi bi-cup-straw"
                                        style={{ marginRight: "10px" }}
                                    ></i>
                                    <div>{`${obj?.plan_type}`}</div>
                                </div>
                                <div className="rate__plan__heading">
                                    <i
                                        className="bi bi bi-person"
                                        style={{ marginRight: "10px" }}
                                    ></i>
                                    <div>Max {`(${obj?.max_people})`}</div>
                                </div>
                            </div>

                            {/* <div className="apply__block rightHalf">
                                <div className="inputBox">
                                    <input
                                        // type="number"
                                        placeholder=''
                                        value={obj?.percent_input}
                                        onChange={(e: any) => {
                                            percentChange(e, id);
                                        }}
                                    />
                                    <div className="percentBox">%</div>
                                </div>
                                <Button
                                    className='apply__block__color'
                                    handleClick={() => percentFetch(id, obj)}
                                >Apply</Button>
                            </div> */}
                        </div>
                        <div className='partner__rate__plan__box__content'>
                            <div className="content__left">
                                <InputTextField
                                    label='BAR Price'
                                    // type='number'
                                    value={obj?.bar_price}
                                    handleChange={(e) => {
                                        validatePositiveInt(e.target.value) && setBarPriceFunc(e, id);
                                    }}
                                />
                                <InputTextField
                                    label='Extra Adult'
                                    // type='number'
                                    value={obj?.extra_adult}
                                    handleChange={(e) => {
                                        validatePositiveInt(e.target.value) && setExtraAdultFunc(e, id);
                                    }}
                                />
                                <InputTextField
                                    label='Extra Child'
                                    // type='number'
                                    value={obj?.extra_child}
                                    handleChange={(e) => {
                                        validatePositiveInt(e.target.value) && setExtraChildFunc(e, id);
                                    }}
                                />
                            </div>
                            <div className="content__right">
                                <div style={{marginTop:"6px"}} className='multiple__occupancy__container'>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="add-rooms-input">
                                            Multiple Occupancy
                                        </InputLabel>
                                        <OutlinedInput
                                            id="add-rooms-input"
                                            label="Add Room Numbers"
                                            style={{ backgroundColor: "white", maxWidth: "650px" }}
                                            value={id === identity ? roomInput : ''}
                                            multiline
                                            onChange={(e) => { 
                                                validatePositiveInt(e.target.value) && onRoomInputChange(e, id); 
                                            }}
                                            onBlur={(e)=> {
                                                // e.target.value = '';
                                                // setRoomInput('');
                                                onBlurRoomInput(e, id, obj);
                                            }}
                                            onKeyPress={(e) => onRoomEnterPress(e, id, obj)}
                                            startAdornment={
                                                obj?.multiple_occupancy.length > 0 &&
                                                (
                                                    <div className="rooms__input__container">
                                                        {obj?.multiple_occupancy.map((room: any, id_no: number) => {
                                                            return (
                                                                <div key={id_no} className="room__number__div">
                                                                    {room}
                                                                    <i
                                                                        className="bi bi-x icon--close"
                                                                        onClick={() => removeRoomInput(room, id)}
                                                                    ></i>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            )}



            {/* -----------------footer section---------------------- */}
            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                <Button
                    handleClick={() => saveHandler()}
                    loader={saveLoader}
                >
                    Save
                </Button>
            </div>
        </>
    )
}

export default RateSetup