import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import InputTextField from "../../Views/inputtextfield/InputTextField";
import Button from "../../Views/buttons/Button";
import cmApi from "../../API/cmApi";
import { toast } from "react-toastify";
import CATCH_MSG from "../../Constants/CatchMsg";

interface IFetchBooking {
    onClose: () => void;
}

const FetchBooking: React.FC<IFetchBooking> = ({ onClose }) => {
    // const { current_property } = useSelector(
    //     (state: RootState) => state.properties
    // );
    const { auth_token, hotel_id } = useSelector(
        (state: RootState) => state.auth
    );

    const [openDatePicker, setOpenDatePicker] = useState(false);

    const [allChannels, setAllChannels] = useState<any>([]);
    const [selectedChannel, setSelectedChannel] = useState<any>([]);
    const [bookingDate, setBookingDate] = useState<any>(moment().format("YYYY-MM-DD"));
    const [bookingID, setBookingID] = useState('');
    const [loader, setLoader] = useState<boolean>(false);


    const onDateChange = (date: any) => {
        let date_str = moment(date).format("YYYY-MM-DD");
        setBookingDate(date_str);
    };

    useEffect(() => {
        fetchAllChannels();
    }, [hotel_id])

    const fetchAllChannels = async () => {

        try {
            let fetch_all_channels = await cmApi.get(`/cm_ota_details/get/${hotel_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth_token}`
                    }
                }
            )


            if (fetch_all_channels.data.status === 1) {
                setAllChannels(fetch_all_channels.data.data)
            }
        }
        catch (error: any) {
            toast.error(CATCH_MSG)
        }

    }




    const fetchBookings = async () => {

        if (!selectedChannel) {
            toast.error("Please select one channel !");
            return;
        }

        if (!bookingID) {
            toast.error("Booking ID not provided !");
            return;
        }

        if (bookingID && selectedChannel) {
            let booking_date = moment(bookingDate).format("YYYY-MM-DD")
            try {
                setLoader(true);
                let fetch_booking = await cmApi.get(`/manual-ota-booking/${hotel_id}/${bookingID}/${selectedChannel}/${booking_date}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth_token}`
                        }
                    })

                if (fetch_booking.data.status === 1) {
                    setLoader(false);
                    toast.success(fetch_booking.data.message);
                    onClose();
                }
                else {
                    setLoader(false);
                    toast.error(fetch_booking.data.message);
                    onClose();
                }
            }
            catch (error: any) {
                setLoader(false);
                toast.error(CATCH_MSG)
            }
        }
    }



    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Fetch Booking</div>
            </div>
            <div className="height-64px"></div>

            <div className="fetch__booking__wrapper">
                <div className="select__channel__wrapper mb-4">
                    <InputSelectField
                        label="Select Channel"
                        value={selectedChannel}
                        handleChange={(e) => { setSelectedChannel(e.target.value) }}
                        options={allChannels.map((data: any) => {
                            return data.ota_name
                        })}
                        values={allChannels.map((data: any) => {
                            return data.ota_name
                        })}
                    />
                </div>

                <div className="select__date__wrapper booking__crs__material__input mb-4">
                    <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select Booking Dates"
                                open={openDatePicker}
                                onOpen={() => setOpenDatePicker(true)}
                                onClose={() => setOpenDatePicker(false)}
                                value={bookingDate}
                                onChange={onDateChange}
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                maxDate={new Date()}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        onClick={() => setOpenDatePicker(true)}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>

                <div className="booking__id__wrapper">
                    <InputTextField
                        label="Booking ID"
                        value={bookingID}
                        handleChange={(e) => { setBookingID(e.target.value) }}
                    />
                </div>
            </div>

            <div className="side__slider__panel__btn">
                <div className="button__wrapper ">
                    <Button
                        className="me-3"
                        icon="bi bi-download"
                        handleClick={fetchBookings}
                        loader={loader}
                    >
                        Fetch
                    </Button>

                </div>
            </div>

        </>
    );
};
export default FetchBooking;
