import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Logout } from "../../UtilityFunctions";
import ReactTooltip from "react-tooltip";
import { CreateRipple } from "../../Views/buttons/NextButton";
import { RootState } from "../../Redux/store";

const Profile = () => {
  const { full_name } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const [showManageDropdown, setShowManageDropdown] = useState(false);
  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [openSlideChangeEmail, setOpenSlideChangeEmail] =
    useState<boolean>(false);

  document.addEventListener("mouseup", function (e: any) {
    let trigger = document.getElementById("manage-logout-trigger");
    let container = document.getElementById("manage-logout");
    if (
      container &&
      !container.contains(e.target) &&
      trigger &&
      !trigger.contains(e.target)
    ) {
      setShowManageDropdown(false);
    }
  });

  return (
    <>
      <div
        id="manage-logout-trigger"
        className="mange_user_wrapper_dropdown_logout"
        onClickCapture={CreateRipple}
        onClick={() => setShowManageDropdown(!showManageDropdown)}
        style={{ margin: "0" }}
        data-for="toolTipTitleProfile"
        data-tip={"User"}
        data-place="bottom"
        data-delay-show="800"
      >
        <div className="user__hotel__initial__name">
          {full_name.slice(0, 1)}
        </div>
      </div>
      <ReactTooltip id="toolTipTitleProfile" globalEventOff="click" />

      <div
        id="manage-logout"
        className={`dropdown__logout__wrapper ${
          showManageDropdown ? "dropDropMenuShow" : "dropDropMenuHide"
        }`}
      >
        <div className="dropdown__menu_list">
          <div className="hotel__admin__user__name__logout">
            {full_name?.length > 20
              ? full_name.slice(0, 20) + "..."
              : full_name}
          </div>
          <div className="hr--line my-0"></div>

          <div className="hr--line my-0"></div>
          <div
            className="hotel__admin__user__log__out__btn"
            onClick={() => {
              Logout();
              //   dispatch(GetRedirectURLStatu(false));
            }}
          >
            Log Out <i className="bi bi-box-arrow-right ms-2"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
