import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import { RootState } from "../../Redux/store";
import Button from "../../Views/buttons/Button";
import InventoryBtn from "../../Views/buttons/Button";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputTextField from "../../Views/inputtextfield/InputTextField";
import Loader from "../../Views/loader/Loader";
import { Capitalize, Logout, validatePositiveInt } from "../../UtilityFunctions";
import { useDispatch } from "react-redux";

// MUI date picker
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { ShowConfirmationPrompt, CancelConfirmationPrompt } from "../../Redux/actions/PromptAction";

export interface IModifyCrsBookingProps {
  onClose: () => void;
  refreshData: () => void;
  booking_details: any
}

const ModifyCrsBooking: React.FC<IModifyCrsBookingProps> = ({
  onClose,
  refreshData,
  booking_details
}) => {

  const dispatch = useDispatch();

  // for dates tab
  const [selectedBookingData, setSelectedBookingData] = useState<any>({})
  const [loaderFetchSelctedBookingData, setLoaderFetchSelectedBookingData] = useState(false);
  const [startDate, setStartDate] = useState<any>(moment());
  const [endDate, setEndDate] = useState<any>(moment().add(1, "d"));
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [loaderCheckAvailabilityDates, setLoaderCheckAvailabilityDates] = useState(false);
  const [datesAvailableStatus, setDatesAvailableStatus] = useState<any>('');
  const [loaderSaveAvailableDates, setLoaderSaveAvailableDates] = useState(false);
  const [loaderPaymentReceived, setLoaderPaymentReceived] = useState(false);

  const [roomTypePrice, setRoomTypePrice] = useState<any>([]);
  const [helperTextRoomTypePrice, setHelperTextRoomTypePrice] = useState<any>([]);
  // for dates tab



  // for room types tab
  // const [selectedRoomTypes,setSelectedRoomTypes]=useState<any>([]);
  // const [loaderFetchSelectedRoomTypesData,setLoaderFetchSelectedRoomTypesData] = useState(false)
  // for room types tab


  // for guest details tab
  const [selectedBookingGuestData, setSelectedBookingGuestData] = useState<any>();
  const [loaderFetchGuestData, setLoaderFetchGuestData] = useState(false);

  const [searchMobileNumber, setSearchMobileNumber] = useState<any>("");
  const [loaderSearchMobileNumber, setLoaderSearchMobileNumber] = useState(false);

  const [modifyFullName, setModifyFullName] = useState('')
  const [modifyMobile, setModifyMobile] = useState<any>('')
  const [modifyEmail, setModifyEmail] = useState('')
  const [modifyAddress, setModifyAddress] = useState<any>('')


  const [modifyCompanyName, setModifyCompanyName] = useState('')
  const [modifyCompanyAddress, setModifyCompanyAddress] = useState<any>('')
  const [modifyGSTIN, setModifyGSTIN] = useState('')

  const [modifyBookingTypeName, setModifyBookingTypeName] = useState('');
  const [modifyGroupGuestName, setModifyGroupGuestName] = useState('');
  const [modifyRoomDetails, setModifyRoomDetails] = useState<any>([])

  const [modifyInternalRemark, setModifyInternalRemark] = useState('')
  const [modifyGuestRemark, setModifyGuestRemark] = useState<any>('')
  const [modifyModificationRemark, setModifyModificationRemark] = useState('')
  const [loaderSaveGuestDetails, setLoaderSaveGuestDetails] = useState(false);
  // for guest details tab  

  // for payment capture tab
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(null);
  const [singleDate, setSingleDate] = useState<any>([]);
  const [paymentCaptureAmount, setPaymentCaptureAmount] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [transactionsData, setTransactionsData] = useState<any>([]);
  const [transactionsTotalAmount, setTransactionsTotalAmount] = useState<any>([]);
  const [transactionsDataStatus, setTransactionsDataStatus] = useState(false);
  const [paymentReceivedId, setPaymentReceivedId] = useState(0);

  const { getBookingDetails, checkAvailabilityDates, saveAvailableDates, getSelectedRoomTypes, getAllRoomTypeRatePlans, getSelectedBookingGuestDetails, saveModifyGuestDetails } = endPoints.MODIFYBOOKING
  const { userInfoCrsGet, crsPaymentCapture, crsPaymentCaptureList, crsDeletePaymentCapture } = endPoints.BOOKINGS;

  const { company_id, hotel_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    fetchBookingData(booking_details);
    // fetchAllRoomTypeRatePlans();
    // fetchSelectedRoomTypesData(booking_details);
    fetchSelectedBookingGuestDetails(booking_details);
  }, [booking_details]);

  // for dates tab
  const fetchBookingData = async (booking_data: any) => {
    setLoaderFetchSelectedBookingData(true);
    try {
      let fetch_booking_data = await beApi.get(`${getBookingDetails}/${hotel_id}/${booking_data.bookingid}`)

      if (fetch_booking_data.data.status === 1) {
        setSelectedBookingData(fetch_booking_data.data.booking_type)
        setLoaderFetchSelectedBookingData(false);
      }
      else {
        toast(fetch_booking_data.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    }
    catch (error: any) {
      console.log(error);
    }
  }

  useEffect(() => {
    setDatesAvailableStatus('');

  }, [startDate, endDate])

  const checkAvailabilityOfDates = async () => {
    if (startDate && endDate) {
      let helper_text_value: any = [];
      setHelperTextRoomTypePrice([]);
      setRoomTypePrice([]);
      setDatesAvailableStatus('')
      setLoaderCheckAvailabilityDates(true);
      try {
        let check_availability_dates = await beApi.post(`${checkAvailabilityDates}`, {
          booking_id: selectedBookingData.booking_id,
          hotel_id: hotel_id,
          from_date: startDate.format("DD-MM-YYYY"),
          to_date: endDate.format("DD-MM-YYYY")
        })

        if (check_availability_dates.data.status === 1) {

          setRoomTypePrice(check_availability_dates.data.room_rates);
          check_availability_dates.data.room_rates.map((items) => {
            helper_text_value.push(false);
          })
          setHelperTextRoomTypePrice(helper_text_value);

          setLoaderCheckAvailabilityDates(false);
          setDatesAvailableStatus('available')
        }
        else {
          setLoaderCheckAvailabilityDates(false);
          setDatesAvailableStatus('not_available')
        }

      }
      catch (error: any) {
        console.log(error);
      }
    }
    else {
      setDatesAvailableStatus('')
      toast("Dates not selected !", {
        hideProgressBar: true,
        type: "error",
      });
    }
  }

  const changeSellingPriceOnModify = (price_index, value) => {

    if (!value || !value.match(/^[0-9]*$/)) {
      helperTextRoomTypePrice[price_index] = true
      setHelperTextRoomTypePrice([...helperTextRoomTypePrice]);
    }

    roomTypePrice[price_index].room_rate = value;
    setRoomTypePrice([...roomTypePrice])
    if (!value.length) {
      setHelperTextRoomTypePrice([]);
    }
  }


  const saveAvailableDatesForModify = async () => {

    if (datesAvailableStatus === 'available' && startDate && endDate && !helperTextRoomTypePrice.includes(true)) {
      setLoaderSaveAvailableDates(true);
      try {
        let save_available_dates = await beApi.post(`${saveAvailableDates}`, {
          booking_id: selectedBookingData.booking_id,
          hotel_id: hotel_id,
          check_in: startDate.format("DD-MM-YYYY"),
          check_out: endDate.format("DD-MM-YYYY"),
          room_rates: roomTypePrice
        })

        if (save_available_dates.data.status === 1) {
          refreshData();
          onClose();
          toast(save_available_dates.data.message, {
            hideProgressBar: true,
            type: "success",
          });
          setLoaderSaveAvailableDates(false);
        }
        else {
          refreshData();
          setLoaderSaveAvailableDates(false);
          toast(save_available_dates.data.message, {
            hideProgressBar: true,
            type: "error",
          });
        }
      }
      catch (error: any) {
        console.log(error);
      }
    }
    else {

      if (datesAvailableStatus === '') {
        toast("Click on Check Availability to check for the availability of selected dates.", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }


      if (datesAvailableStatus === 'not_available') {
        toast("Selected dates are not available for modify !", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }

      if (!startDate || !endDate) {
        toast("Dates not selected !", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }

      if (helperTextRoomTypePrice.includes(true)) {
        toast("Enter Valid Price !", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }
    }
  }
  // for dates tab


  // for room types tab

  // const fetchAllRoomTypeRatePlans = async() =>{
  //   try{
  //     let fetch_all_roomtype_rateplans = await beApi.get(`${getAllRoomTypeRatePlans}/${hotel_id}`);

  //     console.log(fetch_all_roomtype_rateplans);

  //   }
  //   catch(error:any){
  //     console.log(error);
  //   }
  // }




  // const fetchSelectedRoomTypesData = async (booking_data: any) => {
  //   setLoaderFetchSelectedRoomTypesData(true);
  //   try {
  //     let fetch_selected_room_types = await beApi.get(`${getSelectedRoomTypes}/${hotel_id}/${booking_data.bookingid}`)

  //     if (fetch_selected_room_types.data.status === 1) {
  //       setSelectedRoomTypes(fetch_selected_room_types.data.booking_type)
  //       setLoaderFetchSelectedRoomTypesData(false);
  //     }
  //     else {
  //       toast(fetch_selected_room_types.data.message, {
  //         hideProgressBar: true,
  //         type: "error",
  //       });
  //       setLoaderFetchSelectedRoomTypesData(false)
  //     }
  //   }
  //   catch (error: any) {
  //     console.log(error);
  //   }
  // }

  // console.log(selectedRoomTypes);
  // for room types tab


  // for guest details tab

  const fetchSelectedBookingGuestDetails = async (booking_data: any) => {
    setLoaderFetchGuestData(true);
    try {
      let fetch_selected_booking_guest_details = await beApi(`${getSelectedBookingGuestDetails}/${hotel_id}/${booking_data.bookingid}`);

      if (fetch_selected_booking_guest_details.data.status === 1) {
        let fetched_data = fetch_selected_booking_guest_details.data.data
        setSelectedBookingGuestData(fetch_selected_booking_guest_details.data.data)


        setModifyFullName(fetched_data.full_name)
        setModifyMobile(fetched_data.mobile)
        setModifyEmail(fetched_data.email)
        setModifyAddress(fetched_data.address)


        setModifyCompanyName(fetched_data.company_name)
        setModifyCompanyAddress(fetched_data.company_address)
        setModifyGSTIN(fetched_data.GSTIN)

        setModifyGroupGuestName(fetched_data.group_guest_name);
        setModifyBookingTypeName(fetched_data.booking_type_name)
        setModifyRoomDetails(fetched_data.room_details)

        setModifyInternalRemark(fetched_data.internal_remark)
        setModifyGuestRemark(fetched_data.guest_remark)
        setModifyModificationRemark(fetched_data.modification_remark)


        setLoaderFetchGuestData(false);
      }
      else {
        toast(fetch_selected_booking_guest_details.data.message, {
          hideProgressBar: true,
          type: "error",
        });
        setLoaderFetchGuestData(false);
      }
    }
    catch (error: any) {
      console.log()
    }
  }


  const fetchUserInfoCrs = async (guest_det: any) => {
    setLoaderSearchMobileNumber(true);
    let res = await beApi.post(`${userInfoCrsGet}`,
      {
        mobile_number: `+91${guest_det}`,
        company_id: company_id,
      });

    if (res.data.status === 1) {
      let fetched_data = res.data.data;

      setModifyFullName(`${fetched_data.first_name} ${fetched_data.last_name}`);
      setModifyEmail(fetched_data.email_id);
      setModifyMobile(guest_det);
      setLoaderSearchMobileNumber(false);

    } else {
      toast(res.data.message, {
        hideProgressBar: true,
        type: "error",
      });
      setLoaderSearchMobileNumber(false);
    }
  };


  const changeGuestDetailsName = (room_type_index, guest_name_index, value) => {
    modifyRoomDetails[room_type_index].guest_details[guest_name_index] = value;
    setModifyRoomDetails([...modifyRoomDetails])
  }

  const saveModifiedGuestDetails = async () => {
    setLoaderSaveGuestDetails(true);
    updateRoomDetailsWithRoomTypeId();
    try {
      let save_modified_guest_details = await beApi.post(`${saveModifyGuestDetails}`, {
        hotel_id: hotel_id,
        booking_id: selectedBookingData.booking_id,
        full_name: modifyFullName,
        mobile: modifyMobile,
        email_id: modifyEmail,
        address: modifyAddress,
        company_name: modifyCompanyName,
        company_address: modifyCompanyAddress,
        GSTIN: modifyGSTIN,
        room_details: modifyRoomDetails,
        internal_remark: modifyInternalRemark,
        guest_remark: modifyGuestRemark,
        modification_remark: modifyModificationRemark,
        booking_type_name: modifyBookingTypeName,
        group_guest_name: modifyGroupGuestName,
        business_booking: selectedBookingGuestData.business_booking
      });

      if (save_modified_guest_details.data.status === 1) {
        toast(save_modified_guest_details.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        setLoaderSaveGuestDetails(false)
        refreshData();
      }
      else {
        toast(save_modified_guest_details.data.message, {
          hideProgressBar: true,
          type: "error",
        });
        setLoaderSaveGuestDetails(false)
        refreshData();
      }
    }
    catch (error: any) {
      console.log(error);
    }
  }


  // method to update the room details with room type id
  const updateRoomDetailsWithRoomTypeId = () => {
    const updatedModifyRoomDetails = [...modifyRoomDetails];
    booking_details?.room_data?.forEach((ratePlanID: any, index: any) => {
      updatedModifyRoomDetails[index].rate_plan_id = ratePlanID?.rate_plan_id;
    });
    setModifyRoomDetails(updatedModifyRoomDetails);
  }

  useEffect(() => {
    if (!selectedBookingData.booking_id) {
      return
    }
    getTransactionOccurrence();
  }, [selectedBookingData.booking_id]);

  const onDateChange = (date: any) => {
    let datesArray = moment(date).format("YYYY-MM-DD");
    setFromDate(moment(date));
    setSingleDate(datesArray);
  };

  const capturePaymentMode = (paymentMode: string) => {
    setPaymentMode(paymentMode);
  }


  // method to submit payment method type
  const submitPaymentReceived = async () => {
    setLoaderPaymentReceived(true)
    if (!singleDate.length) {
      toast.error("Please select a Date")
    } else if (!paymentCaptureAmount) {
      toast.error("Please enter the amount")
    } else if (!paymentMode) {
      toast.error("Please select a payment mode")
    } else {
      try {
        const payload = {
          "id": paymentReceivedId,
          "booking_id": selectedBookingData.booking_id,
          "hotel_id": hotel_id,
          "date": singleDate,
          "amount": paymentCaptureAmount,
          "payment_mode": paymentMode.toLowerCase(),
          "ref_no": referenceNumber
        }
        const response = await beApi.post(`${crsPaymentCapture}`, payload, {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        })

        if (response?.data?.status === 1) {
          toast.success(response?.data?.message);
          getTransactionOccurrence();
          setLoaderPaymentReceived(false)
          setPaymentReceivedId(0)
        }

        if (response?.data?.status === 401) {
          Logout();
        }

      } catch (error) {
        console.log(error);
      }
    }
    setLoaderPaymentReceived(false)
  }


  const getTransactionOccurrence = async () => {
    try {
      const response = await beApi.get(`${crsPaymentCaptureList}/${selectedBookingData.booking_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      })
      if (response?.data?.status === 1) {
        setTransactionsData(response?.data?.list);
        setTransactionsTotalAmount(response?.data?.total_amount);
        setTransactionsDataStatus(true);
        setPaymentCaptureAmount("");
        setPaymentMode("");
        setReferenceNumber("");
        setStartDate(moment());
      } else if (response?.data?.status === 0) {
        setTransactionsData([]);
        setTransactionsDataStatus(false);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleNegativeNumber = (event: any) => {
    // Prevents the input of negative numbers
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      event.preventDefault();
    }
  };

  // type defined for transaction
  type TransactionDetails = {
    id: number;
    invoice_id: number;
    payment_receive_date: string;
    receive_amount: number;
    payment_mode: string;
    ref_no: string
  }

  // edit the payment remark block
  const editPaymentReceivedBlock = (transactionDetails: TransactionDetails) => {
    setPaymentReceivedId(transactionDetails.id);
    setSingleDate(transactionDetails.payment_receive_date);
    setFromDate(transactionDetails.payment_receive_date);
    setPaymentCaptureAmount(transactionDetails.receive_amount.toString());
    setPaymentMode(transactionDetails.payment_mode.toLowerCase());
    setReferenceNumber(transactionDetails.ref_no);

    const section = document.querySelector('#modify-payment-received-tab');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  // delete the payment remark block
  const deletePaymentReceivedBlock = async (id: number) => {
    setLoaderPaymentReceived(true)
    dispatch(ShowConfirmationPrompt(`Are you sure want to delete the payment remarks?`,
      "DELETE",
      async () => {
        try {
          const response = await beApi.get(`${crsDeletePaymentCapture}/${id}`, {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          })
          if (response.data.status === 1) {
            toast.success(response.data.message);
            getTransactionOccurrence();
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoaderPaymentReceived(false)
        }
        dispatch(CancelConfirmationPrompt());
      }, ["No", "Yes"]
    ))
    setLoaderPaymentReceived(false)
  }

  return (
    <>
      {loaderFetchSelctedBookingData ?
        <div className="loader-setup">
          <Loader />
        </div>

        :
        <>
          <div className="side__slider__header__label__wrapper">
            <div className="side__slider__close">
              <i id="close-slider" onClick={onClose} className="bi bi-x"></i>
            </div>
            <div className="standard-page--label">Modify Booking</div>
            <div className="side__slider__sub__heading me-3">
              ID :  {selectedBookingData && selectedBookingData.booking_id}
            </div>
          </div>

          <div className="height-64px"></div>
          <div className="modify__booking__nav__tab">
            <ul className="nav nav-tabs mb-3 modify-crs-booking-nav" id="modifyBooking" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text active"
                  id="modify-dates-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#dates-tab"
                  type="button"
                  role="tab"
                  aria-controls="modify-dates-tab"
                  aria-selected="true"
                >
                  Dates
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="modify-guests-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#guests-tab"
                  type="button"
                  role="tab"
                  aria-controls="modify-guests-tab"
                  aria-selected="true"
                >
                  Guests
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link tab--link--text"
                  id="modify-payment-received-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#payment-received-tab"
                  type="button"
                  role="tab"
                  aria-controls="modify-payment-received-tab"
                  aria-selected="true"
                >
                  Payment Received
                </button>
              </li>
            </ul>

            <div className="tab-content modify__booking__content" id="modifyBookingContent">

              {/* Date section Tab */}
              {selectedBookingData && <div className="tab-pane fade show active" id="dates-tab" role="tabpanel" aria-labelledby="modify-dates-tab">
                <div className="booking__details__guest__wrapper mt-4 pt-3">
                  <div className="guest__detail__card">
                    <div className="guest__name__wrapper">
                      <div className="guest__name">{selectedBookingData.full_name}</div>
                      <div className="booking__channel__icon">
                        <img
                          className="booking__source__image"
                          src={selectedBookingData.logo}
                          alt="Booking Source Icon"
                        />
                      </div>
                    </div>

                    <div className="guest__booking__date mt-2">
                      Booking Date - {selectedBookingData.booking_date}
                    </div>
                    <div className="guest__email__contact__details mt-2">
                      <div className="guest__number">
                        <i className="bi bi-telephone"></i> {selectedBookingData.mobile}
                      </div>
                      <div className="guest__email__id">
                        <i className="bi bi-envelope"></i> {selectedBookingData.email_id}
                      </div>
                    </div>

                    <div className="checkin__checkout__wrapper mt-3">
                      <div className="checkinout__content">
                        <div className="check__inout__details check-in-border">
                          <div className="check__inout__icon check-in-color">
                            <i className="bi bi-arrow-down-right-circle"></i>
                          </div>
                          <div className="check__inout__date">
                            <div className="check__inout check-in-color">
                              {selectedBookingData.check_in}
                            </div>
                            <div className="check__inout__label">Check-in</div>
                          </div>
                        </div>
                      </div>

                      <div className="number__of__nights__wrapper">
                        <div className="night__icon">
                          <i className="bi bi-moon-fill"></i>
                        </div>
                        <div className="night__number">{selectedBookingData.nights}</div>
                      </div>

                      <div className="checkinout__content">
                        <div className="check__inout__details check-out-border">
                          <div className="check__inout__icon check-out-color">
                            <i className="bi bi-arrow-up-left-circle"></i>
                          </div>
                          <div className="check__inout__date">
                            <div className="check__inout check-out-color">
                              {selectedBookingData.check_out}
                            </div>
                            <div className="check__inout__label">Check-out</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="standard-page--label my-4">
                  Choose New Check-in/out Date
                </div>
                <div className="inventory__update__wrapper px-0 mx-0">
                  <div
                    className="inventory__select__date__wrapper my-4"
                    onClick={() => setFocusedInput("startDate")}
                  >
                    <div className="date__picker__icon">
                      <i className="bi bi-calendar"></i>
                    </div>
                    <div className="date__label">
                      {!startDate && !endDate
                        ? "Select Date Range"
                        : (startDate ? startDate.format("DD MMM, YYYY") : "") +
                        " - " +
                        (endDate ? endDate.format("DD MMM, YYYY") : "")}
                    </div>
                    <CustomDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      focusedInput={focusedInput}
                      setFocusedInput={setFocusedInput}
                      preventPastDates={true}
                    />
                  </div>
                </div>

                <div className="check_avilability_btn">
                  <InventoryBtn
                    handleClick={checkAvailabilityOfDates}
                    loader={loaderCheckAvailabilityDates}
                  >
                    Check Availability
                  </InventoryBtn>
                </div>

                {datesAvailableStatus === 'available' && <><div className="availability__status__wrapper">
                  <div className="dates__wrapper">
                    <div className="dates__value">
                      {startDate && startDate.format("DD MMM YY")} - {endDate && endDate.format("DD MMM YY")}
                    </div>
                  </div>
                  <div className="available__status__wrapper">
                    <div className="status__value">
                      Available
                    </div>
                  </div>
                </div>

                  {roomTypePrice.map((price_data: any, price_index: any) => {
                    return (
                      <div className="room__type__card mt-4" key={price_index}>
                        <div className="standard-page--label mb-4">{price_data.room_type}</div>
                        <div className="selling__rate__display">
                          <InputTextField
                            type="Number"
                            label="Selling Price"
                            value={price_data.room_rate}
                            helperText={helperTextRoomTypePrice[price_index] ? "Enter Valid Price" : ""}
                            handleChange={(e) => { validatePositiveInt(e.target.value) && changeSellingPriceOnModify(price_index, e.target.value) }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </>
                }
                {/* Date section Tab */}



                {datesAvailableStatus === 'not_available' && <div className="availability__status__wrapper">
                  <div className="dates__wrapper">
                    <div className="dates__value">
                      {startDate && startDate.format("DD MMM YY")} - {endDate && endDate.format("DD MMM YY")}
                    </div>
                  </div>
                  <div className="notavailable__status__wrapper">
                    <div className="status__value">
                      Not Available
                    </div>
                  </div>
                </div>}


                <div style={{ height: "250px" }}></div>
                <div className="side__slider__panel__btn">
                  <div className="__btn__wrapper justify-content-between w-100">
                    <Button
                      handleClick={saveAvailableDatesForModify}
                      className="primary-btn"
                      loader={loaderSaveAvailableDates}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>}

              {/* Guests modify tab */}
              {loaderFetchGuestData
                ?
                <div className="loader-setup">
                  <Loader />
                </div>
                :
                <>
                  {selectedBookingGuestData && <div className="tab-pane fade" id="guests-tab" role="tabpanel" aria-labelledby="modify-guests-tab">
                    <div className="booker__details__wrapper">
                      <div className="booker__details__label">Booker Detail</div>

                      <div className="user__search__wrapper mb-4">
                        <div className="input__user__number">
                          <InputTextField
                            label="Phone No."
                            value={searchMobileNumber}
                            handleChange={(e) => setSearchMobileNumber(e.target.value)}
                          />
                        </div>
                        <div className="search__button">
                          <InventoryBtn
                            handleClick={() => fetchUserInfoCrs(searchMobileNumber)}
                            loader={loaderSearchMobileNumber}
                          >
                            {!loaderSearchMobileNumber && <i className="bi bi-search"></i>}
                          </InventoryBtn>
                        </div>
                      </div>

                      <div className="name__wrapper mb-4">
                        <InputTextField
                          label="Name"
                          value={modifyFullName}
                          handleChange={(e) => { setModifyFullName(e.target.value) }
                          }
                        />
                      </div>


                      <div className="user__number__email__wrapper mb-4">
                        <div className="input__user__number">
                          <InputTextField
                            label="Mobile Number"
                            value={modifyMobile}
                            handleChange={(e) => { setModifyMobile(e.target.value) }
                            }
                          />
                        </div>
                        <div className="input__user__email">
                          <InputTextField
                            label="Email Address"
                            value={modifyEmail}
                            handleChange={(e) => { setModifyEmail(e.target.value) }
                            }
                          />
                        </div>
                      </div>

                      <div className="address__wrapper">
                        <InputTextField
                          label="Address"
                          value={modifyAddress}
                          handleChange={(e) => { setModifyAddress(e.target.value) }
                          }
                        />
                      </div>
                    </div>


                    <div className="business__booking__wrapper">
                      <div className="booker__details__label">Business Booking</div>
                      <div className="company__name__address__wrapper mb-4">
                        <div className="company__name__wrapper">
                          <InputTextField
                            label="Company Name"
                            value={modifyCompanyName}
                            handleChange={(e) => { setModifyCompanyName(e.target.value) }
                            }
                          />
                        </div>

                        <div className="company__address__wrapper">
                          <InputTextField
                            label="Company Address"
                            value={modifyCompanyAddress}
                            handleChange={(e) => { setModifyCompanyAddress(e.target.value) }
                            }
                          />
                        </div>
                      </div>

                      <div className="gstin__wrapper mb-4">
                        <InputTextField
                          label="GSTIN"
                          value={modifyGSTIN}
                          handleChange={(e) => { setModifyGSTIN(e.target.value) }
                          }
                        />
                      </div>
                    </div>

                    <div className="booking__type__guest__name__wrapper mb-3">
                      <div className="modify__booking__type__wrapper mb-4">
                        <div className="booker__details__label">Booking Type</div>
                        <div className="checkbox_container">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => setModifyBookingTypeName("FIT")}
                              checked={modifyBookingTypeName === "FIT"}
                            ></input>
                            <label className="form-check-label" htmlFor="flexCheckDefaultModify">
                              FIT
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={() => setModifyBookingTypeName("GROUP")}
                              checked={modifyBookingTypeName === "GROUP"}
                            ></input>
                            <label className="form-check-label" htmlFor="flexCheckDefaultModify">
                              Group
                            </label>
                          </div>
                        </div>
                      </div>



                      {modifyBookingTypeName === "FIT" && <div className="guest__name__wrapper">
                        {modifyRoomDetails.map((room_details_data: any, room_details_index: any) => {
                          return (
                            <div className="guest__name__in__room__type__wrapper mb-3" key={room_details_index}>
                              <div className="label__wrapper mb-4">
                                <div className="label__value">{room_details_data.room_type}</div>
                              </div>

                              {room_details_data.guest_details.map((fetched_guest_name: any, fetech_guest_name_index: any) => {
                                return (
                                  <div className="room__guest__name__wrapper mb-4" key={fetech_guest_name_index}>
                                    <div className="room__label__wrapper mb-3">
                                      <div className="room__label">{`Room ${fetech_guest_name_index + 1}`}</div>
                                    </div>
                                    <div className="input__type__wrapper">
                                      <InputTextField
                                        label="Guest Name"
                                        value={fetched_guest_name}
                                        handleChange={(e) => { changeGuestDetailsName(room_details_index, fetech_guest_name_index, e.target.value) }
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          )
                        })}
                      </div>}




                      {modifyBookingTypeName === "GROUP" && <div className="guest__name__wrapper">
                        <div className="guest__name__in__room__type__wrapper mb-3">
                          <div className="label__wrapper mb-4">
                            <div className="label__value">Guest Detail</div>
                          </div>
                          <div className="room__guest__name__wrapper mb-4">
                            <div className="input__type__wrapper">
                              <InputTextField
                                label="Guest Name"
                                value={modifyGroupGuestName}
                                handleChange={(e) => { setModifyGroupGuestName(e.target.value) }
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>}
                    </div>


                    <div className="remarks__wrapper">
                      <div className="booker__details__label">Remarks</div>
                      <div className="internal__and__guest__remarks__wrapper">
                        <div className="internal__remark__wrapper">
                          <InputTextField
                            label="Internal Remark"
                            value={modifyInternalRemark}
                            handleChange={(e) => { setModifyInternalRemark(e.target.value) }}
                            multiline
                          />
                        </div>

                        <div className="guest__remarks__wrapper">
                          <InputTextField
                            label="Guest Remark"
                            value={modifyGuestRemark}
                            handleChange={(e) => { setModifyGuestRemark(e.target.value) }}
                            multiline
                          />
                        </div>
                      </div>

                      <div className="modification__remark__wrapper">
                        <InputTextField
                          label="Modification Remark"
                          value={modifyModificationRemark}
                          handleChange={(e) => { setModifyModificationRemark(e.target.value) }}
                          multiline
                        />
                      </div>
                    </div>



                    <div style={{ height: "250px" }}></div>
                    <div className="side__slider__panel__btn">
                      <div className="__btn__wrapper justify-content-between w-100">
                        <Button
                          handleClick={saveModifiedGuestDetails}
                          className="primary-btn"
                          loader={loaderSaveGuestDetails}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>}
                </>
              }
              {/* Guests modify tab */}

              {/* Payment Received Tab Content Section */}
              <div className="tab-pane fade" id="payment-received-tab" role="tabpanel" aria-labelledby="modify-payment-received-tab">
                <div className="standard-page--label my-4">Received Date</div>

                <div className="inventory__update__wrapper px-0 mx-0 mui__input__fieldset__legends__unset">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      value={fromDate}
                      onChange={(val) => onDateChange(val)}
                      // minDate={new Date()}
                      maxDate={moment().toDate()}
                      inputFormat="dd MMM yyyy"
                      InputAdornmentProps={{ position: "start" }}
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onClick={(e) => setOpen(true)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="name__wrapper my-4">
                  <InputTextField
                    label="Amount"
                    type="number"
                    inputProps={{
                      onKeyPress: handleNegativeNumber,
                    }}
                    value={paymentCaptureAmount}
                    handleChange={(e) => { setPaymentCaptureAmount(e.target.value) }
                    }
                  />
                </div>

                <div className="payment__mode__wrapper">
                  <div className="standard-page--label my-4">Mode</div>
                  <div className="div__select__inputs__wrapper">
                    <div className="select__input__label">
                      <div className={`${paymentMode.toLowerCase() === "online" ? "select__input__div__active" : "select__input__div"} me-3 mb-3 min-width-126px`} onClick={() => { capturePaymentMode("Online") }}>
                        {paymentMode.toLowerCase() === "online" ? <i className="bi bi-check-lg"></i> : null}
                        Online
                      </div>

                      <div className="select__input__label">
                        <div className={`${paymentMode.toLowerCase() === "cash" ? "select__input__div__active" : "select__input__div"} me-3 mb-3 min-width-126px`} onClick={() => { capturePaymentMode("Cash") }}>
                          {paymentMode.toLowerCase() === "cash" ? <i className="bi bi-check-lg"></i> : null}
                          Cash
                        </div>
                      </div>

                      <div className="select__input__label">
                        <div className={`${paymentMode.toLowerCase() === "card" ? "select__input__div__active" : "select__input__div"} me-3 mb-3 min-width-126px`} onClick={() => { capturePaymentMode("Card") }}>
                          {paymentMode.toLowerCase() === "card" ? <i className="bi bi-check-lg"></i> : null}
                          Card
                        </div>
                      </div>

                      <div className="select__input__label">
                        <div className={`${paymentMode.toLowerCase() === "cheque" ? "select__input__div__active" : "select__input__div"} me-3 mb-3 min-width-126px`} onClick={() => { capturePaymentMode("Cheque") }}>
                          {paymentMode.toLowerCase() === "cheque" ? <i className="bi bi-check-lg"></i> : null}
                          Cheque
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="name__wrapper mb-4 mt-3 pt-2">
                  <InputTextField
                    label="Reference Number (Optional)"
                    value={referenceNumber}
                    handleChange={(e) => { setReferenceNumber(e.target.value) }
                    }
                  />
                </div>

                <div className="check_avilability_btn mt-4 pt-3">
                  <InventoryBtn
                    handleClick={submitPaymentReceived}
                    loader={loaderPaymentReceived}
                  >
                    Submit
                  </InventoryBtn>
                </div>

                {transactionsDataStatus && transactionsData ?
                  <>
                    <div className="transactions__wrapper mt-4">
                      <div className="treansactions__card">
                        <div className="standard-page--label my-3 transactions-bold">Transactions</div>
                        {loaderPaymentReceived ?
                          <div className="loader-setup">
                            <Loader />
                          </div> :
                          <>
                            {transactionsData && transactionsData.map((transactionDetails: any, index: any) => {
                              return (
                                <div className="card__wrapper" key={index}>
                                  <div className="card__wrapper__container">
                                    <div className="transaction__card__container">
                                      <div className="transaction__details__wrapper">
                                        <div className="transaction__date mb-3 me-5 pe-5"><span className="me-2"><i className="bi bi-calendar-check"></i></span>{transactionDetails?.payment_receive_date}</div>
                                        <div className="transaction__referance mb-3"><span className="me-2"><i className="bi bi-gear-fill"></i></span>{Capitalize(transactionDetails?.payment_mode)} {transactionDetails?.ref_no ? `(${transactionDetails?.ref_no})` : null}</div>
                                      </div>

                                      <div className="transaction__amount__section">
                                        <div className="transaction__amount">₹ {transactionDetails?.receive_amount}</div>
                                        <div className="transaction__amount__edit__section">
                                          <div className="transaction__edit me-3" onClick={() => editPaymentReceivedBlock(transactionDetails)}>
                                            <i className="bi bi-pencil-square"></i>
                                          </div>
                                          <div className="transaction__delete" onClick={() => deletePaymentReceivedBlock(transactionDetails.id)}>
                                            <i className="bi bi-trash-fill"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        }
                      </div>
                    </div>

                    <div style={{ height: "110px" }}></div>
                    {transactionsTotalAmount ?
                      <div className="side__slider__panel__btn">
                        <div className="__btn__wrapper justify-content-between w-100">
                          <div className="check_avilability_btn mt-4 pt-3 w-100">
                            <div className="d-flex justify-content-between total__amount__caontainer">
                              <div>Total</div>
                              <div>₹ {transactionsTotalAmount}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null
                    }
                  </> : null
                }
              </div>
              {/* Payment Received Tab Content Section */}

            </div>
          </div>
        </>
      }

    </>
  )

}

export default ModifyCrsBooking