import React, { useEffect, useState } from "react";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import AgentBasedSales from "./OccupancyBaseCalender";
import RecentBookings from "./RecentBookings";
import TotalBookingData from "./TotalBookingData";
import BookingAndRevenue from "./BookingAndRevenue";
import DataLoader from "../../Views/loader/DataLoader";
import OccupancyBaseCalender from "./OccupancyBaseCalender";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

function Dashboard() {
  const { admin_id, hotel_id,role_id } = useSelector((state: RootState) => state.auth);
  const { corporateAgentApi } = endPoints.DASHBOARD;
  const [totalAgent, setTotalAgent] = useState<any>("0");
  const [totalCorporate, setTotalCorporate] = useState<any>("0");
  const [loader, setLoader] = useState<boolean>(false);


  useEffect(() => {
    getAgentCorporateNumber();
  }, []);

  // Api To get Agents and Corporate Number
  const getAgentCorporateNumber = async () => {
    setLoader(true)
    try {
      let agentsCorporateRes = await beApi.get(`${corporateAgentApi}/${hotel_id}`);

      if (agentsCorporateRes.data.status === 1) {
        setTotalCorporate(agentsCorporateRes.data.data.total_corporate);
        setTotalAgent(agentsCorporateRes.data.data.total_agent)
        setLoader(false)
      } else {
        setTotalCorporate("0")
        setTotalAgent("0")
        setLoader(false)
      }

    } catch (error) {
      setLoader(false)

    }
  }
  return (
    <>
      <CustomBreadcrumbs content={[{ label: "Dashboard" }]} />
      <hr />
      {
        role_id != 13 && (
          <div className="dashboard-container">
          <div className="dashboard-left-sec">
            <BookingAndRevenue />
  
          </div>
          <div className="dashboard-right-sec">
            {/* <TotalBookingData /> */}
            <div className="agents_corporate_wrapper">
              <div className="agents_corporate_value_card">
                <div className="agents_corporate_label">Agents</div>
                <div className="agents_corporate_value">
                  {loader ? <DataLoader /> : totalAgent}
                </div>
                <div className="agents_corporate_icon_wrapper">
                  <div className="agents__icon">
                    <i className="bi bi-file-earmark-person"></i>
                  </div>
                </div>
              </div>
              <div className="agents_corporate_value_card">
                <div className="agents_corporate_label">Corporate</div>
                <div className="agents_corporate_value">
                  {loader ? <DataLoader /> : totalCorporate}
                </div>
                <div className="agents_corporate_icon_wrapper">
                  <div className="corporate_icon">
                    <i className="bi bi-briefcase"></i>
                  </div>
                </div>
              </div>
            </div>
            <RecentBookings />
          </div>
        </div>
        )
      }
  
      <OccupancyBaseCalender />
    </>
  );
}

export default Dashboard;
