import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// import bootstrap icon
import "bootstrap-icons/font/bootstrap-icons.css";

import AuthRoutes from "./Routes/AuthRoutes";
import AppRoutes from "./Routes/AppRoutes";

import { PersistGate } from "redux-persist/es/integration/react";
import { persistor, RootState } from "./Redux/store";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './assets/css/styles.min.css'

import "react-sliding-pane/dist/react-sliding-pane.css";
import { useSelector } from 'react-redux';

import { BrowserRouter as Router } from "react-router-dom";
import SalesRoutes from "./Routes/SalesRoutes";

function App() {
  const isAuthorized = useSelector((state: RootState) => state.auth.auth_token);
  const { company_id ,role_id} = useSelector((state: RootState) => state.auth);

  

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer position="top-center" autoClose={1500} closeOnClick hideProgressBar={true} />
      {
        role_id == 13 ? (
      <Router>{isAuthorized && company_id ? <SalesRoutes /> : <AuthRoutes />}</Router>
          
        ): (
      <Router>{isAuthorized && company_id ? <AppRoutes /> : <AuthRoutes />}</Router>
            
        )
}
    </PersistGate>
  );
}

export default App;
