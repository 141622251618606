import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import InventoryBtn from "../../Views/buttons/Button";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";

import { toast } from "react-toastify";
import cmApi from "../../API/cmApi";
import beApi from "../../API/beApi";
import kernelApi from "../../API/kernelApi";

// import date picker
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import { TextField } from "@mui/material";

// import select field
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputTextField from "../../Views/inputtextfield/InputTextField";
import moment from "moment";

// import react-slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Logout,
  validateMobile,
  validatePositiveInt,
} from "../../UtilityFunctions";
import endPoints from "../../API/endPoints";
import axios from "axios";

interface IBlockInventory {
  onClose: () => void;
  refreshData: () => void;
  selectedCheckinDate: any;
  closeNewBookingBtn: () => void;
}

const NewBookings: React.FC<IBlockInventory> = ({
  onClose,
  refreshData,
  selectedCheckinDate,
  closeNewBookingBtn,
}) => {
  const { admin_id, company_id, hotel_id, auth_token, role_id } = useSelector(
    (state: RootState) => state.auth
  );

  const [tab, setTab] = useState(0);
  // const [startDate, setStartDate] = useState<any>(0);
  const [startDate, setStartDate] = useState<any>(0);
  const [endDate, setEndDate] = useState<any>(0);
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [rooms, setRooms] = useState(1);
  const [rate, setRate] = useState<any>(null);
  const [adult, setAdult] = useState<any>(null);
  const [child, setChild] = useState<any>(null);
  const [enableTax, setEnableTax] = useState(true);
  const [nights, setNights] = useState(0);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [allRoomTypeData, setAllRoomTypeData] = useState<any>([]);
  const [selectedPlan, setSelectedPlan] = useState<any>([]);

  const [selectedRooms, setSelectedRooms] = useState<any>();
  const [cart, setCart] = useState<any>([]);

  const [roomsPushedInCart, setRoomsPushedInCart] = useState<any>([]);
  const [fetchedRoomData, setFetchedRoomData] = useState<any>([]);
  const [selectedRatePlans, setSelectedRatePlans] = useState<any>([]);
  const [guestName, setGuestName] = useState<any>([]);
  const [guestNameDisplay, setGuestNameDisplay] = useState<any>([]);

  const [bookingType, setBookingType] = useState<any>("fit");
  const [selectedAdults, setSelectedAdults] = useState<any>();
  const [displayTotalAmount, setDisplayTotalAmount] = useState<any>("");

  const [searchMobileNumber, setSearchMobileNumber] = useState<any>("");

  const [searchGSTNumber, setSearchGSTNumber] = useState<any>("");

  const [bookerName, setBookerName] = useState<any>("");
  const [bookerEmail, setBookerEmail] = useState<any>("");

  const [bookerMobile, setBookerMobile] = useState<any>("");
  const [companyName, setCompanyName] = useState<any>("");
  const [companyAddress, setCompanyAddress] = useState<any>("");
  const [gstIn, setGstIn] = useState<any>("");
  const [enableBusinessBooking, setEnableBusinessBooking] = useState(false);

  const [guestNameValue, setGuestNameValue] = useState<any>();

  const [guestMobileValue, setGuestMobileValue] = useState<any>();

  const [displayRoomPriceFIT, setDisplayRoomPriceFIT] = useState<any>([]);
  const [displayRoomPriceGroup, setDisplayRoomPriceGroup] = useState<any>([]);

  const [switchTax, setSwitchTax] = useState<any>([]);

  const [internalRemark, setInternalRemark] = useState<any>("");
  const [guestRemark, setGuestRemark] = useState<any>("");

  const [alternativeDatesData, setAlternativeDatesData] = useState<any>([]);
  const [displayNoRoomsAvaialbleMsg, setDisplayNoRoomsAvaialbleMsg] =
    useState<any>(false);

  const [minimumAvailableRooms, setMinimumAvailableRooms] = useState<any>(0);

  const [loaderCheckAvailability, setLoaderCheckAvailability] =
    useState<any>(false);
  const [loaderBookNow, setLoaderBookNow] = useState<any>(false);
  const [bookingConfirmed, setBookingConfirmed] = useState<any>(false);

  const steps = ["Availability", "Booking Details", "Confirm"];

  const [displayTotalPrice, setDisplayTotalPrice] = useState<any>("");
  const [displayTotalTaxPrice, setDisplayTotalTaxPrice] = useState<any>("");
  const [taxDetails, setTaxDetails] = useState<any>();

  const [channelType, setChannelType] = useState<any>("direct");
  const [searchPartnerValue, setSearchPartnerValue] = useState<any>("");
  const [displayPartner, setDisplayPartner] = useState(false);
  const [allFetchedPartner, setAllFetchedPartner] = useState<any>([]);
  const [selectedPartner, setSelectedPartner] = useState<any>();
  const [partnerRates, setPartnerRates] = useState<any>([]);
  const [cartPartner, setCartPartner] = useState<any>([]);
  const [displayRoomPriceFITPartner, setDisplayRoomPriceFITPartner] =
    useState<any>([]);
  const [displayRoomPriceGroupPartner, setDisplayRoomPriceGroupPartner] =
    useState<any>([]);
  const [activeExecutiveList, setActiveExecutiveList] = useState<any>([]);

  // state to handle the non taxable booking
  const [nonTaxableBooking, setNonTaxableBooking] = useState(false);
  const [isCheckboxAltered, setIsCheckboxAltered] = useState(false);

  // state to handle the partner rate messages
  const [noPartnerRate, setNoPartnerRate] = useState<boolean>(false);
  const [partnerDefinedRate, setPartnerDefinedRate] = useState<boolean>(false);
  const [selectedPartnerType, setSelectedPartnerType] =
    useState<string>("Direct Booking");

  useEffect(() => {
    closeNewBookingBtn();
  });

  useEffect(() => {
    if (selectedCheckinDate) {
      checkAvailabilityFromView();
    }
  }, [selectedCheckinDate]);

  useEffect(() => {
    setAlternativeDatesData([]);
    setDisplayNoRoomsAvaialbleMsg(false);

    setFetchedRoomData([]);
    setSelectedRatePlans([]);

    setCartPartner([]);
    setCart([]);
    setRoomsPushedInCart([]);
  }, [nights, rooms]);

  const {
    availableRoomGet,
    userInfoCrsGet,
    userGstApi,
    companyProfileGet,
    getLocaleDetails,
    getBusinessSourceDetails,
    toRegisterUser,
    crsActiveExecutiveList,
  } = endPoints.BOOKINGS;

  useEffect(() => {
    if (endDate) {
      convertNights();
    }
  }, [startDate, endDate]);

  const next = () => {
    setTab((tab + 1) % 3);
  };

  // ----------------------------fetch cancellation policy list api-------------------------------------------
  const [policyList, setPolicyList] = useState([]);
  const [policyname, setpolicyName] = useState("");
  const [policyId, setpolicyId] = useState("");
  const [payloadPolicyId, setPayloadPolicyId] = useState(policyId);

  const fetchPolicyList = async () => {
    try {
      const data = await beApi.get(`cancellation-policy-list/${hotel_id}`);
      setPolicyList(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPolicyList();
  }, []);
  //-----------------------------------------------------------------------------------------------
  const decrement = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (state > 0) {
      setState(state - 1);
    }
  };

  const increment = (
    state: number,
    setState: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setState(state + 1);
  };

  const onBookingDetails = () => {
    let no_of_rooms_in_cart = 0;

    for (let i = 0; i < cart.length; i++) {
      no_of_rooms_in_cart += cart[i].rooms.length;
    }

    if (cart.length > 0) {
      if (no_of_rooms_in_cart > rooms) {
        toast(`Maximum ${rooms} rooms can be selected !`, {
          hideProgressBar: true,
          type: "error",
        });
      } else {
        let cart_clone: any[] = [];
        let display_room_price_FIT: any[] = [];
        let display_room_price_group: any[] = [];

        cart_clone = JSON.parse(JSON.stringify(cart));
        display_room_price_FIT = [...displayRoomPriceFIT];
        display_room_price_group = [...displayRoomPriceGroup];

        setDisplayRoomPriceFITPartner(display_room_price_FIT);
        setDisplayRoomPriceGroupPartner(display_room_price_group);
        setCartPartner(cart_clone);

        next();
      }
    } else {
      toast("Rooms not selected !", {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const onConfirm = () => {
    if (channelType === "direct") {
      setDisplayTotalAmount(cart[0].paid_amount.toFixed(2));
    }

    if (channelType === "partner") {
      setDisplayTotalAmount(cartPartner[0].paid_amount.toFixed(2));
    }

    if (bookingType === "group") {
      if (guestMobileValue) {
        if (guestMobileValue.match(/^[0-9]*$/)) {
          next();
        } else {
          toast("Invalid Mobile Number entered !", {
            hideProgressBar: true,
            type: "error",
          });
        }
      } else {
        next();
      }
    } else {
      next();
    }
  };

  const changeBookingType = (val: any) => {
    setBookingType(val);
  };

  const changeChannelType = (val: any) => {
    if (val === "partner") {
      setSelectedPartnerType("");
    } else {
      setNoPartnerRate(false);
      setPartnerDefinedRate(false);
      setSelectedPartnerType("Direct Booking");
    }

    setChannelType(val);
  };

  const fetchSelectedPartnerData = async (selected_partner_data: any) => {
    setBookerName(selected_partner_data?.partner_name);
    setBookerEmail(selected_partner_data?.email_id);
    setBookerMobile(selected_partner_data?.contact_no);

    setDisplayPartner(false);
    setPartnerRates([]);
    let room_details: any = [];
    let display_room_price_FIT: any[] = [...displayRoomPriceFIT];
    let display_room_price_group: any[] = [...displayRoomPriceGroup];

    setSelectedPartner(selected_partner_data.id);
    setSearchPartnerValue(
      selected_partner_data.partner_name +
        " (" +
        selected_partner_data?.partner_type +
        ")"
    );
    setSelectedPartnerType(selected_partner_data?.partner_type);

    let from_date = moment(startDate).format("YYYY-MM-DD");
    let to_date = moment(endDate).format("YYYY-MM-DD");

    cart.map((items: any) => {
      room_details.push({
        room_type_id: items.room_type_id,
        rate_plan_id: items.rate_plan_id,
      });
    });

    let fetch_selected_partner_data = await beApi.post(`get-rate-plan`, {
      hotel_id: hotel_id,
      partner_id: selected_partner_data.id,
      validate_from: from_date,
      validate_to: to_date,
      room_details: room_details,
    });
    if (fetch_selected_partner_data?.data.data.cancellation_policy_id !== "") {
      setpolicyId(
        fetch_selected_partner_data.data.data[0]?.cancellation_policy_id
      );
      setPayloadPolicyId(
        fetch_selected_partner_data.data.data[0]?.cancellation_policy_id
      );
      setpolicyName(
        fetch_selected_partner_data.data.data[0]?.cancellation_policy_name
      );
    }
    if (fetch_selected_partner_data.data.status === 1) {
      setPartnerRates(fetch_selected_partner_data);
      let partner_data = fetch_selected_partner_data.data.data;

      for (let i = 0; i < cartPartner.length; i++) {
        for (let j = 0; j < partner_data.length; j++) {
          if (
            cartPartner[i].room_type_id === partner_data[j].room_type_id &&
            cartPartner[i].rate_plan_id === partner_data[j].rate_plan_id
          ) {
            display_room_price_FIT[i] = parseFloat(partner_data[j].bar_price);
            display_room_price_group[i] = parseFloat(partner_data[j].bar_price);
          }
        }
      }

      setDisplayRoomPriceFITPartner(display_room_price_FIT);
      setDisplayRoomPriceGroupPartner(display_room_price_group);

      for (let cart_data of cartPartner) {
        for (let i = 0; i < partner_data.length; i++) {
          if (
            cart_data.room_type_id === partner_data[i].room_type_id &&
            cart_data.rate_plan_id === partner_data[i].rate_plan_id
          ) {
            cart_data.display_base_price = parseFloat(
              partner_data[i].bar_price
            );
            cart_data.display_extra_adult_price = parseFloat(
              partner_data[i].extra_adult
            );
            cart_data.display_extra_child_price = parseFloat(
              partner_data[i].extra_child
            );

            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                cartItem.bar_price =
                  parseFloat(partner_data[i].bar_price) * nights;

                if (cartItem.no_of_extra_adult) {
                  extra_adult_price +=
                    cartItem.no_of_extra_adult *
                    parseFloat(partner_data[i].extra_adult);
                }

                if (cartItem.no_of_extra_child) {
                  extra_child_price +=
                    cartItem.no_of_extra_child *
                    parseFloat(partner_data[i].extra_child);
                }

                cartItem.day_wise_rates.map((rates_value: any) => {
                  rates_value.bar_price = parseFloat(partner_data[i].bar_price);
                  rates_value.extra_adult_price = parseFloat(
                    partner_data[i].extra_adult
                  );
                  rates_value.extra_child_price = parseFloat(
                    partner_data[i].extra_child
                  );
                  rates_value.multiple_occupancy =
                    partner_data[i].multiple_occupancy;
                });
                cart_data.price +=
                  parseFloat(partner_data[i].bar_price) * nights;
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

            let total_gst_amount = 0;
            if (taxDetails && taxDetails.is_taxable == 1) {
              total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
            }

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }
        }
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cartPartner &&
        cartPartner.length > 0 &&
        cartPartner.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCartPartner([...cartPartner]);
      setPartnerDefinedRate(true);
      setNoPartnerRate(false);
    } else if (fetch_selected_partner_data.data.status === 0) {
      setNoPartnerRate(true);
      setPartnerDefinedRate(false);
    }
  };

  const convertNights = () => {
    let a = moment(startDate);
    let b = moment(endDate);
    let c = b.diff(a, "days");
    setNights(c);
  };

  const [validTypeValues, setValidTypeValues] = useState<any>([]);
  const [partialPaymentHour, setPartialPaymentHour] = useState<any>([]);
  const [partialPaymentDays, setPartialPaymentDays] = useState<any>([]);
  const [partialPaymentOption, setPartialPaymentOption] = useState<any>([]);
  const [partialPaymentPercentageOption, setPartialPaymentPercentageOption] =
    useState<any>([]);
  const [displayCurrency, setDisplayCurrency] = useState<any>("");

  const checkAvailabilityFromView = async () => {
    let start_date = moment(selectedCheckinDate);
    let end_date = moment(selectedCheckinDate).add(1, "d");

    setStartDate(moment(selectedCheckinDate));
    setEndDate(moment(selectedCheckinDate).add(1, "d"));
    setRooms(1);

    setAlternativeDatesData([]);
    setDisplayNoRoomsAvaialbleMsg(false);

    setFetchedRoomData([]);
    setSelectedRatePlans([]);

    setLoaderCheckAvailability(true);

    setCartPartner([]);
    setCart([]);
    setRoomsPushedInCart([]);

    let fetch_room_data: any = [];
    let selected_plans: any = [];
    let res = await beApi.post(
      `${availableRoomGet}`,
      {
        hotel_id: hotel_id,
        from_date: start_date.format("DD-MM-YYYY"),
        to_date: end_date.format("DD-MM-YYYY"),
        no_of_rooms: "1",
      },
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (res.data.paymentOptionsDetails) {
      setValidTypeValues(res.data.paymentOptionsDetails.valid_type);
      setPartialPaymentHour(res.data.paymentOptionsDetails.hours);
      setPartialPaymentDays(res.data.paymentOptionsDetails.days);
      setPartialPaymentOption(res.data.paymentOptionsDetails.payment_options);
      setPartialPaymentPercentageOption(
        res.data.paymentOptionsDetails.payment_percentage
      );
      setDisplayCurrency(res.data.paymentOptionsDetails.currency_name);
    }

    if (res.data.alternative_dates) {
      setAlternativeDatesData(res.data.alternative_dates);
      setDisplayNoRoomsAvaialbleMsg(true);

      setMinimumAvailableRooms(res.data.min_available_rooms);
    } else {
      res.data.data.map((inv_data: any) => {
        let blockStatusCount = 0;
        inv_data.inv.map((inv_Details: any) => {
          if (parseInt(inv_Details.block_status) === 0) {
            blockStatusCount++;
          }
        });
        if (blockStatusCount === inv_data.inv.length) {
          inv_data.block = false;
        } else {
          inv_data.block = true;
        }
      });

      res.data.data.map((inv_data: any) => {
        let display_no_of_rooms: any = [];
        for (let i = 1; i <= inv_data.min_inv; i++) {
          display_no_of_rooms.push(i);
        }
        inv_data.display_no_of_rooms = display_no_of_rooms;
      });

      res.data.data.map((inv_data: any) => {
        if (!inv_data.block) {
          if (inv_data.rate_plans && inv_data.rate_plans.length > 0) {
            inv_data.rate_plans.map((ratePlans: any, index: any) => {
              if (ratePlans && ratePlans.rates) {
                let blocked = false;
                ratePlans.rates.map((rate: any) => {
                  if (rate.block_status === 1) {
                    blocked = true;
                  }
                });
                if (blocked) {
                  inv_data.rate_plans.splice(index, 1);
                }
              }
            });

            fetch_room_data.push(inv_data);
            selected_plans.push({
              room_type_id: inv_data.room_type_id,
              rate_plan_id: inv_data.rate_plans[0].rate_plan_id,
              rooms_selected: "",
            });
          }
        }
      });
    }
    setLoaderCheckAvailability(false);
    setFetchedRoomData(fetch_room_data);
    setSelectedRatePlans(selected_plans);
  };

  const checkAvailability = async () => {
    setAlternativeDatesData([]);
    setDisplayNoRoomsAvaialbleMsg(false);

    setFetchedRoomData([]);
    setSelectedRatePlans([]);

    if (!startDate || !endDate) {
      toast("Please select a date range", {
        hideProgressBar: true,
        type: "error",
      });
    }

    if (startDate && endDate && rooms === 0) {
      toast("Please select number of rooms", {
        hideProgressBar: true,
        type: "error",
      });
    }

    if (startDate && endDate && rooms !== 0) {
      setLoaderCheckAvailability(true);

      setCartPartner([]);
      setCart([]);
      setRoomsPushedInCart([]);

      let fetch_room_data: any = [];
      let selected_plans: any = [];
      let res = await beApi.post(
        `${availableRoomGet}`,
        {
          hotel_id: hotel_id,
          from_date: startDate.format("DD-MM-YYYY"),
          to_date: endDate.format("DD-MM-YYYY"),
          no_of_rooms: rooms.toString(),
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (res.data.paymentOptionsDetails) {
        setValidTypeValues(res.data.paymentOptionsDetails.valid_type);
        setPartialPaymentHour(res.data.paymentOptionsDetails.hours);
        setPartialPaymentDays(res.data.paymentOptionsDetails.days);
        setPartialPaymentOption(res.data.paymentOptionsDetails.payment_options);
        setPartialPaymentPercentageOption(
          res.data.paymentOptionsDetails.payment_percentage
        );
        setDisplayCurrency(res.data.paymentOptionsDetails.currency_name);
      }

      if (res.data.alternative_dates) {
        setAlternativeDatesData(res.data.alternative_dates);
        setDisplayNoRoomsAvaialbleMsg(true);

        setMinimumAvailableRooms(res.data.min_available_rooms);
      } else {
        res.data.data.map((inv_data: any) => {
          let blockStatusCount = 0;
          inv_data.inv.map((inv_Details: any) => {
            if (parseInt(inv_Details.block_status) === 0) {
              blockStatusCount++;
            }
          });
          if (blockStatusCount === inv_data.inv.length) {
            inv_data.block = false;
          } else {
            inv_data.block = true;
          }
        });

        res.data.data.map((inv_data: any) => {
          let display_no_of_rooms: any = [];
          for (let i = 1; i <= inv_data.min_inv; i++) {
            display_no_of_rooms.push(i);
          }
          inv_data.display_no_of_rooms = display_no_of_rooms;
        });

        res.data.data.map((inv_data: any) => {
          if (!inv_data.block) {
            if (inv_data.rate_plans && inv_data.rate_plans.length > 0) {
              inv_data.rate_plans.map((ratePlans: any, index: any) => {
                if (ratePlans && ratePlans.rates) {
                  let blocked = false;
                  ratePlans.rates.map((rate: any) => {
                    if (rate.block_status === 1) {
                      blocked = true;
                    }
                  });
                  if (blocked) {
                    inv_data.rate_plans.splice(index, 1);
                  }
                }
              });

              fetch_room_data.push(inv_data);
              selected_plans.push({
                room_type_id: inv_data.room_type_id,
                rate_plan_id: inv_data.rate_plans[0].rate_plan_id,
                rooms_selected: "",
              });
            }
          }
        });
      }
      setLoaderCheckAvailability(false);
      setFetchedRoomData(fetch_room_data);
      setSelectedRatePlans(selected_plans);
    }
  };

  const callCheckAvailability = async (
    date_from: any,
    date_to: any,
    no_of_rooms: any
  ) => {
    setLoaderCheckAvailability(true);
    setAlternativeDatesData([]);
    setDisplayNoRoomsAvaialbleMsg(false);
    setMinimumAvailableRooms(0);
    // setRooms(no_of_rooms);
    setStartDate(moment(date_from));
    setEndDate(moment(date_to));

    setCartPartner([]);
    setCart([]);
    setRoomsPushedInCart([]);

    setFetchedRoomData([]);
    setSelectedRatePlans([]);

    let fetch_room_data: any = [];
    let selected_plans: any = [];

    let res = await beApi.post(
      `${availableRoomGet}`,
      {
        hotel_id: hotel_id,
        from_date: moment(date_from).format("DD-MM-YYYY"),
        to_date: moment(date_to).format("DD-MM-YYYY"),
        no_of_rooms: rooms.toString(),
      },
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    res.data.data.map((inv_data: any) => {
      let blockStatusCount = 0;
      inv_data.inv.map((inv_Details: any) => {
        if (parseInt(inv_Details.block_status) === 0) {
          blockStatusCount++;
        }
      });
      if (blockStatusCount === inv_data.inv.length) {
        inv_data.block = false;
      } else {
        inv_data.block = true;
      }
    });

    res.data.data.map((inv_data: any) => {
      let display_no_of_rooms: any[] = [];
      for (let i = 1; i <= inv_data.min_inv; i++) {
        display_no_of_rooms.push(i);
      }
      inv_data.display_no_of_rooms = display_no_of_rooms;
    });

    res.data.data.map((inv_data: any) => {
      if (!inv_data.block) {
        if (inv_data.rate_plans && inv_data.rate_plans.length > 0) {
          inv_data.rate_plans.map((ratePlans: any, index: any) => {
            if (ratePlans && ratePlans.rates) {
              let blocked = false;
              ratePlans.rates.map((rate: any) => {
                if (rate.block_status === 1) {
                  blocked = true;
                }
              });
              if (blocked) {
                inv_data.rate_plans.splice(index, 1);
              }
            }
          });

          fetch_room_data.push(inv_data);
          selected_plans.push({
            room_type_id: inv_data.room_type_id,
            rate_plan_id: inv_data.rate_plans[0].rate_plan_id,
            rooms_selected: "",
          });
        }
      }
    });
    setLoaderCheckAvailability(false);
    setFetchedRoomData(fetch_room_data);
    setSelectedRatePlans(selected_plans);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };
  const nextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prevHandler = (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => {
    //  hasPrev:true;
    return (
      <div className="button-next" onClick={prevSlide}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  };

  function SampleNextArrow(props: { onClick: any }) {
    const { onClick } = props;
    return (
      <div className="button-next" onClick={onClick}>
        <i className="bi bi-arrow-right"></i>
      </div>
    );
  }
  function SamplePrevArrow(props: { onClick: any }) {
    const { onClick } = props;
    return (
      <div className="button-next" onClick={onClick}>
        <i className="bi bi-arrow-left"></i>
      </div>
    );
  }

  const getRooms = (room_type_id: any) => {
    let noOfRooms: any = [];
    let totalNoofRooms: any = [];
    let totalRooms: any = [];
    allRoomTypeData.map((data: any, i: any) => {
      if ((room_type_id = data.room_type_id)) {
        for (let i = 1; i <= data.no_of_rooms; i++) {
          noOfRooms.push(i);
        }
        totalNoofRooms.push({ rooms: noOfRooms, room_type: room_type_id });
      }
    });
    totalNoofRooms
      .filter((item: any) => item["room_type"] === room_type_id)
      .map((rooms: any) => (totalRooms = rooms["rooms"]));
    return totalRooms;
  };

  const selectRatePlan = (ratePlanId: any, index: any, data: any) => {
    for (let i = 0; i < cart.length; i++) {
      if (data.room_type_id == cart[i].room_type_id) {
        cart.splice(i, 1);
      }
    }

    for (let i = 0; i < roomsPushedInCart.length; i++) {
      if (data.room_type_id == roomsPushedInCart[i]) {
        roomsPushedInCart.splice(i, 1);
      }
    }
    selectedRatePlans[index].rooms_selected = "";
    selectedRatePlans[index].rate_plan_id = parseInt(ratePlanId);
    setCart([...cart]);
    setRoomsPushedInCart([...roomsPushedInCart]);
    setSelectedRatePlans([...selectedRatePlans]);
  };

  const totalRoomSelected = (
    e: any,
    minimum_inventory: any,
    data: any,
    index: any
  ) => {
    let guest_name: any[] = [];
    let guest_name_display: any[] = [];

    let display_room_price_FIT: any[] = [];
    let display_room_price_group: any[] = [];

    let switch_tax: any[] = [];
    let counter_index = 0;

    selectedRatePlans[index].rooms_selected = parseInt(e);

    if (e == 0) {
      for (let i = 0; i < cart.length; i++) {
        if (data.room_type_id == cart[i].room_type_id) {
          cart.splice(i, 1);
        }
      }

      for (let i = 0; i < roomsPushedInCart.length; i++) {
        if (data.room_type_id == roomsPushedInCart[i]) {
          roomsPushedInCart.splice(i, 1);
        }
      }

      setCart([...cart]);
      setRoomsPushedInCart([...roomsPushedInCart]);
    } else {
      if (!roomsPushedInCart.includes(data.room_type_id)) {
        roomsPushedInCart.push(data.room_type_id);
        let rateplan_data: any;
        data.rate_plans.map((item: any) => {
          if (item.rate_plan_id == selectedRatePlans[index].rate_plan_id) {
            rateplan_data = item;
          }
        });

        let cartItem: any = {
          room_type: "",
          plan_type: "",
          room_type_id: 0,
          price: 0,
          rooms: [],
          tax: [],
          discounted_price: 0,
          paid_amount: 0,
          paid_amount_per: 0,
          rates_for_coupons: [],
        };

        cartItem["room_type"] = data.room_type;
        cartItem["room_type_id"] = data.room_type_id;
        cartItem["added_to_cart"] = true;
        cartItem["add_room"] = false;
        cartItem["rate_plan_id"] = rateplan_data.rate_plan_id;
        cartItem["max_room_capacity"] = data.max_room_capacity;
        cartItem["max_child"] = data.max_child;
        cartItem["max_people"] = data.max_people;
        cartItem["extra_person"] = data.extra_person;
        cartItem["plan_type"] = rateplan_data.plan_type;
        cartItem["plan_name"] = rateplan_data.plan_name;

        cartItem["max_occupancy_value"] = data.max_occupancy;
        cartItem["display_base_price"] = rateplan_data.bar_price;

        rateplan_data.rates.map((rate: any) => {
          cartItem["display_extra_adult_price"] = rate.extra_adult_price;
          cartItem["display_extra_child_price"] = rate.extra_child_price;
        });

        cartItem["discount_percent"] = 0;

        let total_adults =
          parseInt(data.max_people) + parseInt(data.extra_person);
        let total_child = parseInt(data.max_child) + parseInt(data.extra_child);

        let total_infant = parseInt(data.max_infant);

        let count: any[] = [];
        let child_count: any[] = [];

        let infant_count: any[] = [];
        for (let i = 1; i <= total_adults; i++) {
          let countdata = { option: i, value: i };
          count.push(countdata);
        }
        for (let j = 0; j <= total_child; j++) {
          let countdata = { option: j, value: j };
          child_count.push(countdata);
        }

        for (let k = 0; k <= total_infant; k++) {
          let countdata = { option: k, value: k };
          infant_count.push(countdata);
        }

        let child_no;
        if (data.max_occupancy !== 0) {
          child_no = 0;
        } else {
          child_no = parseInt(data.max_child);
        }

        cartItem["rooms"][0] = {
          room: "Room1",
          adults: count,
          selected_adult: parseInt(data.max_people),
          childs: child_count,
          infants: infant_count,
          selected_infant: 0,
          selected_child: child_no,
          rate_plan_id: rateplan_data.rate_plan_id,
          extra_adult_price: 0,
          extra_child_price: 0,
          bar_price: parseFloat(rateplan_data.bar_price),
          day_wise_rates: rateplan_data.rates,
        };
        cartItem["rates_for_coupons"] = rateplan_data.rates;

        cartItem.rooms[0]["bar_price"] = getRoomPrice(
          data.room_type_id,
          rateplan_data.rate_plan_id
        );
        cartItem.rooms[0]["bar_price"] = parseFloat(
          cartItem.rooms[0]["bar_price"]
        );

        cart.push(cartItem);
      }

      setCart([...cart]);
      setRoomsPushedInCart([...roomsPushedInCart]);
    }

    for (let cart_data of cart) {
      if (data.room_type_id == cart_data.room_type_id) {
        cart_data.discount_percent = 0;
        cart_data.discounted_price = 0;
        cart_data.display_base_price_after_discount = 0;

        let length_room = cart_data && cart_data.rooms.length;
        for (let i = length_room; i > 1; i--) {
          let no_of_rooms = cart_data && cart_data.rooms.length;
          if (no_of_rooms > 1) {
            cart_data.rooms.splice(no_of_rooms - 1, 1);
          }
        }

        let total_rooms = parseInt(e);

        for (let i = 0; i < total_rooms; i++) {
          if (i > 0) {
            cart_data.rooms = [...cart_data.rooms, cart_data.rooms[0]];
          }
          cart_data.rooms[i] = {
            ...cart_data.rooms[i],
            bar_price: getRoomPrice(
              cart_data.room_type_id,
              cart_data.rate_plan_id
            ),
          };
          cart_data.rooms[i] = { ...cart_data.rooms[i], room: `Room${i + 1}` };
        }
      }
    }

    for (let cart_data of cart) {
      cart_data.price = 0;
      let extra_adult_price = 0;
      let extra_child_price = 0;

      cart_data.rooms &&
        cart_data.rooms.map((cartItem: any) => {
          extra_adult_price += cartItem.extra_adult_price;
          extra_child_price += cartItem.extra_child_price;
          cart_data.price += cartItem.bar_price;
        });

      cart_data.display_price =
        cart_data.price + extra_adult_price + extra_child_price;

      cart_data.tax = [];
      // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

      let total_gst_amount = 0;
      if (taxDetails && taxDetails.is_taxable == 1) {
        total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
      }

      cart_data.tax.push({
        gst_price: total_gst_amount,
        other_tax: [],
      });
    }

    let gst_total_price = 0;
    let totalPrice = 0;
    let totalDiscountPrice = 0;
    cart &&
      cart.length > 0 &&
      cart.map((cartItem: any) => {
        let extra_adult_price = 0;
        let extra_child_price = 0;
        cartItem.rooms.map((room: any) => {
          extra_adult_price += room.extra_adult_price;
          extra_child_price += room.extra_child_price;
        });
        totalDiscountPrice += cartItem.discounted_price;
        gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
        totalPrice += cartItem.price + extra_adult_price + extra_child_price;

        let display_gst_price = 0;
        display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

        cartItem.display_pay_amount =
          cartItem.price +
          extra_adult_price +
          extra_child_price -
          cartItem.discounted_price;

        cartItem.display_pay_amount_after_tax =
          cartItem.price +
          extra_adult_price +
          extra_child_price +
          display_gst_price -
          cartItem.discounted_price;
      });

    cart.map((cartItem: any) => {
      cartItem.paid_amount = totalPrice + gst_total_price - totalDiscountPrice;
      cartItem.paid_amount_per = 100;
    });

    for (let cart_data of cart) {
      for (let i = 0; i < cart_data.rooms.length; i++) {
        guest_name.push("");
        guest_name_display.push({
          room_type_id: cart_data.room_type_id,
          index: i,
          first_name: "",
          last_name: "",
        });
        cart_data.rooms[i].counter_index = counter_index;
        counter_index++;
      }
    }

    for (let cart_data of cart) {
      display_room_price_FIT.push(cart_data.display_base_price);
      display_room_price_group.push(cart_data.display_base_price);
      switch_tax.push(false);
    }

    setCart([...cart]);
    setGuestName(guest_name);
    setGuestNameValue(guest_name);
    setGuestNameDisplay(guest_name_display);
    setDisplayRoomPriceFIT(display_room_price_FIT);
    setDisplayRoomPriceGroup(display_room_price_group);
    setSwitchTax(switch_tax);
  };

  const getRoomPrice = (room_type_id: any, rate_plan_id: any) => {
    let curPrice = 0;
    fetchedRoomData &&
      fetchedRoomData.map((inv: any) => {
        if (room_type_id == inv.room_type_id) {
          inv.rate_plans &&
            inv.rate_plans.map((rate_plan: any) => {
              if (rate_plan_id == rate_plan.rate_plan_id) {
                rate_plan.rates &&
                  rate_plan.rates.map((rate: any) => {
                    curPrice += parseFloat(rate.bar_price);
                  });
              }
            });
        }
      });

    if (channelType === "partner") {
      for (let i = 0; i < partnerRates.length; i++) {
        if (
          partnerRates[i].room_type_id === room_type_id &&
          partnerRates[i].rate_plan_id === rate_plan_id
        ) {
          curPrice = parseFloat(partnerRates[i].bar_price) * nights;
        }
      }
    }

    return curPrice;
  };

  const calculateGstAmount = (rooms: any, cart: any) => {
    let from_date = moment(startDate).format("YYYY-MM-DD");
    let to_date = moment(endDate).format("YYYY-MM-DD");

    let end_date = moment(to_date).subtract(1, "days").format("YYYY-MM-DD");

    let dateArray = getDateArray(from_date, end_date);
    let gstAmount = 0;

    dateArray &&
      dateArray.map((value: any) => {
        rooms.map((rates_for_discount: any) => {
          let gst_price = 0;
          let total_bar_price = 1;
          let total_pay_price;

          rates_for_discount.day_wise_rates &&
            rates_for_discount.day_wise_rates.map((day_rate: any) => {
              if (value === day_rate.date) {
                total_bar_price = day_rate.bar_price;

                if (day_rate.new_bar_price && day_rate.new_bar_price > 0) {
                  total_bar_price = day_rate.new_bar_price;
                }

                if (
                  rates_for_discount.extra_adult_price > 0 &&
                  rates_for_discount.no_of_extra_adult &&
                  rates_for_discount.no_of_extra_adult > 0
                ) {
                  total_bar_price =
                    total_bar_price +
                    day_rate.extra_adult_price *
                      rates_for_discount.no_of_extra_adult;
                }

                if (
                  rates_for_discount.extra_child_price > 0 &&
                  rates_for_discount.no_of_extra_child &&
                  rates_for_discount.no_of_extra_child > 0
                ) {
                  total_bar_price =
                    total_bar_price +
                    day_rate.extra_child_price *
                      rates_for_discount.no_of_extra_child;
                }

                if (
                  day_rate.applied_discount_price &&
                  day_rate.applied_discount_price > 0
                ) {
                  total_pay_price =
                    total_bar_price - day_rate.applied_discount_price;
                } else {
                  total_pay_price = total_bar_price;
                }

                gst_price += total_pay_price;
                const gstPercent: any = checkGSTPercent(gst_price);
                gstAmount += (gst_price * gstPercent) / 100;
              }
            });
        });
      });
    return gstAmount;
  };

  const getDateArray = (startDate: any, stopDate: any) => {
    var dateArray: any[] = [];
    var currentDate = moment(startDate);
    var stop_date = moment(stopDate);
    while (currentDate <= stop_date) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  };

  const checkGSTPercent = (price: any) => {
    // if (price <= 1000) {
    //   return 0;
    // } else if (price > 1000 && price <= 7500) {
    //   return 12;
    // } else if (price > 7500) {
    //   return 18;
    // }

    let tax_percent = 0;

    if (taxDetails && taxDetails.tax_range_type === "range") {
      if (taxDetails.tax_value.length > 0) {
        taxDetails.tax_value.map((items: any) => {
          if (
            price >= parseFloat(items.start_range) &&
            price <= parseFloat(items.end_range)
          ) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    if (taxDetails && taxDetails.tax_range_type === "single") {
      if (taxDetails.tax_value.length > 0) {
        taxDetails.tax_value.map((items: any) => {
          if (price >= parseFloat(items.start_range)) {
            tax_percent = parseFloat(items.value);
          }
        });
      }
    }

    return tax_percent;
  };

  const saveGuestName = (
    value: any,
    index: any,
    room_type_id: any,
    counter_index: any
  ) => {
    guestNameValue[counter_index] = value;
    setGuestNameValue([...guestNameValue]);

    let nameArr = value.split(" ");
    let firstName = nameArr[0];
    let lastName = "";
    if (nameArr.length > 1) {
      for (let i = 1; i < nameArr.length; i++) {
        lastName += nameArr[i] + " ";
      }
    } else {
      lastName = "NA";
    }

    for (let i = 0; i < guestNameDisplay.length; i++) {
      if (
        guestNameDisplay[i].index == index &&
        guestNameDisplay[i].room_type_id == room_type_id
      ) {
        guestName[i] = `${firstName} ${lastName}`;
        guestNameDisplay[i] = {
          ...guestNameDisplay[i],
          first_name: firstName,
          last_name: lastName,
        };
      }
    }

    setGuestName([...guestName]);
    setGuestNameDisplay([...guestNameDisplay]);
  };

  const saveGuestNameCorporate = (value: any) => {
    for (let i = 0; i < guestNameValue.length; i++) {
      guestNameValue[i] = value;
    }
    setGuestNameValue([...guestNameValue]);

    let nameArr = value.split(" ");
    let firstName = nameArr[0];
    let lastName = "";
    if (nameArr.length > 1) {
      for (let i = 1; i < nameArr.length; i++) {
        lastName += nameArr[i] + " ";
      }
    } else {
      lastName = "NA";
    }

    for (let i = 0; i < guestNameDisplay.length; i++) {
      guestName[i] = `${firstName} ${lastName}`;
      guestNameDisplay[i] = { ...guestNameDisplay[i], first_name: value };
    }

    setGuestName([...guestName]);
    setGuestNameDisplay([...guestNameDisplay]);
  };

  const handleAdultChange = (
    e: any,
    max_occupancy: any,
    room: any,
    room_data: any,
    cart_data: any
  ) => {
    let invData = getAllInv(cart_data.room_type_id, cart_data.rate_plan_id);
    let selected_adults = parseInt(e.target.value);
    setSelectedAdults(selected_adults);
    let adult = selected_adults - 1;
    let update_price = 0;
    let extra_adult_price = 0;
    let no_of_extra_adults = 0;
    let extra_child_price = 0;
    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      max_room_capacity: cart_data.max_room_capacity,
      max_adult: cart_data.max_people,
      extra_adult: cart_data.extra_person,
      selected_person: selected_adults,
      max_occupancy,
      room: room,
      type: "adult",
    };

    //Check For maximum room capacity
    checkMaxRoomCapacity(checkMaxRoomCapacityData, cart_data);
    if (selected_adults < invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        // updateExtraChildPrice(extra_child_price, room, cart_data);
      }

      extra_adult_price = 0;

      invData["inv_multiple_occupancy"].map((occupancy: any) => {
        if (occupancy[adult]) {
          update_price += parseFloat(occupancy[adult]);
        }
      });

      if (!update_price) {
        update_price = getRoomPrice(
          cart_data.room_type_id,
          cart_data.rate_plan_id
        );
      }
    }
    if (selected_adults > invData["max_people"]) {
      extra_adult_price = 0;
      update_price = getRoomPrice(
        cart_data.room_type_id,
        cart_data.rate_plan_id
      );
      no_of_extra_adults = selected_adults - invData["max_people"];
      extra_adult_price = no_of_extra_adults * invData["extra_adult_price"];
      cart_data.extraAdultMessage = (
        invData["extra_adult_price"] / nights
      ).toFixed();
    }
    if (selected_adults === invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        // updateExtraChildPrice(extra_child_price, room, cart_data);
      }
      extra_adult_price = 0;
      update_price = getRoomPrice(
        cart_data.room_type_id,
        cart_data.rate_plan_id
      );
    }

    // room_data.no_of_extra_adult = no_of_extra_adults;

    // updateExtraAdultPrice(extra_adult_price, room, cart_data);

    cart_data = { ...cart_data };

    if (channelType === "direct") {
      for (let cart_data of cart) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cart &&
        cart.length > 0 &&
        cart.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cart.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCart([...cart]);
    }

    if (channelType === "partner") {
      for (let cart_data of cartPartner) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cartPartner &&
        cartPartner.length > 0 &&
        cartPartner.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCartPartner([...cartPartner]);
    }
  };

  const handleChildChange = (
    e: any,
    max_occupancy: any,
    room: any,
    room_data: any,
    cart_data: any
  ) => {
    let selected_child = parseInt(e.target.value); //Selected child
    let invData = getAllInv(cart_data.room_type_id, cart_data.rate_plan_id);
    let extra_child_price = 0;
    let no_of_extra_child = 0;

    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      room_type_id: cart_data.room_type_id,
      rate_plan_id: cart_data.rate_plan_id,
      max_room_capacity: cart_data.max_room_capacity,
      max_adult: cart_data.max_people,
      max_child: cart_data.max_child,
      max_occupancy,
      extra_adult: cart_data.extra_person,
      selected_person: selected_child,
      room: room,
      type: "child",
    };
    checkMaxRoomCapacity(checkMaxRoomCapacityData, cart_data);

    if (max_occupancy !== 0 && selectedAdults == invData["max_people"]) {
      invData["max_child"] = 0;
    }
    if (selected_child < invData["max_child"]) {
      extra_child_price = 0;
    }
    if (selected_child > invData["max_child"]) {
      no_of_extra_child = selected_child - invData["max_child"];
      extra_child_price = no_of_extra_child * invData["extra_child_price"];
      if (invData["extra_child_price"] > 0) {
        cart_data.extraChildMessage = (
          invData["extra_child_price"] / nights
        ).toFixed();
      }
    }
    if (selected_child === invData["max_child"]) {
      extra_child_price = 0;
    }

    // room_data.no_of_extra_child = no_of_extra_child;
    // updateExtraChildPrice(extra_child_price, room, cart_data);
    cart_data = { ...cart_data };

    if (channelType === "direct") {
      for (let cart_data of cart) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cart &&
        cart.length > 0 &&
        cart.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cart.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCart([...cart]);
    }

    if (channelType === "partner") {
      for (let cart_data of cartPartner) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cartPartner &&
        cartPartner.length > 0 &&
        cartPartner.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCartPartner([...cartPartner]);
    }
  };

  const getAllInv = (room_type_id: any, rate_plan_id: any) => {
    let invDetail: any = [];
    invDetail["bar_price"] = 0;
    invDetail["extra_adult_price"] = 0;
    invDetail["extra_child_price"] = 0;
    invDetail["multiple_occupancy"] = [];
    invDetail["inv_multiple_occupancy"] = [];

    fetchedRoomData &&
      fetchedRoomData.map((inv: any) => {
        if (room_type_id == inv.room_type_id) {
          inv.rate_plans.map((rate_plan: any) => {
            if (rate_plan_id == rate_plan.rate_plan_id) {
              rate_plan.rates.map((rate: any) => {
                invDetail["room_type"] = inv.room_type;
                invDetail["max_people"] = inv.max_people;
                invDetail["max_child"] = inv.max_child;
                invDetail["max_occupancy"] = inv.max_occupancy;
                invDetail["extra_person"] = inv.extra_person;
                invDetail["extra_child"] = inv.extra_child;
                invDetail["plan_type"] = rate_plan.plan_type;
                invDetail["bar_price"] += rate_plan.bar_price;
                invDetail["extra_adult_price"] += rate.extra_adult_price;
                invDetail["extra_child_price"] += rate.extra_child_price;
                invDetail["display_extra_adult_price"] = parseInt(
                  rate.extra_adult_price
                ).toFixed(2);
                invDetail["display_extra_child_price"] =
                  rate.extra_child_price.toFixed(2);
                invDetail["inv_multiple_occupancy"].push(
                  rate.multiple_occupancy
                );
              });
            }
          });
        }
      });

    if (channelType === "partner") {
      for (let i = 0; i < partnerRates.length; i++) {
        if (
          partnerRates[i].room_type_id === room_type_id &&
          partnerRates[i].rate_plan_id === rate_plan_id
        ) {
          invDetail["bar_price"] =
            parseFloat(partnerRates[i].bar_price) * nights;
          invDetail["extra_adult_price"] =
            parseFloat(partnerRates[i].extra_adult) * nights;
          invDetail["extra_child_price"] =
            parseFloat(partnerRates[i].extra_child) * nights;
          invDetail["display_extra_adult_price"] = partnerRates[i].extra_adult;
          invDetail["display_extra_child_price"] = partnerRates[i].extra_child;
          invDetail["inv_multiple_occupancy"] = [];
          for (let j = 0; j < nights; j++) {
            invDetail["inv_multiple_occupancy"].push(
              partnerRates[i].multiple_occupancy
            );
          }
        }
      }
    }

    return invDetail;
  };

  const checkMaxRoomCapacity = (roomCapacityCheckObj: any, cart_data: any) => {
    let max_capacity_status = roomCapacityCheckObj.max_room_capacity;
    let max_adult = roomCapacityCheckObj.max_adult;
    let extra_adult = roomCapacityCheckObj.extra_adult;
    let selected_person = roomCapacityCheckObj.selected_person;
    let room = roomCapacityCheckObj.room;
    let type = roomCapacityCheckObj.type;
    const { max_occupancy } = roomCapacityCheckObj;
    cart_data.rooms &&
      cart_data.rooms.map((roomObj: any) => {
        if (roomObj.room == room) {
          if (max_capacity_status === 0) {
            if (type === "adult") roomObj.selected_adult = selected_person;
            if (type === "child") roomObj.selected_child = selected_person;
          }
          if (max_capacity_status === 1) {
            if (max_occupancy && max_occupancy !== 0 && max_occupancy !== "") {
              if (roomObj.selected_adult == max_adult + extra_adult) {
                roomObj.selected_child = 0;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child <= max_occupancy
              ) {
                roomObj.selected_adult = selected_person;
              }

              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child <= max_occupancy
              ) {
                roomObj.selected_child = selected_person;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child > max_occupancy
              ) {
                roomObj.selected_adult = selected_person;
                roomObj.selected_child = max_occupancy - roomObj.selected_adult;
                // updateExtraChildPrice(0, room, cart_data);
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child > max_occupancy
              ) {
                roomObj.selected_child = selected_person;
                roomObj.selected_adult = max_occupancy - roomObj.selected_child;
                // updateExtraAdultPrice(0, room, cart_data);
              }
            } else {
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child <=
                  max_adult + extra_adult
              ) {
                roomObj.selected_adult = selected_person;
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child <=
                  max_adult + extra_adult
              ) {
                roomObj.selected_child = selected_person;
              }
              if (
                type === "adult" &&
                roomObj.selected_adult + roomObj.selected_child >
                  max_adult + extra_adult
              ) {
                roomObj.selected_adult = selected_person;
                roomObj.selected_child =
                  max_adult + extra_adult - roomObj.selected_adult;
                // updateExtraChildPrice(0, room, cart_data);
              }
              if (
                type === "child" &&
                roomObj.selected_adult + roomObj.selected_child >
                  max_adult + extra_adult
              ) {
                roomObj.selected_child = selected_person;
                roomObj.selected_adult =
                  max_adult + extra_adult - roomObj.selected_child;
                // updateExtraAdultPrice(0, room, cart_data);
              }
            }
          }
        }
      });

    cart_data = { ...cart_data };
  };

  const updateExtraAdultPrice = (
    extra_adult_price: any,
    room: any,
    cart: any
  ) => {
    cart.rooms &&
      cart.rooms.map((roomObj: any) => {
        if (roomObj.room == room) {
          roomObj.extra_adult_price = extra_adult_price;

          if (extra_adult_price == 0) {
            roomObj.no_of_extra_adult = 0;
          }
        }
      });

    cart = { ...cart };
  };

  const updateExtraChildPrice = (
    extra_child_price: any,
    room: any,
    cart: any
  ) => {
    cart.rooms &&
      cart.rooms.map((roomObj: any) => {
        if (roomObj.room == room) {
          roomObj.extra_child_price = extra_child_price;
          if (extra_child_price == 0) {
            roomObj.no_of_extra_child = 0;
          }
        }
      });
    cart = { ...cart };
  };

  const handleAdultChangeCorporate = (
    e: any,
    max_occupancy: any,
    room: any,
    room_data: any,
    cart_data: any
  ) => {
    let invData = getAllInv(cart_data.room_type_id, cart_data.rate_plan_id);
    let selected_adults = parseInt(e.target.value);
    setSelectedAdults(selected_adults);
    let adult = selected_adults - 1;
    let update_price = 0;
    let extra_adult_price = 0;
    let no_of_extra_adults = 0;
    let extra_child_price = 0;
    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      max_room_capacity: cart_data.max_room_capacity,
      max_adult: cart_data.max_people,
      extra_adult: cart_data.extra_person,
      selected_person: selected_adults,
      max_occupancy,
      room: room,
      type: "adult",
    };

    //Check For maximum room capacity
    checkMaxRoomCapacityCorporate(checkMaxRoomCapacityData, cart_data);
    if (selected_adults < invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        // updateExtraChildPriceCorporate(extra_child_price, cart_data);
      }

      extra_adult_price = 0;

      invData["inv_multiple_occupancy"].map((occupancy: any) => {
        if (occupancy[adult]) {
          update_price += parseFloat(occupancy[adult]);
        }
      });

      if (!update_price) {
        update_price = getRoomPrice(
          cart_data.room_type_id,
          cart_data.rate_plan_id
        );
      }
    }
    if (selected_adults > invData["max_people"]) {
      extra_adult_price = 0;
      update_price = getRoomPrice(
        cart_data.room_type_id,
        cart_data.rate_plan_id
      );
      no_of_extra_adults = selected_adults - invData["max_people"];
      extra_adult_price = no_of_extra_adults * invData["extra_adult_price"];
      cart_data.extraAdultMessage = (
        invData["extra_adult_price"] / nights
      ).toFixed();
    }
    if (selected_adults === invData["max_people"]) {
      if (invData["max_occupancy"] !== 0) {
        // updateExtraChildPriceCorporate(extra_child_price, cart_data);
      }
      extra_adult_price = 0;
      update_price = getRoomPrice(
        cart_data.room_type_id,
        cart_data.rate_plan_id
      );
    }

    // room_data.no_of_extra_adult = no_of_extra_adults;

    // updateExtraAdultPriceCorporate(extra_adult_price, cart_data);

    cart_data = { ...cart_data };

    if (channelType === "direct") {
      for (let cart_data of cart) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cart &&
        cart.length > 0 &&
        cart.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cart.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCart([...cart]);
    }

    if (channelType === "partner") {
      for (let cart_data of cartPartner) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cartPartner &&
        cartPartner.length > 0 &&
        cartPartner.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCartPartner([...cartPartner]);
    }
  };

  const handleChildChangeCorporate = (
    e: any,
    max_occupancy: any,
    room: any,
    room_data: any,
    cart_data: any
  ) => {
    let selected_child = parseInt(e.target.value); //Selected child
    let invData = getAllInv(cart_data.room_type_id, cart_data.rate_plan_id);
    let extra_child_price = 0;
    let no_of_extra_child = 0;

    //Check For maximum room capacity
    let checkMaxRoomCapacityData = {
      room_type_id: cart_data.room_type_id,
      rate_plan_id: cart_data.rate_plan_id,
      max_room_capacity: cart_data.max_room_capacity,
      max_adult: cart_data.max_people,
      max_child: cart_data.max_child,
      max_occupancy,
      extra_adult: cart_data.extra_person,
      selected_person: selected_child,
      room: room,
      type: "child",
    };
    checkMaxRoomCapacityCorporate(checkMaxRoomCapacityData, cart_data);

    if (max_occupancy !== 0 && selectedAdults == invData["max_people"]) {
      invData["max_child"] = 0;
    }
    if (selected_child < invData["max_child"]) {
      extra_child_price = 0;
    }
    if (selected_child > invData["max_child"]) {
      no_of_extra_child = selected_child - invData["max_child"];
      extra_child_price = no_of_extra_child * invData["extra_child_price"];
      if (invData["extra_child_price"] > 0) {
        cart_data.extraChildMessage = (
          invData["extra_child_price"] / nights
        ).toFixed();
      }
    }
    if (selected_child === invData["max_child"]) {
      extra_child_price = 0;
    }

    // room_data.no_of_extra_child = no_of_extra_child;
    // updateExtraChildPriceCorporate(extra_child_price, cart_data);
    cart_data = { ...cart_data };

    if (channelType === "direct") {
      for (let cart_data of cart) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cart &&
        cart.length > 0 &&
        cart.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cart.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCart([...cart]);
    }

    if (channelType === "partner") {
      for (let cart_data of cartPartner) {
        cart_data.price = 0;
        let extra_adult_price = 0;
        let extra_child_price = 0;

        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            extra_adult_price += cartItem.extra_adult_price;
            extra_child_price += cartItem.extra_child_price;
            if (cartItem.new_bar_price) {
              cart_data.price += cartItem.new_bar_price;
            } else {
              cart_data.price += cartItem.bar_price;
            }
          });

        cart_data.display_price =
          cart_data.price + extra_adult_price + extra_child_price;

        cart_data.tax = [];
        // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

        let total_gst_amount = 0;
        if (taxDetails && taxDetails.is_taxable == 1) {
          total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
        }

        cart_data.tax.push({
          gst_price: total_gst_amount,
          other_tax: [],
        });
      }

      let gst_total_price = 0;
      let totalPrice = 0;
      let totalDiscountPrice = 0;
      cartPartner &&
        cartPartner.length > 0 &&
        cartPartner.map((cartItem: any) => {
          let extra_adult_price = 0;
          let extra_child_price = 0;
          cartItem.rooms.map((room: any) => {
            extra_adult_price += room.extra_adult_price;
            extra_child_price += room.extra_child_price;
          });
          totalDiscountPrice += cartItem.discounted_price;
          gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
          totalPrice += cartItem.price + extra_adult_price + extra_child_price;

          let display_gst_price = 0;
          display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

          cartItem.display_pay_amount =
            cartItem.price +
            extra_adult_price +
            extra_child_price -
            cartItem.discounted_price;

          cartItem.display_pay_amount_after_tax =
            cartItem.price +
            extra_adult_price +
            extra_child_price +
            display_gst_price -
            cartItem.discounted_price;
        });

      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount =
          totalPrice + gst_total_price - totalDiscountPrice;
        cartItem.paid_amount_per = 100;
      });

      setCartPartner([...cartPartner]);
    }
  };

  const updateExtraAdultPriceCorporate = (
    extra_adult_price: any,
    cart: any
  ) => {
    cart.rooms &&
      cart.rooms.map((roomObj: any) => {
        roomObj.extra_adult_price = extra_adult_price;
        if (extra_adult_price == 0) {
          roomObj.no_of_extra_adult = 0;
        }
      });

    cart = { ...cart };
  };

  const updateExtraChildPriceCorporate = (
    extra_child_price: any,
    cart: any
  ) => {
    cart.rooms &&
      cart.rooms.map((roomObj: any) => {
        roomObj.extra_child_price = extra_child_price;
        if (extra_child_price == 0) {
          roomObj.no_of_extra_child = 0;
        }
      });
    cart = { ...cart };
  };

  const checkMaxRoomCapacityCorporate = (
    roomCapacityCheckObj: any,
    cart_data: any
  ) => {
    let max_capacity_status = roomCapacityCheckObj.max_room_capacity;
    let max_adult = roomCapacityCheckObj.max_adult;
    let extra_adult = roomCapacityCheckObj.extra_adult;
    let selected_person = roomCapacityCheckObj.selected_person;
    let room = roomCapacityCheckObj.room;
    let type = roomCapacityCheckObj.type;
    const { max_occupancy } = roomCapacityCheckObj;
    cart_data.rooms &&
      cart_data.rooms.map((roomObj: any) => {
        if (max_capacity_status === 0) {
          if (type === "adult") roomObj.selected_adult = selected_person;
          if (type === "child") roomObj.selected_child = selected_person;
        }
        if (max_capacity_status === 1) {
          if (max_occupancy && max_occupancy !== 0 && max_occupancy !== "") {
            if (roomObj.selected_adult == max_adult + extra_adult) {
              roomObj.selected_child = 0;
            }
            if (
              type === "adult" &&
              roomObj.selected_adult + roomObj.selected_child <= max_occupancy
            ) {
              roomObj.selected_adult = selected_person;
            }

            if (
              type === "child" &&
              roomObj.selected_adult + roomObj.selected_child <= max_occupancy
            ) {
              roomObj.selected_child = selected_person;
            }
            if (
              type === "adult" &&
              roomObj.selected_adult + roomObj.selected_child > max_occupancy
            ) {
              roomObj.selected_adult = selected_person;
              roomObj.selected_child = max_occupancy - roomObj.selected_adult;
              // updateExtraChildPrice(0, room, cart_data);
            }
            if (
              type === "child" &&
              roomObj.selected_adult + roomObj.selected_child > max_occupancy
            ) {
              roomObj.selected_child = selected_person;
              roomObj.selected_adult = max_occupancy - roomObj.selected_child;
              // updateExtraAdultPrice(0, room, cart_data);
            }
          } else {
            if (
              type === "adult" &&
              roomObj.selected_adult + roomObj.selected_child <=
                max_adult + extra_adult
            ) {
              roomObj.selected_adult = selected_person;
            }
            if (
              type === "child" &&
              roomObj.selected_adult + roomObj.selected_child <=
                max_adult + extra_adult
            ) {
              roomObj.selected_child = selected_person;
            }
            if (
              type === "adult" &&
              roomObj.selected_adult + roomObj.selected_child >
                max_adult + extra_adult
            ) {
              roomObj.selected_adult = selected_person;
              roomObj.selected_child =
                max_adult + extra_adult - roomObj.selected_adult;
              // updateExtraChildPrice(0, room, cart_data);
            }
            if (
              type === "child" &&
              roomObj.selected_adult + roomObj.selected_child >
                max_adult + extra_adult
            ) {
              roomObj.selected_child = selected_person;
              roomObj.selected_adult =
                max_adult + extra_adult - roomObj.selected_child;
              // updateExtraAdultPrice(0, room, cart_data);
            }
          }
        }
      });

    cart_data = { ...cart_data };
  };

  const changedPriceFIT = (
    value: any,
    cart_data: any,
    index: any,
    tax_value: any
  ) => {
    if (channelType === "direct") {
      displayRoomPriceFIT[index] = value;
      setDisplayRoomPriceFIT([...displayRoomPriceFIT]);

      displayRoomPriceGroup[index] = value;
      setDisplayRoomPriceGroup([...displayRoomPriceGroup]);
    }

    if (channelType === "partner") {
      displayRoomPriceFITPartner[index] = value;
      setDisplayRoomPriceFITPartner([...displayRoomPriceFITPartner]);

      displayRoomPriceGroupPartner[index] = value;
      setDisplayRoomPriceGroupPartner([...displayRoomPriceGroupPartner]);
    }

    let gst_percentage = 0;

    if (tax_value) {
      if (taxDetails && taxDetails.tax_range_type === "range") {
        let allcondition = true;
        if (taxDetails.tax_value.length > 0) {
          taxDetails.tax_value.map((items: any) => {
            if (parseFloat(items.value) === 0) {
              cart_data.discounted_price = 0;

              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value >= items.bar_price) {
                      allcondition = false;
                    }
                    // added to fix price modify issue
                  });
                });

              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && allcondition) {
                      items.new_bar_price = 0;
                      items.applied_discount_price = items.bar_price - value;
                      cart_data.discounted_price += items.bar_price - value;
                    }
                    // added to fix price modify issue
                    else {
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value;
                      items.new_bar_price = parseFloat(value);
                    }
                    // added to fix price modify issue
                  });
                });

              cart_data.display_base_price_after_discount = 0;
              // && allcondition
              if (value <= cart_data.display_base_price) {
                let discountedPrice = cart_data.display_base_price - value;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = 0;
            } else if (
              value >= parseFloat(items.start_range) &&
              value <= parseFloat(items.end_range)
            ) {
              let tax_percent = parseFloat(items.value);
              tax_percent = tax_percent / 100 + 1;

              cart_data.discounted_price = 0;
              let allroomsameprice = true;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value > items.bar_price) {
                      allroomsameprice = false;
                    }
                  });
                });
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && allroomsameprice) {
                      items.new_bar_price = 0;
                      items.applied_discount_price =
                        items.bar_price - value / tax_percent;
                      cart_data.discounted_price +=
                        items.bar_price - value / tax_percent;
                    } else {
                      // added to fix price modify issue
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value / tax_percent;
                      // added to fix price modify issue
                      items.new_bar_price = value / tax_percent;
                    }
                  });
                });
              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice =
                  cart_data.display_base_price - value / tax_percent;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }
              gst_percentage = parseFloat(items.value);
            }
          });
        }
      }

      if (taxDetails && taxDetails.tax_range_type === "single") {
        if (taxDetails.tax_value.length > 0) {
          taxDetails.tax_value.map((items: any) => {
            if (parseFloat(items.value) === 0) {
              cart_data.discounted_price = 0;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price) {
                      items.new_bar_price = 0;
                      items.applied_discount_price = items.bar_price - value;
                      cart_data.discounted_price += items.bar_price - value;
                    }
                    // added to fix price modify issue
                    else {
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value;
                      items.new_bar_price = parseFloat(value);
                    }
                    // added to fix price modify issue
                  });
                });

              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice = cart_data.display_base_price - value;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = 0;
            } else if (value >= parseFloat(items.start_range)) {
              let tax_percent = parseFloat(items.value);
              tax_percent = tax_percent / 100 + 1;

              cart_data.discounted_price = 0;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price) {
                      items.new_bar_price = 0;
                      items.applied_discount_price =
                        items.bar_price - value / tax_percent;
                      cart_data.discounted_price +=
                        items.bar_price - value / tax_percent;
                    } else {
                      // added to fix price modify issue
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value / tax_percent;
                      // added to fix price modify issue
                      items.new_bar_price = value / tax_percent;
                    }
                  });
                });
              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice =
                  cart_data.display_base_price - value / tax_percent;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = parseFloat(items.value);
            }
          });
        }
      }

      if (channelType === "direct") {
        for (let cart_data of cart) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          let total_gst_amount = calculateGstAmountForBackTracking(
            cart_data.rooms,
            cart_data,
            gst_percentage
          );

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cart &&
          cart.length > 0 &&
          cart.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cart.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCart([...cart]);
      }

      if (channelType === "partner") {
        for (let cart_data of cartPartner) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          let total_gst_amount = calculateGstAmountForBackTracking(
            cart_data.rooms,
            cart_data,
            gst_percentage
          );

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cartPartner &&
          cartPartner.length > 0 &&
          cartPartner.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCartPartner([...cartPartner]);
      }
    } else {
      cart_data.discounted_price = 0;
      let allcondition = true;

      cart_data.rooms &&
        cart_data.rooms.map((cartItem: any) => {
          // added to fix price modify issue
          cartItem.new_bar_price = 0;
          // added to fix price modify issue
          cartItem.day_wise_rates.map((items: any) => {
            if (value > items.bar_price) {
              allcondition = false;
            }
            // added to fix price modify issue
          });
        });

      cart_data.rooms &&
        cart_data.rooms.map((cartItem: any) => {
          // added to fix price modify issue
          cartItem.new_bar_price = 0;
          // added to fix price modify issue
          cartItem.day_wise_rates.map((items: any) => {
            if (value <= items.bar_price && allcondition) {
              items.new_bar_price = 0;
              items.applied_discount_price = items.bar_price - value;
              cart_data.discounted_price += items.bar_price - value;
            }
            // added to fix price modify issue
            else {
              cartItem.new_bar_price += value;
              items.applied_discount_price = 0;
              items.new_bar_price = parseFloat(value);
            }
            // added to fix price modify issue
          });
        });

      if (value <= cart_data.display_base_price) {
        let discountedPrice = cart_data.display_base_price - value;
        cart_data.display_base_price_after_discount =
          cart_data.display_base_price - discountedPrice;
      }
      // added to fix price modify issue
      else {
        cart_data.display_base_price_after_discount = value;
      }
      // added to fix price modify issue

      if (channelType === "direct") {
        for (let cart_data of cart) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

          let total_gst_amount = 0;
          if (taxDetails && taxDetails.is_taxable == 1) {
            total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
          }

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cart &&
          cart.length > 0 &&
          cart.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cart.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCart([...cart]);
      }

      if (channelType === "partner") {
        for (let cart_data of cartPartner) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

          let total_gst_amount = 0;
          if (taxDetails && taxDetails.is_taxable == 1) {
            total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
          }

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cartPartner &&
          cartPartner.length > 0 &&
          cartPartner.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              +cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              +cartItem.discounted_price;
          });

        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCartPartner([...cartPartner]);
      }
    }
  };
  const handleSwitchTaxFIT = (value: any, cart_data: any, index: any) => {
    switchTax[index] = !switchTax[index];
    setSwitchTax([...switchTax]);
    gstBacktrackingSwitchTaxFIT(value, cart_data, index, switchTax[index]);
  };

  const gstBacktrackingSwitchTaxFIT = (
    value: any,
    cart_data: any,
    index: any,
    tax_value: any
  ) => {
    displayRoomPriceFIT[index] = value;
    setDisplayRoomPriceFIT([...displayRoomPriceFIT]);

    let gst_percentage = 0;
    if (value) {
      if (tax_value) {
        if (taxDetails && taxDetails.tax_range_type === "range") {
          if (taxDetails.tax_value.length > 0) {
            taxDetails.tax_value.map((items: any) => {
              if (parseFloat(items.value) === 0) {
                cart_data.discounted_price = 0;
                let isAllcondition = true;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value > items.bar_price) {
                        isAllcondition = false;
                      }

                      // added to fix price modify issue
                    });
                  });
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isAllcondition) {
                        items.new_bar_price = 0;
                        items.applied_discount_price = items.bar_price - value;
                        cart_data.discounted_price += items.bar_price - value;
                      }
                      // added to fix price modify issue
                      else {
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value;
                        items.new_bar_price = parseFloat(value);
                      }
                      // added to fix price modify issue
                    });
                  });

                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice = cart_data.display_base_price - value;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = 0;
              } else if (
                value >= parseFloat(items.start_range) &&
                value <= parseFloat(items.end_range)
              ) {
                let tax_percent = parseFloat(items.value);
                tax_percent = tax_percent / 100 + 1;

                cart_data.discounted_price = 0;
                let isAllcodition = true;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value > items.bar_price) {
                        isAllcodition = false;
                      }
                    });
                  });
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isAllcodition) {
                        items.new_bar_price = 0;
                        items.applied_discount_price =
                          items.bar_price - value / tax_percent;
                        cart_data.discounted_price +=
                          items.bar_price - value / tax_percent;
                      } else {
                        // added to fix price modify issue
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value / tax_percent;
                        // added to fix price modify issue
                        items.new_bar_price = value / tax_percent;
                      }
                    });
                  });
                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice =
                    cart_data.display_base_price - value / tax_percent;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = parseFloat(items.value);
              }
            });
          }
        }

        if (taxDetails && taxDetails.tax_range_type === "single") {
          if (taxDetails.tax_value.length > 0) {
            taxDetails.tax_value.map((items: any) => {
              if (parseFloat(items.value) === 0) {
                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price) {
                        items.new_bar_price = 0;
                        items.applied_discount_price = items.bar_price - value;
                        cart_data.discounted_price += items.bar_price - value;
                      }
                      // added to fix price modify issue
                      else {
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value;
                        items.new_bar_price = parseFloat(value);
                      }
                      // added to fix price modify issue
                    });
                  });

                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice = cart_data.display_base_price - value;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = 0;
              } else if (value >= parseFloat(items.start_range)) {
                let tax_percent = parseFloat(items.value);
                tax_percent = tax_percent / 100 + 1;

                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price) {
                        items.new_bar_price = 0;
                        items.applied_discount_price =
                          items.bar_price - value / tax_percent;
                        cart_data.discounted_price +=
                          items.bar_price - value / tax_percent;
                      } else {
                        // added to fix price modify issue
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value / tax_percent;
                        // added to fix price modify issue
                        items.new_bar_price = value / tax_percent;
                      }
                    });
                  });
                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice =
                    cart_data.display_base_price - value / tax_percent;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = parseFloat(items.value);
              }
            });
          }
        }

        if (channelType === "direct") {
          for (let cart_data of cart) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            let total_gst_amount = calculateGstAmountForBackTracking(
              cart_data.rooms,
              cart_data,
              gst_percentage
            );

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cart &&
            cart.length > 0 &&
            cart.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cart.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCart([...cart]);
        }

        if (channelType === "partner") {
          for (let cart_data of cartPartner) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            let total_gst_amount = calculateGstAmountForBackTracking(
              cart_data.rooms,
              cart_data,
              gst_percentage
            );

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cartPartner &&
            cartPartner.length > 0 &&
            cartPartner.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cartPartner.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCartPartner([...cartPartner]);
        }
      } else {
        cart_data.discounted_price = 0;
        let isAllcondition = true;
        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            // added to fix price modify issue
            cartItem.new_bar_price = 0;
            // added to fix price modify issue
            cartItem.day_wise_rates.map((items: any) => {
              if (value > items.bar_price) {
                isAllcondition = false;
              }
            });
          });
        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            // added to fix price modify issue
            cartItem.new_bar_price = 0;
            // added to fix price modify issue
            cartItem.day_wise_rates.map((items: any) => {
              if (value <= items.bar_price && isAllcondition) {
                items.new_bar_price = 0;
                items.applied_discount_price = items.bar_price - value;
                cart_data.discounted_price += items.bar_price - value;
              }
              // added to fix price modify issue
              else {
                items.applied_discount_price = 0;
                cartItem.new_bar_price += value;
                items.new_bar_price = parseFloat(value);
              }
              // added to fix price modify issue
            });
          });

        if (value <= cart_data.display_base_price) {
          let discountedPrice = cart_data.display_base_price - value;
          cart_data.display_base_price_after_discount =
            cart_data.display_base_price - discountedPrice;
        }
        // added to fix price modify issue
        else {
          cart_data.display_base_price_after_discount = value;
        }
        // added to fix price modify issue

        if (channelType === "direct") {
          for (let cart_data of cart) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

            let total_gst_amount = 0;
            if (taxDetails && taxDetails.is_taxable == 1) {
              total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
            }

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cart &&
            cart.length > 0 &&
            cart.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cart.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCart([...cart]);
        }

        if (channelType === "partner") {
          for (let cart_data of cartPartner) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

            let total_gst_amount = 0;
            if (taxDetails && taxDetails.is_taxable == 1) {
              total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
            }

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cartPartner &&
            cartPartner.length > 0 &&
            cartPartner.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cartPartner.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCartPartner([...cartPartner]);
        }
      }
    }
  };

  const changedPriceGroup = (
    value: any,
    cart_data: any,
    index: any,
    tax_value: any
  ) => {
    if (channelType === "direct") {
      displayRoomPriceFIT[index] = value;
      setDisplayRoomPriceFIT([...displayRoomPriceFIT]);

      displayRoomPriceGroup[index] = value;
      setDisplayRoomPriceGroup([...displayRoomPriceGroup]);
    }

    if (channelType === "partner") {
      displayRoomPriceFITPartner[index] = value;
      setDisplayRoomPriceFITPartner([...displayRoomPriceFITPartner]);

      displayRoomPriceGroupPartner[index] = value;
      setDisplayRoomPriceGroupPartner([...displayRoomPriceGroupPartner]);
    }

    let gst_percentage = 0;

    if (tax_value) {
      if (taxDetails && taxDetails.tax_range_type === "range") {
        if (taxDetails.tax_value.length > 0) {
          taxDetails.tax_value.map((items: any) => {
            if (parseFloat(items.value) === 0) {
              cart_data.discounted_price = 0;
              let isSameBarprice = true;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value > items.bar_price) {
                      isSameBarprice = false;
                    }
                  });
                });
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && isSameBarprice) {
                      items.new_bar_price = 0;
                      items.applied_discount_price = items.bar_price - value;
                      cart_data.discounted_price += items.bar_price - value;
                    }
                    // added to fix price modify issue
                    else {
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value;
                      items.new_bar_price = parseFloat(value);
                    }
                    // added to fix price modify issue
                  });
                });

              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice = cart_data.display_base_price - value;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = 0;
            } else if (
              value >= parseFloat(items.start_range) &&
              value <= parseFloat(items.end_range)
            ) {
              let tax_percent = parseFloat(items.value);
              tax_percent = tax_percent / 100 + 1;

              cart_data.discounted_price = 0;
              let isSameBarprice = true;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value > items.bar_price) {
                      isSameBarprice = false;
                    }
                  });
                });
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && isSameBarprice) {
                      items.new_bar_price = 0;
                      items.applied_discount_price =
                        items.bar_price - value / tax_percent;
                      cart_data.discounted_price +=
                        items.bar_price - value / tax_percent;
                    } else {
                      // added to fix price modify issue
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value / tax_percent;
                      // added to fix price modify issue
                      items.new_bar_price = value / tax_percent;
                    }
                  });
                });
              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice =
                  cart_data.display_base_price - value / tax_percent;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = parseFloat(items.value);
            }
          });
        }
      }

      if (taxDetails && taxDetails.tax_range_type === "single") {
        if (taxDetails.tax_value.length > 0) {
          taxDetails.tax_value.map((items: any) => {
            if (parseFloat(items.value) === 0) {
              cart_data.discounted_price = 0;
              let isSameBarprice = true;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value > items.bar_price) {
                      isSameBarprice = false;
                    }
                  });
                });
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && isSameBarprice) {
                      items.new_bar_price = 0;
                      items.applied_discount_price = items.bar_price - value;
                      cart_data.discounted_price += items.bar_price - value;
                    }
                    // added to fix price modify issue
                    else {
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value;
                      items.new_bar_price = parseFloat(value);
                    }
                    // added to fix price modify issue
                  });
                });
              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice = cart_data.display_base_price - value;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = 0;
            } else if (value >= parseFloat(items.start_range)) {
              let tax_percent = parseFloat(items.value);
              tax_percent = tax_percent / 100 + 1;

              cart_data.discounted_price = 0;
              let isSameBarprice = true;
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value > items.bar_price) {
                      isSameBarprice = false;
                    }
                  });
                });
              cart_data.rooms &&
                cart_data.rooms.map((cartItem: any) => {
                  // added to fix price modify issue
                  cartItem.new_bar_price = 0;
                  // added to fix price modify issue
                  cartItem.day_wise_rates.map((items: any) => {
                    if (value <= items.bar_price && isSameBarprice) {
                      items.new_bar_price = 0;
                      items.applied_discount_price =
                        items.bar_price - value / tax_percent;
                      cart_data.discounted_price +=
                        items.bar_price - value / tax_percent;
                    } else {
                      // added to fix price modify issue
                      items.applied_discount_price = 0;
                      cartItem.new_bar_price += value / tax_percent;
                      // added to fix price modify issue
                      items.new_bar_price = value / tax_percent;
                    }
                  });
                });
              cart_data.display_base_price_after_discount = 0;
              if (value <= cart_data.display_base_price) {
                let discountedPrice =
                  cart_data.display_base_price - value / tax_percent;
                cart_data.display_base_price_after_discount =
                  cart_data.display_base_price - discountedPrice;
              }

              gst_percentage = parseFloat(items.value);
            }
          });
        }
      }

      if (channelType === "direct") {
        for (let cart_data of cart) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          let total_gst_amount = calculateGstAmountForBackTracking(
            cart_data.rooms,
            cart_data,
            gst_percentage
          );

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cart &&
          cart.length > 0 &&
          cart.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cart.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCart([...cart]);
      }

      if (channelType === "partner") {
        for (let cart_data of cartPartner) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          let total_gst_amount = calculateGstAmountForBackTracking(
            cart_data.rooms,
            cart_data,
            gst_percentage
          );

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cartPartner &&
          cartPartner.length > 0 &&
          cartPartner.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCartPartner([...cartPartner]);
      }
    } else {
      cart_data.discounted_price = 0;
      let isSameBarprice = true;
      cart_data.rooms &&
        cart_data.rooms.map((cartItem: any) => {
          // added to fix price modify issue
          cartItem.new_bar_price = 0;
          // added to fix price modify issue
          cartItem.day_wise_rates.map((items: any) => {
            if (value > items.bar_price) {
              isSameBarprice = false;
            }
          });
        });
      cart_data.rooms &&
        cart_data.rooms.map((cartItem: any) => {
          // added to fix price modify issue
          cartItem.new_bar_price = 0;
          // added to fix price modify issue
          cartItem.day_wise_rates.map((items: any) => {
            if (value <= items.bar_price && isSameBarprice) {
              items.new_bar_price = 0;
              items.applied_discount_price = items.bar_price - value;
              cart_data.discounted_price += items.bar_price - value;
            }
            // added to fix price modify issue
            else {
              items.applied_discount_price = 0;
              cartItem.new_bar_price += value;
              items.new_bar_price = parseFloat(value);
            }
            // added to fix price modify issue
          });
        });

      if (value <= cart_data.display_base_price) {
        let discountedPrice = cart_data.display_base_price - value;
        cart_data.display_base_price_after_discount =
          cart_data.display_base_price - discountedPrice;
      }
      // added to fix price modify issue
      else {
        cart_data.display_base_price_after_discount = value;
      }
      // added to fix price modify issue

      if (channelType === "direct") {
        for (let cart_data of cart) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

          let total_gst_amount = 0;
          if (taxDetails && taxDetails.is_taxable == 1) {
            total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
          }

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cart &&
          cart.length > 0 &&
          cart.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cart.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCart([...cart]);
      }

      if (channelType === "partner") {
        for (let cart_data of cartPartner) {
          cart_data.price = 0;
          let extra_adult_price = 0;
          let extra_child_price = 0;

          cart_data.rooms &&
            cart_data.rooms.map((cartItem: any) => {
              extra_adult_price += cartItem.extra_adult_price;
              extra_child_price += cartItem.extra_child_price;
              if (cartItem.new_bar_price) {
                cart_data.price += cartItem.new_bar_price;
              } else {
                cart_data.price += cartItem.bar_price;
              }
            });

          cart_data.display_price =
            cart_data.price + extra_adult_price + extra_child_price;

          cart_data.tax = [];
          // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

          let total_gst_amount = 0;
          if (taxDetails && taxDetails.is_taxable == 1) {
            total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
          }

          cart_data.tax.push({
            gst_price: total_gst_amount,
            other_tax: [],
          });
        }

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cartPartner &&
          cartPartner.length > 0 &&
          cartPartner.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCartPartner([...cartPartner]);
      }
    }
  };

  const handleSwitchTaxGroup = (value: any, cart_data: any, index: any) => {
    switchTax[index] = !switchTax[index];
    setSwitchTax([...switchTax]);
    gstBacktrackingSwitchTaxGroup(value, cart_data, index, switchTax[index]);
  };

  const gstBacktrackingSwitchTaxGroup = (
    value: any,
    cart_data: any,
    index: any,
    tax_value: any
  ) => {
    displayRoomPriceGroup[index] = value;
    setDisplayRoomPriceGroup([...displayRoomPriceGroup]);
    let isSameBarpriceGroup = true;
    cart_data.rooms &&
      cart_data.rooms.map((cartItem: any) => {
        // added to fix price modify issue
        cartItem.new_bar_price = 0;
        // added to fix price modify issue
        cartItem.day_wise_rates.map((items: any) => {
          if (value > items.bar_price) {
            isSameBarpriceGroup = false;
          }
          // added to fix price modify issue
        });
      });
    let gst_percentage = 0;
    if (value) {
      if (tax_value) {
        if (taxDetails && taxDetails.tax_range_type === "range") {
          if (taxDetails.tax_value.length > 0) {
            taxDetails.tax_value.map((items: any) => {
              if (parseFloat(items.value) === 0) {
                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isSameBarpriceGroup) {
                        items.new_bar_price = 0;
                        items.applied_discount_price = items.bar_price - value;
                        cart_data.discounted_price += items.bar_price - value;
                      }
                      // added to fix price modify issue
                      else {
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value;
                        items.new_bar_price = parseFloat(value);
                      }
                      // added to fix price modify issue
                    });
                  });
                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice = cart_data.display_base_price - value;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = 0;
              } else if (
                value >= parseFloat(items.start_range) &&
                value <= parseFloat(items.end_range)
              ) {
                let tax_percent = parseFloat(items.value);
                tax_percent = tax_percent / 100 + 1;

                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isSameBarpriceGroup) {
                        items.new_bar_price = 0;
                        items.applied_discount_price =
                          items.bar_price - value / tax_percent;
                        cart_data.discounted_price +=
                          items.bar_price - value / tax_percent;
                      } else {
                        // added to fix price modify issue
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value / tax_percent;
                        // added to fix price modify issue
                        items.new_bar_price = value / tax_percent;
                      }
                    });
                  });
                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice =
                    cart_data.display_base_price - value / tax_percent;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = parseFloat(items.value);
              }
            });
          }
        }

        if (taxDetails && taxDetails.tax_range_type === "single") {
          if (taxDetails.tax_value.length > 0) {
            taxDetails.tax_value.map((items: any) => {
              if (parseFloat(items.value) === 0) {
                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isSameBarpriceGroup) {
                        items.new_bar_price = 0;
                        items.applied_discount_price = items.bar_price - value;
                        cart_data.discounted_price += items.bar_price - value;
                      }
                      // added to fix price modify issue
                      else {
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value;
                        items.new_bar_price = parseFloat(value);
                      }
                      // added to fix price modify issue
                    });
                  });

                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice = cart_data.display_base_price - value;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = 0;
              } else if (value >= parseFloat(items.start_range)) {
                let tax_percent = parseFloat(items.value);
                tax_percent = tax_percent / 100 + 1;

                cart_data.discounted_price = 0;
                cart_data.rooms &&
                  cart_data.rooms.map((cartItem: any) => {
                    // added to fix price modify issue
                    cartItem.new_bar_price = 0;
                    // added to fix price modify issue
                    cartItem.day_wise_rates.map((items: any) => {
                      if (value <= items.bar_price && isSameBarpriceGroup) {
                        items.new_bar_price = 0;
                        items.applied_discount_price =
                          items.bar_price - value / tax_percent;
                        cart_data.discounted_price +=
                          items.bar_price - value / tax_percent;
                      } else {
                        // added to fix price modify issue
                        items.applied_discount_price = 0;
                        cartItem.new_bar_price += value / tax_percent;
                        // added to fix price modify issue
                        items.new_bar_price = value / tax_percent;
                      }
                    });
                  });
                cart_data.display_base_price_after_discount = 0;
                if (value <= cart_data.display_base_price) {
                  let discountedPrice =
                    cart_data.display_base_price - value / tax_percent;
                  cart_data.display_base_price_after_discount =
                    cart_data.display_base_price - discountedPrice;
                }

                gst_percentage = parseFloat(items.value);
              }
            });
          }
        }

        if (channelType === "direct") {
          for (let cart_data of cart) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            let total_gst_amount = calculateGstAmountForBackTracking(
              cart_data.rooms,
              cart_data,
              gst_percentage
            );

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cart &&
            cart.length > 0 &&
            cart.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cart.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCart([...cart]);
        }

        if (channelType === "partner") {
          for (let cart_data of cartPartner) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            let total_gst_amount = calculateGstAmountForBackTracking(
              cart_data.rooms,
              cart_data,
              gst_percentage
            );

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cartPartner &&
            cartPartner.length > 0 &&
            cartPartner.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cartPartner.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCartPartner([...cartPartner]);
        }
      } else {
        cart_data.discounted_price = 0;
        cart_data.rooms &&
          cart_data.rooms.map((cartItem: any) => {
            // added to fix price modify issue
            cartItem.new_bar_price = 0;
            // added to fix price modify issue
            cartItem.day_wise_rates.map((items: any) => {
              if (value <= items.bar_price && isSameBarpriceGroup) {
                items.new_bar_price = 0;
                items.applied_discount_price = items.bar_price - value;
                cart_data.discounted_price += items.bar_price - value;
              }
              // added to fix price modify issue
              else {
                items.applied_discount_price = 0;
                cartItem.new_bar_price += value;
                items.new_bar_price = parseFloat(value);
              }
              // added to fix price modify issue
            });
          });

        if (value <= cart_data.display_base_price) {
          let discountedPrice = cart_data.display_base_price - value;
          cart_data.display_base_price_after_discount =
            cart_data.display_base_price - discountedPrice;
        }
        // added to fix price modify issue
        else {
          cart_data.display_base_price_after_discount = value;
        }
        // added to fix price modify issue

        if (channelType === "direct") {
          for (let cart_data of cart) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

            let total_gst_amount = 0;
            if (taxDetails && taxDetails.is_taxable == 1) {
              total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
            }

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cart &&
            cart.length > 0 &&
            cart.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cart.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCart([...cart]);
        }

        if (channelType === "partner") {
          for (let cart_data of cartPartner) {
            cart_data.price = 0;
            let extra_adult_price = 0;
            let extra_child_price = 0;

            cart_data.rooms &&
              cart_data.rooms.map((cartItem: any) => {
                extra_adult_price += cartItem.extra_adult_price;
                extra_child_price += cartItem.extra_child_price;
                if (cartItem.new_bar_price) {
                  cart_data.price += cartItem.new_bar_price;
                } else {
                  cart_data.price += cartItem.bar_price;
                }
              });

            cart_data.display_price =
              cart_data.price + extra_adult_price + extra_child_price;

            cart_data.tax = [];
            // let total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);

            let total_gst_amount = 0;
            if (taxDetails && taxDetails.is_taxable == 1) {
              total_gst_amount = calculateGstAmount(cart_data.rooms, cart_data);
            }

            cart_data.tax.push({
              gst_price: total_gst_amount,
              other_tax: [],
            });
          }

          let gst_total_price = 0;
          let totalPrice = 0;
          let totalDiscountPrice = 0;
          cartPartner &&
            cartPartner.length > 0 &&
            cartPartner.map((cartItem: any) => {
              let extra_adult_price = 0;
              let extra_child_price = 0;
              cartItem.rooms.map((room: any) => {
                extra_adult_price += room.extra_adult_price;
                extra_child_price += room.extra_child_price;
              });
              totalDiscountPrice += cartItem.discounted_price;
              gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
              totalPrice +=
                cartItem.price + extra_adult_price + extra_child_price;

              let display_gst_price = 0;
              display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

              cartItem.display_pay_amount =
                cartItem.price +
                extra_adult_price +
                extra_child_price -
                cartItem.discounted_price;

              cartItem.display_pay_amount_after_tax =
                cartItem.price +
                extra_adult_price +
                extra_child_price +
                display_gst_price -
                cartItem.discounted_price;
            });

          cartPartner.map((cartItem: any) => {
            cartItem.paid_amount =
              totalPrice + gst_total_price - totalDiscountPrice;
            cartItem.paid_amount_per = 100;
          });

          setCartPartner([...cartPartner]);
        }
      }
    }
  };

  const fetchUserInfoCrs = async (guest_det: any) => {
    if (!guest_det) {
      return;
    }
    let res = await beApi.post(
      `${userInfoCrsGet}`,
      {
        mobile_number: `+91${guest_det}`,
        company_id: company_id,
      },
      {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      }
    );

    if (res.data.status === 1) {
      let fetched_data = res.data.data;

      setBookerName(`${fetched_data.first_name} ${fetched_data.last_name}`);
      setBookerEmail(fetched_data.email_id);
      setBookerMobile(guest_det);

      // if (enableBusinessBooking) {
      //   setCompanyName(fetched_data.company_name);
      //   setCompanyAddress(fetched_data.address);
      //   setGstIn(fetched_data.GSTIN);
      // }
    } else {
      toast(res.data.message, {
        hideProgressBar: true,
        type: "error",
      });
      setBookerName("");
      setBookerEmail("");
      setBookerMobile("");

      // setCompanyName('');
      // setCompanyAddress('');
      // setGstIn('');
    }
  };

  const fetchCompanyDetails = async (gst_no: any) => {
    let res = await beApi.get(`${userGstApi}/${gst_no}`, {
      headers: {
        Authorization: "Bearer " + auth_token,
      },
    });

    if (res.data.status === 1) {
      let fetched_data = res.data.data;
      if (enableBusinessBooking) {
        setCompanyName(fetched_data.company_name);
        setCompanyAddress(fetched_data.address);
        setGstIn(gst_no);
      }
    } else {
      toast(res.data.message, {
        hideProgressBar: true,
        type: "error",
      });
      setCompanyName("");
      setCompanyAddress("");
      setGstIn("");
    }
  };

  const handleEnableBusinessbooking = (value: any) => {
    if (!value) {
      setCompanyName("");
      setCompanyAddress("");
      setGstIn("");
    }

    setEnableBusinessBooking(!value);
  };

  const [paymentLinkType, setPaymentLinkType] = useState<any>(1);
  const [paymentLinkStatus, setPaymentLinkStatus] = useState<any>(1);

  const [paymentLinkValidType, setPaymentLinkValidtype] = useState<any>(1);
  const [validTime, setValidTime] = useState<any>(1);
  const [validHour, setValidHour] = useState<any>(1);
  const [validDays, setValidDays] = useState<any>(1);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<any>("");

  const [partialPayOption, setPartialPayOption] = useState<any>(1);
  const [partialPayStatus, setPartialPayStatus] = useState<any>(false);

  const [paymentPercent, setPaymentPercent] = useState<any>("");
  const [amountToPay, setAmountToPay] = useState<any>("");

  const [partialPercentage, setpartialPercentage] = useState<any>();
  const [partialPay, setPartialPay] = useState<any>();

  const [manuallyEnteredPartialPayPrice, setManuallyEnteredPartialPayPrice] =
    useState<any>("");
  const [displayPartialPayFields, setdisplayPartialPayFields] =
    useState<any>(false);

  const [businessSource, setBusinessSource] = useState<any>([]);
  const [bookingTypeOptions, setBookingTypeOptions] = useState<any>([]);

  const [selectedBusinessSource, setSelectedBusinessSource] = useState<any>("");
  const [selectedBookingType, setSelectedBookingType] = useState<any>("");
  const [selectedSalesExecutive, setSelectedSalesExecutive] = useState<any>("");

  useEffect(() => {
    setSelectedBusinessSource(selectedPartnerType);
  }, [selectedPartnerType]);

  const onItemChange = (value: any) => {
    if (parseInt(value) === 1) {
      setdisplayPartialPayFields(true);
      setPaymentLinkType(paymentLinkValidType);
      if (paymentLinkValidType === 1) {
        setValidTime(validHour);
      } else {
        setValidTime(validDays);
      }
    } else {
      setdisplayPartialPayFields(false);
      setPaymentLinkType("NA");
      setValidTime("0");
    }
    setSelectedPaymentMode(value);
  };

  const paymentLinkOption = (link: any) => {
    setPaymentLinkValidtype(link);
    if (parseInt(link) === 1) {
      setPaymentLinkStatus(1);
      setValidTime(validHour);
    } else {
      setPaymentLinkStatus(2);
      setValidTime(validDays);
    }
    setPaymentLinkType(link);
  };

  const onTypeChange = (value: any) => {
    if (paymentLinkType === 1) {
      setValidHour(value);
      // setValidTime(validHour); // In wrong order state was updating
      setValidTime(value);
    } else {
      setValidDays(value);
      // setValidTime(validDays); // In wrong order state was updating
      setValidTime(value);
    }
  };

  const paymentOption = (value: any) => {
    if (parseInt(value) === 1) {
      setPartialPayOption(1);
      setPartialPayStatus(false);
      setAmountToPay("");
      setPaymentPercent("");
    } else {
      setPartialPayOption(2);
      setPartialPayStatus(false);
    }
  };

  const partialPayment = (e: any) => {
    setPaymentPercent(e);
    setPartialPayStatus(true);
    setpartialPercentage(paymentPercent);

    let total_amount = parseFloat(displayTotalAmount);
    let partial_Pay: any = total_amount * (e / 100);
    setPartialPay(parseFloat(partial_Pay).toFixed(2));
    setAmountToPay(partial_Pay.toFixed(2));

    if (channelType === "direct") {
      cart.map((cartItem: any) => {
        cartItem.paid_amount = parseFloat(partial_Pay).toFixed(2);
        cartItem.partial_amount_per = paymentPercent;
      });
      setCart([...cart]);
    }

    if (channelType === "partner") {
      cartPartner.map((cartItem: any) => {
        cartItem.paid_amount = parseFloat(partial_Pay).toFixed(2);
        cartItem.partial_amount_per = paymentPercent;
      });
      setCartPartner([...cartPartner]);
    }
  };

  const handleManuallyEnteredPartialPayPrice = (e: any) => {
    setManuallyEnteredPartialPayPrice(e);
  };

  const validateManuallyEnteredPartialPayPrice = () => {
    if (channelType === "direct") {
      if (manuallyEnteredPartialPayPrice > cart[0]?.paid_amount) {
        toast("Entered Price is greater than the Total Price !", {
          hideProgressBar: true,
          type: "error",
        });
        setManuallyEnteredPartialPayPrice("");
        setPartialPay("");
        setAmountToPay("");
        setpartialPercentage("");

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cart &&
          cart.length > 0 &&
          cart.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cart.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCart([...cart]);
      } else {
        setPartialPayStatus(true);
        let partial_Pay = "";
        if (manuallyEnteredPartialPayPrice.trim() === "") {
          partial_Pay = displayTotalAmount;
        } else {
          partial_Pay = manuallyEnteredPartialPayPrice;
        }
        setPartialPay(parseFloat(partial_Pay).toFixed(2));
        setAmountToPay(parseFloat(partial_Pay).toFixed(2));
        setpartialPercentage("");
        let percentage_paid_amount = 0;
        cart.map((cartItem: any) => {
          cartItem.paid_amount = parseFloat(partial_Pay).toFixed(2);
          cartItem.partial_amount_per = "";
          percentage_paid_amount =
            parseFloat(manuallyEnteredPartialPayPrice) /
            cartItem.display_pay_amount_after_tax;
          percentage_paid_amount *= 100;
          cartItem.paid_amount_per = percentage_paid_amount.toFixed(2);
        });
        setCart([...cart]);
      }
    }

    if (channelType === "partner") {
      if (manuallyEnteredPartialPayPrice > cartPartner[0].paid_amount) {
        toast("Entered Price is greater than the Total Price !", {
          hideProgressBar: true,
          type: "error",
        });
        setManuallyEnteredPartialPayPrice("");
        setPartialPay("");
        setAmountToPay("");
        setpartialPercentage("");

        let gst_total_price = 0;
        let totalPrice = 0;
        let totalDiscountPrice = 0;
        cartPartner &&
          cartPartner.length > 0 &&
          cartPartner.map((cartItem: any) => {
            let extra_adult_price = 0;
            let extra_child_price = 0;
            cartItem.rooms.map((room: any) => {
              extra_adult_price += room.extra_adult_price;
              extra_child_price += room.extra_child_price;
            });
            totalDiscountPrice += cartItem.discounted_price;
            gst_total_price += cartItem.tax[0] && cartItem.tax[0].gst_price;
            totalPrice +=
              cartItem.price + extra_adult_price + extra_child_price;

            let display_gst_price = 0;
            display_gst_price = cartItem.tax[0] && cartItem.tax[0].gst_price;

            cartItem.display_pay_amount =
              cartItem.price +
              extra_adult_price +
              extra_child_price -
              cartItem.discounted_price;

            cartItem.display_pay_amount_after_tax =
              cartItem.price +
              extra_adult_price +
              extra_child_price +
              display_gst_price -
              cartItem.discounted_price;
          });

        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount =
            totalPrice + gst_total_price - totalDiscountPrice;
          cartItem.paid_amount_per = 100;
        });

        setCartPartner([...cartPartner]);
      } else {
        setPartialPayStatus(true);
        let partial_Pay = "";
        if (manuallyEnteredPartialPayPrice.trim() === "") {
          partial_Pay = displayTotalAmount;
        } else {
          partial_Pay = manuallyEnteredPartialPayPrice;
        }
        setPartialPay(parseFloat(partial_Pay).toFixed(2));
        setAmountToPay(parseFloat(partial_Pay).toFixed(2));
        setpartialPercentage("");
        let percentage_paid_amount = 0;
        cartPartner.map((cartItem: any) => {
          cartItem.paid_amount = parseFloat(partial_Pay).toFixed(2);
          cartItem.partial_amount_per = "";
          percentage_paid_amount =
            parseFloat(manuallyEnteredPartialPayPrice) /
            cartItem.display_pay_amount_after_tax;
          percentage_paid_amount *= 100;
          cartItem.paid_amount_per = percentage_paid_amount.toFixed(2);
        });
        setCartPartner([...cartPartner]);
      }
    }
  };

  const [companyUrl, setCompanyUrl] = useState<any>();
  const [companyApiKey, setCompanyApiKey] = useState<any>();

  useEffect(() => {
    getApiKey(company_id);
    getRequiredObjects();
    getTaxDetails(hotel_id);
    getActiveExecutiveList();
  }, []);

  useEffect(() => {
    fetchAllPartner(hotel_id);
  }, [hotel_id]);

  const getApiKey = async (company_id: any) => {
    try {
      let response = await kernelApi.get(`${companyProfileGet}/${company_id}`, {
        headers: {
          Authorization: "Bearer " + auth_token,
        },
      });

      if (response.data.status === 1) {
        setCompanyUrl(response.data.data.company_url);
        setCompanyApiKey(response.data.data.api_key);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        Logout();
      }
    }
  };

  const getTaxDetails = async (hotel_id: any) => {
    let response = await kernelApi.get(`${getLocaleDetails}/${hotel_id}`, {
      headers: {
        Authorization: "Bearer " + auth_token,
      },
    });

    if (response.data.status === 1) {
      setTaxDetails(response.data.states);
    }
  };

  const getRequiredObjects = async () => {
    let response = await beApi.get(`${getBusinessSourceDetails}`, {
      headers: {
        Authorization: "Bearer " + auth_token,
      },
    });

    if (response.data.status === 1) {
      setBusinessSource(response.data.business_source);
      setBookingTypeOptions(response.data.booking_type);
    }
  };

  const getActiveExecutiveList = async () => {
    try {
      let response = await beApi.get(
        `${crsActiveExecutiveList}/${hotel_id}/${admin_id}`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );
      if (response.data.status === 1) {
        setActiveExecutiveList(response.data.list);
        if (response.data.list.length == 1 && role_id == 13) {
          setSelectedSalesExecutive(response.data.list[0].id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllPartner = async (hotel_id: any) => {
    try {
      let fetchAllPartner = await beApi.get(`active-partner-list/${hotel_id}`);

      if (fetchAllPartner.status === 200) {
        setAllFetchedPartner(fetchAllPartner.data);
      } else {
        setAllFetchedPartner([]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const bookNow = async () => {
    let from_date = moment(startDate).format("DD-MM-YYYY");
    let to_date = moment(endDate).format("DD-MM-YYYY");

    let noOf_adults: any[] = [];
    let noOf_childs: any[] = [];
    let guest_names = [];

    if (channelType === "direct") {
      for (let cart_data of cart) {
        for (let i = 0; i < cart_data.rooms.length; i++) {
          noOf_adults.push(cart_data.rooms[i].selected_adult);
          noOf_childs.push(cart_data.rooms[i].selected_child);
        }
      }
    }

    if (channelType === "partner") {
      for (let cart_data of cartPartner) {
        for (let i = 0; i < cart_data.rooms.length; i++) {
          noOf_adults.push(cart_data.rooms[i].selected_adult);
          noOf_childs.push(cart_data.rooms[i].selected_child);
        }
      }
    }

    guest_names = guestName;

    let guest_first_name;
    let guest_last_name;

    let fullname = bookerName;
    let email_id = bookerEmail;
    let contact_no = bookerMobile;
    let company_name = companyName;
    let company_address = companyAddress;
    let gst_no = gstIn;
    let internal_remark = internalRemark;
    let guest_remark = guestRemark;

    let internalRemarks;
    let guestRemarks;
    internalRemarks = internal_remark != "" ? internal_remark : "NA";
    guestRemarks = guest_remark != "" ? guest_remark : "NA";
    let paymentOption = selectedPaymentMode;
    let payValidValue;
    let paymentlinkType;
    if (paymentOption == 1) {
      payValidValue = validTime;
      paymentlinkType = paymentLinkValidType;
    } else {
      payValidValue = 0;
      paymentlinkType = 0;
    }

    if (!selectedBookingType) {
      toast("Booking Type is required!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (!fullname) {
      toast("Booker name is required!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }

    // // if (!contact_no.match(/^[0-9]*$/)) {
    // if (!validateMobile(contact_no)) {
    //   toast("Invalid Mobile Number entered!", {
    //     hideProgressBar: true,
    //     type: "error",
    //   });
    //   return;
    // }

    if (
      !email_id.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      toast("Invalid Email Id entered!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (collectAdivance) {
      if (+advanceAmount > +displayTotalAmount) {
        toast("Advance amount is greater than total amount!", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }
      if (advanceAmount == "0" || advanceAmount == "") {
        toast("Advance collected amount cannot be 0!", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }
      if (!paymentMode) {
        toast("Select Advance Collect Payment Mode!", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }
      if (!collectedby) {
        toast("Please select Collecting Partner!", {
          hideProgressBar: true,
          type: "error",
        });
        return;
      }
    }
    if (paymentOption == "") {
      toast("Payment option is required!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (payloadPolicyId == "") {
      toast("Select Cancellation Policy", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }

    if (!selectedBusinessSource) {
      toast("Business Source is required!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }

    if (
      fullname != "" &&
      email_id != "" &&
      contact_no != "" &&
      paymentOption != "" &&
      selectedBookingType &&
      selectedBusinessSource
    ) {
      setLoaderBookNow(true);
      //spliting name
      var names = fullname.split(" ");
      if (!names || names.length <= 1) {
        guest_first_name = fullname;
        guest_last_name = "NA";
      } else {
        guest_first_name = fullname.split(" ").slice(0, -1).join(" ");
        guest_last_name = fullname.split(" ").slice(-1).join(" ");
      }

      let res_user_register = await beApi.post(
        `${toRegisterUser}`,
        {
          company_url: companyUrl,
          email_id: email_id,
          mobile: contact_no,
          first_name: guest_first_name,
          last_name: guest_last_name,
          company_name: company_name,
          GST_IN: gst_no,
          address: company_address,
          zip_code: "",
          country: "",
          state: "",
          city: "",
          identity: "",
          identity_no: "",
          expiry_date: "",
          date_of_birth: "",
          GSTIN: "",
        },
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (res_user_register.data.status === 1) {
        let auth_token = res_user_register.data.auth_token;

        // if (selectedBookingType !== 2) {
        let res_push_booking = await beApi.post(
          `/bookingEngine/bookings/${companyApiKey}`,
          {
            hotel_id: hotel_id,
            from_date: from_date,
            to_date: to_date,
            coupon: [],
            cart:
              channelType === "direct"
                ? nonTaxableBooking
                  ? cartNonTaxableBooking
                  : cart
                : nonTaxableBooking
                ? cartNonTaxableBooking
                : cartPartner,
            paid_service: [],
            visitors_ip: "10.10.10.10",
            reference: null,
            source: "CRS",

            // partner_id: selectedPartner
          },
          {
            headers: {
              Authorization: "Bearer " + auth_token,
            },
          }
        );

        if (res_push_booking.data.status === 1) {
          let invoice_id = res_push_booking.data.invoice_id;
          let invoice_secureHash = res_push_booking.data.invoice_secureHash;

          let get_crs_booking = await beApi.get(
            `/crs-booking/${invoice_id}/crs`,
            {
              headers: {
                Authorization: "Bearer " + auth_token,
              },
            }
          );

          if (get_crs_booking.data.status === 1) {
            let push_crs_booking = await beApi.post(`/crs/crs_bookings`, [
              {
                guest_details: guest_names.toString(),
                no_of_adult: noOf_adults.toString(),
                no_of_child: noOf_childs.toString(),
                payment_type: paymentOption,
                valid_type: paymentlinkType,
                valid_hour: payValidValue,
                invoice_id: invoice_id,
                secure_hash: invoice_secureHash,
                internal_remark: internalRemarks,
                guest_remark: guestRemarks,
                partner_id: selectedPartner,
                booking_type: bookingType,
                selected_booking_type: selectedBookingType,
                selected_business_source: selectedBusinessSource,
                selected_sales_executive: selectedSalesExecutive,
                is_taxable_booking: nonTaxableBooking,
                cancellation_policy_id: payloadPolicyId,
                admin_id: admin_id,
                advance_amount: advanceAmount,
                advanace_collection_date: firstDate,
                advance_paymentMode: paymentMode,
                advance_collectedby: collectedby,
                advance_refference_number: refference_number,
              },
            ]);

            if (push_crs_booking.data.status === 1) {
              setLoaderBookNow(false);
              setBookingConfirmed(true);
              toast(push_crs_booking.data.message, {
                hideProgressBar: true,
                type: "success",
              });
              refreshData();
              onClose();
            }
          }
        }
        // }

        // else {

        //   let res_push_enquiry = await beApi.post(
        //     `/crs-booking-enquiry`,
        //     {
        //       hotel_id: current_property.hotel_id,
        //       from_date: from_date,
        //       to_date: to_date,
        //       coupon: [],
        //       cart: cart,
        //       paid_service: [],
        //       visitors_ip: "10.10.10.10",
        //       reference: null,
        //     },
        //     {
        //       headers: {
        //         Authorization: "Bearer " + auth_token,
        //       },
        //     }
        //   );

        //   if (res_push_enquiry.data.status === 1) {
        //     setLoaderBookNow(false);
        //     setBookingConfirmed(true);
        //     toast(res_push_enquiry.data.message, {
        // hideProgressBar: true,
        // type: "success"
        // });
        //     onClose();
        //   }
        // }
      }
    }
  };

  const calculateGstAmountForBackTracking = (
    rooms: any,
    cart: any,
    gst_percentage: any
  ) => {
    let from_date = moment(startDate).format("YYYY-MM-DD");
    let to_date = moment(endDate).format("YYYY-MM-DD");

    let end_date = moment(to_date).subtract(1, "days").format("YYYY-MM-DD");

    let dateArray = getDateArray(from_date, end_date);
    let gstAmount = 0;

    dateArray &&
      dateArray.map((value: any) => {
        rooms.map((rates_for_discount: any) => {
          let gst_price = 0;
          let total_bar_price = 1;
          let total_pay_price;

          rates_for_discount.day_wise_rates &&
            rates_for_discount.day_wise_rates.map((day_rate: any) => {
              if (value === day_rate.date) {
                total_bar_price = day_rate.bar_price;

                if (day_rate.new_bar_price && day_rate.new_bar_price > 0) {
                  total_bar_price = day_rate.new_bar_price;
                }

                if (
                  rates_for_discount.extra_adult_price > 0 &&
                  rates_for_discount.no_of_extra_adult &&
                  rates_for_discount.no_of_extra_adult > 0
                ) {
                  total_bar_price =
                    total_bar_price +
                    day_rate.extra_adult_price *
                      rates_for_discount.no_of_extra_adult;
                }

                if (
                  rates_for_discount.extra_child_price > 0 &&
                  rates_for_discount.no_of_extra_child &&
                  rates_for_discount.no_of_extra_child > 0
                ) {
                  total_bar_price =
                    total_bar_price +
                    day_rate.extra_child_price *
                      rates_for_discount.no_of_extra_child;
                }

                if (
                  day_rate.applied_discount_price &&
                  day_rate.applied_discount_price > 0
                ) {
                  total_pay_price =
                    total_bar_price - day_rate.applied_discount_price;
                } else {
                  total_pay_price = total_bar_price;
                }

                gst_price += total_pay_price;
                gstAmount += (gst_price * gst_percentage) / 100;
              }
            });
        });
      });
    return gstAmount;
  };

  const validateTotalRooms = (event: any) => {
    if ((event.target.innerText + event.key).length > 3) {
      event.preventDefault();
    }
  };

  const handleNegativeNumber = (event: any) => {
    // Prevents the input of negative numbers
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      event.preventDefault();
    }
  };

  const changeTaxableBooking = () => {
    setIsCheckboxAltered(true);
    setNonTaxableBooking(!nonTaxableBooking);
  };

  const [cartNonTaxableBooking, setCartNonTaxableBooking] = useState<any>([]);

  useEffect(() => {
    let cartDataCopy = channelType === "direct" ? cart : cartPartner;
    let paidAmount = 0;
    cartDataCopy.forEach((items, index) => {
      paidAmount += displayRoomPriceFIT[index] * nights * items.rooms.length;
    });

    let cartNonTaxBookings = cartDataCopy.map((items, index) => ({
      ...items,
      paid_amount: paidAmount,
      tax: items.tax.map((taxItem) => ({
        ...taxItem,
        gst_price: 0,
      })),
    }));

    setCartNonTaxableBooking(cartNonTaxBookings);
  }, [nonTaxableBooking]);

  useEffect(() => {
    if (bookingTypeOptions.length == 1) {
      setSelectedBookingType(bookingTypeOptions[0].booking_type_id);
    }
  }, [bookingTypeOptions]);

  // =================Advance Collect Option==============================
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));
  const [collectAdivance, setCollectAdvance] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [collectedby, setCollectedBy] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [refference_number, setReffrenceNumber] = useState("");
  const capturePaymentMode = (paymentMode: string) => {
    setPaymentMode(paymentMode);
  };
  const getCurrentDate = () => {
    setFirstDate(moment().format("YYYY-MM-DD"));
    // setCurrentYear(moment().year());
    // setCurrentMonth(moment().format("MMM"));
  };

  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
    // setCurrentYear(moment(date_str).year());
    // setCurrentMonth(moment(date_str).format("MMM"));
  };

  return (
    <>
      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i className="bi bi-x" onClick={onClose}></i>
        </div>
        <div className="standard-page--label">New Booking</div>
      </div>
      <div className="height-64px"></div>

      <div className="new__bookings__wrapper">
        <div className="new__room__type__progress__step">
          {steps.map((step, index) => {
            return (
              <div className="label__progress__wrapper" key={index}>
                {index < tab ? (
                  <div
                    className="progress__status status--completed"
                    onClick={() => setTab(index)}
                  >
                    <i className="bi bi-check2"></i>
                  </div>
                ) : (
                  <div
                    className={`progress__status status--${
                      index === tab ? "in--progress" : "pending"
                    }`}
                  >
                    {index + 1}
                  </div>
                )}
                {/* {
                  index === 2 && bookingConfirmed &&
                  <div className="progress__status status--completed">
                  <i className="bi bi-check2"></i>
                </div>
                } */}
                <div className="progress__status__label__text">{step}</div>
              </div>
            );
          })}
        </div>
        {tab === 0 && (
          <>
            <div className="date_nights_label_content_wrapper">
              <div className="checkin_dates_wrapper">
                <div className="check_in_out_label my-3">
                  Choose Check-in/out Date
                </div>
                <div
                  className="bookings__select__date__wrapper "
                  onClick={() => setFocusedInput("startDate")}
                >
                  <div className="date__picker__icon">
                    {" "}
                    <i className="bi bi-calendar"></i>
                  </div>
                  <div className="date__label">
                    {!startDate && !endDate
                      ? "Select Dates"
                      : (startDate
                          ? moment(startDate).format("DD MMM, YYYY")
                          : "") +
                        " - " +
                        (endDate ? moment(endDate).format("DD MMM, YYYY") : "")}
                  </div>
                  <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    focusedInput={focusedInput}
                    setFocusedInput={setFocusedInput}
                    preventPastDates={true}
                  />
                </div>
              </div>
              <div className="nights_wrapper">
                <div className="nights_label my-3">Nights</div>
                <div className="nights__contain">
                  <i className="bi bi-moon-fill"></i>
                  <span className="ms-2">{nights}</span>
                </div>
              </div>
              <div className="rooms_wrapper">
                <div className="rooms_label my-3">Rooms(s)</div>
                <div className="increment__decrement__btn_bookings ">
                  <div
                    className="decrement__btn"
                    onClick={() => decrement(rooms, setRooms)}
                  >
                    <i className="bi bi-dash changing--icon"></i>
                  </div>
                  <div
                    className="changing__value"
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onKeyPress={(e: any) => validateTotalRooms(e)}
                    onBlur={(e: any) => setRooms(parseInt(e.target.innerText))}
                  >
                    {rooms}
                  </div>

                  <div
                    className="increment__btn"
                    onClick={() => increment(rooms, setRooms)}
                  >
                    <i className="bi bi-plus changing--icon"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="check_avilability_btn">
              <InventoryBtn
                handleClick={checkAvailability}
                loader={loaderCheckAvailability}
              >
                Check Availability
              </InventoryBtn>
            </div>
            {allRoomTypeData.length > 0 && (
              <div className="roomtypes_header">
                <div className="room_type_header">Room Type</div>
                <div className="rate_room_container">
                  <div className="rate_room_label rate_header">Rate</div>
                  <div className="rate_room_label room_header">Room</div>
                </div>
              </div>
            )}

            {fetchedRoomData &&
              fetchedRoomData.map((data: any, id: any) => {
                return (
                  <div className="roomtypes__content__container">
                    <div className="roomtypes__display">
                      {data.room_type}
                      <div className="availability__content">
                        <div className="avl_label">Avl</div>
                        <div className="inv_label">
                          {data.display_no_of_rooms.length}
                        </div>
                      </div>
                    </div>
                    <div className="plan__dropdown__wrapper">
                      <div className="plan_content">
                        {" "}
                        <InputSelectField
                          label="Plan"
                          value={
                            selectedRatePlans.length > 0 &&
                            selectedRatePlans[id].rate_plan_id
                          }
                          handleChange={(e) =>
                            selectRatePlan(e.target.value, id, data)
                          }
                          values={data.rate_plans.map((plans: any, i: any) => {
                            return plans.rate_plan_id;
                          })}
                          options={data.rate_plans.map((plans: any, i: any) => {
                            return plans.plan_name;
                          })}
                        />
                      </div>

                      <div className="plan_content">
                        <InputSelectField
                          label="Rooms"
                          value={
                            selectedRatePlans.length > 0 &&
                            selectedRatePlans[id].rooms_selected
                          }
                          handleChange={(e) =>
                            totalRoomSelected(
                              e.target.value,
                              data.min_inv,
                              data,
                              id
                            )
                          }
                          options={data.display_no_of_rooms.map(
                            (rooms: any) => {
                              return rooms;
                            }
                          )}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

            {displayNoRoomsAvaialbleMsg && (
              <div className="close_available_container">
                <div className="close_icon">
                  {" "}
                  <i className="bi bi-x-circle"></i>
                </div>
                <div className="rooms_available_label">
                  {" "}
                  No Room (s) available on mentioned dates
                </div>
                <div className="available__rooms">
                  Available Rooms - {minimumAvailableRooms}
                </div>
              </div>
            )}

            {alternativeDatesData && alternativeDatesData.length > 0 && (
              <>
                <div className="left_right_btn_container ">
                  <div className="alternatives_label">Alternatives</div>
                  <div className="btn_container">
                    <div className="button-next" onClick={prevSlide}>
                      <i className="bi bi-arrow-left"></i>
                    </div>
                    <div className="button-next" onClick={nextSlide}>
                      <i className="bi bi-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <Slider
                  arrows={true}
                  slidesPerRow={3}
                  centerPadding="5px"
                  className="slider"
                >
                  {alternativeDatesData.map(
                    (item: any, index_alternate_dates: any) => {
                      return (
                        <div
                          className="availability_card_container"
                          key={index_alternate_dates}
                          onClick={() =>
                            callCheckAvailability(
                              item.date_from,
                              item.date_to,
                              item.no_of_rooms
                            )
                          }
                        >
                          <div className="date_label">
                            {moment(item.date_from).format("DD MMM YYYY")} -{" "}
                            {moment(item.date_to).format("DD MMM YYYY")}
                          </div>
                          <div className="avl_rooms_label">
                            Avl Rooms- {item.no_of_rooms}
                          </div>
                          <div className="select_label">Select</div>
                        </div>
                      );
                    }
                  )}
                </Slider>
              </>
            )}
          </>
        )}

        {/* // Booking details */}
        {tab === 1 && (
          <>
            <div className="dates__rooms__nights__title__container">
              <div>Choose Check-in/out Date</div>

              <div className="nights_label_wrapper">
                <div className="nights_label">Nights</div>
                {/* <div>Rooms(s)</div> */}
              </div>
            </div>
            <div className="dates__nights__rooms__container">
              <div className="date_wrapper">
                <div className="calander__icon">
                  <i className="bi bi-calendar"></i>
                </div>
                <div className="confirm_date_label">
                  {" "}
                  {moment(startDate).format("DD MMM, YYYY")}-
                  {moment(endDate).format("DD MMM, YYYY")}
                </div>
              </div>
              <div className="room_nights_wrapper">
                <div className="nights__container">
                  <i className="bi bi-moon-fill"></i>
                  <span className="ms-2">{nights}</span>
                </div>
                {/* <div className="rooms__container">
                  <LocalHotelIcon />
                  <span className="ms-2"></span>
                </div> */}
              </div>
            </div>

            <div className="booking__channels__type__wrapper mt-2 mb-4">
              <div className="booking__type__wrapper">
                <div className="bookingtype_label">Booking Type</div>
                <div className="checkbox_container">
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => changeBookingType("fit")}
                      checked={bookingType === "fit"}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      FIT
                    </label>
                  </div>

                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => changeBookingType("group")}
                      checked={bookingType === "group"}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Group
                    </label>
                  </div>
                </div>
              </div>

              <div className="channel__type__wrapper">
                <div className="bookingtype_label">Channel Type</div>
                <div className="checkbox_container">
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => changeChannelType("direct")}
                      checked={channelType === "direct"}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Direct
                    </label>
                  </div>

                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={() => changeChannelType("partner")}
                      checked={channelType === "partner"}
                    ></input>
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Partner
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {channelType === "partner" && (
              <div className="search__partner__wrapper">
                <div className="search__partner">
                  <input
                    placeholder="Search Partner"
                    value={searchPartnerValue}
                    onChange={(e) => {
                      setSearchPartnerValue(e.target.value);
                      setDisplayPartner(true);
                    }}
                  />
                  <i className="bi bi-search"></i>
                </div>
                {searchPartnerValue.length > 0 && displayPartner && (
                  <div className="mt-1">
                    <ul className="partner__list">
                      {allFetchedPartner
                        .filter((item: any) =>
                          item.partner_name
                            .toLowerCase()
                            ?.includes(searchPartnerValue?.toLowerCase())
                        )
                        .map((partner_data: any, partner_idx: any) => (
                          <li
                            className={
                              selectedPartner === partner_data.id
                                ? "partner__name mb-1 partner__name__hover partner__name__selected"
                                : "partner__name partner__name__hover"
                            }
                            onClick={() => {
                              fetchSelectedPartnerData(partner_data);
                            }}
                            key={partner_idx}
                          >
                            {partner_data.partner_name}{" "}
                            <span
                              className={
                                partner_data.partner_type === "Agent"
                                  ? "agent_color"
                                  : "corporate_color"
                              }
                            >
                              {" "}
                              ({partner_data.partner_type})
                            </span>{" "}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            )}

            {noPartnerRate && (
              <div className="partner_rate_message mt-1 mb-3">
                No rates defined for Partner showing default rates
              </div>
            )}

            {partnerDefinedRate && (
              <div className="partner_rate_message mt-1 mb-3">
                Partner rates available
              </div>
            )}

            {/* Disable the non taxable booking section */}
            {/* {(taxDetails?.is_taxable == 1 || isCheckboxAltered) &&
              <div className="booking__channels__type__wrapper mt-3 mb-4">
                <div className="booking__type__wrapper" style={{ display: "flex", alignItems: "center" }}>
                  <div className="bookingtype_label mt-0">This booking will be a non taxable booking</div>
                  <div className="checkbox_container ms-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={changeTaxableBooking}
                      checked={nonTaxableBooking}
                    ></input>
                  </div>
                </div>
              </div>} */}

            {/* for fit */}
            {bookingType === "fit" && (
              <>
                {channelType === "direct" && (
                  <>
                    {cart.map((cart_data: any, index_fit: any) => {
                      return (
                        <div className="cart_card_wrapper" key={index_fit}>
                          <div className="roomtype_wrapper">
                            <div className="roomtype__label">
                              {cart_data.room_type}
                            </div>

                            <div className="plan_label">
                              <i className="bi bi-cup-straw"></i>{" "}
                              {cart_data.plan_type}
                            </div>

                            <div className="plan_label">
                              {" "}
                              <i className="bi bi-house"></i>{" "}
                              {cart_data.rooms.length}
                            </div>
                          </div>
                          <div className="roomrate_wrapper">
                            <div className="roomrate__label">
                              <InputTextField
                                label="Room Rate/Night"
                                value={displayRoomPriceFIT[index_fit]}
                                type="number"
                                handleChange={(e) =>
                                  changedPriceFIT(
                                    parseFloat(e.target.value),
                                    cart_data,
                                    index_fit,
                                    switchTax[index_fit]
                                  )
                                }
                              />
                            </div>
                            {!nonTaxableBooking &&
                              taxDetails?.is_taxable == 1 && (
                                <div className="toggle__wrapper">
                                  <div className="tax_label">Including Tax</div>
                                  <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                      onChange={() =>
                                        handleSwitchTaxFIT(
                                          channelType === "direct"
                                            ? displayRoomPriceFIT[index_fit]
                                            : displayRoomPriceFITPartner[
                                                index_fit
                                              ],
                                          cart_data,
                                          index_fit
                                        )
                                      }
                                      checked={switchTax[index_fit]}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="price_break_up_label">
                            {" "}
                            Amount for {nights * cart_data.rooms.length} Room
                            Nights
                          </div>
                          {nonTaxableBooking ? (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label w-25">
                                Total{" "}
                                {displayRoomPriceFIT[index_fit] *
                                  nights *
                                  cart_data.rooms.length}
                              </div>
                            </div>
                          ) : (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label">
                                Before Tax{" "}
                                {cart_data.display_pay_amount.toFixed(2)}
                              </div>
                              {taxDetails?.is_taxable == 1 && (
                                <div className="plan_label">
                                  Tax {cart_data.tax[0].gst_price.toFixed(2)}
                                </div>
                              )}
                              <div className="plan_label">
                                Total{" "}
                                {cart_data.display_pay_amount_after_tax.toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}

                {channelType === "partner" && (
                  <>
                    {cartPartner.map((cart_data: any, index_fit: any) => {
                      return (
                        <div className="cart_card_wrapper" key={index_fit}>
                          <div className="roomtype_wrapper">
                            <div className="roomtype__label">
                              {cart_data.room_type}
                            </div>

                            <div className="plan_label">
                              <i className="bi bi-cup-straw"></i>{" "}
                              {cart_data.plan_type}
                            </div>

                            <div className="plan_label">
                              {" "}
                              <i className="bi bi-house"></i>{" "}
                              {cart_data.rooms.length}
                            </div>
                          </div>
                          <div className="roomrate_wrapper">
                            <div className="roomrate__label">
                              <InputTextField
                                label="Room Rate/Night"
                                type="number"
                                value={displayRoomPriceFITPartner[index_fit]}
                                handleChange={(e) =>
                                  changedPriceFIT(
                                    parseFloat(e.target.value),
                                    cart_data,
                                    index_fit,
                                    switchTax[index_fit]
                                  )
                                }
                              />
                            </div>
                            {!nonTaxableBooking &&
                              taxDetails?.is_taxable == 1 && (
                                <div className="toggle__wrapper">
                                  <div className="tax_label">Including Tax</div>
                                  <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                      onChange={() =>
                                        handleSwitchTaxFIT(
                                          channelType === "direct"
                                            ? displayRoomPriceFIT[index_fit]
                                            : displayRoomPriceFITPartner[
                                                index_fit
                                              ],
                                          cart_data,
                                          index_fit
                                        )
                                      }
                                      checked={switchTax[index_fit]}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>

                          <div className="price_break_up_label">
                            {" "}
                            Amount for {nights * cart_data.rooms.length} Room
                            Nights
                          </div>
                          {nonTaxableBooking ? (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label w-25">
                                Total{" "}
                                {displayRoomPriceFITPartner[index_fit] *
                                  nights *
                                  cart_data.rooms.length}
                              </div>
                            </div>
                          ) : (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label">
                                Before Tax{" "}
                                {cart_data.display_pay_amount.toFixed(2)}
                              </div>
                              {taxDetails?.is_taxable == 1 && (
                                <div className="plan_label">
                                  Tax {cart_data.tax[0].gst_price.toFixed(2)}
                                </div>
                              )}
                              <div className="plan_label">
                                Total{" "}
                                {cart_data.display_pay_amount_after_tax.toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}

                <div className="Guest_details_label">Guest Details</div>

                {channelType === "direct" && (
                  <>
                    {cart.map((cart_data: any, index_cart_fit: any) => {
                      return (
                        <React.Fragment key={index_cart_fit}>
                          <div className="bookingtype_label">
                            {cart_data.room_type}
                          </div>
                          {cart_data.rooms.map(
                            (rooms_selected: any, idx_room: any) => {
                              return (
                                <div
                                  className="guest__details__wrapper"
                                  key={idx_room}
                                >
                                  <div className="guest_details_label">
                                    {rooms_selected.rooms}
                                  </div>
                                  <div className="guest__input__container">
                                    <div className="guestname__label">
                                      <InputTextField
                                        label="Guest Name"
                                        value={
                                          guestNameValue[
                                            rooms_selected.counter_index
                                          ]
                                        }
                                        handleChange={(e) =>
                                          saveGuestName(
                                            e.target.value,
                                            idx_room,
                                            cart_data.room_type_id,
                                            rooms_selected.counter_index
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="adult_dropdowns">
                                      <InputSelectField
                                        label="Adult"
                                        value={rooms_selected.selected_adult}
                                        values={rooms_selected.adults.map(
                                          (adult: any) => {
                                            return adult.value;
                                          }
                                        )}
                                        options={rooms_selected.adults.map(
                                          (adult: any) => {
                                            return adult.option;
                                          }
                                        )}
                                        handleChange={(e) =>
                                          handleAdultChange(
                                            e,
                                            cart_data.max_occupancy,
                                            rooms_selected.room,
                                            rooms_selected,
                                            cart_data
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="adult_dropdowns">
                                      <InputSelectField
                                        label="Child"
                                        value={rooms_selected.selected_child}
                                        values={rooms_selected.childs.map(
                                          (child: any) => {
                                            return child.value;
                                          }
                                        )}
                                        options={rooms_selected.childs.map(
                                          (child: any) => {
                                            return child.option;
                                          }
                                        )}
                                        handleChange={(e) =>
                                          handleChildChange(
                                            e,
                                            cart_data.max_occupancy,
                                            rooms_selected.room,
                                            rooms_selected,
                                            cart_data
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}

                {channelType === "partner" && (
                  <>
                    {cartPartner.map((cart_data: any, index_cart_fit: any) => {
                      return (
                        <React.Fragment key={index_cart_fit}>
                          <div className="bookingtype_label">
                            {cart_data.room_type}
                          </div>
                          {cart_data.rooms.map(
                            (rooms_selected: any, idx_room: any) => {
                              return (
                                <div
                                  className="guest__details__wrapper"
                                  key={idx_room}
                                >
                                  <div className="guest_details_label">
                                    {rooms_selected.rooms}
                                  </div>
                                  <div className="guest__input__container">
                                    <div className="guestname__label">
                                      <InputTextField
                                        label="Guest Name"
                                        value={
                                          guestNameValue[
                                            rooms_selected.counter_index
                                          ]
                                        }
                                        handleChange={(e) =>
                                          saveGuestName(
                                            e.target.value,
                                            idx_room,
                                            cart_data.room_type_id,
                                            rooms_selected.counter_index
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="adult_dropdowns">
                                      <InputSelectField
                                        label="Adult"
                                        value={rooms_selected.selected_adult}
                                        values={rooms_selected.adults.map(
                                          (adult: any) => {
                                            return adult.value;
                                          }
                                        )}
                                        options={rooms_selected.adults.map(
                                          (adult: any) => {
                                            return adult.option;
                                          }
                                        )}
                                        handleChange={(e) =>
                                          handleAdultChange(
                                            e,
                                            cart_data.max_occupancy,
                                            rooms_selected.room,
                                            rooms_selected,
                                            cart_data
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="adult_dropdowns">
                                      <InputSelectField
                                        label="Child"
                                        value={rooms_selected.selected_child}
                                        values={rooms_selected.childs.map(
                                          (child: any) => {
                                            return child.value;
                                          }
                                        )}
                                        options={rooms_selected.childs.map(
                                          (child: any) => {
                                            return child.option;
                                          }
                                        )}
                                        handleChange={(e) =>
                                          handleChildChange(
                                            e,
                                            cart_data.max_occupancy,
                                            rooms_selected.room,
                                            rooms_selected,
                                            cart_data
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </>
            )}
            {/* for fit */}

            {/* for group */}
            {bookingType === "group" && (
              <>
                {channelType === "direct" && (
                  <>
                    {cart.map((cart_data: any, index_group: any) => {
                      return (
                        <div className="cart_card_wrapper" key={index_group}>
                          <div className="roomtype_wrapper">
                            <div className="roomtype__label">
                              {cart_data.room_type}
                            </div>

                            <div className="plan_label">
                              <i className="bi bi-cup-straw"></i>{" "}
                              {cart_data.plan_type}
                            </div>

                            <div className="plan_label">
                              {" "}
                              <i className="bi bi-house"></i>{" "}
                              {cart_data.rooms.length}
                            </div>
                          </div>
                          <div className="roomrate_wrapper">
                            <div className="roomrate__label">
                              <InputTextField
                                label="Room Rate/Night"
                                type="number"
                                value={displayRoomPriceGroup[index_group]}
                                handleChange={(e) =>
                                  changedPriceGroup(
                                    parseFloat(e.target.value),
                                    cart_data,
                                    index_group,
                                    switchTax[index_group]
                                  )
                                }
                              />

                              {/* <input type="text"
                          value={(cart_data.display_base_price_after_discount == 0) ? cart_data.display_base_price : cart_data.display_base_price_after_discount}
                          onBlur={(e) => changedPriceGroup(e.target.value, cart_data)}
                        /> */}
                            </div>
                            <div className="adult_dropdown">
                              <InputSelectField
                                label="Adult"
                                value={cart_data.rooms[0].selected_adult}
                                values={cart_data.rooms[0].adults.map(
                                  (adult: any) => {
                                    return adult.value;
                                  }
                                )}
                                options={cart_data.rooms[0].adults.map(
                                  (adult: any) => {
                                    return adult.option;
                                  }
                                )}
                                handleChange={(e) =>
                                  handleAdultChangeCorporate(
                                    e,
                                    cart_data.max_occupancy,
                                    cart_data.rooms[0].room,
                                    cart_data.rooms[0],
                                    cart_data
                                  )
                                }
                              />
                            </div>
                            <div className="adult_dropdown">
                              <InputSelectField
                                label="Child"
                                value={cart_data.rooms[0].selected_child}
                                values={cart_data.rooms[0].childs.map(
                                  (child: any) => {
                                    return child.value;
                                  }
                                )}
                                options={cart_data.rooms[0].childs.map(
                                  (child: any) => {
                                    return child.option;
                                  }
                                )}
                                handleChange={(e) =>
                                  handleChildChangeCorporate(
                                    e,
                                    cart_data.max_occupancy,
                                    cart_data.rooms[0].room,
                                    cart_data.rooms[0],
                                    cart_data
                                  )
                                }
                              />
                            </div>
                          </div>
                          {!nonTaxableBooking &&
                            taxDetails?.is_taxable == 1 && (
                              <div className="toggle__wrapper">
                                <div className="tax_label">Including Tax</div>
                                <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={() =>
                                      handleSwitchTaxGroup(
                                        channelType === "direct"
                                          ? displayRoomPriceGroup[index_group]
                                          : displayRoomPriceGroupPartner[
                                              index_group
                                            ],
                                        cart_data,
                                        index_group
                                      )
                                    }
                                    checked={switchTax[index_group]}
                                  />
                                </div>
                              </div>
                            )}

                          <div className="price_break_up_label">
                            {" "}
                            Amount for {nights * cart_data.rooms.length} Room
                            Nights
                          </div>
                          {nonTaxableBooking ? (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label w-25">
                                Total{" "}
                                {displayRoomPriceGroup[index_group] *
                                  nights *
                                  cart_data.rooms.length}
                              </div>
                            </div>
                          ) : (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label">
                                Before Tax{" "}
                                {cart_data.display_pay_amount.toFixed(2)}
                              </div>
                              {taxDetails?.is_taxable == 1 && (
                                <div className="plan_label">
                                  Tax {cart_data.tax[0].gst_price.toFixed(2)}
                                </div>
                              )}
                              <div className="plan_label">
                                Total{" "}
                                {cart_data.display_pay_amount_after_tax.toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}

                {channelType === "partner" && (
                  <>
                    {cartPartner.map((cart_data: any, index_group: any) => {
                      return (
                        <div className="cart_card_wrapper" key={index_group}>
                          <div className="roomtype_wrapper">
                            <div className="roomtype__label">
                              {cart_data.room_type}
                            </div>

                            <div className="plan_label">
                              <i className="bi bi-cup-straw"></i>{" "}
                              {cart_data.plan_type}
                            </div>

                            <div className="plan_label">
                              {" "}
                              <i className="bi bi-house"></i>{" "}
                              {cart_data.rooms.length}
                            </div>
                          </div>
                          <div className="roomrate_wrapper">
                            <div className="roomrate__label">
                              <InputTextField
                                label="Room Rate/Night"
                                value={
                                  displayRoomPriceGroupPartner[index_group]
                                }
                                type="number"
                                handleChange={(e) =>
                                  changedPriceGroup(
                                    parseFloat(e.target.value),
                                    cart_data,
                                    index_group,
                                    switchTax[index_group]
                                  )
                                }
                              />

                              {/* <input type="text"
                          value={(cart_data.display_base_price_after_discount == 0) ? cart_data.display_base_price : cart_data.display_base_price_after_discount}
                          onBlur={(e) => changedPriceGroup(e.target.value, cart_data)}
                        /> */}
                            </div>
                            <div className="adult_dropdown">
                              <InputSelectField
                                label="Adult"
                                value={cart_data.rooms[0].selected_adult}
                                values={cart_data.rooms[0].adults.map(
                                  (adult: any) => {
                                    return adult.value;
                                  }
                                )}
                                options={cart_data.rooms[0].adults.map(
                                  (adult: any) => {
                                    return adult.option;
                                  }
                                )}
                                handleChange={(e) =>
                                  handleAdultChangeCorporate(
                                    e,
                                    cart_data.max_occupancy,
                                    cart_data.rooms[0].room,
                                    cart_data.rooms[0],
                                    cart_data
                                  )
                                }
                              />
                            </div>
                            <div className="adult_dropdown">
                              <InputSelectField
                                label="Child"
                                value={cart_data.rooms[0].selected_child}
                                values={cart_data.rooms[0].childs.map(
                                  (child: any) => {
                                    return child.value;
                                  }
                                )}
                                options={cart_data.rooms[0].childs.map(
                                  (child: any) => {
                                    return child.option;
                                  }
                                )}
                                handleChange={(e) =>
                                  handleChildChangeCorporate(
                                    e,
                                    cart_data.max_occupancy,
                                    cart_data.rooms[0].room,
                                    cart_data.rooms[0],
                                    cart_data
                                  )
                                }
                              />
                            </div>
                          </div>
                          {!nonTaxableBooking &&
                            taxDetails?.is_taxable == 1 && (
                              <div className="toggle__wrapper">
                                <div className="tax_label">Including Tax</div>
                                <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    onChange={() =>
                                      handleSwitchTaxGroup(
                                        channelType === "direct"
                                          ? displayRoomPriceGroup[index_group]
                                          : displayRoomPriceGroupPartner[
                                              index_group
                                            ],
                                        cart_data,
                                        index_group
                                      )
                                    }
                                    checked={switchTax[index_group]}
                                  />
                                </div>
                              </div>
                            )}

                          <div className="price_break_up_label">
                            {" "}
                            Amount for {nights * cart_data.rooms.length} Room
                            Nights
                          </div>
                          {nonTaxableBooking ? (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label w-25">
                                Total{" "}
                                {displayRoomPriceGroupPartner[index_group] *
                                  nights *
                                  cart_data.rooms.length}
                              </div>
                            </div>
                          ) : (
                            <div className="price_breakup_wrapper">
                              <div className="plan_label">
                                Before Tax{" "}
                                {cart_data.display_pay_amount.toFixed(2)}
                              </div>
                              {taxDetails?.is_taxable == 1 && (
                                <div className="plan_label">
                                  Tax {cart_data.tax[0].gst_price.toFixed(2)}
                                </div>
                              )}
                              <div className="plan_label">
                                Total{" "}
                                {cart_data.display_pay_amount_after_tax.toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}

                {/* guest details */}
                <div className="guest__details__wrapper">
                  <div className="guest_details_label"> Guest Details</div>
                  <div className="guest__input__container">
                    <div className="guestname__label">
                      <InputTextField
                        label="Guest Name"
                        value={guestNameValue[0]}
                        handleChange={(e) =>
                          saveGuestNameCorporate(e.target.value)
                        }
                      />
                    </div>
                    <div className="guestname__label">
                      <InputTextField
                        label="Mobile Number"
                        value={guestMobileValue}
                        handleChange={(e) =>
                          setGuestMobileValue(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* for group */}

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <InventoryBtn>
                Next <i className="bi bi-arrow-right"></i>
              </InventoryBtn>
            </div>
          </>
        )}

        {/* confirm booking */}
        {tab === 2 && (
          <>
            {/* <div className="bookingtype_label">Price Breakup</div>
            <div className="totalprice__wrapper">
              <div className="price__tax__card">
                <div className="total__price">
                  <div className="bookingtype_label mt-0 mb-2">Total Price</div>
                  <div className="total__tax__wrapper mb-2 me-3">
                    <div className="inr__label">INR</div>
                    <div className="price__label">{displayTotalPrice.toFixed(2)}</div>
                  </div>
                </div>

                <div className="tax__wrapper">
                  <div className="bookingtype_label mt-0 mb-2">Tax</div>
                  <div className="total__tax__wrapper mb-2">
                    <div className="inr__label">INR</div>
                    <div className="price__label">{displayTotalTaxPrice.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="bookingtype_label">Total Price (Tax Inclusive)</div>
            <div className="totalprice__wrapper">
              <div className="inr__label">{displayCurrency}</div>
              <div className="price__label">{displayTotalAmount}</div>
            </div>

            <div className="bookingtype_label_wrapper ">
              <div className="bookingtype_dropdowns_wrapper me-4">
                <div className="bookingtype_label mb-3">Booking Type</div>
                <div className="type_dropdown">
                  <InputSelectField
                    label="Type"
                    value={selectedBookingType}
                    values={bookingTypeOptions.map((data: any) => {
                      return data.booking_type_id;
                    })}
                    options={bookingTypeOptions.map((data: any) => {
                      return data.name;
                    })}
                    handleChange={(e) => setSelectedBookingType(e.target.value)}
                  />
                </div>
              </div>

              <div className="bookingtype_dropdowns_wrapper me-4">
                <div className="business_label mb-3">Business Source</div>
                <div className="source_dropdown">
                  <InputSelectField
                    label="Source"
                    value={selectedBusinessSource}
                    values={businessSource.map((data: any) => {
                      return data.name;
                    })}
                    options={businessSource.map((data: any) => {
                      return data.name;
                    })}
                    handleChange={(e) =>
                      setSelectedBusinessSource(e.target.value)
                    }
                    disable={selectedPartnerType.length > 0}
                  />
                </div>
              </div>

              <div className="bookingtype_dropdowns_wrapper">
                <div className="business_label mb-3">Sales Executive</div>
                <div className="source_dropdown">
                  <InputSelectField
                    label="Sales Executive"
                    value={selectedSalesExecutive}
                    values={
                      role_id == 13
                        ? [
                            ...activeExecutiveList.map((data: any) => {
                              return data.id;
                            }),
                          ]
                        : [
                            "None",
                            ...activeExecutiveList.map((data: any) => {
                              return data.id;
                            }),
                          ]
                    }
                    options={
                      role_id == 13
                        ? [
                            ...activeExecutiveList.map((data: any) => {
                              return data.name;
                            }),
                          ]
                        : [
                            "None",
                            ...activeExecutiveList.map((data: any) => {
                              return data.name;
                            }),
                          ]
                    }
                    handleChange={(e) =>
                      setSelectedSalesExecutive(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="bookingtype_label">Booker Information</div>
            <div className="search__wrapper">
              <div className="mobile_number">
                <InputTextField
                  label="Search by Mobile Number"
                  value={searchMobileNumber}
                  handleChange={(e) => setSearchMobileNumber(e.target.value)}
                />
              </div>
              <div className="search_btn">
                <InventoryBtn
                  handleClick={() => fetchUserInfoCrs(searchMobileNumber)}
                >
                  <i className="bi bi-search"></i>
                </InventoryBtn>
              </div>
            </div>

            <div className="name_container">
              <InputTextField
                label="Name"
                value={bookerName}
                handleChange={(e) => setBookerName(e.target.value)}
              />
            </div>
            <div className="number_address_wrapper">
              <div className="number_container">
                <InputTextField
                  label="Mobile Number"
                  value={bookerMobile}
                  handleChange={(e) => {
                    validatePositiveInt(e.target.value) &&
                      setBookerMobile(e.target.value);
                  }}
                />
              </div>
              <div className="address_container ms-3">
                <InputTextField
                  label="Email Address"
                  value={bookerEmail}
                  handleChange={(e) => setBookerEmail(e.target.value)}
                />
              </div>
            </div>

            <div
              className="bussiness__label__wrapper "
              style={{ justifyContent: "space-between" }}
            >
              <div className="bussiness_label">Business Booking</div>

              <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  onChange={() =>
                    handleEnableBusinessbooking(enableBusinessBooking)
                  }
                  checked={enableBusinessBooking}
                />
              </div>
            </div>

            {enableBusinessBooking && (
              <>
                <div className="search__wrapper">
                  <div className="mobile_number">
                    <InputTextField
                      label="Search by GST Number"
                      value={searchGSTNumber}
                      handleChange={(e) => setSearchGSTNumber(e.target.value)}
                    />
                  </div>
                  <div className="search_btn">
                    <InventoryBtn
                      handleClick={() => fetchCompanyDetails(searchGSTNumber)}
                    >
                      <i className="bi bi-search"></i>
                    </InventoryBtn>
                  </div>
                </div>

                <div className="number_address_wrapper">
                  <div className="number_container">
                    <InputTextField
                      label="Company Name"
                      value={companyName}
                      handleChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="address_container ms-3">
                    <InputTextField
                      label="Company Address"
                      value={companyAddress}
                      handleChange={(e) => setCompanyAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="name_container">
                  <InputTextField
                    label="GSTIN"
                    value={gstIn}
                    handleChange={(e) => setGstIn(e.target.value)}
                  />
                </div>
              </>
            )}
            {/* ========================Advance Payment Option=================================== */}
            <div
              className="bussiness__label__wrapper mt-3"
              style={{ justifyContent: "space-between" }}
            >
              <div className="bussiness_label ">Collect Advance</div>

              <div className="form-check form-switch custom-switch check--box--width toggle-btn ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  onChange={() => setCollectAdvance(!collectAdivance)}
                  checked={collectAdivance}
                />
              </div>
            </div>
            {collectAdivance && (
              <div className="mt-3">
                <div className="calendar__filter__wrapper mb-0">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    // className="search__right__wrapper"
                  >
                    <div
                      style={{ width: "48%" }}
                      className="change__calendar__filter"
                    >
                      <div className="booking__crs__material__input">
                        <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label=""
                              open={openDatePicker}
                              onOpen={() => setOpenDatePicker(true)}
                              onClose={() => setOpenDatePicker(false)}
                              value={moment(firstDate)}
                              onChange={onDateChange}
                              inputFormat="dd MMM yyyy"
                              disableMaskedInput
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  onClick={() => setOpenDatePicker(true)}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            />
                            {/* <div className="apple">apple</div> */}
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "-6px", width: "50%" }}
                      className="number_address_wrapper"
                    >
                      <div
                        style={{ width: "100%" }}
                        className="number_container"
                      >
                        <InputTextField
                          label="Amount"
                          value={advanceAmount}
                          handleChange={(e) => {
                            validatePositiveInt(e.target.value) &&
                              setAdvanceAmount(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="standard-page--label mb-3"
                  style={{ fontWeight: "bolder" }}
                >
                  Refference Number (Optional)
                </div>
                <div style={{ width: "100%" }} className="number_container">
                  <InputTextField
                    label="Refference Number"
                    value={refference_number}
                    handleChange={(e) => {
                      setReffrenceNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="payment__mode__wrapper">
                  <div
                    className="standard-page--label mb-3"
                    style={{ fontWeight: "bolder" }}
                  >
                    Payment Mode
                  </div>
                  <div className="div__select__inputs__wrapper">
                    <div className="select__input__label">
                      <div
                        className={`${
                          paymentMode.toLowerCase() === "online"
                            ? "select__input__div__active"
                            : "select__input__div"
                        } me-3 mb-3 min-width-126px`}
                        onClick={() => {
                          capturePaymentMode("Online");
                        }}
                      >
                        {paymentMode.toLowerCase() === "online" ? (
                          <i className="bi bi-check-lg"></i>
                        ) : null}
                        Online
                      </div>

                      <div className="select__input__label">
                        <div
                          className={`${
                            paymentMode.toLowerCase() === "cash"
                              ? "select__input__div__active"
                              : "select__input__div"
                          } me-3 mb-3 min-width-126px`}
                          onClick={() => {
                            capturePaymentMode("Cash");
                          }}
                        >
                          {paymentMode.toLowerCase() === "cash" ? (
                            <i className="bi bi-check-lg"></i>
                          ) : null}
                          Cash
                        </div>
                      </div>

                      <div className="select__input__label">
                        <div
                          className={`${
                            paymentMode.toLowerCase() === "card"
                              ? "select__input__div__active"
                              : "select__input__div"
                          } me-3 mb-3 min-width-126px`}
                          onClick={() => {
                            capturePaymentMode("Card");
                          }}
                        >
                          {paymentMode.toLowerCase() === "card" ? (
                            <i className="bi bi-check-lg"></i>
                          ) : null}
                          Card
                        </div>
                      </div>

                      <div className="select__input__label">
                        <div
                          className={`${
                            paymentMode.toLowerCase() === "cheque"
                              ? "select__input__div__active"
                              : "select__input__div"
                          } me-3 mb-3 min-width-126px`}
                          onClick={() => {
                            capturePaymentMode("Cheque");
                          }}
                        >
                          {paymentMode.toLowerCase() === "cheque" ? (
                            <i className="bi bi-check-lg"></i>
                          ) : null}
                          Cheque
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="bussiness_label"
                  style={{ fontWeight: "bolder" }}
                >
                  Collected By
                </div>
                <div className="checkbox_container">
                  <div className="form-check mt-3">
                    <input
                      // id="email-with-link"
                      className="form-check-input"
                      type="radio"
                      name="advancepayment"
                      value="agent"
                      onChange={(e) => setCollectedBy(e.target.value)}
                    ></input>
                    <label
                      className="form-check-label"
                      // htmlFor="email-with-link"
                      style={{ cursor: "pointer" }}
                    >
                      Agent
                    </label>
                  </div>
                  <div className="form-check mt-3">
                    <input
                      // id="email-with-nolink"
                      className="form-check-input"
                      type="radio"
                      name="advancepayment"
                      value="hotel"
                      onChange={(e) => setCollectedBy(e.target.value)}
                    ></input>
                    <label
                      className="form-check-label"
                      // htmlFor="email-with-nolink"
                      style={{ cursor: "pointer" }}
                    >
                      Hotel
                    </label>
                  </div>
                </div>
              </div>
            )}
            {/* ===================================================================== */}

            <div className="bookingtype_label">Payment Option</div>

            <div className="checkbox_container">
              <div className="form-check mt-4">
                <input
                  id="email-with-nolink"
                  className="form-check-input"
                  type="radio"
                  name="payment"
                  value="2"
                  onChange={(e) => onItemChange(e.target.value)}
                ></input>
                <label
                  className="form-check-label"
                  htmlFor="email-with-nolink"
                  style={{ cursor: "pointer" }}
                >
                  Email (no payment link)
                </label>
              </div>
              <div className="form-check mt-4">
                <input
                  id="noemail-with-nolink"
                  className="form-check-input"
                  type="radio"
                  name="payment"
                  value="3"
                  onChange={(e) => onItemChange(e.target.value)}
                ></input>
                <label
                  className="form-check-label"
                  htmlFor="noemail-with-nolink"
                  style={{ cursor: "pointer" }}
                >
                  No Email No Payment Link
                </label>
              </div>
            </div>
            {displayTotalAmount >= 1 && (
              <div className="checkbox_container">
                <div className="form-check mt-4">
                  <input
                    id="email-with-link"
                    className="form-check-input"
                    type="radio"
                    name="payment"
                    value="1"
                    onChange={(e) => onItemChange(e.target.value)}
                  ></input>
                  <label
                    className="form-check-label"
                    htmlFor="email-with-link"
                    style={{ cursor: "pointer" }}
                  >
                    Email with Payment Link
                  </label>
                </div>
              </div>
            )}
            {displayPartialPayFields && (
              <>
                <div className="payment_link_label mb-4">
                  Payment Link Valid Upto:
                </div>
                <div className="bookingtype_dropdowns_wrapper d-flex mb-4">
                  <div className="timetype_dropdown">
                    <InputSelectField
                      label="Valid Type"
                      value={paymentLinkValidType}
                      values={validTypeValues.map((data: any) => {
                        return data.id;
                      })}
                      options={validTypeValues.map((data: any) => {
                        return data.name;
                      })}
                      handleChange={(e) => paymentLinkOption(e.target.value)}
                    />
                  </div>

                  {paymentLinkValidType !== 3 ? (
                    <>
                      <div
                        className="time_dropdown"
                        hidden={paymentLinkStatus !== 1}
                      >
                        <InputSelectField
                          label="Hour"
                          value={validHour}
                          values={partialPaymentHour.map((data: any) => {
                            return data.id;
                          })}
                          options={partialPaymentHour.map((data: any) => {
                            return data.name;
                          })}
                          handleChange={(e) => {
                            onTypeChange(e.target.value);
                          }}
                        />
                      </div>
                      <div
                        className="time_dropdown"
                        hidden={paymentLinkStatus !== 2}
                      >
                        <InputSelectField
                          label="Days"
                          value={validDays}
                          values={partialPaymentDays.map((data: any) => {
                            return data.id;
                          })}
                          options={partialPaymentDays.map((data: any) => {
                            return data.name;
                          })}
                          handleChange={(e) => onTypeChange(e.target.value)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="partial_payment_label mb-4">
                  Partial Payment
                </div>
                <div className="bookingtype_dropdowns_wrapper d-flex">
                  <div className="payment_type_dropdown">
                    <InputSelectField
                      label="Payment Option"
                      value={partialPayOption}
                      values={partialPaymentOption.map((data: any) => {
                        return data.id;
                      })}
                      options={partialPaymentOption.map((data: any) => {
                        return data.name;
                      })}
                      handleChange={(e) => paymentOption(e.target.value)}
                    />
                  </div>

                  <div
                    className="payment_quantity_dropdown"
                    hidden={partialPayOption !== 1}
                  >
                    <InputSelectField
                      label="Payment Option Percentage"
                      value={paymentPercent}
                      values={partialPaymentPercentageOption.map(
                        (data: any) => {
                          return data.id;
                        }
                      )}
                      options={partialPaymentPercentageOption.map(
                        (data: any) => {
                          return data.name;
                        }
                      )}
                      handleChange={(e) => partialPayment(e.target.value)}
                    />
                  </div>

                  <div
                    className="payment_quantity_dropdown"
                    hidden={partialPayOption !== 2}
                  >
                    <InputTextField
                      type="number"
                      label="Pay Amount"
                      value={manuallyEnteredPartialPayPrice}
                      inputProps={{
                        onKeyPress: handleNegativeNumber,
                      }}
                      handleChange={(e) =>
                        handleManuallyEnteredPartialPayPrice(e.target.value)
                      }
                      handleBlur={validateManuallyEnteredPartialPayPrice}
                    />
                  </div>

                  {partialPayOption === 1 && (
                    <div className="payment_quantity_dropdown">
                      <InputTextField
                        label="Amount to be paid"
                        value={amountToPay}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="remarks_label">Remarks</div>
            <div className="name_container">
              <InputTextField
                label="Internal Remark"
                value={internalRemark}
                handleChange={(e) => setInternalRemark(e.target.value)}
                multiline
              />
            </div>
            <div className="name_container">
              <InputTextField
                label="Guest Remark"
                value={guestRemark}
                handleChange={(e) => setGuestRemark(e.target.value)}
                multiline
              />
            </div>
            <div>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {"Select Cancellation Policy"}
                  </InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={obj?.selectedPolicy || ''}
                    label="Select Cancellation Policy"
                    onChange={(e: any) => {
                      setPayloadPolicyId(e.target.value.id);
                      setpolicyName(e.target.value.policy_name);
                    }}
                  >
                    {policyList?.map((el: any) => (
                      <MenuItem key={el.id} value={el}>
                        {el.policy_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <InventoryBtn>
                Next <i className="bi bi-arrow-right"></i>
              </InventoryBtn>
            </div>
          </>
        )}
        {tab === 0 && (
          <>
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              {cart.length ? (
                <InventoryBtn handleClick={onBookingDetails}>
                  Next <i className="bi bi-arrow-right"></i>
                </InventoryBtn>
              ) : (
                <InventoryBtn className="inactiveBtn">
                  Next <i className="bi bi-arrow-right"></i>
                </InventoryBtn>
              )}
            </div>
          </>
        )}
        {tab === 1 && (
          <>
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <InventoryBtn handleClick={onConfirm}>
                Next <i className="bi bi-arrow-right"></i>
              </InventoryBtn>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div
              className={
                loaderBookNow
                  ? "side__slider__panel__btn new__bookings__button"
                  : "side__slider__panel__btn"
              }
            >
              {loaderBookNow ? (
                <InventoryBtn loader={loaderBookNow}>Processing</InventoryBtn>
              ) : (
                <InventoryBtn handleClick={bookNow}>Book Now</InventoryBtn>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default NewBookings;
