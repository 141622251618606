import React, { useState, useEffect } from 'react';
import CustomBreadcrumbs from '../../components/breadcrumbs/CustomBreadcrumbs';
import Button from '../../Views/buttons/Button';
import SlidingPane from "react-sliding-pane";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import AddSalesExecutive from "./AddSalesExecutive";
import EditSalesExecutive from "./EditSalesExecutive";
import Loader from '../../Views/loader/Loader';
import endPoints from '../../API/endPoints';
import beApi from '../../API/beApi';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Redux/store';
import { CancelConfirmationPrompt, ShowConfirmationPrompt } from "../../Redux/actions/PromptAction";
import { toast } from 'react-toastify';

const SalesExecutive = () => {
    const dispatch = useDispatch();

    const { getSalesExecutive, updateSalesExecutiveStatus } = endPoints.SALESEXECUTIVE;
    const { hotel_id, auth_token,admin_id } = useSelector((state: RootState) => state.auth);

    const [loader, setLoader] = useState(false);
    const [defaultMsg, setDefaultMsg] = useState('No data found');

    //pagination
    const pageSize = [10, 15, 20, 25, 30];
    const [selectPageSize, setSelectPageSize] = useState(10);
    const [noOfPages, setNoOfPages] = useState(1);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [customPageRange, setCustomPageRange] = useState<any[]>([]);

    const [editSliderOpen, setEditSliderOpen] = useState(false);
    const [addSliderOpen, setAddSliderOpen] = useState(false);
    const [salesExecutiveList, setSalesExecutiveList] = useState([]);
    const [salesExecutiveSearchInput, setSalesExecutiveSearchInput] = useState("");
    const [editSliderData, setEditSliderData] = useState<any>();
    const [refreshDataAddNewSalesExecutive, setRefreshDataAddNewSalesExecutive] = useState(true);
    const [refreshDataEditSalesExecutive, setRefreshDataEditSalesExecutive] = useState(true);
    const [filteredSalesExecutiveList, setFilteredSalesExecutiveList] = useState<any>([])

    // Handler function to construct custom Page Range-- Pagination
    useEffect(() => {
        // setIsLoading(true);
        if (noOfPages < 7) {
            let arr: number[] = [];
            for (let i = 0; i < noOfPages; i++) {
                arr.push(i + 1);
            }
            setCustomPageRange(arr);
        } else {
            if (currentPageNo < 4) {
                let arr: any[] = [1, 2, 3, 4, 5, "z", noOfPages];
                setCustomPageRange(arr);
            } else if (currentPageNo > noOfPages - 3) {
                let arr: any[] = [
                    1,
                    "y",
                    noOfPages - 4,
                    noOfPages - 3,
                    noOfPages - 2,
                    noOfPages - 1,
                    noOfPages,
                ];
                setCustomPageRange(arr);
            } else {
                let arr: any[] = [
                    1,
                    "y",
                    currentPageNo - 1,
                    currentPageNo,
                    currentPageNo + 1,
                    "z",
                    noOfPages,
                ];
                setCustomPageRange(arr);
            }
        }
        // setIsLoading(false);
    }, [currentPageNo, noOfPages]);

    // Current page from custom page range
    const currentPageNoFromCustom = (item: any) => {
        if (item === "y") {
            setCurrentPageNo(currentPageNo - 3);
        } else if (item === "z") {
            setCurrentPageNo(currentPageNo + 3);
        } else {
            setCurrentPageNo(item);
        }
    };

    // Pagination
    const rightArrowClick = () => {
        if (currentPageNo < noOfPages) {
            setCurrentPageNo(currentPageNo + 1);
        }
    };

    const leftArrowClick = () => {
        if (currentPageNo > 1) {
            setCurrentPageNo(currentPageNo - 1);
        }
    };

    useEffect(() => {
        if (filteredSalesExecutiveList?.length > 0) {
            setNoOfPages(Math.ceil(filteredSalesExecutiveList?.length / selectPageSize));
        }
    }, [selectPageSize, filteredSalesExecutiveList]);

    const noDataFound = {
        with: '100%',
        minHeight: '180px',
        backgroundColor: '#ffffff',
        borderRadius: '8px'
    }

    const emptyDivStyles = {
        height: "500px",
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: "lightgray"
    }

    useEffect(() => {
        if (!hotel_id) {
            return
        }
        getSalesExectiveList();
    }, [hotel_id, refreshDataAddNewSalesExecutive, refreshDataEditSalesExecutive])

    useEffect(() => {
        if (!salesExecutiveSearchInput.length) {
            getSalesExectiveList();
        }
    }, [salesExecutiveSearchInput])

    useEffect(() => {
        const newFilteredSalesExecutive = salesExecutiveList.filter((executives: any) => {
            return executives?.name.toLowerCase().includes(salesExecutiveSearchInput.toLowerCase()) || executives?.email.toLowerCase().includes(salesExecutiveSearchInput.toLowerCase()) || executives?.phone.toLowerCase().includes(salesExecutiveSearchInput.toLowerCase()) || executives?.whatsapp_number.toLowerCase().includes(salesExecutiveSearchInput.toLowerCase())
        })

        setFilteredSalesExecutiveList(newFilteredSalesExecutive)
    }, [salesExecutiveSearchInput])


    const getSalesExectiveList = async () => {
        setLoader(true);
        try {
            const response = await beApi.get(`${getSalesExecutive}/${hotel_id}/${admin_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth_token}`,
                    }
                })
            if (response.data.status === 1) {
                setSalesExecutiveList(response.data.list);
                setLoader(false);
                setNoOfPages(
                    Math.ceil(response?.data?.list?.length / selectPageSize)
                );
                setFilteredSalesExecutiveList(response?.data?.list)
            } else {
                setLoader(false)
                setDefaultMsg(response.data.message)
            }
            setCurrentPageNo(1);

        } catch (error) {
            console.log(error);
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }

    const openTheConfirmationPopup = (Id: any, activeStatus: number) => {
        dispatch(
            ShowConfirmationPrompt(
                `Are you sure you want to ${activeStatus ? "deactivate" : "activate"} the Sales Executive?`,
                `${activeStatus ? "DELETE" : "UPDATE"}`,
                () => changeSalesExecutiveStatus(Id, activeStatus),
                ["No", "Yes"]
            ))
    }

    const changeSalesExecutiveStatus = async (Id: any, activeStatus: any) => {
        dispatch(CancelConfirmationPrompt());
        activeStatus = activeStatus ? 0 : 1;
        try {
            const payload = {
                sales_executive_id: Id,
                hotel_id: hotel_id,
                status: activeStatus
            }
            const response = await beApi.post(`${updateSalesExecutiveStatus}`, payload, {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                },
            })

            if (response.data.status === 1) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
            getSalesExectiveList();
        } catch (error) {
            console.log(error);
        }
    }

    const editSalesExecutiveData = (e: any, listItems: any) => {
        e.preventDefault();
        setEditSliderOpen(true);
        setEditSliderData(listItems);
    }

    return (
        <>
            <div className="property__breadcrumbs">
                <CustomBreadcrumbs
                    content={[
                        { label: "Sales Executive", },
                    ]}
                />

                <div className="manage__bookings__action__right manage__actions__wrapper">
                    <div className="bookingTypes">
                        <div className="searchInput" style={{
                            background: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '0.5rem',
                            border: '1px solid lightgray',
                            padding: '0.54rem 0.5rem'
                        }} >
                            <i className="bi bi-search" style={{
                                marginRight: "0.7rem",
                                color: "#223189",
                                cursor: 'pointer'
                            }}></i>
                            <input type="text" name="input" id="input" onChange={(e) => {
                                setSalesExecutiveSearchInput(e.target.value)
                            }} placeholder='Search Sales Executive' value={salesExecutiveSearchInput} />
                        </div>
                    </div>
                </div>

                <div className="new__booking__btn__wrapper">
                    <Button
                        className="new__bookings"
                        icon="bi bi-plus-lg"
                        handleClick={() => { setAddSliderOpen(true) }}
                    >
                        Add
                    </Button>
                </div>
            </div>

            <div className="hr--line"></div>

            {loader ? <div style={emptyDivStyles}><Loader /></div> :
                filteredSalesExecutiveList.length ? <>
                    <div className="list-view-table-wrapper mb-4" >
                        <table className="table table-borderless">
                            <thead>
                                <tr className="listview__table__heading">
                                    <th>Name</th>
                                    <th>Phone No</th>
                                    <th>Email</th>
                                    <th>Whatsapp</th>
                                    <th>Edit</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSalesExecutiveList.slice(
                                    selectPageSize * (currentPageNo - 1),
                                    selectPageSize * currentPageNo
                                ).map((listItems: any, id: number) => {
                                    return (
                                        <tr key={id} className="listview__table__data">
                                            <td>
                                                <div className="customer-details">{listItems?.name}</div>
                                            </td>

                                            <td>
                                                <div className="customer-details">{listItems?.phone}</div>
                                            </td>

                                            <td>
                                                <div className="customer-details">{listItems?.email}</div>
                                            </td>

                                            <td>
                                                <div className="customer-details">{listItems?.whatsapp_number}</div>
                                            </td>

                                            <td>
                                                <div className="customer-details">
                                                    {/* <div className="edit__sales__executive" onClick={(e) => editSalesExecutiveData(e, listItems)}>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div> */}
                                                     <button onClick={(e) => editSalesExecutiveData(e, listItems)}  style={{  color: "white",backgroundColor:"#4AD933", marginTop: "0.5rem" ,width:"fit-content",padding:"0.4rem",paddingTop:"0.2rem",paddingBottom:"0.2rem",borderRadius:"10px"}}>
                                                                    {/* <i className="bi bi-pencil-square"></i> */}
                                                                    <i className="bi bi-pencil-square"></i>
                                                                </button>
                                                </div>
                                            </td>

                                            <td>
                                                <div className="customer-details">
                                                    <div className="toogle__switch">
                                                        <div className="form-check form-switch custom-switch">
                                                            <input type="checkbox" className="form-check-input"
                                                                checked={listItems?.is_active}
                                                                onChange={() => openTheConfirmationPopup(listItems?.id, listItems?.is_active)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <div className="list--view--pagination">
                            <div className="pagination__left">
                                <div className="pagination__dropdown">
                                    <InputSelectField
                                        label="Select"
                                        value={selectPageSize}
                                        options={pageSize}
                                        handleChange={(e) => {
                                            setSelectPageSize(e.target.value);
                                            setNoOfPages(
                                                Math.ceil(filteredSalesExecutiveList.length / e.target.value)
                                            );
                                            setCurrentPageNo(1);
                                        }}
                                    />
                                </div>
                                <ul>
                                    {Array(noOfPages)
                                        .fill(0)
                                        .map((p, i) => {
                                            return (
                                                <li
                                                    key={i}
                                                    onClick={() => setCurrentPageNo(i + 1)}
                                                    className={`${i + 1 === currentPageNo
                                                        ? "active__page"
                                                        : "showing__page__number"
                                                        }`}
                                                >
                                                    {i + 1}
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                            <div className="pagination__right">
                                <div className="arrow__container me-3" onClick={leftArrowClick}>
                                    <i className="bi bi-chevron-left font--weight"></i>
                                </div>
                                <div className="arrow__container" onClick={rightArrowClick}>
                                    <i className="bi bi-chevron-right font--weight"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                    <div style={noDataFound}>
                        <div style={emptyDivStyles}>{defaultMsg}</div>
                    </div>
            }

            <SlidingPane
                hideHeader
                from="right"
                width="30%"
                isOpen={addSliderOpen}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setAddSliderOpen(false)}>
                <AddSalesExecutive
                    onClose={() => { setAddSliderOpen(false) }}
                    hotelId={hotel_id}
                    refreshData={() => setRefreshDataAddNewSalesExecutive(!refreshDataAddNewSalesExecutive)}
                />
            </SlidingPane>

            <SlidingPane
                hideHeader
                from="right"
                width="30%"
                isOpen={editSliderOpen}
                className="sliding-pane-custom"
                closeIcon={<i className="bi bi-x-lg"></i>}
                onRequestClose={() => setEditSliderOpen(false)}>
                <EditSalesExecutive
                    onClose={() => { setEditSliderOpen(false) }}
                    editSliderData={editSliderData}
                    refreshData={() => setRefreshDataEditSalesExecutive(!refreshDataEditSalesExecutive)}
                />
            </SlidingPane>
        </>
    )
}

export default SalesExecutive