import React, { useEffect, useState } from "react";
import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../Views/buttons/Button";

import { useNavigate } from "react-router";
import { RootState } from "../../Redux/store";

import moment from "moment";
import beApi from "../../API/beApi";

import { useDispatch, useSelector } from "react-redux";
import {
  SaveBooking,
  SaveBookingCRS,
  SaveBookingDetailedInfo,
} from "../../Redux/actions/BookingsAction";

import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../Views/inputtextfield/InputMultiSelectField";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import gemsApi from "../../API/gemsApi";
import { toast } from "react-toastify";

import ModifyBooking from "./ModifyBooking";
import CancelBooking from "./CancelBooking";
import CalendarSkeleton from "../../Views/loader/CalendarSkeleton";
import NewBookings from "./NewBookings";
import endPoints from "../../API/endPoints";
import FetchBooking from "./FetchBookings";
import DataLoader from "../../Views/loader/DataLoader";
import Loader from "../../Views/loader/Loader";
import { Tooltip } from "@mui/material";
import ModifyCrsBooking from "./ModifyCrsBooking";
import DayBooking from "./DayBooking/DayBooking";
import { RoomBookingsList } from "./RoomBookingsList";
import { DayBookingModify } from "./DayBookingModify";

const ListView = () => {
  const navigate = useNavigate();

  UpdateSidebar("Bookings", "List View");
  const [bookingTab, setBookingTab] = useState("roombooking");
  const [openSlideNewBooking, setOpenSlideNewBooking] = useState(false);
  const [openSliderDayBooking, setOpenSlideDayBooking] = useState(false);
  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);
  const [selectCheckinDate, setSelectCheckinDate] = useState("");
  const [newSubTab, setNewSubTab] = useState<boolean>(false);
  const [refreshDataNewBooking, setRefreshDataNewBooking] = useState(true);

  const Datarefresh = () => {
}

  return (
    <>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__bookings__action__right">
          {/* <div className="status__icon__wrapper booking__color">
            <i className="bi bi-circle-fill me-1"></i> Bookings
          </div>
          <div className="status__icon__wrapper checkedin__color">
            <i className="bi bi-circle-fill me-1"></i> Checked-in
          </div> */}

          {/* <div className="fetch__booking__btn__wrapper">
            <Button
              className="fetch_bookings"
              icon="bi bi-download"
              handleClick={() => setOpenSlideFetchBooking(true)}
              isAccessible={access}
            >
              Fetch
            </Button>
          </div> */}
          <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Tooltip title={" Room Booking"} placement="top" arrow>
                    <a
                    onClick={()=>setBookingTab("roombooking")}
                      className="nav-link active"
                      id="list-tab"
                      data-bs-toggle="tab"
                      data-bs-target="list"
                      aria-controls="list"
                      role="tab"
                      aria-selected="true"
                    >
                      Room Booking
                    </a>
                  </Tooltip>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <Tooltip title={"Package Booking"} placement="top" arrow>
                    <a
                      // onClick={() => navigate("/bookings/crs-view")}
                      className="nav-link"
                      id="crs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="crs"
                      aria-controls="crs"
                      role="tab"
                      aria-selected="true"
                    >
                      Package Booking
                    </a>
                  </Tooltip>
                </li> */}
                <li className="nav-item" role="presentation">
                  <Tooltip title={"Day Booking"} placement="top" arrow>
                    <a
                      onClick={() => setBookingTab("daybooking")}
                      className="nav-link"
                      id="crs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="crs"
                      aria-controls="crs"
                      role="tab"
                      aria-selected="true"
                    >
                      Day Booking
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </div>
          <div className="new__booking__btn__wrapper">
            <Button
              className="new__bookings"
              icon="bi bi-plus-lg"
              handleClick={() => {
                setSelectCheckinDate("");
                setNewSubTab(!newSubTab);
              }}
              isAccessible={true}
            >
              New
            </Button>
          </div>

          {newSubTab ? (
            <div className={`new_booking_sub_tab`}>
              {/* <div className={`new_booking_sub_tab animate__animated animate__fadeIn`}> */}
              <div
                className="room_booking_sub_tab"
                onClick={() => setOpenSlideNewBooking(true)}
              >
                <i className="bi bi-box me-2"></i>
                Room Booking
              </div>
              {/* <div className="package_booking_sub_tab" onClick={() => setOpenSlidePackageBooking(true)}>
                  <i className="bi bi-boxes me-2"></i>
                  Package Booking
                </div> */}
              <div
                className="package_booking_sub_tab"
                onClick={() => setOpenSlideDayBooking(true)}
              >
                <i className="bi bi-brightness-high me-2 fs-5"></i>
                Day Booking
              </div>
            </div>
          ) : null}
          
        </div>
      </div>

      <div className="hr--line"></div>
      {
        bookingTab=="roombooking" && 
        
      <RoomBookingsList refreshData={refreshDataNewBooking} />
      }
      {
        bookingTab=="daybooking" && 
        
      <DayBookingModify 
      
      />
      }

      <SlidingPane
        hideHeader
        from="right"
        width="50%"
        isOpen={openSlideNewBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenSlideNewBooking(false);
        }}
      >
        <NewBookings
          onClose={() => setOpenSlideNewBooking(false)}
          refreshData={() => setRefreshDataNewBooking(!refreshDataNewBooking)}
          selectedCheckinDate={selectCheckinDate}
          closeNewBookingBtn={() => setNewSubTab(false)}
        />
      </SlidingPane>

      {/* =================New Day Booking===================== */}
      <SlidingPane
        hideHeader
        from="right"
        width="37%"
        isOpen={openSliderDayBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setOpenSlideDayBooking(false);
        }}
      >
        <DayBooking
          onClose={() => setOpenSlideDayBooking(false)}
          refreshData={() => setRefreshDataNewBooking(!refreshDataNewBooking)}
          selectedCheckinDate={selectCheckinDate}
          closeNewBookingBtn={() => setNewSubTab(false)}
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlideFetchBooking}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideFetchBooking(false)}
      >
        <FetchBooking onClose={() => setOpenSlideFetchBooking(false)} />
      </SlidingPane>
    </>
  );
};

export default ListView;
