import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoginSuccess } from "../Redux/actions/LoginAction";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginTypeAction } from "../Redux/actions/LoginTypeAction";

const Loginwithoutcredential = () => {
  let dispatch = useDispatch();

  const navigate = useNavigate();

  let login_data: any = useParams();

  let send_login_data = {
    admin_id: parseInt(window.atob(login_data.admin_id)),
    auth_token: login_data.auth_token,
    company_id: parseInt(window.atob(login_data.company_id)),
    full_name: window.atob(login_data.full_name),
    hotel_id: window.atob(login_data.hotel_id),
    hotel_name: window.atob(login_data.hotel_name),
  };
  useEffect(() => {
    dispatch(LoginSuccess(send_login_data));
    dispatch(LoginTypeAction("loginwithoutcred"));
    navigate("/dashboard");
  }, [send_login_data]);

  return <></>;
};

export default Loginwithoutcredential;
