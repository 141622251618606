import { LOGIN_TYPE } from "../actionTypes";

const initialState = ''

const LoginTypeReducer = (state = initialState, action: any) => {
    if (action.type === LOGIN_TYPE) {
        return action.payload
    }

    else return state
};

export default LoginTypeReducer;
