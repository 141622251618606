import React, { useEffect, useState } from "react";
import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";
import Button from "../../Views/buttons/Button";
import { useNavigate } from "react-router";
import { RootState } from "../../Redux/store";
import moment from "moment";
import beApi from "../../API/beApi";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveBooking,
  SaveBookingCRS,
  SaveBookingDetailedInfo,
} from "../../Redux/actions/BookingsAction";
import { Button as BUTTON } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../Views/inputtextfield/InputMultiSelectField";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import gemsApi from "../../API/gemsApi";
import { toast } from "react-toastify";

import CancelBooking from "./CancelBooking";
import endPoints from "../../API/endPoints";
import Loader from "../../Views/loader/Loader";
import ModifyCrsBooking from "./ModifyCrsBooking";
import axios from "axios";
import alphaApi from "../../API/alphaApi";
import ModifyDayBooking from "./ModifyDayBooking";

export const DayBookingModify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  UpdateSidebar("Bookings", "List View");

  const { hotel_id } = useSelector((state: RootState) => state.auth);
  const [booking_details, setBooking_details] = useState<any>();

  const [bookingDetails, setBookingDetails] = useState<any[]>([]);
  const [allSource, setAllSource] = useState<any[]>([]);
  const [allSourcesName, setAllSourcesName] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState<any[]>([]);

  const [searchInput, setSearchInput] = useState("");
  const [statusInput, setStatusInput] = useState("confirm");
  const [dateType, setDateType] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [startDate, setStartDate] = useState<any>(moment().subtract(7, "d"));
  const [endDate, setEndDate] = useState<any>(moment());
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [sortingOrder, setSortingOrder] = useState("ASC");
  const [isLoading, setIsLoading] = useState(false);

  const [noDataFound, setNoDataFound] = useState(true);
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  const [openBookingDetailsSlider, setopenBookingDetailsSlider] =
    useState(false);
  const [openModifyBookingSlider, setopenModifyBookingSlider] = useState(false);
  const [openCancelBookingSlider, setopenCancelBookingSlider] = useState(false);

  const [refreshTrigger, setRefreshTrigger] = useState(true);

  const [refreshDataNewBooking, setRefreshDataNewBooking] = useState(true);

  const queryType = {
    "Booking Date": 1,
    "Visit Date": 2,
  };

  const pageSize = [10, 15, 20, 25, 30];
  const { auth_token, admin_id } = useSelector(
    (state: RootState) => state.auth
  );

  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);

  const { listViewBooking, bookingDetailsCRSGet, allSources } =
    endPoints.BOOKINGS;

  useEffect(() => {
    const status = accessData.filter((iteam: any) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
      ? setAccess(true)
      : setAccess(false);
  }, [accessData]);

  useEffect(() => {
    bookingListView();
  }, [
    hotel_id,
    startDate,
    endDate,
    dateType,
    selectedSource,
    statusInput,
    refreshTrigger,
    refreshDataNewBooking,
  ]);

  useEffect(() => {
    setNoOfPages(
      Math.ceil(listViewFilter(bookingDetails).length / selectPageSize)
    );
    if (noOfPages === 0) {
      setNoDataFound(true);
    } else if (noOfPages > 0) {
      setNoDataFound(false);
    }
  }, [selectedSource, searchInput, statusInput, noOfPages]);

  // API call fot getting the booking details
  const bookingListView = async () => {
    setIsLoading(true);
    setNoDataFound(false);
    setBookingDetails([]);

    const payload = {
      from_date: startDate,
      to_date: endDate,
      date_type: dateType,
      booking_status: statusInput,
      admin_id: admin_id,
    };
    // from_date: "2024-04-01", to_date: "2024-05-01",
    try {
      let response = await alphaApi.post(
        `/extranetv4/day-booking/booking-list/${hotel_id}`,
        payload
      );
      if (response.data.status === 1) {
        let res = response.data.data;
        setBookingDetails(res);
        setNoOfPages(Math.ceil(res.length / selectPageSize));
        setIsLoading(false);
        setNoDataFound(false);
      } else if (response.data.status === 0) {
        setNoDataFound(true);
        setIsLoading(false);
        setBookingDetails([]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getDayBookingDetailsCRS = async (booking: any) => {
    try {
      const response = await axios.get(
        `https://be-alpha.bookingjini.com/extranetv4/day-booking/modify-details/${booking.invoice_id}`,
        // `https://be-alpha.bookingjini.com/extranetv4/day-booking/modify-details/DB-253`,
        {
          headers: {
            Authorization: "Bearer " + auth_token,
          },
        }
      );

      if (response.data.status === 1) {
        setBooking_details(response.data.data);
        dispatch(SaveBookingCRS(response.data.data));
        setopenBookingDetailsSlider(true);
      } else {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "error",
        });
      }
    } catch (error) {
      toast(CATCH_MESSAGE, {
        hideProgressBar: true,
        type: "error",
      });
    }
  };

  const searchOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === "" && noOfPages > 0) {
      setNoDataFound(false);
    }
    setSearchInput(val);
  };

  // selected sources values
  const isAllSelected =
    allSourcesName.length > 0 &&
    selectedSource.length === allSourcesName.length;

  const sourceOnchange = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedSource(
        selectedSource.length === allSourcesName.length ? [] : allSourcesName
      );
      return;
    }
    setSelectedSource(value);
  };
  // API call for getting all the source
  const getAllSource = async () => {
    let res = await beApi.get(`${allSources}/${hotel_id}`);
    if (res.data.status === 1) {
      let sources = res.data.data;
      setAllSource(sources);

      let otaname: string[] = [];
      let otaid: number[] = [];
      sources.forEach((source: any) => {
        if (source.ota_name === "CRS") {
          otaname.push(source.ota_name);
        }
        otaid.push(source.ota_id);
      });

      setAllSourcesName(otaname);
      setSelectedSource(otaname);
    }
  };
  // Filter function
  const listViewFilter = (data: any[]) => {
    return data?.filter((book: any) => {
      return (
        book.booking_id.toString().includes(searchInput) ||
        book.guest_name
          .split(",")[0]
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    return data;
  };

  // Sorting
  const sortByClick = (date: string) => {
    if (sortingOrder === "ASC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate > newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("DEC");
    } else if (sortingOrder === "DEC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate < newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("ASC");
    }
  };

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };

  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const onModifyBookingClick = () => {
    setopenBookingDetailsSlider(false);
    setopenModifyBookingSlider(true);
  };

  // Cancell Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [loader, setLoader] = useState<boolean>(false);
  const handleCancell = async () => {
    setLoader(true);
    setOpen(false);
    try {
      const response = await alphaApi.get(
        `/extranetv4/day-booking/cancel-booking/${booking_details?.booking_id}`
      );

      if (response.data.status === 1) {
        setLoader(false);
        setopenBookingDetailsSlider(false);
        bookingListView();
        toast.success(response.data.message);
        setOpen(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(CATCH_MESSAGE);
      setLoader(false);
      setOpen(false);
    }
    setLoader(false);
    setOpen(false);
    setopenBookingDetailsSlider(false);
  };
  useEffect(() => {
    getAllSource();
  }, [hotel_id]);
  return (
    <div>
      <div className="calendar__filter__wrapper">
        <div className="search__booking__wrapper">
          <i className="bi bi-search"></i>
          <input
            placeholder="Search Bookings"
            value={searchInput}
            onChange={(e) => searchOnchange(e)}
          />
        </div>
        <div className="listview__datepicker__wrapper me-3">
          <div className="date__dropdown__wrapper mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={dateType}
              handleChange={(e) => setDateType(e.target.value)}
              options={Object.keys(queryType)}
              values={Object.values(queryType)}
            />
          </div>
        </div>

        <div
          className="listview__select__date__wrapper"
          onClick={() => setFocusedInput("startDate")}
        >
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            minimumNights={0}
            // preventPastDates={true}
          />
        </div>

        <div className="dropdown__right__wrapper">
          <div className="status__dropdown__wrapper me-3 custom--width mui__input__fieldset__legends__unset">
            <InputMultiSelectField
              label=""
              value={selectedSource}
              handleChange={(evnt) => sourceOnchange(evnt)}
              options={allSourcesName}
              isAllSelected={isAllSelected}
            />
          </div>
          <div className="status__dropdown__wrapper mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={statusInput}
              handleChange={(e) => setStatusInput(e.target.value)}
              options={["Confirmed", "Cancelled"]}
              values={["confirm", "cancelled"]}
            />
          </div>

          {/* <div className="change__calendar__filter"></div> */}
        </div>
      </div>
      <div className="list-view-table-wrapper">
        {/* <table className="table table-borderless"> */}
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Loader />
          </div>
        ) : noDataFound ? (
          <div className="table">
            <div className="empty__room__div mt-5">
              <i className="bi bi-x-circle mb-3"></i>
              <div>You don't have any bookings yet.</div>
            </div>
          </div>
        ) : (
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>Booking ID</th>
                <th>Source</th>
                <th onClick={() => sortByClick("booking_date")}>
                  <div className="poniter">
                    Booking Date
                    <i
                      className={`bi bi-caret-${
                        sortingOrder === "ASC" ? "down" : "up"
                      }-fill`}
                    ></i>
                  </div>
                </th>
                <th>Experience name</th>
                <th>Booker Details</th>
                <th>Persons</th>
                <th>Visit Date</th>
                <th>Payment Status</th>
                <th>Booking Status</th>
              </tr>
            </thead>
            <tbody>
              {listViewFilter(bookingDetails)
                ?.slice(
                  selectPageSize * (currentPageNo - 1),
                  selectPageSize * currentPageNo
                )
                .map((item: any, id: number) => (
                  <tr key={id} className="listview__table__data">
                    <td>
                      <div
                        onClick={() => getDayBookingDetailsCRS(item)}
                        className="booking__id d-flex align-items-center justify-content-start"
                      >
                        {item.booking_id}
                        {/* {item.business_booking === 1 && (
                          <span className="business__booking__wrapper ms-2">
                            <i className="bi bi-buildings"></i>
                          </span>
                        )} */}
                      </div>
                    </td>
                    <td>
                      {/* <div className="listview__image">
                        <img src={item.logo} />
                      </div> */}
                      <div className="booking__date ps-0">
                        {item.booking_source}
                      </div>
                    </td>
                    <td>
                      <div className="booking__date ps-0">
                        {item.booking_date}
                      </div>
                    </td>
                    <td>
                      <div className="guest">{item.package_name}</div>
                    </td>
                    <td>
                      <div className="">
                        <span className="ms-2">{item.guest_name}</span>
                      </div>
                    </td>
                    <td>
                      <div className="">
                        <span className="ms-2">{item.no_of_guest}</span>
                      </div>
                    </td>
                    <td>
                      <div className="check__in__out">{item.outing_dates}</div>
                    </td>
                    <td>
                      <div className={`${item?.payment_status_color}`}>
                        {`${item?.payment_status}`}
                      </div>
                    </td>
                    <td>
                      <div></div>
                      <div
                        className={`${
                          item.booking_status === "Confirm"
                            ? "confirmed__label"
                            : "cancelled"
                        }`}
                      >
                        {item.booking_status}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!isLoading && !noDataFound && noOfPages !== 0 && (
          <div className="list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label=""
                  value={selectPageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setCurrentPageNo(1);
                    setNoOfPages(
                      Math.ceil(bookingDetails.length / e.target.value)
                    );
                  }}
                  options={pageSize}
                />
              </div>
              <ul>
                {Array(noOfPages)
                  .fill(0)
                  .map((_, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => setCurrentPageNo(i + 1)}
                        className={`${
                          i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                        }`}
                      >
                        {i + 1}
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Booking Detail Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openBookingDetailsSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setopenBookingDetailsSlider(false)}
      >
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => setopenBookingDetailsSlider(false)}
              className="bi bi-x"
            ></i>
          </div>
          {booking_details && (
            <>
              <div className="standard-page--label">Booking Detail</div>
              <div className="side__slider__sub__heading me-3">
                ID : {booking_details?.booking_id}
              </div>
            </>
          )}
        </div>
        <div className="height-64px"></div>
        {booking_details ? (
          <div className="booking__details__guest__wrapper">
            <div className="guest__detail__card">
              <div className="guest__name__wrapper">
                <div className="guest__name">{booking_details?.name}</div>
                <div className="booking__channel__icon">
                  <img
                    className="booking__source__image"
                    src={booking_details?.package_logo}
                    alt="Booking Source"
                  />
                </div>
              </div>

              <div className="guest__booking__date mt-2">
                Booking Date - {booking_details?.booking_date}
              </div>
              <div className="guest__email__contact__details mt-2">
                <div className="guest__number">
                  <i className="bi bi-telephone"></i> {booking_details?.mobile}{" "}
                </div>
                <div className="guest__email__id">
                  <i className="bi bi-envelope"></i> {booking_details?.email_id}
                </div>
              </div>

              {/* {booking_details?.booking_source === "CRS" ? (
                <>
                  <div>
                    <div className="guest__booking__date mt-2">
                      Payment Options
                    </div>
                    <div className="guest__email__contact__details">
                      {booking_details?.payment_options}
                    </div>
                  </div>
                </>
              ) : null} */}
            </div>

            <div>
              <React.Fragment>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  style={{ height: "300px" }}
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <i className="bi bi-question-circle me-2"></i>
                      Are you sure you want to cancel this booking
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <BUTTON onClick={handleCancell}>Yes</BUTTON>
                    <BUTTON onClick={handleClose} autoFocus>
                      No
                    </BUTTON>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
            </div>
            <div className="standard-page--label my-3">Day booking detail</div>
            <div className="booking__room__detail__wrapper mb-4">
              <div className="booking__room__image">
                <img src={booking_details?.package_images} alt={"daybooking"} />
              </div>
              <div className="booking__room__details">
                <div className="room__type__meal__plan mb-3">
                  <div className="room__type__name">
                    <i className="bi bi-brightness-high me-2"></i>
                    {booking_details?.package_name}
                  </div>
                </div>
                <div className="booking__room__nights mb-3">
                  <i className="bi bi-calendar2-check-fill me-2"></i>{" "}
                  {booking_details?.outing_dates}
                </div>
                <div className="booking__room__type__rate mb-3">
                  <i className="bi bi-people me-2"></i>{" "}
                  <div className="adult__child__wrapper">
                    <div className="adult__wrapper me-2">
                      {booking_details?.no_of_guest}{" "}
                      {booking_details?.bookable_unit_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="booking__details__price__wrapper">
              <div className="standard-page--label my-3">Price Breakup</div>
              <div
                style={{ display: "grid" }}
                className="booking__details__price__card"
              >
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Price excl. Tax
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.price_excluding_tax}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Tax
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.tax_amount}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Price Incl. Tax
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.price_including_tax}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Selling Price
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.selling_price}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Paid Amount
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.paid_amount}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="booking__detaila__price__input__wrapper me-3">
                    <div
                      style={{ fontWeight: "semibold" }}
                      className="price__input__label mb-2"
                    >
                      Pay at hotel
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "end" }}
                    className="booking__detaila__price__input__wrapper"
                  >
                    <div
                      style={{ fontWeight: "bold" }}
                      className="price__input__label mb-2"
                    >
                      {String.fromCharCode(
                        parseInt(booking_details?.currency_symbol, 16)
                      )}{" "}
                      {booking_details?.pay_at_hotel}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "100px",
                backgroundColor: "white",
              }}
            ></div>
            <div className="side__slider__panel__btn">
              <div className="__btn__wrapper booking__btn__alignment">
                {booking_details?.is_modifiable === 1 && (
                  <Button
                    handleClick={onModifyBookingClick}
                    className="modify__bookinhg__btn"
                    isAccessible={access}
                  >
                    Modify Booking
                  </Button>
                )}
                {/* {booking_details?.is_checkin !== 0 &&
                  <Button handleClick={onCheckinClick} className="checkin__bookinhg__btn" isAccessible={access}>
                    Check-in
                  </Button>
                } */}
                {booking_details?.is_cancelable == 1 && (
                  <Button
                    handleClick={handleClickOpen}
                    loader={loader}
                    className={loader ? "modify_cancel_booking_btn" : ""}
                  >
                    Cancel booking
                  </Button>
                )}
                {/* <Button
                  // handleClick={downloadBookingVoucher}
                  className="modify__bookinhg__btn"
                  loader={isPDFLoading}
                >
                  Download Voucher
                </Button> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="empty__room__div mt-5">
            <i className="bi bi-x-circle mb-3"></i>
            <div>Booking details not found</div>
          </div>
        )}
      </SlidingPane>
      {/* Modify Booking Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        className="sliding-pane-modify-booking"
        isOpen={openModifyBookingSlider}
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => {
          setopenModifyBookingSlider(false);
          setopenBookingDetailsSlider(true);
        }}
      >
        {booking_details ? (
          <ModifyDayBooking
            onClose={() => {
              setopenModifyBookingSlider(false);
              setopenBookingDetailsSlider(false);
            }}
            refreshData={() => setRefreshTrigger(!refreshTrigger)}
            booking_details={booking_details}
            // trigger={refreshTrigger}
            // setTrigger={setRefreshTrigger}
          />
        ) : (
          <></>
        )}
      </SlidingPane>
    </div>
  );
};
