import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import endPoints from "../../API/endPoints";
import { RootState } from "../../Redux/store";
import beApi from "../../API/beApi";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../Redux/actions/PromptAction";

import { toast } from "react-toastify";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../Views/buttons/Button";
import Loader from "../../Views/loader/Loader";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import SlidingPane from "react-sliding-pane";

import { Tooltip } from "@mui/material";
import AddCancellationPolicy from "./AddCancellationPolicy";
import EditCacellationPolicy from "./EditCancellationPolicy";
import axios from "axios";

const CancelationPolicy = () => {
  const dispatch = useDispatch();

  const { getCancelPolicy} =
    endPoints.CANCELATIONPOLICY;
  const { hotel_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );

  const [loader, setLoader] = useState(false);
  const [defaultMsg, setDefaultMsg] = useState("No data found");

  //pagination
  const pageSize = [10, 15, 20, 25, 30];
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [customPageRange, setCustomPageRange] = useState<any[]>([]);

  const [editSliderOpen, setEditSliderOpen] = useState(false);
  const [addSliderOpen, setAddSliderOpen] = useState(false);
  const [cancellationPolicyList, setCancellationPolicyList] = useState([]);

  const [editSliderData, setEditSliderData] = useState<any>();
  const [
    refreshDataAddNewCancellationPolicy,
    setRefreshDataAddNewCancellationPolicy,
  ] = useState(true);
  const [
    refreshDataEditCancellationPolicy,
    setRefreshDataEditCancellationPolicy,
  ] = useState(true);
  const [
    refreshDataDeleteCancellationPolicy,
    setRefreshDataDeleteCancellationPolicy,
  ] = useState(true);

  // Handler function to construct custom Page Range-- Pagination
  useEffect(() => {
    // setIsLoading(true);
    if (noOfPages < 7) {
      let arr: number[] = [];
      for (let i = 0; i < noOfPages; i++) {
        arr.push(i + 1);
      }
      setCustomPageRange(arr);
    } else {
      if (currentPageNo < 4) {
        let arr: any[] = [1, 2, 3, 4, 5, "z", noOfPages];
        setCustomPageRange(arr);
      } else if (currentPageNo > noOfPages - 3) {
        let arr: any[] = [
          1,
          "y",
          noOfPages - 4,
          noOfPages - 3,
          noOfPages - 2,
          noOfPages - 1,
          noOfPages,
        ];
        setCustomPageRange(arr);
      } else {
        let arr: any[] = [
          1,
          "y",
          currentPageNo - 1,
          currentPageNo,
          currentPageNo + 1,
          "z",
          noOfPages,
        ];
        setCustomPageRange(arr);
      }
    }
    // setIsLoading(false);
  }, [currentPageNo, noOfPages]);

  // Current page from custom page range
  const currentPageNoFromCustom = (item: any) => {
    if (item === "y") {
      setCurrentPageNo(currentPageNo - 3);
    } else if (item === "z") {
      setCurrentPageNo(currentPageNo + 3);
    } else {
      setCurrentPageNo(item);
    }
  };

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };

  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const noDataFound = {
    with: "100%",
    minHeight: "180px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
  };

  const emptyDivStyles = {
    height: "500px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "lightgray",
  };

  useEffect(() => {
    if (!hotel_id) {
      return;
    }
    getCancellationPolicyList();
  }, [
    hotel_id,
    refreshDataEditCancellationPolicy,
    refreshDataAddNewCancellationPolicy,
    refreshDataDeleteCancellationPolicy,
  ]);

  const getCancellationPolicyList = async () => {
    setLoader(true);
    try {
      const response = await beApi.post(`${getCancelPolicy}`,{ hotel_id: hotel_id,}, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });


      if (response.data.status === 1) {
        setCancellationPolicyList(response.data.data);
        setLoader(false);
        setNoOfPages(Math.ceil(response?.data?.data?.length / selectPageSize));
      } else {
        setLoader(false);
        setDefaultMsg(response.data.message);
      }
      setCurrentPageNo(1);
    } catch (error) {
      console.log(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };
  // console.log(cancellationPolicyList,"list")



  const editSalesExecutiveData = (e: any, listItems: any) => {
    e.preventDefault();
    setEditSliderOpen(true);
    setEditSliderData(listItems);
  };
  const HandelePolicyDelete = async (id: any) => {
    dispatch(CancelConfirmationPrompt());
    try {
      const payload = { id: id, hotel_id: hotel_id };
      let response = await axios.post(
        `https://be.bookingjini.com/extranetv4/remove-crs-cancellation-policy`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (response.data.status === 1) {
        toast(response.data.message, {
          hideProgressBar: true,
          type: "success",
        });
        setRefreshDataDeleteCancellationPolicy(
          !refreshDataDeleteCancellationPolicy
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openTheConfirmationPopup = (Id: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure you want to Delete?`,
        `${true ? "DELETE" : "UPDATE"}`,
        () => HandelePolicyDelete(Id),
        ["No", "Yes"]
      )
    );
  };
  return (
    <>
      <div
        style={{ justifyContent: "space-between" }}
        className="property__breadcrumbs"
      >
        <CustomBreadcrumbs content={[{ label: "Cancellation Policy" }]} />

        <div className="new__booking__btn__wrapper">
          <Button
            className="new__bookings"
            icon="bi bi-plus-lg"
            handleClick={() => {
              setAddSliderOpen(true);
            }}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="hr--line"></div>

      {loader ? (
        <div style={emptyDivStyles}>
          <Loader />
        </div>
      ) : cancellationPolicyList.length ? (
        <>
          <div className="list-view-table-wrapper mb-4">
            <table className="table table-borderless">
              <thead>
                <tr className="listview__table__heading">
                  <th>Sl.no</th>
                  <th>Policy Name</th>
                  <th>Description</th>

                  <th style={{ textAlign: "center" }}>Edit / View</th>
                  <th style={{ textAlign: "center" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {cancellationPolicyList
                  .slice(
                    selectPageSize * (currentPageNo - 1),
                    selectPageSize * currentPageNo
                  )
                  .map((listItems: any, id: number) => {
                    return (
                      <tr key={id} className="listview__table__data">
                        <td>
                          <div className="customer-details ms-3">{id + 1}</div>
                        </td>
                        <td>
                          <div className="customer-details">
                            {listItems?.policy_name || ""}
                          </div>
                        </td>

                        <td>
                          {/* <div className="customer-details">{listItems?.phone}</div> */}
                          <div className="customer-details">
                            <Tooltip
                              title={""}
                              placement="top"
                              arrow
                            >
                              <span>
                                {listItems?.cancel_policy.length <= 80 ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: listItems?.cancel_policy,
                                    }}
                                  />
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        listItems?.cancel_policy.slice(0, 80) +
                                        "...",
                                    }}
                                  />
                                )}
                                <style>{`
    span ol {
      margin-bottom: 0;
      padding-left:1rem;
    }
    span p {
      margin-bottom: 0;
    }
  `}</style>
                              </span>
                            </Tooltip>
                          </div>
                        </td>

                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customer-details"
                          >
                            {/* <div className="edit__sales__executive" onClick={(e) => editSalesExecutiveData(e, listItems)}>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div> */}
                            <button
                              onClick={(e) =>
                                editSalesExecutiveData(e, listItems)
                              }
                              style={{
                                color: "white",
                                backgroundColor: "#4AD933",
                                marginTop: "0.5rem",
                                width: "fit-content",
                                padding: "0.4rem",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                borderRadius: "10px",
                              }}
                            >
                              {/* <i className="bi bi-pencil-square"></i> */}
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          </div>
                        </td>

                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="customer-details"
                          >
                            {/* <div className="edit__sales__executive" onClick={(e) => editSalesExecutiveData(e, listItems)}>
                                                        <i className="bi bi-pencil-square"></i>
                                                    </div> */}
                            <button
                              // onClick={(e) =>
                              //   HandelePolicyDelete(listItems.id)
                              // }
                              onClick={() =>
                                openTheConfirmationPopup(listItems?.id)
                              }
                              style={{
                                color: "white",
                                backgroundColor: "#E64467",
                                marginTop: "0.5rem",
                                width: "fit-content",
                                padding: "0.4rem",
                                paddingTop: "0.2rem",
                                paddingBottom: "0.2rem",
                                borderRadius: "10px",
                              }}
                            >
                              {/* <i className="bi bi-pencil-square"></i> */}
                              <i className="bi bi-trash3"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="list--view--pagination">
              <div className="pagination__left">
                <div className="pagination__dropdown">
                  <InputSelectField
                    label="Select"
                    value={selectPageSize}
                    options={pageSize}
                    handleChange={(e) => {
                      setSelectPageSize(e.target.value);
                      setNoOfPages(
                        Math.ceil(
                          cancellationPolicyList.length / e.target.value
                        )
                      );
                      setCurrentPageNo(1);
                    }}
                  />
                </div>
                <ul>
                  {Array(noOfPages)
                    .fill(0)
                    .map((p, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => setCurrentPageNo(i + 1)}
                          className={`${
                            i + 1 === currentPageNo
                              ? "active__page"
                              : "showing__page__number"
                          }`}
                        >
                          {i + 1}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="pagination__right">
                <div className="arrow__container me-3" onClick={leftArrowClick}>
                  <i className="bi bi-chevron-left font--weight"></i>
                </div>
                <div className="arrow__container" onClick={rightArrowClick}>
                  <i className="bi bi-chevron-right font--weight"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={noDataFound}>
          <div style={emptyDivStyles}>{defaultMsg}</div>
        </div>
      )}

      <SlidingPane
        hideHeader
        from="right"
        width="30%"
        isOpen={addSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setAddSliderOpen(false)}
      >
        <AddCancellationPolicy
          onClose={() => {
            setAddSliderOpen(false);
          }}
          hotelId={hotel_id}
          refreshData={() =>
            setRefreshDataAddNewCancellationPolicy(
              !refreshDataAddNewCancellationPolicy
            )
          }
        />
      </SlidingPane>

      <SlidingPane
        hideHeader
        from="right"
        width="45%"
        isOpen={editSliderOpen}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setEditSliderOpen(false)}
      >
        <EditCacellationPolicy
          onClose={() => {
            setEditSliderOpen(false);
          }}
          editSliderData={editSliderData}
          refreshData={() =>
            setRefreshDataEditCancellationPolicy(
              !refreshDataEditCancellationPolicy
            )
          }
        />
      </SlidingPane>
    </>
  );
};

export default CancelationPolicy;

// import CustomBreadcrumbs from '../components/breadcrumbs/CustomBreadcrumbs'
// import Loader from '../Views/loader/Loader';

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Button from '../Views/buttons/Button';
// import beApi from '../API/beApi';
// import endPoints from '../API/endPoints';
// import { useSelector } from 'react-redux';
// import { RootState } from '../Redux/store';
// import { toast } from 'react-toastify';
// import { CATCH_MESSAGE } from '../UtilityFunctions';

// const CancelationPolicy = () => {
//     const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
//     const { createCancelPolicy, fetchCancelPolicy } = endPoints.CANCELATIONPOLICY;
//     const [loader, setLoader] = useState<boolean>(false);
//     const [btnloader, setBtnLoader] = useState<boolean>(false);
//     const [cancelationData, setCancelationData] = useState("");
//     const [charCount, setCharCount] = useState({
//         child: 0,
//         privacy: 0,
//         terms_cond: 0,
//         cancellation: 0,
//     });

//     function removeTags(str: String) {
//         str = str.toString();
//         const finalStr = str.replace(/&nbsp;/g, " ");
//         return finalStr.replace(/(<([^>]+)>)/gi, ""); // Regular expression to identify HTML tags in the input string. Replacing the identified,HTML tag with a null string.
//     }

//     useEffect(() => {
//         fetchPolicyData()
//     }, [])

//     // Api To Fetch Cancellation Policy Data
//     const fetchPolicyData = async () => {
//         setLoader(true)
//         try {
//             let fetchRes = await beApi.get(`${fetchCancelPolicy}/${hotel_id}`);
//             if (fetchRes.data.status === 1) {
//                 setCancelationData(fetchRes.data.policies)
//                 console.log(fetchRes.data.policies)
//             } else {
//                 setCancelationData("")
//             }

//         } catch {
//             toast.error(CATCH_MESSAGE)

//         } finally {
//             setLoader(false)
//         }
//     }

//     // Api To Save Cancellation Policy
//     const SaveHandler = async () => {
//         setBtnLoader(true);
//         try {
//             let response = await beApi.post(`${createCancelPolicy}`, {
//                 hotel_id: hotel_id,
//                 cancel_policy: cancelationData
//             });

//             if (response.data.status === 1) {
//                 toast.success(response.data.message);
//             } else {
//                 toast.error(response.data.message);
//             }

//         } catch (error: any) {
//             toast.error(CATCH_MESSAGE);
//         } finally {
//             setBtnLoader(false);
//         }
//     }

//     return (
//         <>
//             <CustomBreadcrumbs content={[{ label: "Cancellation Policy" }]} />
//             <hr />
//             {loader ? (
//                 <div className="loader-setup">
//                     <Loader />
//                 </div>
//             ) : (
//                 <>
//                     <div className="cancelation__policy__wrapper">
//                         <div className="manage__terms__poliy__wrapper pb-0">
//                             <div id="editor">
//                                 <CKEditor
//                                     editor={ClassicEditor}
//                                     data={cancelationData}
//                                     config={{
//                                         removePlugins: [
//                                             "EasyImage",
//                                             "ImageUpload",
//                                             "MediaEmbed",
//                                             "Table",
//                                         ],
//                                     }}
//                                     onChange={(event: string, editor: any) => {
//                                         const data = editor.getData();
//                                         setCancelationData(data);
//                                         setCharCount({
//                                             ...charCount,
//                                             terms_cond: removeTags(data).length,
//                                         });
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         <div className="character__count__number">
//                             <div className="character__count__label">
//                                 Used <span className='count_number'>{charCount.terms_cond}</span>
//                             </div>
//                         </div>

//                         <hr />
//                         <div className="save-btn">
//                             <Button
//                                 className='save-btn'
//                                 loader={btnloader}
//                                 handleClick={() => {
//                                     SaveHandler();
//                                 }}
//                             >
//                                 Save
//                             </Button>
//                         </div>
//                     </div>

//                 </>
//             )}
//         </>
//     )
// }

// export default CancelationPolicy
