import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import BEUrlReducer from "./reducers/BeUrlReducer";
import BookingsReducer from "./reducers/BookingsReducer";
import HotelNameReducer from "./reducers/HotelNameReducer";
import LoginReducer from "./reducers/LoginReducer";
import LoginTypeReducer from "./reducers/LoginTypeReducer";
import PromptReducer from "./reducers/PromptReducer";
import SidebarReducer from "./reducers/SidebarReducer";
import UserAcessReducer from "./reducers/UserAcessReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    // "sidebar",
    "auth",
    "properties",
    "loginType",
    "beUrl",
    // "bookings",
    "userAcess",
  ],
};

const rootReducer = combineReducers({
  sidebar: SidebarReducer,
  auth: LoginReducer,
  prompt: PromptReducer,
  loginType: LoginTypeReducer,
  beUrl: BEUrlReducer,
  bookings: BookingsReducer,
  userAcess: UserAcessReducer,
});

export default persistReducer(persistConfig, rootReducer);
