import React, { useEffect, useState } from "react";

import loginDisplayImages_1 from "../assets/image/crsImages/crs-login-top-image.png";
import loginDisplayImages_2 from "../assets/image/crsImages/crs-login-bottom-image.png";
import overlayImg from "../assets/image/crsImages/crs-login-bottom-overlay-image.png";

import InputTextField from "../Views/inputtextfield/InputTextField";
import InputPasswordField from "../Views/inputtextfield/InputPasswordField";
import { validateEmail, validateMobile } from "../UtilityFunctions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { browserName } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import kernelApi from "../API/kernelApi";
import { LoginSuccess } from "../Redux/actions/LoginAction";
import CATCH_MSG from "../Constants/CatchMsg";
import { LoginTypeAction } from "../Redux/actions/LoginTypeAction";

function Login() {
  //STATES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");

  const [isloading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //FUNCTIONS
  const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (
      validateEmail(e.target.value) ||
      validateMobile(e.target.value) ||
      !e.target.value
    ) {
      setEmailHelperText("");
    } else {
      setEmailHelperText("Invalid email or mobile");
    }
  };

  const PasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 4 && e.target.value) {
      setPasswordHelperText("Invalid password");
    } else {
      setPasswordHelperText(" ");
    }
  };

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      LoginFormSubmit();
    }
  };

  const LoginFormSubmit = async () => {
    if (!email) {
      toast.error("Enter your email or mobile");
    } else if (!password) {
      toast.error("Please enter your password");
    } else {
      // setIsLoading(true);
      try {
        let response = await kernelApi.post("/admin/auth", {
          email: email,
          password: password,
          browser: browserName,
          creation_mode: "WEBSITE",
        });
        if (response.data.status === 1) {
          navigate("/");
          dispatch(LoginTypeAction("logincred"));
          dispatch(LoginSuccess(response.data));
        } else {
          toast.error(response.data.message);
        }
      } catch {
        toast.error(CATCH_MSG);
      }
    }
  };

  return (
    <div className="loginContainer">
      <div className="left">
        <div className="loginBox">
          <div className="loginBoxHeader">Login to Bookingjini CRS</div>

          <div className="div--email--input">
            <InputTextField
              value={email}
              helperText={emailHelperText}
              label="Email or Mobile"
              handleChange={(e) => {
                EmailOnChange(e);
              }}
              onKeyPress={(e) => {
                onKeyEnter(e);
              }}
              handleFocus={(e) => {
                if (!e.target.value) setEmailHelperText("");
              }}
            />
          </div>

          <div className="div--password--input">
            <InputPasswordField
              value={password}
              helperText={passwordHelperText}
              onEnterKey={onKeyEnter}
              handleFocus={(e) => {
                if (!e.target.value) setPasswordHelperText("");
              }}
              handleChange={(e) => {
                PasswordOnChange(e);
              }}
            />
          </div>

          <button className="loginBtn" onClick={() => LoginFormSubmit()}>
            Login
          </button>
        </div>
      </div>

      <div className="right">
        <div className="topLoginImage">
          <img src={loginDisplayImages_1} alt="loginDisplayImages" />
        </div>
        <div className="bottomLoginImage">
          <img src={loginDisplayImages_2} alt="loginDisplayImages" />
          <img
            src={overlayImg}
            className="overlayImg"
            alt="loginDisplayImages"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
