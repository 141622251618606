import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { nodeModuleNameResolver } from 'typescript';
import beApi from '../../API/beApi';
import CATCH_MSG from '../../Constants/CatchMsg';
import { RootState } from "../../Redux/store";
import { Capitalize, validateEmail, validateGstNo, validateMobile, validatePinCode } from '../../UtilityFunctions';
import Button from '../../Views/buttons/Button';
import InputTextField from '../../Views/inputtextfield/InputTextField';
import Loader from '../../Views/loader/Loader';


export interface IEditPartnerSliderContent {
  onClose: () => void;
  runTheFunc: () => void;
  editPartner: any;
}


const EditPartnerSliderContent: React.FC<IEditPartnerSliderContent>
  = ({
    onClose, runTheFunc, editPartner,
  }) => {

    const { company_id, auth_token, hotel_id } = useSelector(
      (state: RootState) => state.auth
    );

    const doNothing = () => { }

    const [gstNum, setGstNum] = useState('')
    const [mobiNum, setMobiNum] = useState('')
    const [isGst, setIsGst] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [validationTypeVal, setValidationTypeVal] = useState('GSTIN')
    const [newPardnerAdded, addNewPartnerAdded] = useState(false)

    const [checkAgent, setCheckAgent] = useState(true)
    const [checkCorporate, setCheckCorporate] = useState(false)
    const [partnerType, setPartnerType] = useState('agent')

    const [agentNameInput, setAgentNameInput] = useState('')
    const [mobileNumberInput, setMobileNumberInput] = useState('')
    const [countryInput, setCountryInput] = useState('')
    const [stateInput, setStateInput] = useState('')
    const [pinInput, setPinInput] = useState('')
    const [cityInput, setCityInput] = useState('')
    const [addressInput, setAddressInput] = useState('')
    const [panInput, setPanInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [agentWebsite, setAgentWebsite] = useState('')
    const [phoneHelperText, setPhoneHelperText] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [pinHelperText, setPinHelperText] = useState("");
    const [gstHelperText, setGstHelperText] = useState("");

    const [isMobiPresentInDatabase, setIsMobiPresentInDatabase] = useState<any>(null)
    const [notPresentMobiError, setNotPresentMobiError] = useState('')

    const [idSearched, setIdSearched] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [agentCompanyName,setAgentCompanyName]=useState("");
    const [forEdit, setForEdit] = useState<boolean>(false);

    useEffect(() => {
      // Handler for editing a agent details
      if (editPartner) {
        setForEdit(true);
        addNewPartnerAdded(true);
        if (editPartner.gstin) {
          setValidationTypeVal('GSTIN');
          setAgentNameInput(editPartner?.partner_name);
          setMobiNum(editPartner?.contact_no);
          setEmailInput(editPartner?.email_id);
          setPanInput(editPartner?.pan);
          setAddressInput(editPartner?.address);
          setCountryInput(editPartner?.country);
          setPinInput(editPartner?.pin);
          setCityInput(editPartner?.city);
          setStateInput(editPartner?.state);
          setGstNum(editPartner?.gstin);
          setPartnerType(editPartner?.partner_type);
          setAgentCompanyName(editPartner?.company_name)
          setAgentWebsite(editPartner?.website)
          editPartner?.partner_type === "agent" ? setCheckAgent(true) : setCheckAgent(false);
          editPartner?.partner_type === "corporate" ? setCheckCorporate(true) : setCheckCorporate(false);
        } else {
          setValidationTypeVal('Mobile');
          setAgentNameInput(editPartner?.partner_name);
          setMobiNum(editPartner?.contact_no);
          setEmailInput(editPartner?.email_id);
          setPanInput(editPartner?.pan);
          setAddressInput(editPartner?.address);
          setCountryInput(editPartner?.country);
          setPinInput(editPartner?.pin);
          setCityInput(editPartner?.city);
          setStateInput(editPartner?.state);
          setGstNum(editPartner?.gstin);
          setPartnerType(editPartner?.partner_type);
          setAgentCompanyName(editPartner?.company_name)
          setAgentWebsite(editPartner?.website)
          editPartner?.partner_type === "agent" ? setCheckAgent(true) : setCheckAgent(false);
          editPartner?.partner_type === "corporate" ? setCheckCorporate(true) : setCheckCorporate(false);
        }
      } else {
        setForEdit(false);
        addNewPartnerAdded(false);
      }
    }, [])

    const isGstAvailable = (val: string) => {
      if (val === 'yes') {
        setIsGst(true)
        setIsMobile(false)
        setValidationTypeVal('GSTIN')
        setGstNum('')
        setMobiNum('')
      }
      else if (val === 'no') {
        setIsGst(false)
        setIsMobile(true)
        setValidationTypeVal('Mobile')
        setGstNum('')
        setMobiNum('')
      }
    }

    const setInputType = (e: any) => {
      if (e.target.value === 'agent') {
        setCheckAgent(true)
        setCheckCorporate(false)
        setPartnerType('agent')
      }
      else if (e.target.value === 'corporate') {
        setCheckAgent(false)
        setCheckCorporate(true)
        setPartnerType('corporate')
      }
    }

    const checkTheGST = async () => {
      if (!gstNum) return
      else {
        setIsMobiPresentInDatabase(null)
        setIdSearched(true)
        try {
          const res = await beApi.get(`partner-gst-details/${hotel_id}/${gstNum}`)
          if (res.data.status === 1) {
            setEmailInput(res.data.data.email_id)
            setPanInput(res.data.data.pan)
            setAddressInput(res.data.data.address)
            setCountryInput(res.data.data.country)
            setPinInput(res.data.data.pin)
            setStateInput(res.data.data.state)
            setCityInput(res.data.data.city)
            setMobileNumberInput(res.data.data.contact_no)
            setAgentNameInput(res.data.data.partner_name)
            setIsMobiPresentInDatabase(true)
          }
          else {
            setNotPresentMobiError('GST not available')
            setIsMobiPresentInDatabase(false)
          }
        }
        catch {
          toast.error(CATCH_MSG)
        }
      }
    }

    const checkTheMobi = async () => {
      if (!mobiNum) return
      else {
        setIsMobiPresentInDatabase(null)
        setIdSearched(true)
        try {
          const res = await beApi.get(`partner-contact-details/${hotel_id}/${mobiNum}`)
          if (res.data.status === 1) {
            setEmailInput(res.data.data.email_id)
            setPanInput(res.data.data.pan)
            setAddressInput(res.data.data.address)
            setCountryInput(res.data.data.country)
            setPinInput(res.data.data.pin)
            setStateInput(res.data.data.state)
            setCityInput(res.data.data.city)
            setMobileNumberInput(res.data.data.contact_no)
            setAgentNameInput(res.data.data.partner_name)
            setIsMobiPresentInDatabase(true)
          }
          else {
            setNotPresentMobiError('Mobile not available')
            setIsMobiPresentInDatabase(false)
          }
        }
        catch {
          toast.error(CATCH_MSG)
          setIsMobiPresentInDatabase(false)
        }
      }
    }

    const postApiCall = async () => {
      if (
        !agentNameInput ||
        !mobiNum ||
        !emailInput ||
        // !panInput ||
        !addressInput ||
        !countryInput ||
        !pinInput ||
        !cityInput ||
        !stateInput ||
        phoneHelperText || emailHelperText || pinHelperText
      ) {
        setIsLoading(false)
        toast.error('Fill all the fields')
        return
      }
      else {
        try {
          const payload = {
            'gstin': gstNum,
            "partner_type": partnerType,
            "desgination": partnerType,
            "partner_name": agentNameInput,
            "contact_no": mobiNum,
            "email_id": emailInput,
            "pan": panInput,
            "address": addressInput,
            'country': countryInput,
            "pin": pinInput,
            "state": stateInput,
            "city": cityInput,
            "hotel_id": hotel_id,
            "contact_person": "",
            "company_name":agentCompanyName
          }
          const res = await beApi.post('add-partner', payload)
          if (res.data.status === 1) {
            toast.success(res.data.message)
            setIsLoading(false)
            setEmailInput('')
            setPanInput('')
            setAddressInput('')
            setCountryInput('')
            setPinInput('')
            setStateInput('')
            setCityInput('')
            setMobileNumberInput('')
            setAgentNameInput('')
            setGstNum('')
            setTimeout(() => {
              onClose()
            }, 500);
            runTheFunc()
          }
          else {
            toast.error(res.data.message)
            setIsLoading(false)
            setEmailInput('')
            setPanInput('')
            setAddressInput('')
            setCountryInput('')
            setPinInput('')
            setStateInput('')
            setCityInput('')
            setMobileNumberInput('')
            setAgentNameInput('')
            setGstNum('')
          }
        }
        catch {
          setIsLoading(false)
          toast.error(CATCH_MSG)
        }
      }
    }

    // ----------------------------------- Edit API call
    const editApiCall = async () => {
      if (
        !agentNameInput ||
        !mobiNum ||
        !emailInput ||
       
        !addressInput ||
        !countryInput ||
        !pinInput ||
        !cityInput ||
        !stateInput ||
        phoneHelperText || emailHelperText || pinHelperText
      ) {
        setIsLoading(false)
        toast.error('Fill all the fields')
        return
      }
      else {
        try {
          const payload = {
            'gstin': gstNum,
            "partner_type": partnerType,
            "desgination": partnerType,
            "partner_name": agentNameInput,
            "contact_no": mobiNum,
            "email_id": emailInput,
            "pan": panInput,
            "address": addressInput,
            'country': countryInput,
            "pin": pinInput,
            "state": stateInput,
            "city": cityInput,
            "hotel_id": hotel_id,
            "contact_person": "",
            "company_name": agentCompanyName,
            "website": agentWebsite
          }
          const res = await beApi.post(`update-partner/${editPartner?.id}`, payload)
          if (res.data.status === 1) {
            toast.success(res.data.message)
            setIsLoading(false)
            setEmailInput('')
            setPanInput('')
            setAddressInput('')
            setCountryInput('')
            setPinInput('')
            setStateInput('')
            setCityInput('')
            setMobileNumberInput('')
            setAgentNameInput('')
            setGstNum('')
            setTimeout(() => {
              onClose()
            }, 500);
            runTheFunc()
          }
          else {
            toast.error(res.data.message)
            setIsLoading(false)
            setEmailInput('')
            setPanInput('')
            setAddressInput('')
            setCountryInput('')
            setPinInput('')
            setStateInput('')
            setCityInput('')
            setMobileNumberInput('')
            setAgentNameInput('')
            setGstNum('')
          }
        }
        catch {
          setIsLoading(false)
          toast.error(CATCH_MSG)
        }
      }
    }

    // -----------------------------------
    const onKeyEnterForMobi = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (phoneHelperText === '') {
          checkTheMobi();
          // setIdSearched(true)
        }
      }
    };

    const onKeyEnterForGST = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (phoneHelperText === '') {
          checkTheGST();
          // setIdSearched(true)
        }
      }
    };

    const PhoneNumOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMobiNum(e.target.value);
      if (
        validateMobile(e.target.value) ||
        // validateMobile(e.target.value) ||
        !e.target.value
      ) {
        setPhoneHelperText("");
      } else {
        setPhoneHelperText("Invalid mobile number");
      }
    };

    const EmailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailInput(e.target.value);
      if (
        validateEmail(e.target.value) ||
        // validateMobile(e.target.value) ||
        !e.target.value
      ) {
        setEmailHelperText("");
      } else {
        setEmailHelperText("Invalid email");
      }
    };

    const PinOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPinInput(e.target.value);
      if (
        validatePinCode(e.target.value) ||
        // validateMobile(e.target.value) ||
        !e.target.value
      ) {
        setPinHelperText("");
      } else {
        setPinHelperText("Invalid PIN");
      }
    };

    const GSTOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setGstNum(e.target.value);
      if (
        validateGstNo(e.target.value) ||
        // validateMobile(e.target.value) ||
        !e.target.value
      ) {
        setGstHelperText("");
      } else {
        setGstHelperText("Invalid GST no.");
      }
    };




    return (
      <div style={{ position: 'relative' }}>

        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => {
                onClose();
              }}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">{newPardnerAdded ? `${!forEdit ? "New Partner" : "Edit Partner"}` : `Partner`}</div>
        </div>

        <div className="partnerValidationTypeSelectionWrapper">
          {newPardnerAdded ?
            <>
              {/* ------------------------------------------- */}
              {validationTypeVal === 'GSTIN' ?
                <InputTextField
                  label={`GSTIN no.`}
                  value={gstNum}
                  handleChange={(e) => setGstNum(e.target.value)}
                  newIcon='bi-search'
                // stylings={iconStyles}
                // runningTheFunction={() => checkTheGST()}
                // helperText={gstHelperText}
                // handleChange={(e) => {
                //   GSTOnChange(e)
                // }}
                // handleFocus={(e) => {
                //   if (!e.target.value) setGstHelperText("");
                // }}
                // onKeyPress={(e) => { onKeyEnterForGST(e) }}
                // helperText={phoneHelperText}
                />
                :
                <InputTextField
                  label={`Mobile no.`}
                  value={mobiNum}
                  helperText={phoneHelperText}
                  handleChange={(e) => {
                    PhoneNumOnChange(e)
                  }}
                  // runningTheFunction={() => checkTheMobi()}
                  handleFocus={(e) => {
                    if (!e.target.value) setPhoneHelperText("");
                  }}
                // handleChange={(e) => setMobiNum(e.target.value)}
                // newIcon='bi-search'
                // stylings={iconStyles}
                // onKeyPress={(e) => { mobiNum && onKeyEnterForMobi(e) }
                // }
                />
              }
              <div className='agentOrCorporateRadioSelectorBox' style={{ display: "flex", alignItems: "center", marginBottom: "1.5rem" }}>
                <div
                  style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '3rem'
                  }}
                >
                  <input type="radio" name="agent" value='agent' id="agent" style={{ width: '20px', height: '20px', marginRight: '0.5rem' }} onChange={(e) => setInputType(e)} checked={checkAgent} />
                  <label htmlFor="agent">Agent</label>
                </div>

                <div
                  style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                  }}
                >
                  <input type="radio" name="corporate" value='corporate' id="corporate" style={{ width: '20px', height: '20px', marginRight: '0.5rem' }} onChange={(e) => setInputType(e)} checked={checkCorporate} />
                  <label htmlFor="corporate">Corporate</label>
                </div>
              </div>
              {/* ------------------------------------------- */}
              <InputTextField
                label={partnerType === 'agent' ? `Agent Name` : 'Corporate Name'}
                value={Capitalize(agentNameInput)}
                handleChange={(e) => { setAgentNameInput(Capitalize(e.target.value)) }}
              />
              {/* ------------------------------------------- */}
              {
                validationTypeVal === 'GSTIN' ?
                  // <InputTextField
                  //   label={`Number`}
                  //   value={mobileNumberInput}
                  //   handleChange={(e) => { setMobileNumberInput(e.target.value) }}
                  // />
                  <InputTextField
                    label={`Mobile no.`}
                    value={mobiNum}
                    helperText={phoneHelperText}
                    handleChange={(e) => {
                      PhoneNumOnChange(e)
                    }}
                    handleFocus={(e) => {
                      if (!e.target.value) setPhoneHelperText("");
                    }}
                    // handleChange={(e) => setMobiNum(e.target.value)}
                    newIcon='bi-search'
                    stylings={iconStyles}
                    onKeyPress={(e) => { onKeyEnterForMobi(e) }
                    }
                  />
                  : <></>
              }
               <InputTextField
                  label={` Company Name (Optional)`}
                  value={agentCompanyName}
                  type='text'
                  handleChange={(e) => setAgentCompanyName(e.target.value)}
              />
              <InputTextField
                  label={` Website Name(Optional)`}
                  value={agentWebsite}
                  type='text'
                  handleChange={(e) => setAgentWebsite(e.target.value)}
                          />
              {/* ------------------------------------------- */}
              <InputTextField
                label={`Email`}
                value={emailInput}
                // handleChange={(e) => { setEmailInput(e.target.value) }}
                helperText={emailHelperText}
                handleChange={(e) => {
                  EmailOnChange(e)
                }}
                handleFocus={(e) => {
                  if (!e.target.value) setEmailHelperText("");
                }}
              />
              <InputTextField
                label={`PAN No. (Optional)`}
                value={panInput.toUpperCase()}
                handleChange={(e) => { setPanInput(e.target.value.toUpperCase()) }}
              />
              <InputTextField
                label={`Address`}
                value={Capitalize(addressInput)}
                handleChange={(e) => { setAddressInput(Capitalize(e.target.value)) }}
              />
              <div style={{ display: "flex", gap: '1rem' }}>
                <InputTextField
                  label={`Country`}
                  value={Capitalize(countryInput)}
                  handleChange={(e) => { setCountryInput(Capitalize(e.target.value)) }}
                />
                <InputTextField
                  label={`Pin`}
                  value={pinInput}
                  // handleChange={(e) => { setPinInput(e.target.value) }}
                  helperText={pinHelperText}
                  handleChange={(e) => {
                    PinOnChange(e)
                  }}
                  handleFocus={(e) => {
                    if (!e.target.value) setPinHelperText("");
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                <InputTextField
                  label={`State`}
                  value={Capitalize(stateInput)}
                  handleChange={(e) => { setStateInput(Capitalize(e.target.value)) }}
                />
                <InputTextField
                  label={`City`}
                  value={Capitalize(cityInput)}
                  handleChange={(e) => { setCityInput(Capitalize(e.target.value)) }}
                />
              </div>
            </>
            :
            <>
              <div className="validattionTypeLable">
                <div className="lable">Does Partner has {validationTypeVal} no. ?</div>
                <label htmlFor="yes">Yes</label>
                <input type="checkbox" name="yes" id="yes" checked={isGst} onChange={() => {
                  isGstAvailable('yes')
                  setIdSearched(false)
                }} />
                <label htmlFor="no">No</label>
                <input type="checkbox" name="no" id="no" checked={isMobile} onChange={() => {
                  isGstAvailable('no')
                  setIdSearched(false)
                }} />
              </div>
              <div className="gstIdInput">
                {validationTypeVal === 'GSTIN' ?
                  <InputTextField
                    label={`GSTIN no.`}
                    value={gstNum}
                    handleChange={(e) => setGstNum(e.target.value)}
                    newIcon='bi-search'
                    stylings={iconStyles}
                    settingState={() => setIdSearched(true)}
                    runningTheFunction={() => checkTheGST()}
                    // helperText={phoneHelperText}
                    onKeyPress={(e) => { onKeyEnterForGST(e) }}
                  />
                  :
                  <InputTextField
                    label={`Mobile no.`}
                    value={mobiNum}
                    // handleChange={(e) => setMobiNum(e.target.value)}
                    helperText={phoneHelperText}
                    handleChange={(e) => {
                      PhoneNumOnChange(e)
                    }}
                    handleFocus={(e) => {
                      if (!e.target.value) setPhoneHelperText("");
                    }}
                    newIcon='bi-search'
                    stylings={iconStyles}
                    settingState={() => setIdSearched(true)}
                    runningTheFunction={() => checkTheMobi()}
                    onKeyPress={(e) => { onKeyEnterForMobi(e) }
                    }
                  />
                }

                {
                  idSearched ?
                    isMobiPresentInDatabase && typeof isMobiPresentInDatabase == 'boolean' ?
                      <>
                        {/* ------------------------------------------- */}
                        <InputTextField
                          label={partnerType === 'agent' ? `Agent Name` : 'Corporate Name'}
                          value={agentNameInput}
                          handleChange={(e) => { }}
                        />
                        {gstNum ? <InputTextField
                          label={`Mobile`}
                          value={mobileNumberInput}
                          handleChange={(e) => { }}
                        /> : <></>}
                        <InputTextField
                          label={`Email`}
                          value={emailInput}
                          handleChange={(e) => { }}
                        />
                        <InputTextField
                          label={`PAN No. (Optional)`}
                          value={panInput}
                          handleChange={(e) => { }}
                        />
                        <InputTextField
                          label={`Address`}
                          value={addressInput}
                          handleChange={(e) => { }}
                        />
                        <div style={{ display: "flex", gap: '1rem' }}>
                          <InputTextField
                            label={`Country`}
                            value={countryInput}
                            handleChange={(e) => { }}
                          />
                          <InputTextField
                            label={`Pin`}
                            value={pinInput}
                            handleChange={(e) => { }}
                          />
                        </div>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <InputTextField
                            label={`State`}
                            value={stateInput}
                            handleChange={(e) => { }}
                          />
                          <InputTextField
                            label={`City`}
                            value={cityInput}
                            handleChange={(e) => { }}
                          />
                        </div>
                        <div className="side__slider__panel__btn">
                          <div className="__btn__wrapper">
                            <Button handleClick={() => {
                              onClose()
                            }}>Save</Button>
                          </div>
                        </div>
                      </>
                      :

                      !isMobiPresentInDatabase && typeof isMobiPresentInDatabase == 'boolean' ?
                        <div className="addPartnerOption" style={{
                          width: '100%', display: 'flex', justifyContent: "space-between", alignItems: "center",
                        }}>
                          <div className="errorMsg" style={{ width: "60%", color: "#EA4335", }}>{notPresentMobiError}</div>
                          <div style={{ width: "40%", display: "flex", justifyContent: "right", alignItems: "center", color: "#223189" }}>
                            <div className="addPartnerLable" style={{ marginRight: '0.5rem' }} >Add New</div>
                            <div className="addNewPartnerBtn" style={{
                              background: "#223189", borderRadius: '50%', height: "2rem", width: '2rem',
                              display: 'flex', justifyContent: 'center', alignItems: "center", cursor: 'pointer'
                            }}>
                              <i className="bi bi-plus" style={{ color: "white", fontSize: '25px' }} onClick={() => addNewPartnerAdded(true)}></i>
                            </div>
                          </div>
                        </div>
                        :
                        <div style={{ width: "100%", height: "200px", display: 'flex', justifyContent: 'center', alignItems: "center" }}><Loader />
                        </div>

                    : <></>
                }
              </div>
            </>
          }
        </div>

        {newPardnerAdded ?
          <div className="side__slider__panel__btn">
            <div className="__btn__wrapper">
              {
                isLoading ?
                  <Button>
                    Save
                    <span
                      className="spinner-border spinner-border-sm pd-4"
                      role="status"
                      aria-hidden="true"
                      style={{ marginLeft: '0.5rem' }}
                    ></span>
                  </Button>
                  :
                  <Button handleClick={() => {
                    setIsLoading(true)
                    !forEdit ? postApiCall() : editApiCall()
                    // onClose()
                  }}>Save</Button>
              }
            </div>
          </div>
          : <></>
        }

      </div>
    )
  }

const iconStyles = {
  color: "#223189",
  fontSize: '18px'
}

export default EditPartnerSliderContent