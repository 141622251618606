import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import { RootState } from "../../Redux/store";

const BookingAndRevenue = ({

}: any) => {
  const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
  const { bookingRevenuePieApi } = endPoints.DASHBOARD;
  const pieBookingRevenueData = ["Today", "MTD", "YTD"];
  const [activeMenuTab, setActiveMenuTab] = useState<any>("MTD");
  const [bookingData, setBookingData] = useState<any>([]);
  const [totalBookingData, setTotalBookingData] = useState<any>();
  const [revenueData, setRevenueData] = useState<any>([]);
  const [totalRevenueData, setTotalRevenueData] = useState<any>();
  const [colorCode, setColorCode] = useState<any>([]);


  const switchMenuTab = (tab: string) => {
    setActiveMenuTab(tab);
  };

  useEffect(() => {
    getBookingRevenueData();
  }, [hotel_id, activeMenuTab]);




  // Api To Get Booking and Revenue information

  const getBookingRevenueData = async () => {

    try {
      const bookingRevenue = await beApi.get(
        `${bookingRevenuePieApi}/${hotel_id}/${activeMenuTab}`
      );
      if (bookingRevenue.data.status === 1) {
        setBookingData(bookingRevenue.data.data.bookings);
        setTotalBookingData(bookingRevenue.data.data.total_bookings);
        setRevenueData(bookingRevenue.data.data.revenue);
        setTotalRevenueData(bookingRevenue.data.data.total_revenue);
        setColorCode(bookingRevenue.data.data.color_code);


      } else {
        setBookingData([]);
        setRevenueData([]);
        setColorCode([]);

      }
    } catch (error) {
      console.log(error);

    }
  };

  return (
    <>
      <div className="dashboard__standard__header pt-1 mb-3">
        <div className="dashboard__standard__label">
          Bookings and Revenue
        </div>
        <div className="dashboard__standard__action__btns">
          <ul className="nav nav-tabs" id="duesTab" role="tablist">
            {pieBookingRevenueData?.map((tab) => {
              return (
                <li
                  className="nav-item"
                  role="presentation"
                  key={tab}
                  onClick={() => switchMenuTab(tab)}
                >
                  <a
                    className={`nav-link ${tab === activeMenuTab ? "active" : ""
                      }`}
                    id={`${tab}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab}`}
                    aria-controls={`#${tab}`}
                    role="tab"
                    aria-selected="true"
                  >
                    {tab}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="dashborad__standard__chart__wrapper">
        {bookingData?.length > 0 ? (
          <>
            <div className="dashboard__pie__chart">
              <div className="pie__chart__wrapper">
                <div className="center__data__inside__pie">
                  <div className="pie__label">Bookings</div>
                  <div className="pie__data">{totalBookingData}</div>
                </div>

                {bookingData && (
                  <ResponsivePie
                    data={bookingData}
                    // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                    margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                    innerRadius={0.6}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    enableArcLabels={true}
                    enableArcLinkLabels={false}
                    animate={true}
                    colors={colorCode}
                    arcLabelsTextColor={{
                      theme: "labels.text.fill",
                      from: "color",
                      modifiers: [["darker", 20]],
                    }}
                  />
                )}
              </div>
              <div className="pie__chart__wrapper">
                <div className="center__data__inside__pie">
                  <div className="pie__label">Revenue</div>
                  <div className="pie__data">{totalRevenueData}</div>
                </div>
                {revenueData && (
                  <ResponsivePie
                    data={revenueData}
                    // margin={{ top: 38, right: 22, bottom: 22, left: 38 }}
                    margin={{ top: 38, right: 22, bottom: 22, left: 24 }}
                    innerRadius={0.6}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLabelsTextColor={{
                      theme: "labels.text.fill",
                      from: "color",
                      modifiers: [["darker", 3]],
                    }}
                    enableArcLabels={true}
                    enableArcLinkLabels={false}
                    animate={true}
                    colors={colorCode}
                  />
                )}
              </div>
            </div>
            <div className="dashboard__pie__legends">
              <div className="ota__item__wrapper">
                {bookingData?.map((labelData: any, index: number) => {
                  return (
                    <div
                      key={labelData.id}
                      className="ota__name__list me-4 mb-3"
                    >
                      <div
                        className="ota__color__box me-2"
                        style={{
                          backgroundColor: colorCode[index],
                        }}
                      ></div>
                      <div className="ota__label">{labelData.label}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="pie-nodata-container">
            <div className="no-data-label-container">
              <div className="icon-container">
                <i className="bi bi-x-circle mb-3"></i>
              </div>

              <div className="no-data-label">
                No Data on Bookings and Revenue
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookingAndRevenue;
