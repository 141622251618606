import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import beApi from "../../API/beApi";
import endPoints from "../../API/endPoints";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import { RootState } from "../../Redux/store";
import Button from "../../Views/buttons/Button";
import InventoryBtn from "../../Views/buttons/Button";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputTextField from "../../Views/inputtextfield/InputTextField";
import Loader from "../../Views/loader/Loader";
import {
  Capitalize,
  Logout,
  validateMobile,
  validatePositiveInt,
} from "../../UtilityFunctions";
import { useDispatch } from "react-redux";

// MUI date picker
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  ShowConfirmationPrompt,
  CancelConfirmationPrompt,
} from "../../Redux/actions/PromptAction";
import axios from "axios";
import Slider from "react-slick";
import alphaApi from "../../API/alphaApi";

export interface IModifyCrsBookingProps {
  onClose: () => void;
  refreshData: () => void;
  booking_details: any;
}

const ModifyDayBooking: React.FC<IModifyCrsBookingProps> = ({
  onClose,
  refreshData,
  booking_details,
}) => {
  const dispatch = useDispatch();

  // for dates tab
  const [selectedBookingData, setSelectedBookingData] = useState<any>({});
  const { company_id, hotel_id, auth_token } = useSelector(
    (state: RootState) => state.auth
  );
  const { userInfoCrsGet, userGstApi, companyProfileGet } = endPoints.BOOKINGS;
  const { getDayBookingData, postBookingDetails } = endPoints.DAYBOOKING;
  const steps = ["Availability", "Confirm"];
  const [daybookingData, setDayBookingData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [alternativeDatesData, setAlternativeDatesData] = useState<any>([]);
  const [tab, setTab] = useState(0);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openAdvanceDatePicker, setOpenAdvanceDatePicker] = useState(false);
  const [firstDate, setFirstDate] = useState(moment().format("YYYY-MM-DD"));
  const [checkInDate, setCheckInDate] = useState(moment().format("YYYY-MM-DD"));
  const [loaderBookNow, setLoaderBookNow] = useState<any>(false);
  const [arrivalTime, setArrivalTime] = useState("");
  const [internalRemark, setInternalRemark] = useState("");
  const [guestRemark, setGuestRemark] = useState("");
  const [sendMailtoGuest, setSendMailtoGuest] = useState(false);
  const [TotalPackage, setTotalPackages] = useState(0);
  const [packageData, setPackageData] = useState<any>([]);
// ============Payment Modify Page==================
const [searchMobileNumber, setSearchMobileNumber] = useState<any>(booking_details.mobile);

const [searchGSTNumber, setSearchGSTNumber] = useState<any>("");

const [bookerName, setBookerName] = useState<any>(booking_details.name);
const [bookerEmail, setBookerEmail] = useState<any>(booking_details?.email_id);
const [PackagePrice, SetPackagePrice] = useState(0);
const [totalGst, setTotalGst] = useState(0);
const [bookerMobile, setBookerMobile] = useState<any>(booking_details.mobile);
const [companyName, setCompanyName] = useState<any>("");
const [companyAddress, setCompanyAddress] = useState<any>("");
const [collectAdivance, setCollectAdvance] = useState(false);
const [gstIn, setGstIn] = useState<any>("");
const [enableBusinessBooking, setEnableBusinessBooking] = useState(booking_details.is_business_booking?true:false);
const [paymentMode, setPaymentMode] = useState("");
const [collectedby, setCollectedBy] = useState("");
const [advanceAmount, setAdvanceAmount] = useState(0 + "");
const [refference_number, setReffrenceNumber] = useState("");
const [companyApiKey, setCompanyApiKey] = useState<any>();
 // ==========================PayMent Page===============================
 const fetchUserInfoCrs = async (guest_det: any) => {
  if (!guest_det) {
    return;
  }
  let res = await beApi.post(
    `${userInfoCrsGet}`,
    {
      mobile_number: `+91${guest_det}`,
      company_id: company_id,
    },
    {
      headers: {
        Authorization: "Bearer " + auth_token,
      },
    }
  );

  if (res.data.status === 1) {
    let fetched_data = res.data.data;

    setBookerName(`${fetched_data.first_name} ${fetched_data.last_name}`);
    setBookerEmail(fetched_data.email_id);
    setBookerMobile(guest_det);
  } else {
    toast(res.data.message, {
      hideProgressBar: true,
      type: "error",
    });
    setBookerName("");
    setBookerEmail("");
    setBookerMobile("");
  }
};
const handleEnableBusinessbooking = (value: any) => {
  if (!value) {
    setCompanyName("");
    setCompanyAddress("");
    setGstIn("");
  }

  setEnableBusinessBooking(!value);
};
useEffect(()=>{
if(enableBusinessBooking){
  setCompanyName(booking_details?.company_name);
  setCompanyAddress(booking_details?.company_address);
  setGstIn(booking_details?.gstin);
  setSearchGSTNumber(booking_details?.gstin);
}
},[])
const fetchCompanyDetails = async (gst_no: any) => {
  let res = await beApi.get(`${userGstApi}/${gst_no}`, {
    headers: {
      Authorization: "Bearer " + auth_token,
    },
  });

  if (res.data.status === 1) {
    let fetched_data = res.data.data;
    if (enableBusinessBooking) {
      setCompanyName(fetched_data.company_name);
      setCompanyAddress(fetched_data.address);
      setGstIn(gst_no);
    }
  } else {
    toast(res.data.message, {
      hideProgressBar: true,
      type: "error",
    });
    setCompanyName("");
    setCompanyAddress("");
    setGstIn("");
  }
};
const capturePaymentMode = (paymentMode: string) => {
  setPaymentMode(paymentMode);
};
const bookNow = async () => {
  const fullName = bookerName.split(" ");
  let secondName = "";
  if (fullName.length == 3) {
    secondName = fullName[1] + " " + fullName[2];
  } else if (fullName.length == 2) {
    secondName = fullName[1];
  }
  if (!bookerName) {
    toast("Booker name is required!", {
      hideProgressBar: true,
      type: "error",
    });
    return;
  }

  // if (!contact_no.match(/^[0-9]*$/)) {
  if (!validateMobile(bookerMobile)) {
    toast("Invalid Mobile Number entered!", {
      hideProgressBar: true,
      type: "error",
    });
    return;
  }

  if (
    !bookerEmail.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    )
  ) {
    toast("Invalid Email Id entered!", {
      hideProgressBar: true,
      type: "error",
    });
    return;
  }
  if (collectAdivance) {
    if (+advanceAmount > +totalGst + +PackagePrice) {
      toast("Advance amount is greater than total amount!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (advanceAmount == "0" || advanceAmount == "") {
      toast("Advance collected amount cannot be 0!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (!paymentMode) {
      toast("Select Advance Collect Payment Mode!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
    if (!collectedby) {
      toast("Please select Collecting Partner!", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }
  }
  //  ============payload===================
  const payload=
    {
      booking_id:booking_details?.booking_id,
      modified_outing_date: checkInDate,
      no_of_guest:TotalPackage,
      total_amount:totalGst + PackagePrice,
      tax_amount:totalGst,
      paid_amount:advanceAmount,
      collected_by:collectedby,
      payment_mode:1,
      refference_number:refference_number,
      company_name:companyName,
      company_address:companyAddress,
      GST_IN:gstIn,
      arrival_time:arrivalTime,
      Internal_remark:internalRemark,
      guest_note:guestRemark,
      mobile:bookerMobile,
      name:bookerName,
      email_id:bookerEmail,
      mail_to_guest:sendMailtoGuest ? 1 : 0
  }
  
  // const payload2 = {
  //   user_details: {
  //     first_name: fullName[0],
  //     last_name: secondName,
  //     email_id: bookerEmail,
  //     mobile: bookerMobile,
  //     company_name: companyName,
  //     GST_IN: gstIn,
  //     guest_note: guestRemark,
  //     Internal_remark: internalRemark,
  //     arrival_time: arrivalTime,
  //     mail_to_guest: sendMailtoGuest ? 1 : 0,
  //     address: "",
  //     zip_code: "",
  //     country: "",
  //     state: "",
  //     city: "",
  //     identity: {
  //       identity_type: "",
  //       identity_no: "",
  //       expiry_date: "",
  //     },
  //   },
  //   booking_details: {
  //     booking_id:booking_details?.booking_id,
  //     hotel_id: hotel_id,
  //     checkin_date: checkInDate,
  //     tax_amount: totalGst,
  //     booking_reference: refference_number,
  //     source: "Website",
  //     opted_book_assure: 0,
  //     payment_mode: paymentMode,
  //     advance_collect_amt: advanceAmount,
  //     advance_collection_date: firstDate,
  //     collected_by: collectedby,
  //     private_coupon: "",
  //     amount_to_pay: totalGst + PackagePrice,
  //     currency: "INR",
  //   },
  //   package_details: {
  //     package_id: packageData?.package_id,
  //     no_of_package: TotalPackage,
  //   },
  // };
  setLoaderBookNow(true);
  const res = await alphaApi.post(
    `extranetv4/day-booking/modify-booking`,
    payload
  );

  if (res.data.status == 1) {
    toast(res.data.message, {
      hideProgressBar: true,
      type: "success",
    });
    onClose();
    refreshData();
  } else {
    toast(res.data.message, {
      hideProgressBar: true,
      type: "error",
    });
    onClose();
    refreshData();
  }
  setLoaderBookNow(false);
};
// ==========================================
  const onDateChange = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setFirstDate(date_str);
  };
  const ChangeDate = (date: any) => {
    let date_str = moment(date).format("YYYY-MM-DD");
    setCheckInDate(date_str);
  };
  const checkAvailability = async () => {
    setLoading(true);
    setDayBookingData([]);
    setPackageData([]);
    setTotalPackages(0);
    let res = await alphaApi.post(
      `/extranetv4/day-booking/package-availablity`,
      {
        booking_id: booking_details?.booking_id,
        modified_outing_date: checkInDate,
      }
    );

    if (res.data.status == 1) {
      setDayBookingData(res.data.data);
      setAlternativeDatesData(res.data.alternate_dates);
    }
    setLoading(false);
  };
  const IncreasePackage = (data: any) => {
    if (TotalPackage === 0) {
      setPackageData(data);
    }
    setTotalPackages(TotalPackage + 1);
  };
  const DecreasePackage = () => {
    if (TotalPackage === 1) {
      setPackageData([]);
    }
    setTotalPackages(TotalPackage - 1);
  };
  useEffect(() => {
    checkAvailability();
  }, [checkInDate]);
  const clearCart = () => {
    setPackageData([]);
    setTotalPackages(0);
  };
// for changing tab
const navigateToSecondTab = () => {
  const secondTabButton = document.getElementById('modify-guest-tab');
  if (secondTabButton) {
    secondTabButton.click();
  }
};
useEffect(() => {
  SetPackagePrice(TotalPackage * packageData.rate);
  setTotalGst(
    ((TotalPackage * packageData.rate) / 100) *
      packageData.tax_percentage
  );
}, [TotalPackage]);
  return (
    <div className="me-3 ms-3">
      <div className="height-64px"></div>
  

      <div className="side__slider__header__label__wrapper">
        <div className="side__slider__close">
          <i id="close-slider" onClick={onClose} className="bi bi-x"></i>
        </div>
        <div className="standard-page--label">Modify Booking</div>
        <div className="side__slider__sub__heading me-3">
          ID : {selectedBookingData && booking_details?.booking_id}
        </div>
      </div>
      <div className="modify__booking__nav__tab mb-0">
  <ul
    className="nav nav-tabs  modify-crs-booking-nav w-100"
    id="modifyBooking"
    role="tablist"
  >
    <li className="nav-item w-50" role="presentation">
      <button
        className="nav-link tab--link--text active"
        id="modify-dates-tab"
        data-bs-toggle="tab"
        data-bs-target="#dates-tab"
        type="button"
        role="tab"
        aria-controls="dates-tab"
        aria-selected="true"
      >
        Date & Person
      </button>
    </li>
    <li className="nav-item w-50" role="presentation">
      <button
        className="nav-link tab--link--text"
        // className={`nav-link tab--link--text ${TotalPackage > 0  ? 'disabled' : ''}`}
        id={TotalPackage > 0 ?"modify-guest-tab":""}
        data-bs-toggle="tab"
        data-bs-target={TotalPackage > 0 ?"#guest-tab":""}
        type="button"
        role="tab"
        aria-controls="guest-tab"
        aria-selected="false"
        
      >
        Booker Details
      </button>
    </li>
  </ul>
</div>
<div className="tab-content modify__booking__content" id="modifyBookingContent">
  <div
    className="tab-pane fade show active"
    id="dates-tab"
    role="tabpanel"
    aria-labelledby="modify-dates-tab"
  >
     <div className="booking__details__guest__wrapper  ">
        <div className="guest__detail__card">
          <div className="guest__name__wrapper">
            <div className="guest__name">{booking_details?.name}</div>
            <div className="booking__channel__icon">
              <img
                className="booking__source__image"
                src={booking_details?.package_logo}
                alt="Booking Source"
              />
            </div>
          </div>

          <div className="guest__booking__date mt-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="mb-1">Experience name:   {booking_details?.package_name}</p>
              
            </div>
          </div>
          <div className="guest__booking__date mt-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="mb-1">Total Person: {booking_details?.no_of_guest}</p>
              
            </div>
          </div>
          <div className="guest__booking__date mt-1">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="mb-1">Booking Date - {booking_details?.booking_date}</p>
              <p className="mb-1">Visite Date -{booking_details?.outing_dates}</p>
            </div>
          </div>
          <div className="guest__email__contact__details mt-1">
            <div className="guest__number">
              <i className="bi bi-telephone"></i> {booking_details?.mobile}{" "}
            </div>
            <div className="guest__email__id">
              <i className="bi bi-envelope"></i> {booking_details?.email_id}
            </div>
          </div>
        </div>
      </div>
      <div className="date_nights_label_content_wrapper mt-0">
        <div
          style={{ width: "100%" }}
          className="booking__crs__material__input"
        >
          <div className="check_in_out_label mb-2 mt-2">New Visit Date</div>
          <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label=""
                open={openDatePicker}
                minDate={new Date()}
                onOpen={() => setOpenDatePicker(true)}
                onClose={() => setOpenDatePicker(false)}
                value={moment(checkInDate)}
                onChange={ChangeDate}
                inputFormat="dd MMM yyyy"
                disableMaskedInput
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    onClick={() => setOpenDatePicker(true)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
              {/* <div className="apple">apple</div> */}
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <div className="new__bookings__wrapper mt-5">
        {daybookingData?.map((data: any, id: any) => {
          return (
            <div className="roomtypes__content__container">
              <div
                style={{ height: "50px", width: "136%" }}
                className="roomtypes__display"
              >
                {data.package_name}
                <div className="availability__content">
                  <div className="avl_label">Avl</div>
                  <div className="inv_label">{data.no_of_guest}</div>
                </div>
              </div>
              <div
                style={{ justifyContent: "end" }}
                className="plan__dropdown__wrapper"
              >
                <div
                  style={{ marginTop: "0px" }}
                  className="date_nights_label_content_wrapper"
                >
                  <div className="rooms_wrapper">
                    <div className="increment__decrement__btn_bookings">
                      <div className="decrement__btn">
                        <button
                          style={
                            packageData?.package_id !== data?.package_id &&
                            packageData.length != 0
                              ? {
                                  backgroundColor: "#EEEEEE",
                                  color: "#8D8D8D",
                                  padding: "3px",
                                }
                              : {}
                          }
                          disabled={
                            packageData?.package_id !== data?.package_id &&
                            packageData.length != 0
                          }
                          onClick={() => {
                            if (TotalPackage > 0) {
                              DecreasePackage();
                            }
                          }}
                        >
                          <i className="bi bi-dash changing--icon"></i>
                        </button>
                      </div>
                      <div
                        className="changing__value"
                        contentEditable={false}
                        suppressContentEditableWarning={true}
                        // onKeyPress={(e: any) => validateTotalRooms(e)}
                        // onBlur={(e: any) => setRooms(parseInt(e.target.innerText))}
                      >
                        {packageData?.package_id === data?.package_id
                          ? TotalPackage
                          : 0}
                      </div>

                      <div className="increment__btn">
                        <button
                          style={
                            packageData?.package_id !== data?.package_id &&
                            packageData.length != 0
                              ? {
                                  backgroundColor: "#EEEEEE",
                                  color: "#8D8D8D",
                                  padding: "3px",
                                }
                              : {}
                          }
                          disabled={
                            packageData?.package_id !== data?.package_id &&
                            packageData.length != 0
                          }
                          onClick={() => {
                            if (TotalPackage < data.no_of_guest) {
                              IncreasePackage(data);
                            }
                          }}
                        >
                          <i className="bi bi-plus changing--icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        {Loading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <span
              className="spinner-border spinner-border-sm pd-4"
              role="status"
              aria-hidden="true"
            ></span>

            <span style={{ marginBottom: "5px", marginLeft: "10px" }}>
              Looking for Day Booking packages
            </span>
          </div>
        )}
        { !Loading && daybookingData.length==0 && (
          <div
            style={{
              width: "100%",
              display: "gird",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <span
                      className="spinner-border spinner-border-sm pd-4"
                      role="status"
                      aria-hidden="true"
                    ></span> */}
              <i className="bi bi-x-circle fs-3"></i>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  marginBottom: "5px",
                  marginLeft: "10px",
                  textAlign: "center",
                  width: "250px",
                }}
              >
                No Day Booking packages are available for the selected date
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="side__slider__panel__btn">
              {TotalPackage > 0 ? (
                <InventoryBtn handleClick={navigateToSecondTab}>
                  Next <i className="bi bi-arrow-right"></i>
                </InventoryBtn>
              ) : (
                <InventoryBtn className="inactiveBtn">
                  Next <i className="bi bi-arrow-right"></i>
                </InventoryBtn>
              )}
            </div>
  </div>
  <div
    className="tab-pane fade"
    id="guest-tab"
    role="tabpanel"
    aria-labelledby="modify-guest-tab"
  >
    <div className="new__bookings__wrapper ">
      
      
        
          <>
            <div className="dates__rooms__nights__title__container">
              <div
                style={{
                  borderRadius: "8px",
                  padding: "5px",
                  border: "1px solid #C0C0C0",
                  width: "100%",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <div
                      className="bookingtype_label"
                      style={{
                        marginTop: "0.5rem",
                        marginLeft: "7px",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Day booking name
                    </div>
                    <div className="room_nights_wrapper">
                      <div className="nights__container">
                        <span className="ms-2">
                          {packageData?.package_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="bookingtype_label"
                      style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                    >
                      new visit Date
                    </div>

                    <div className="date_wrapper" style={{ display: "flex" }}>
                      <div
                        className="calander__icon"
                        style={{ marginRight: "5px" }}
                      >
                        <i className="bi bi-calendar"></i>
                      </div>
                      <div className="confirm_date_label">{checkInDate}</div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="bookingtype_label"
                      style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                    >
                      Person
                    </div>
                    <div className="room_nights_wrapper">
                      <div className="nights__container">
                        <span className="ms-2">{TotalPackage}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ===============Payment Page=============================== */}
            <>
              <div
                className="bookingtype_label"
                style={{ marginBottom: "10px" }}
              >
                Price Breakup
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  padding: "5px",
                  border: "1px solid #C0C0C0",
                }}
              >
                <div style={{ width: "48%" }}>
                  <div
                    style={{ marginTop: "0.5rem", marginBottom: "-0.5rem" }}
                    className="bookingtype_label"
                  >
                    Total Price
                  </div>
                  <div className="totalprice__wrapper">
                    <div className="inr__label">INR</div>
                    <div className="price__label">
                      {PackagePrice.toFixed(2)}
                    </div>
                  </div>
                </div>
                <div style={{ width: "48%" }}>
                  <div
                    style={{ marginTop: "0.5rem", marginBottom: "-0.5rem" }}
                    className="bookingtype_label"
                  >
                    Tax
                  </div>
                  <div className="totalprice__wrapper">
                    <div className="inr__label">GST</div>
                    <div className="price__label">{totalGst.toFixed(2)}</div>
                  </div>
                </div>
              </div>

              <div className="bookingtype_label">Booker Information</div>
              <div className="search__wrapper">
                <div style={{ width: "400px" }} className="mobile_number">
                  <InputTextField
                    label="Search by Mobile Number"
                    value={searchMobileNumber}
                    handleChange={(e) => setSearchMobileNumber(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: "4px" }} className="search_btn">
                  <InventoryBtn
                    handleClick={() => fetchUserInfoCrs(searchMobileNumber)}
                  >
                    <i className="bi bi-search"></i>
                  </InventoryBtn>
                </div>
              </div>

              <div className="name_container">
                <InputTextField
                  label="Name"
                  value={bookerName}
                  handleChange={(e) => setBookerName(e.target.value)}
                />
              </div>
              <div className="number_address_wrapper">
                <div className="number_container">
                  <InputTextField
                    label="Mobile Number"
                    value={bookerMobile}
                    handleChange={(e) => {
                      validatePositiveInt(e.target.value) &&
                        setBookerMobile(e.target.value);
                    }}
                  />
                </div>
                <div className="address_container ms-3">
                  <InputTextField
                    label="Email Address"
                    value={bookerEmail}
                    handleChange={(e) => setBookerEmail(e.target.value)}
                  />
                </div>
              </div>

              <div
                className="bussiness__label__wrapper "
                style={{ justifyContent: "space-between" }}
              >
                <div className="bussiness_label">Business Booking</div>

                <div className="form-check form-switch custom-switch check--box--width toggle-btn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    onChange={() =>
                      handleEnableBusinessbooking(enableBusinessBooking)
                    }
                    checked={enableBusinessBooking}
                  />
                </div>
              </div>

              {enableBusinessBooking && (
                <>
                  <div className="search__wrapper">
                    <div style={{ width: "400px" }} className="mobile_number">
                      <InputTextField
                        label="Search by GST Number"
                        value={searchGSTNumber}
                        handleChange={(e) => setSearchGSTNumber(e.target.value)}
                      />
                    </div>
                    <div style={{ marginTop: "4px" }} className="search_btn">
                      <InventoryBtn
                        handleClick={() => fetchCompanyDetails(searchGSTNumber)}
                      >
                        <i className="bi bi-search"></i>
                      </InventoryBtn>
                    </div>
                  </div>

                  <div className="number_address_wrapper">
                    <div className="number_container">
                      <InputTextField
                        label="Company Name"
                        value={companyName}
                        handleChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="address_container ms-3">
                      <InputTextField
                        label="Company Address"
                        value={companyAddress}
                        handleChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="name_container">
                    <InputTextField
                      label="GSTIN"
                      value={gstIn}
                      handleChange={(e) => setGstIn(e.target.value)}
                    />
                  </div>
                </>
              )}
              {/* ========================Advance Payment Option=================================== */}
              <div
                className="bussiness__label__wrapper mt-3"
                style={{ justifyContent: "space-between" }}
              >
                <div className="bussiness_label ">Collect Advance</div>

                <div className="form-check form-switch custom-switch check--box--width toggle-btn ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    onChange={() => setCollectAdvance(!collectAdivance)}
                    checked={collectAdivance}
                  />
                </div>
              </div>
              {collectAdivance && (
                <div className="mt-3">
                  <div className="calendar__filter__wrapper mb-0">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                      // className="search__right__wrapper"
                    >
                      <div
                        style={{ width: "48%" }}
                        className="change__calendar__filter"
                      >
                        <div className="booking__crs__material__input">
                          <div className="inventory__date__select__btn mui__input__fieldset__legends__unset">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label=""
                                open={openAdvanceDatePicker}
                                onOpen={() => setOpenAdvanceDatePicker(true)}
                                onClose={() => setOpenAdvanceDatePicker(false)}
                                value={moment(firstDate)}
                                onChange={onDateChange}
                                inputFormat="dd MMM yyyy"
                                disableMaskedInput
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    onClick={() => setOpenAdvanceDatePicker(true)}
                                    onKeyDown={(e) => e.preventDefault()}
                                  />
                                )}
                              />
                              {/* <div className="apple">apple</div> */}
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "-6px", width: "50%" }}
                        className="number_address_wrapper"
                      >
                        <div
                          style={{ width: "100%" }}
                          className="number_container"
                        >
                          <InputTextField
                            label="Amount"
                            value={advanceAmount}
                            handleChange={(e) => {
                              validatePositiveInt(e.target.value) &&
                                setAdvanceAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="standard-page--label mb-3"
                    style={{ fontWeight: "bolder" }}
                  >
                    Refference Number (Optional)
                  </div>
                  <div style={{ width: "100%" }} className="number_container">
                    <InputTextField
                      label="Refference Number"
                      value={refference_number}
                      handleChange={(e) => {
                        setReffrenceNumber(e.target.value);
                      }}
                    />
                  </div>
                  <div className="payment__mode__wrapper">
                    <div
                      className="standard-page--label mb-3"
                      style={{ fontWeight: "bolder" }}
                    >
                      Payment Mode
                    </div>
                    <div className="div__select__inputs__wrapper">
                      <div className="select__input__label">
                        <div
                          className={`${
                            paymentMode.toLowerCase() === "online"
                              ? "select__input__div__active"
                              : "select__input__div"
                          } me-3 mb-3 min-width-126px`}
                          onClick={() => {
                            capturePaymentMode("Online");
                          }}
                        >
                          {paymentMode.toLowerCase() === "online" ? (
                            <i className="bi bi-check-lg"></i>
                          ) : null}
                          Online
                        </div>

                        <div className="select__input__label">
                          <div
                            className={`${
                              paymentMode.toLowerCase() === "cash"
                                ? "select__input__div__active"
                                : "select__input__div"
                            } me-3 mb-3 min-width-126px`}
                            onClick={() => {
                              capturePaymentMode("Cash");
                            }}
                          >
                            {paymentMode.toLowerCase() === "cash" ? (
                              <i className="bi bi-check-lg"></i>
                            ) : null}
                            Cash
                          </div>
                        </div>

                        <div className="select__input__label">
                          <div
                            className={`${
                              paymentMode.toLowerCase() === "card"
                                ? "select__input__div__active"
                                : "select__input__div"
                            } me-3 mb-3 min-width-126px`}
                            onClick={() => {
                              capturePaymentMode("Card");
                            }}
                          >
                            {paymentMode.toLowerCase() === "card" ? (
                              <i className="bi bi-check-lg"></i>
                            ) : null}
                            Card
                          </div>
                        </div>

                        <div className="select__input__label">
                          <div
                            className={`${
                              paymentMode.toLowerCase() === "cheque"
                                ? "select__input__div__active"
                                : "select__input__div"
                            } me-3 mb-3 min-width-126px`}
                            onClick={() => {
                              capturePaymentMode("Cheque");
                            }}
                          >
                            {paymentMode.toLowerCase() === "cheque" ? (
                              <i className="bi bi-check-lg"></i>
                            ) : null}
                            Cheque
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="bussiness_label"
                    style={{ fontWeight: "bolder" }}
                  >
                    Collected By
                  </div>
                  <div className="checkbox_container">
                    <div className="form-check mt-3">
                      <input
                        // id="email-with-link"
                        className="form-check-input"
                        type="radio"
                        name="advancepayment"
                        value="agent"
                        onChange={(e) => setCollectedBy(e.target.value)}
                      ></input>
                      <label
                        className="form-check-label"
                        // htmlFor="email-with-link"
                        style={{ cursor: "pointer" }}
                      >
                        Agent
                      </label>
                    </div>
                    <div className="form-check mt-3">
                      <input
                        // id="email-with-nolink"
                        className="form-check-input"
                        type="radio"
                        name="advancepayment"
                        value="hotel"
                        onChange={(e) => setCollectedBy(e.target.value)}
                      ></input>
                      <label
                        className="form-check-label"
                        // htmlFor="email-with-nolink"
                        style={{ cursor: "pointer" }}
                      >
                        Hotel
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {/* ===================================================================== */}
              <div
                className="bussiness__label__wrapper mt-3"
                style={{ justifyContent: "space-between" }}
              >
                <div className="bussiness_label ">Send Email to guest</div>

                <div className="form-check form-switch custom-switch check--box--width toggle-btn ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    onChange={() => setSendMailtoGuest(!sendMailtoGuest)}
                    checked={sendMailtoGuest}
                  />
                </div>
              </div>

              <div className="name_container">
                <InputTextField
                  label="Expected time of arrival"
                  value={arrivalTime}
                  handleChange={(e) => setArrivalTime(e.target.value)}
                />
              </div>

              <div className="remarks_label">Remarks</div>
              <div className="name_container">
                <InputTextField
                  label="Internal Remark"
                  value={internalRemark}
                  // value={internalRemark}
                  handleChange={(e) => setInternalRemark(e.target.value)}
                  multiline
                />
              </div>
              <div className="name_container">
                <InputTextField
                  label="Guest Remark"
                  value={guestRemark}
                  handleChange={(e) => setGuestRemark(e.target.value)}
                  multiline
                />
              </div>
              <div></div>
              <div
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "white",
                }}
              ></div>
              <div
                style={{
                  justifyContent: "start",
                  gap: "10px",
                }}
                className={
                  loaderBookNow
                    ? "side__slider__panel__btn new__bookings__button"
                    : "side__slider__panel__btn"
                }
              >
          
                {loaderBookNow ? (
                  <InventoryBtn loader={loaderBookNow}>Processing</InventoryBtn>
                ) : (
                  <InventoryBtn handleClick={bookNow}>Save</InventoryBtn>
                )}
              </div>
            </>
          
          </>
        
      </div>
  </div>
</div>
    
    </div>
  );
};

export default ModifyDayBooking;
