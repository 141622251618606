import React, { useEffect, useState, useRef } from "react";
import { CATCH_MESSAGE, getImage, UpdateSidebar } from "../../UtilityFunctions";

import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../Views/buttons/Button";

import { useNavigate } from "react-router";
import { RootState } from "../../Redux/store";

import moment from "moment";
import beApi from "../../API/beApi";

import { useDispatch, useSelector } from "react-redux";
import {
  SaveBooking,
  SaveBookingDetailedInfo,
} from "../../Redux/actions/BookingsAction";

import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import InputMultiSelectField from "../../Views/inputtextfield/InputMultiSelectField";
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";

// import sliding model box
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import gemsApi from "../../API/gemsApi";
import { toast } from "react-toastify";

import ModifyBooking from "./ModifyBooking";
import CancelBooking from "./CancelBooking";
import CalendarSkeleton from "../../Views/loader/CalendarSkeleton";
import NewBookings from "./NewBookings";
import endPoints from "../../API/endPoints";
import FetchBooking from "./FetchBookings";
import ResendMail from "./ResendMail";
import DataLoader from "../../Views/loader/DataLoader";
import Loader from "../../Views/loader/Loader";
import cmApi from "../../API/cmApi";
import axios from "axios";
import ChargeCard from "./ChargeCard";

const PaymentOptnReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getSalesExecutive } = endPoints.SALESEXECUTIVE;

  UpdateSidebar("Reports");

  const { admin_id, hotel_id } = useSelector((state: RootState) => state.auth);
  const { booking_details } = useSelector((state: RootState) => state.bookings);

  const [bookingDetails, setBookingDetails] = useState<any[]>([]);
  const [allSource, setAllSource] = useState<any[]>([]);
  const [allSourcesName, setAllSourcesName] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState<any[]>([]);

  const [searchInput, setSearchInput] = useState("");
  const [statusInput, setStatusInput] = useState(0);
  const [status, setStatus] = useState(0);
  const [dateType, setDateType] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [startDate, setStartDate] = useState<any>(moment()); // Past seven days
  const [endDate, setEndDate] = useState<any>(moment());
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const [sortingOrder, setSortingOrder] = useState("ASC");
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(true);
  const [allAgentData, setAllAgentData] = useState<any[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>("0");
  const [selectSalesExecutive, setSelectSalesExecutive] = useState<any>(null);
  const [salesExecutiveList, setSalesExecutiveList] = useState([]);

  const [openBookingDetailsSlider, setopenBookingDetailsSlider] =
    useState(false);
  const [openModifyBookingSlider, setopenModifyBookingSlider] = useState(false);
  const [openCancelBookingSlider, setopenCancelBookingSlider] = useState(false);

  const [openSlideNewBooking, setOpenSlideNewBooking] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const [openSlideFetchBooking, setOpenSlideFetchBooking] = useState(false);

  const [loader, setLoader] = useState(false);

  const [menuOpen, setMenuOpen] = useState<any>();
  const [clickedId, setClickedId] = useState();
  const [resendMailOpen, setResendMailOpen] = useState(false);
  const [chargeCardOpen, setChargeCardOpen] = useState(false);
  const [selectedBookingData, setSelectedBookingData] = useState();
  const [selectedBookingId, setSelectedBookingId] = useState("");


  const queryType = {
    "Booking Date": 1,
    "Check-in Date": 2,
    "Check-out Date": 3,
  };

  const pageSize = [10, 30, 50];
  const { auth_token } = useSelector((state: RootState) => state.auth);

  const { accessData, adminAcess } = useSelector(
    (state: RootState) => state.userAcess
  );

  const [access, setAccess] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  const beEndpoint =
    process.env.REACT_APP_ENV === "PROD"
      ? "https://be.bookingjini.com/extranetv4"
      : "https://dev.be.bookingjini.com/";

  // Code to Close that active dropdown option on click of outside

  // Function to Show Options of Three Dot
  // const openActiveOptions = (index: any) => {
  //   if (menuOpen === index) {
  //     setMenuOpen("");
  //   } else {
  //     setMenuOpen(index);
  //   }
  // };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event?.target)) {
        setMenuOpen("");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setMenuOpen]);

  const {
    isgemsSubscribed,
    viewPayOptnBookingReports,
    bookingDetailsGet,
    allSources,
    stayDetailsByBookingIdGetV2,
    listBookingReport,
    agentApi
  } = endPoints.BOOKINGS;

  useEffect(() => {
    const status = accessData.filter((iteam: any) => {
      return iteam?.code === "BE";
    })[0]?.access_value;
    adminAcess === 911
      ? setAccess(true)
      : status === 1
        ? setAccess(true)
        : setAccess(false);
  }, [accessData]);


  useEffect(() => {
    getAllAgents();
    getAllSalesExecutive();
  }, [])

  // API to get All Sales Executive Details
  const getAllSalesExecutive = async () => {
    setLoader(true);
    try {
      const response = await beApi.get(`${getSalesExecutive}/${hotel_id}/${admin_id}`,
     
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          }
        })
      if (response.data.status === 1) {
        setSalesExecutiveList(response.data.list);
        if (response.data.list.length == 1) { 
          
          setSelectSalesExecutive(response.data.list[0].id)
          
        } else {
          setSelectSalesExecutive("0")
        }
        setLoader(false);
      } else {
        setSalesExecutiveList([]);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  }

  // Api To Get All Agents Details
  const getAllAgents = async () => {
    try {
      let agentRes = await beApi.get(`${agentApi}/${hotel_id}`);
      if (agentRes.data.status === 1) {
        setAllAgentData(agentRes.data.data)
      } else {
        setAllAgentData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (selectSalesExecutive !== null) {
    bookingListView();
    }
  }, [
    hotel_id,
    startDate,
    endDate,
    statusInput,
    status,
    refreshTrigger,
    selectedAgent,
    selectSalesExecutive,
    dateType,
  ]);

  // Function For Pagination
  useEffect(() => {
    setNoOfPages(
      Math.ceil(listViewFilter(bookingDetails).length / selectPageSize)
    );
    if (noOfPages === 0) {
      setNoDataFound(true);
    } else if (noOfPages > 0) {
      setNoDataFound(false);
    }
  }, [selectedSource, searchInput, statusInput, noOfPages, status]);

  // API call fot getting the booking details
  const bookingListView = async () => {
    setIsLoading(true);
    try {
      let response = await beApi.post(`/${viewPayOptnBookingReports}`, {
        from_date: moment(startDate).format("YYYY-MM-DD"),
        to_date: moment(endDate).format("YYYY-MM-DD"),
        payment_options: statusInput,
        booking_status: status,
        agent_id: selectedAgent.toString(),
        hotel_id: hotel_id.toString(),
        sales_executive_id: selectSalesExecutive.toString(),
        admin_id:admin_id
      });
      if (response.data.status === 1) {
        let res = response.data.data;
        setBookingDetails(res);
        setNoOfPages(Math.ceil(res.length / selectPageSize));
        setIsLoading(false);
        setNoDataFound(false);
      } else if (response.data.status === 0) {
        setNoDataFound(true);
        setIsLoading(false);
        setBookingDetails([]);

      }
    } catch {
      setIsLoading(false);

    }
  };

  //   useEffect(() => {
  //     getAllSource();
  //   }, [hotel_id]);

  // API call for getting all the source
  const getAllSource = async () => {
    let res = await beApi.get(`${allSources}/${hotel_id}`);
    if (res.data.status === 1) {
      let sources = res.data.data;
      setAllSource(sources);

      let otaname: string[] = [];
      let otaid: number[] = [];
      sources.forEach((source: any) => {
        otaname.push(source.ota_name);
        otaid.push(source.ota_id);
      });
      setAllSourcesName(otaname);
      setSelectedSource(otaname);
    }
  };

  const searchOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === "" && noOfPages > 0) {
      setNoDataFound(false);
    }
    setSearchInput(val);
  };

  // selected sources values
  const isAllSelected =
    allSourcesName.length > 0 &&
    selectedSource.length === allSourcesName.length;

  const sourceOnchange = (e: any) => {
    const value = e.target.value;

    if (value[value.length - 1] === "all") {
      setSelectedSource(
        selectedSource.length === allSourcesName.length ? [] : allSourcesName
      );
      return;
    }
    setSelectedSource(value);
  };

  // Filter function
  const listViewFilter = (data: any[]) => {
    return data?.filter((book: any) => {
      return (
        book.unique_id?.toString()?.includes(searchInput) ||
        book.customer_details
          .split(",")[0]
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
  };

  // Sorting
  const sortByClick = (date: string) => {
    if (sortingOrder === "ASC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate > newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("DEC");
    } else if (sortingOrder === "DEC") {
      const sorted = [...bookingDetails].sort((a, b) => {
        const oldDate = moment.utc(a[date]);
        const newDate = moment.utc(b[date]);
        return oldDate < newDate ? 1 : -1;
      });
      setBookingDetails(sorted);
      setSortingOrder("ASC");
    }
  };

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };

  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const downloadBookings = () => {

    let arr: any[] = [];
    allSource.forEach((source) => {
      if (selectedSource.includes(source.ota_name)) {
        arr.push(source.ota_id);
      }
    });
    let from_date = moment(startDate).format("YYYY-MM-DD");
    let to_date = moment(endDate).format("YYYY-MM-DD");

    let encodeURL = `${hotel_id}#${from_date}#${to_date}#${statusInput}`;
    let downloadUrl = `${beEndpoint}/crs-booking-report-download/${hotel_id}/${from_date}/${to_date}/${statusInput}/${status}/${selectedAgent}/${admin_id}`;
    window.location.assign(downloadUrl);
  };

  return (
    <div style={{marginLeft:"-15px"}}>
      <div className="property__breadcrumbs">
        <Breadcrumbs />
        <div className="manage__bookings__action__right">
          <div className="fetch__booking__btn__wrapper">
            <Button
              className="fetch_bookings"
              icon="bi bi-download"
              handleClick={() => downloadBookings()}
            >
              Download
            </Button>
          </div>

          {/* <div className="calendar__view__wrapper">
            <div className="calendar__view__tab">
              <ul className="nav nav-tabs" id="mealPlanTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link " onClick={() => navigate("/reports")}>
                    <i className="bi bi-calendar-week"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    onClick={() =>
                      navigate(
                        `/bookings/${
                          isGems ? "gems/frontoffice-view" : "frontoffice-view"
                        }`
                      )
                    }
                    className="nav-link"
                  >
                    <i className="bi bi-calendar2-range"></i>
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link active">
                    <i className="bi bi-grid-3x3-gap"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      <div className="hr--line"></div>
      <div className="calendar__filter__wrapper">
        {/* <div className="search__booking__wrapper">
          <i className="bi bi-search"></i>
          <input
            placeholder="Search Bookings"
            value={searchInput}
            onChange={(e) => searchOnchange(e)}
          />
        </div>*/}

        <div className="dropdown__right__wrapper">
          {/* <div className="status__dropdown__wrapper me-3 custom--width mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={paymentStatus}
              handleChange={(e) => setPaymentStatus(e.target.value)}
              options={[
                "All Status",
                "Prepaid",
                "Partial Paid",
                "Pay at Hotel",
                "Waiting for Payment",
              ]}
              values={[
                "all",
                "prepaid",
                "partial_paid",
                "pay_at_hotel",
                "waiting_for_payment",
              ]}
            />
          </div> */}

          <div className="listview__datepicker__wrapper me-3">
            <div className="date__dropdown__wrapper mui__input__fieldset__legends__unset">
              <InputSelectField
                label=""
                value={dateType}
                handleChange={(e) => setDateType(e.target.value)}
                options={Object.keys(queryType)}
                values={Object.values(queryType)}
              />
            </div>
          </div>

          <div
            className="listview__select__date__wrapper me-3"
            onClick={() => setFocusedInput("startDate")}
          >
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
            // preventPastDates={true}
            />
          </div>

          <div className="status__dropdown__wrapper payment_optn  mui__input__fieldset__legends__unset me-3">
            <InputSelectField
              label=""
              value={statusInput}
              handleChange={(e) => setStatusInput(e.target.value)}
              options={[
                "All Payment Options",
                "Email with Payment Link",
                "Email with No Payment Link",
                "No Email No Payment Link",
              ]}
              values={[0, 1, 2, 3]}
            />
          </div>
          <div className="status__dropdown__wrapper  mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={status}
              handleChange={(e) => setStatus(e.target.value)}
              options={["All Status", "Confirmed", "Cancelled"]}
              values={[0, 1, 3]}
            />
          </div>
          {/* <div className="change__calendar__filter"></div> */}
          <div className="status__dropdown__wrapper ms-3 mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={selectedAgent}
              handleChange={(e) => setSelectedAgent(e.target.value)}
              options={["All Partners", ...allAgentData.map((iteam: any) => iteam.partner_name)]}
              values={["0", ...allAgentData.map((iteam: any) => iteam.id)]}
            />
          </div>

          <div className="status__dropdown__wrapper ms-3 mui__input__fieldset__legends__unset">
            <InputSelectField
              label=""
              value={selectSalesExecutive}
              handleChange={(e) => setSelectSalesExecutive(e.target.value)}
              options={salesExecutiveList.length > 1 ? ["All Sales Executive", ...salesExecutiveList.map((iteam: { name: string }) => iteam.name)] : salesExecutiveList.map((iteam: { name: string }) => iteam.name)}
              values={salesExecutiveList.length>1?["0", ...salesExecutiveList.map((iteam: any) => iteam.id)]:[ ...salesExecutiveList.map((iteam: any) => iteam.id)]}
            />
          </div>
        </div>
      </div>

      {loader ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="list-view-table-wrapper">
          {isLoading ? (
            <CalendarSkeleton />
          ) : noDataFound ? (
            <div className="table">
              <div className="empty__room__div mt-5">
                <i className="bi bi-x-circle mb-3"></i>
                <div>You don't have any bookings yet.</div>
              </div>
            </div>
          ) : (
            <div className="table-responsive scrollbar-thin">
              <table className="table table-borderless">
                <thead>
                  <tr className="listview__table__heading">
                    <th>Booking ID</th>
                    <th>Guest Name</th>
                    <th>Booking Time</th>
                    <th>Payment Expected In</th>
                    <th>Time Left</th>
                    <th>Total Amount</th>
                    <th>Requested Amount</th>
                    <th>Received Amount</th>
                    <th>Payment Option</th>
                    <th>Partner</th>
                    <th>Sales Executive</th>
                    <th>Booking Status</th>

                    {/* <th onClick={() => sortByClick("booking_date")}>
                    <div className="poniter">
                      Booking Date
                      <i
                        className={`bi bi-caret-${
                          sortingOrder === "ASC" ? "down" : "up"
                        }-fill`}
                      ></i>
                    </div>
                  </th>
                  <th>Guest</th>
                  
                 
                 
                  <th>Payment Status</th>
                  <th>Booking Status</th> */}
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {listViewFilter(bookingDetails)
                    ?.slice(
                      selectPageSize * (currentPageNo - 1),
                      selectPageSize * currentPageNo
                    )
                    .map((item: any, id: number) => (
                      <tr key={id} className="listview__table__data reports__table__data">
                        <td>
                          <div>{item.booking_id}</div>
                        </td>
                        <td>
                          <div className="reports-guest-name">
                            {item.customer_details}
                          </div>
                        </td>
                        {/* <td>
                        <div className="booking__date ps-0">
                          {item.no_of_rooms}
                        </div>
                      </td> */}
                        {/* <td>
                        <div>{item.nights}</div>
                      </td> */}
                        <td>
                          <div>
                            {item.display_booking_date}
                            <br />
                            {item.display_booking_time}
                          </div>
                        </td>
                        <td>
                          <div>{item.payment_expected_in}</div>
                        </td>
                        <td>
                          <div>{item.time_left}</div>
                        </td>
                        <td>
                          <div>
                            {String.fromCharCode(
                              parseInt(item.currency_symbol, 16)
                            )}{" "}
                            {item.total_amount}
                          </div>
                        </td>
                        <td>
                          <div>
                            {String.fromCharCode(
                              parseInt(item.currency_symbol, 16)
                            )}{" "}
                            {item.requested_amount}
                          </div>
                        </td>
                        <td>
                          <div>
                            {String.fromCharCode(
                              parseInt(item.currency_symbol, 16)
                            )}{" "}
                            {item.recived_amount}
                          </div>
                        </td>
                        <td>
                          <div>{item.payment_options}</div>
                        </td>
                        <td>
                          <div>{item.partner_name}</div>
                        </td>

                        <td>
                          <div>{item.sales_executive}</div>
                        </td>

                        <td>
                          <div
                            className={`${item.booking_status === "Confirmed"
                              ? "confirmed__label"
                              : "cancelled"
                              }`}
                          >
                            {item.btn_status === "Confirmed" ? (
                              <>
                                {/* <i className="bi bi-check"></i> */}
                                {item.booking_status}
                              </>
                            ) : (
                              <>
                                {/* <i className="bi bi-x"></i> */}
                                {item.booking_status}
                              </>
                            )}
                          </div>
                        </td>

                        {/* <td>
                        <div className="check__in__out">{`${moment(
                          item.checkin_at
                        ).format("DD MMM")}-${moment(item.checkout_at).format(
                          "DD MMM YY"
                        )}`}</div>
                      </td> */}
                        {/* <td>
                        <div
                          className={`payment_status__label
                          `}
                          style={{ color: `${item.color_code}` }}
                          // className="payment_status_text"
                        >
                          {`${item.payment_status} ${
                            item.remaning_time !== 0
                              ? ` (${item.remaning_time})`
                              : ""
                          } `}
                        </div>
                      </td> */}

                        {/* <td className="drop-down-container">
                        <div
                          onClick={() => {
                            openActiveOptions(id);
                            setClickedId(item.unique_id);
                          }}
                        >
                          <i className="dropdown-header bi bi-three-dots-vertical"></i>
                        </div>
                        {menuOpen === id ? (
                          <>
                            <div className="dropdown-list-container" ref={ref}>
                              <div className="dropdown-list">
                                {item?.is_mail_send_enable ? (
                                  <div
                                    className="option"
                                    onClick={() =>
                                      resendEmailHandler(item.unique_id)
                                    }
                                  >
                                    <h6>Send Mail</h6>
                                  </div>
                                ) : (
                                  <div className="option-disabled">
                                    <h6>Send Mail</h6>
                                  </div>
                                )}
                                {item?.no_show_flag ? (
                                  <div
                                    className="option"
                                    onClick={() => noShowHandler(item)}
                                  >
                                    <h6>No Show</h6>
                                  </div>
                                ) : (
                                  <div className="option-disabled">
                                    <h6>No Show</h6>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && !noDataFound && noOfPages !== 0 && (
            <div className="list--view--pagination mt-4">
              <div className="pagination__left">
                <div className="pagination__dropdown">
                  <InputSelectField
                    label="Select"
                    value={selectPageSize}
                    handleChange={(e) => {
                      setSelectPageSize(e.target.value);
                      setCurrentPageNo(1);
                      setNoOfPages(
                        Math.ceil(bookingDetails.length / e.target.value)
                      );
                    }}
                    options={pageSize}
                  />
                </div>
                <ul>
                  {Array(noOfPages)
                    .fill(0)
                    .map((_, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => setCurrentPageNo(i + 1)}
                          className={`${i + 1 === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                            }`}
                        >
                          {i + 1}
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="pagination__right">
                <div className="arrow__container me-3" onClick={leftArrowClick}>
                  <i className="bi bi-chevron-left font--weight"></i>
                </div>
                <div className="arrow__container" onClick={rightArrowClick}>
                  <i className="bi bi-chevron-right font--weight"></i>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentOptnReport;
