import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import { toast } from "react-toastify";
import beApi from "../../API/beApi";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import CATCH_MSG from "../../Constants/CatchMsg";
import { RootState } from "../../Redux/store";
import { CATCH_MESSAGE } from "../../UtilityFunctions";
import Button from "../../Views/buttons/Button";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import Loader from "../../Views/loader/Loader";
import RateSetup from "./RateSetup";
import { Tooltip } from "@mui/material";
import moment from "moment";

const PartnerRateMapping = () => {
  // Redux
  const { company_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );
  // State
  const [addSliderOpen, setAddSliderOpen] = useState<boolean>(false);
  const [openSlideRateSetup, setOpenSlideRateSetup] = useState<boolean>(false);
  const [partnerInfo, setPartnerInfo] = useState<any>("");
  const [roomSeasonData, setRoomSeasonData] = useState<any>(null);
  const [selectPageSize, setSelectPageSize] = useState(5);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectedRatePlan, setSelectedRatePlan] = useState<any>(null);
  const [customPageRange, setCustomPageRange] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const param = useParams();
  const pageSize = [5, 10, 15, 20, 25, 30];
  // const pageSize =[1,2,3,4,5,6,7,8,9,10,11,12];

  // Handler function to construct custom Page Range-- Pagination
  useEffect(() => {
    // setIsLoading(true);
    if (noOfPages < 7) {
      let arr: number[] = [];
      for (let i = 0; i < noOfPages; i++) {
        arr.push(i + 1);
      }
      setCustomPageRange(arr);
    } else {
      if (currentPageNo < 4) {
        let arr: any[] = [1, 2, 3, 4, 5, "z", noOfPages];
        setCustomPageRange(arr);
      } else if (currentPageNo > noOfPages - 3) {
        let arr: any[] = [
          1,
          "y",
          noOfPages - 4,
          noOfPages - 3,
          noOfPages - 2,
          noOfPages - 1,
          noOfPages,
        ];
        setCustomPageRange(arr);
      } else {
        let arr: any[] = [
          1,
          "y",
          currentPageNo - 1,
          currentPageNo,
          currentPageNo + 1,
          "z",
          noOfPages,
        ];
        setCustomPageRange(arr);
      }
    }
    // setIsLoading(false);
  }, [currentPageNo, noOfPages]);

  // Current page from custom page range
  const currentPageNoFromCustom = (item: any) => {
    if (item === "y") {
      setCurrentPageNo(currentPageNo - 3);
    } else if (item === "z") {
      setCurrentPageNo(currentPageNo + 3);
    } else {
      setCurrentPageNo(item);
    }
  };

  // Pagination
  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };
  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  const getPartnerByID = async () => {
   
    try {
      const res = await beApi.get(`select-partner-details/${param.id}`);
      
      if (res.data.status === 1) {
        setPartnerInfo(res.data.data);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      } else {
        setPartnerInfo("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRoomDetailById = async () => {
    try {
      const res = await beApi.get(`rate-mapping/${hotel_id}/${param.id}`);
      
      if (res.data.status === 1) {
        setIsLoading(false);
        setRoomSeasonData(res.data.data);

        setNoOfPages(
          Math.ceil(
            // (selectedOrgList.length ? selectedOrgList.length : organisationList.length)
            res?.data?.data?.length / selectPageSize
          )
        );
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setRoomSeasonData([]);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const refreshFunntion = () => {
    getRoomDetailById();
  };

  useEffect(() => {
    getPartnerByID();
  }, []);

  useEffect(() => {
    // if (!openSlideRateSetup) return
    getRoomDetailById();
    // }, [openSlideRateSetup]);
  }, []);

  const rateSetupOnClick = (obj: any) => {
   
    setOpenSlideRateSetup(true);
    setSelectedRatePlan(obj);
  };
 

  return (
    <>
      <div className="property__breadcrumbs mb-5 mt-3">
        <CustomBreadcrumbs
          content={[
            {
               label: "Partner", href:"/partner"
            },
            {
              // label: `${partnerInfo?.partner_name}(${partnerInfo?.partner_type})`,
              label:`Rate Mapping (${param.name})`
            },
            // {
            //   // label: `${partnerInfo?.partner_name}(${partnerInfo?.partner_type})`,
            //   label:"Rate Mapping"
            // },
          ]}
        />
      </div>

      <div className="hr--line"></div>

      {isLoading ? (
        <div style={emptyDivStyle}>
          <Loader />
        </div>
      ) : roomSeasonData && roomSeasonData.length ? (
        <>
          <div className="list-view-table-wrapper mb-4">
            {/* --------------rate mapping----------- */}
            <table className="table table-borderless">
              <thead>
                <tr className="listview__table__heading">
                  <th className="ms-3">Si.no</th>
                  <th>Room Type</th>
                  <th>Season</th>
                  <th>Date</th>
                  <th style={{textAlign:"center"}} >Status</th>
                  <th style={{display:"flex",justifyContent:"center"}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {roomSeasonData && roomSeasonData.length
                  ? roomSeasonData
                      ?.slice(
                        selectPageSize * (currentPageNo - 1),
                        selectPageSize * currentPageNo
                      )
                      .map((obj: any, id: any) => {
                        return (
                          <React.Fragment key={id}>
                            <tr className="listview__table__data">
                              <td>
                                <div className="customer-details">
                                  {id + 1 + (currentPageNo - 1) * 5}
                                </div>
                              </td>
                              <td>
                                <Tooltip
                                  title={obj?.room_type_name}
                                  placement="top"
                                  arrow
                                >
                                  <div className="customer-details">
                                    {obj?.room_type_name.length <= 15
                                      ? obj?.room_type_name
                                      : obj?.room_type_name.slice(0, 15) +
                                        "..."}
                                  </div>
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip
                                  title={obj?.season_name}
                                  placement="top"
                                  arrow
                                >
                                  <div className="customer-details">
                                    {obj?.season_name.length <= 20
                                      ? obj?.season_name
                                      : obj?.season_name.slice(0, 20) + "..."}
                                  </div>
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip
                                  title={obj?.season_name}
                                  placement="top"
                                  arrow
                                >
                                  <div className="season-details">{`${moment(
                        obj?.validate_from
                      ).format("DD MMMM YYYY")} - ${moment(
                        obj?.validate_to
                      ).format("DD MMMM YYYY")}`}</div>
                                </Tooltip>
                              </td>
                              <td>
                                <div style={{display:"flex",justifyContent:"center"}} className="customer-details">
                                  {/* {
																	obj?.is_active ?
																		<h4 style={{ color: "green" }}><i className="bi bi-patch-check"></i></h4> :
																		<h4 style={{ color: "red" }}><i className="bi bi-patch-exclamation"></i></h4>
																} */}
                                  <p
                                  
                                    className={
                                      obj.is_active
                                        ? "Activestatus"
                                        : "InActivestatus"
                                    }
                                  >
                                    {obj?.is_active ? "Mapped" : "Unmapped"}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div
                                  // className='customer-details'
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    // className="new__bookings"
                                    icon="bi bi-gear"
                                    handleClick={() => rateSetupOnClick(obj)}
                                  >
                                    Setup
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                  : []}
              </tbody>
            </table>

            <div className="list--view--pagination">
              <div className="pagination__left">
                <div className="pagination__dropdown">
                  <InputSelectField
                    label="Select"
                    value={selectPageSize}
                    options={pageSize}
                    handleChange={(e) => {
                      setSelectPageSize(e.target.value);
                      setNoOfPages(
                        Math.ceil(
                          // (selectedOrgList.length ? selectedOrgList.length : organisationList.length)
                          roomSeasonData.length / e.target.value
                        )
                      );
                      setCurrentPageNo(1);
                    }}
                  />
                </div>
                <ul style={{ alignItems: "center" }}>
                  {/* {Array(noOfPages)
											.fill(0)
											.map((p, i) => {
												const num = i + 1
												return (
													<div key={i}>
														<li

															onClick={() => setCurrentPageNo(num)}
															className={`${num === currentPageNo
																? "active__page"
																: "showing__page__number"
																}`}
															style={(num > currentPageNo + 1 && num < (Array(noOfPages).length - 1)) || num < currentPageNo ? { display: 'none' } : {}}
														>
															{num}
														</li>
														<div style={num === currentPageNo + 1 && num < (Array(noOfPages).length - 1) ? { marginLeft: "0.9rem" } : { display: "none" }}>.....</div>
													</div>
												);
											})} */}
                  {customPageRange.map((item, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => currentPageNoFromCustom(item)}
                        className={`${
                          item === currentPageNo
                            ? "active__page"
                            : "showing__page__number"
                        }`}
                      >
                        {typeof item === "string" ? "..." : item}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="pagination__right">
                <div className="arrow__container me-3" onClick={leftArrowClick}>
                  <i className="bi bi-chevron-left font--weight"></i>
                </div>
                <div className="arrow__container" onClick={rightArrowClick}>
                  <i className="bi bi-chevron-right font--weight"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={emptyDivStyle}>Data Not Available</div>
      )}

      {/* ------------Sliding Pane for setup------------ */}
      <SlidingPane
        hideHeader
        from="right"
        width="40%"
        isOpen={openSlideRateSetup}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideRateSetup(false)}
      >
        <RateSetup
          onClose={() => setOpenSlideRateSetup(false)}
          selectedRatePlan={selectedRatePlan}
          agentId={param.id}
          refreshFunntion={refreshFunntion}
        />
      </SlidingPane>
    </>
  );
};

const emptyDivStyle = {
  width: "100%",
  height: "500px",
  color: "lightgray",
  background: "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default PartnerRateMapping;
