import React, { useRef, useState } from "react";
import Button from "../../Views/buttons/Button";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { toast } from "react-toastify";
import beApi from "../../API/beApi";
import axios from "axios";
export const PartnerBulkUpload = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedExcel, setSelectedExcel] = useState<File | null>(null);
  const [files, setFiles] = useState<any>(null);
  const inputExcel = useRef<HTMLInputElement | null>(null);

  const onExcelSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedExcel(file);
      setFiles(file);
    }
  };

  const packageUploadHandler = () => {
    if (inputExcel.current) {
      inputExcel.current.click(); // Trigger file input click
    }
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://be-alpha.bookingjini.com/public/Demo1.xlsx";
    link.setAttribute("download", "template.xlsx"); // You can set the name of the downloaded file here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleUploadBulkPartner = async () => {
    setIsLoading(true);
    if (!selectedExcel) {
      return;
    }

    // Create FormData
    const payload = new FormData();
    payload.append("import_file", selectedExcel);

    try {
      // Send FormData with Axios
      const res = await beApi.post(
        "https://be-alpha.bookingjini.com/extranetv4/partner-import",
        payload
      );
      if (res.data.status) {
        toast.success(res.data.message);
        onClose();
      } else {
        toast.error(res.data.message);
      } // Log response data
    } catch (error) {
      toast.error("Please upload correct file");
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <div>
        <div className="side__slider__header__label__wrapper">
          <div className="side__slider__close">
            <i
              onClick={() => {
                onClose();
              }}
              className="bi bi-x"
            ></i>
          </div>
          <div className="standard-page--label">Bulk Upload</div>
        </div>
        <div
          style={{
            marginTop: "50px",
            fontSize: "16px",
            color: "#646464",
            marginBottom: "10px",
          }}
        >
          Upload Your Document (excel)
        </div>
        {/* =====================uploading Excel============================================================ */}
        <div className="add__package__upload__image mb-3">
          {/* <div className="standard-page--label mb-3">
        Upload Excel Sheet
      </div> */}

          <div
            className="add__package__upload__section"
            onClick={(e) => {
              packageUploadHandler();
            }}
          >
            {/* <input
          id="upload-excel"
          ref={inputExcel}
          type="file"
          accept=".xls, .xlsx"
          className="d-none"
          onChange={onExcelSelect}
        /> */}

            {/* Display selected file name */}
            {selectedExcel ? (
              <div className="drag__package__image__box">
                <div className="drag__icon">
                  <i className="bi bi-file-earmark-excel"></i>
                </div>
                <div className="drag__text__label">{selectedExcel.name}</div>
              </div>
            ) : (
              <div
                className="drag__package__image__box"
                // className="add__package__upload__section"
                // onClick={(e) => packageUploadHandler(e)}
              >
                <div className="drag__icon">
                  <i className="bi bi-file-earmark-excel"></i>
                </div>
                <div className="drag__text__label">
                  Upload Document
                  <input
                    id="upload-images"
                    ref={inputExcel}
                    type="file"
                    accept=".xls, .xlsx"
                    // className="d-none"
                    style={{ visibility: "hidden" }}
                    onChange={onExcelSelect}
                    multiple
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {selectedExcel ? */}
      <div
        className="side__slider__panel__btn"
        style={{ marginBottom: "70px", boxShadow: "none" }}
      >
        <div>Click here to download template</div>
        <Button handleClick={handleDownload}>
          <i className="bi bi-download"></i> <span>Download</span>
        </Button>
      </div>
      <div className="side__slider__panel__btn">
        <div className="__btn__wrapper">
          {isLoading ? (
            <Button>
              Upload
              <span
                className="spinner-border spinner-border-sm pd-4"
                role="status"
                aria-hidden="true"
                style={{ marginLeft: "0.5rem" }}
              ></span>
            </Button>
          ) : (
            <Button
              handleClick={() => {
                if (selectedExcel) {
                  handleUploadBulkPartner();
                } else {
                  toast("Select File First", {
                    hideProgressBar: true,
                    type: "error",
                  });
                }
              }}
            >
              Upload
            </Button>
          )}
        </div>
      </div>
      {/* : <></>
        } */}
    </div>
  );
};
