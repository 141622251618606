import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

export interface IInputMultiSelectFieldProps {
  label: string;
  value: string[];
  handleChange?: (e: any) => void;
  options: any[];
  optionValues?: string[];
  disable?: boolean;
  isAllSelected: any;
}

const InputMultiSelectField: React.FC<IInputMultiSelectFieldProps> = ({
  label,
  value,
  options,
  handleChange,
  isAllSelected,
}) => {
  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => selected.join(", ")}
          // MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              indeterminate={value.length > 0 && value.length < options.length}
            />
            <ListItemText primary="Select All" />
          </MenuItem>

          {options.map((option, i) => (
            <MenuItem key={i} value={option}>
              <Checkbox checked={value.indexOf(option) > -1} />
              <ListItemText primary={option} />
              
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default InputMultiSelectField;
