import React from "react";

// import mui components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export interface IInputSelectFieldProps {
  label: string;
  value: string | number;
  handleChange?: (e: any) => void;
  helperText?: string;
  options?: string[] | number[];
  values?: string[] | number[];
  disable?: boolean;
  readOnly?: boolean;
  disabledOptions?: number[];
}

const InputSelectField: React.FC<IInputSelectFieldProps> = ({
  label,
  value,
  disable,
  handleChange,
  options,
  values,
  readOnly,
  disabledOptions,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="${label}">{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={handleChange}
        disabled={disable}
        readOnly={readOnly}
        MenuProps={{ disableScrollLock: true }}
      >
        {options?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={values ? values[index] : option}
              disabled={disabledOptions?.includes(index)}
            >
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default InputSelectField;
