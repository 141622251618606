import { LOGIN_TYPE } from "../actionTypes"

export const LoginTypeAction = (data: string) => {
    return (
        {
            type: LOGIN_TYPE,
            payload: data
        }
    )
}
