import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { ActiveSidebarSubitem } from '../../Redux/actions/SidebarAction';
import { RootState } from '../../Redux/store';

export interface SidebarItemProps {
    label: string;
    icon: string;
    href: string;
    active: boolean;
    handleClick: () => void;
    dropdown?: string[];
    showDropdown: boolean | undefined
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, href, active, dropdown, handleClick }) => {
    const dispatch = useDispatch()

    const [showDropdown, setShowDropdown] = useState(active);
    const { active_sidebar_subitem } = useSelector((state: RootState) => state.sidebar)

    const { pathname } = useLocation()
    // console.log(pathname, href, active)

    return (
        <>
            <Link to={dropdown ? "#" : href}>
                <li
                    className={`sidebar__list__items ${href === pathname ? "sidebar__list__items__active" : ""}`}
                    onClick={() => { handleClick(); setShowDropdown(!showDropdown) }}
                >

                    <span className="sidenav__icon__wrapper"><i className={`bi ${icon} sidenav--menu--icons`}></i></span>
                    <span>{label}</span>
                    {dropdown &&
                        <span className={`sidenav__arrow`}><i className={`gg-chevron-down sidenav--menu--icons ${showDropdown ? "icon__rotate__180" : "icon__rotate__360"}`}></i></span>
                    }
                </li>
            </Link>
            {dropdown &&
                <ul className={`sidebar__dropdown__wrapper ${showDropdown ? "dropShow" : "dropHide"}`}>
                    {dropdown.map((title, index) => {
                        return (
                            <Link key={index} to={`${href}/${title.toLowerCase()}`}>
                                <li onClick={() => dispatch(ActiveSidebarSubitem(title))} className={`sidebar__dropdown__list ${active_sidebar_subitem === title ? "sidebar__dropdown__active" : ""}`}>{title}</li>
                            </Link>)
                    })}
                </ul>
            }
        </>
    )
}

export default SidebarItem
