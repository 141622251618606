import React from 'react';

const DataLoader = () => {
    return (
        <>
            <div className="div__data__loader__wrapper">
                <div className="data--loader"></div>
            </div>
        </>
    )
}

export default DataLoader