import React, { useState, useEffect } from 'react';
import InputTextField from '../../Views/inputtextfield/InputTextField';
import Button from '../../Views/buttons/Button';
import { validateEmail, validateFullName, validateMobile } from '../../UtilityFunctions';
import { toast } from 'react-toastify';
import endPoints from '../../API/endPoints';
import beApi from '../../API/beApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

export interface IEditPartnerSliderContent {
    onClose: () => void;
    editSliderData: any;
    refreshData: () => void;
}

const EditSalesExecutive: React.FC<IEditPartnerSliderContent> = ({ onClose, editSliderData, refreshData }) => {
    const { auth_token, hotel_id } = useSelector((state: RootState) => state.auth);
    const { updateSalesExecutive } = endPoints.SALESEXECUTIVE;

    // initial States
    const [salesExecutiveId, setSalesExecutiveId] = useState("");
    const [salesExecutiveName, setSalesExecutiveName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [whatsAppNumber, setWhatsAppNumber] = useState("");

    // get initial data from prop
    useEffect(() => {
        setSalesExecutiveId(editSliderData?.id);
        setSalesExecutiveName(editSliderData?.name);
        setPhoneNumber(editSliderData?.phone);
        setEmailAddress(editSliderData?.email);
        setWhatsAppNumber(editSliderData?.whatsapp_number);
    }, [editSliderData])

    // initial helper text
    const [salesExecutivHelperText, setSalesExecutivHelperText] = useState("");
    const [phoneNumberHelperText, setPhoneNumberHelperText] = useState("");
    const [emailAddressHelperText, setEmailAddressHelperText] = useState("");
    const [whatsAppNumberHelperText, setWhatsAppNumberHelperText] = useState("");
    const [saveLoader, setSaveLoader] = useState<boolean>(false);

    const salesExecutiveNameOnChange = (name: any) => {
        if (!validateFullName(name) && name) {
            setSalesExecutivHelperText("Please enter a valid name");
        } else {
            setSalesExecutivHelperText("");
        }
        setSalesExecutiveName(name);
    }

    const phoneNumberOnChange = (phone: string) => {
        let mobileNumber = phone.slice(0, 10);
        if (!validateMobile(mobileNumber) && mobileNumber) {
            setPhoneNumberHelperText("Please enter a valid mobile number");
        } else {
            setPhoneNumberHelperText("");
        }
        setPhoneNumber(mobileNumber);
    }

    const emailAddressOnChange = (email: string) => {
        if (!validateEmail(email) && email) {
            setEmailAddressHelperText("Please enter a valid email address")
        } else {
            setEmailAddressHelperText("");
        }
        setEmailAddress(email)
    }

    const whatsAppNumberOnChange = (whatsApp: string) => {
        let whatsAppNumber = whatsApp.slice(0, 10);
        if (!validateMobile(whatsAppNumber) && whatsAppNumber) {
            setWhatsAppNumberHelperText("Please enter a valid whatsApp number");
        } else {
            setWhatsAppNumberHelperText("");
        }
        setWhatsAppNumber(whatsAppNumber);
    }

    const editSalesExecutiveDetails = async () => {
        setSaveLoader(true);
        if (salesExecutiveName.length, phoneNumber.length, emailAddress.length, whatsAppNumber.length) {
            try {
                const payload = {
                    hotel_id: hotel_id,
                    name: salesExecutiveName,
                    phone: phoneNumber,
                    email: emailAddress,
                    whatsapp_number: whatsAppNumber
                }
                let response = await beApi.post(`${updateSalesExecutive}/${salesExecutiveId}`, payload,
                    {
                        headers: {
                            Authorization: `Bearer ${auth_token}`,
                        }
                    })
                console.log(response.data);
                if (response.data.status === 1) {
                    setSaveLoader(false);
                    toast(response.data.message, {
                        hideProgressBar: true,
                        type: "success",
                    });
                    onClose();
                    refreshData();
                }
            } catch (error) {
                console.log(error);
                setSaveLoader(false);
            }
        } else {
            toast.error("Kindly fill all the fields");
        }
        setSaveLoader(false);
    }

    return (
        <>
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i
                        onClick={() => {
                            onClose();
                        }}
                        className="bi bi-x"
                    ></i>
                </div>
                <div className="standard-page--label">Edit Sales Executive</div>
            </div>

            <div className="height-64px"></div>

            <div className="add__new__sales__executive mt-3">
                <div className="div__inputs__wrapp">
                    <div className="div--email--input">
                        <InputTextField
                            value={salesExecutiveName}
                            helperText={salesExecutivHelperText}
                            label="Name"
                            handleChange={(e) => salesExecutiveNameOnChange(e.target.value)}
                            handleFocus={(e) => {
                                if (!e.target.value) setSalesExecutivHelperText("");
                            }}
                        />
                    </div>
                </div>

                <div className="div__inputs__wrapp mt-3">
                    <div className="div--email--input">
                        <InputTextField
                            value={phoneNumber}
                            helperText={phoneNumberHelperText}
                            label="Phone"
                            handleChange={(e) => phoneNumberOnChange(e.target.value)}
                            handleFocus={(e) => {
                                if (!e.target.value) setPhoneNumberHelperText("");
                            }}
                        />
                    </div>
                </div>

                <div className="div__inputs__wrapp mt-3">
                    <div className="div--email--input">
                        <InputTextField
                            value={emailAddress}
                            helperText={emailAddressHelperText}
                            label="Email Address"
                            handleChange={(e) => emailAddressOnChange(e.target.value)}
                            handleFocus={(e) => {
                                if (!e.target.value) setEmailAddressHelperText("");
                            }}
                        />
                    </div>
                </div>

                <div className="div__inputs__wrapp mt-3">
                    <div className="div--email--input">
                        <InputTextField
                            value={whatsAppNumber}
                            helperText={whatsAppNumberHelperText}
                            label="Whatsapp Number"
                            handleChange={(e) => whatsAppNumberOnChange(e.target.value)}
                            handleFocus={(e) => {
                                if (!e.target.value) setWhatsAppNumberHelperText("");
                            }}
                        />
                    </div>
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            >
            </div>

            <div className="side__slider__panel__btn">
                <div className="__btn__wrapper justify-content-between w-100">
                    <Button className="primary-btn" handleClick={editSalesExecutiveDetails} loader={saveLoader}>Save</Button>
                </div>
            </div>
        </>
    )
}

export default EditSalesExecutive;