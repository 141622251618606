import React from "react";
import { Outlet } from "react-router-dom";

import AppHeader from "../components/header/AppHeader";
import AppSidebar from "../components/sidebar/AppSidebar";
import AppContent from "../components/contents/AppContent";

// import animate css
import "animate.css";

import ConfirmationPrompt from "./ConfirmationPrompt";


const DefaultLayout: React.FC = () => {
  return (
    <>
      <ConfirmationPrompt />
      <div>
        <AppHeader />
        <div>
          <AppSidebar />
          <AppContent>
            <Outlet />

          </AppContent>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
