import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CustomDateRangePicker from "../../components/daterangepicker/DateRangePicker";
import InventoryBtn from "../../Views/buttons/Button";
import InputTextField from '../../Views/inputtextfield/InputTextField';
import { toast } from 'react-toastify';
import { Capitalize, CATCH_MESSAGE } from '../../UtilityFunctions';
import beApi from '../../API/beApi';
import { RootState } from '../../Redux/store';
import { useSelector } from 'react-redux';


interface IEditSeason {
    onClose: () => void;
    callTheApi: () => void;
    planForEdit: any;
}

const EditSeason: React.FC<IEditSeason> = ({ onClose, planForEdit, callTheApi }) => {
    // state
    const [startDateChange, setStartDateChange] = useState<any>(moment(planForEdit?.validate_from));
    const [startDate, setStartDate] = useState<any>(moment(planForEdit?.validate_from));

    const [endDateChange, setEndDateChange] = useState<any>(moment(planForEdit?.validate_to));
    const [endDate, setEndDate] = useState<any>(moment(planForEdit?.validate_to));

    const [focusedInput, setFocusedInput] = useState<any>(null);

    const [seasonNameChange, setSeasonNameChange] = useState<any>(planForEdit?.season_type);
    const [seasonName, setSeasonName] = useState<any>(planForEdit?.season_type);

    const [isLoading, setIsLoading] = useState(false)

    // state from redux
    const { admin_id, auth_token, hotel_id } = useSelector(
        (state: RootState) => state.auth
    );

    // Handler function for updating season plan name
    const editSeasonPlan = async () => {
        if (seasonName === seasonNameChange && startDate.format() === startDateChange.format() && endDate.format() === endDateChange.format()) {
            setIsLoading(false)
            onClose()
        }
        else {
            if (!seasonNameChange || !startDateChange || !endDateChange) {
                toast.error("Enter all the required field!.");
                setIsLoading(false);
                return;
            }
            let payload = {
                hotel_id: hotel_id,
                season_type: seasonNameChange,
                validate_from: startDateChange.format("YYYY-MM-DD"),
                validate_to: endDateChange.format("YYYY-MM-DD")
            }
            try {
                let response = await beApi.post(`/update-season/${planForEdit?.season_id}`, payload, {
                    headers: {
                        Authorization: `Bearer ${auth_token}`,
                    },
                });

                if (response.data.status === 1) {
                    setIsLoading(false)
                    callTheApi()
                    toast.success(response.data.message);
                    onClose();
                } else {
                    setIsLoading(false)
                    toast.error(response.data.message);
                }
            }
            catch {
                setIsLoading(false)
                toast.error(CATCH_MESSAGE)
            }
        }
    }




    return (
        <>
            {/* {console.log("planForEdit ::::", planForEdit)} */}
            <div className="side__slider__header__label__wrapper">
                <div className="side__slider__close">
                    <i className="bi bi-x" onClick={onClose}></i>
                </div>
                <div className="standard-page--label">Edit Season</div>
            </div>
            <div className="height-64px"></div>

            <div className="text_date_picker_wrapper">
                <InputTextField
                    value={Capitalize(seasonNameChange)}
                    // helperText={emailHelperText}
                    label="Season"
                    handleChange={(e) => { setSeasonNameChange(Capitalize(e.target.value)) }
                    }
                // onKeyPress={(e) => { onKeyEnter(e) }
                // }
                // handleFocus={(e) => {
                //     if (!e.target.value) setEmailHelperText("");
                // }}
                />

                <div className="date_nights_label_content_wrapper_season">
                    <div className="checkin_dates_wrapper">
                        <div
                            className="bookings__select__date__wrapper "
                            onClick={() => setFocusedInput('startDate')}
                        >
                            <div className="date__picker__icon">
                                {" "}
                                <i className="bi bi-calendar"></i>
                            </div>
                            <div className="date__label">
                                {!startDateChange && !endDateChange
                                    ? "Select Date Range"
                                    : (startDateChange
                                        ? moment(startDateChange).format("DD MMM, YYYY")
                                        : "") +
                                    " - " +
                                    (endDateChange ? moment(endDateChange).format("DD MMM, YYYY") : "")}
                            </div>
                            <CustomDateRangePicker
                                startDate={startDateChange}
                                endDate={endDateChange}
                                setStartDate={setStartDateChange}
                                setEndDate={setEndDateChange}
                                focusedInput={focusedInput}
                                setFocusedInput={setFocusedInput}
                                preventPastDates={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                }}
            ></div>
            <div className="side__slider__panel__btn">
                {
                    isLoading ?
                        <InventoryBtn>
                            Save
                            <span
                                className="spinner-border spinner-border-sm pd-4"
                                role="status"
                                aria-hidden="true"
                                style={{ marginLeft: '0.5rem' }}
                            ></span>
                        </InventoryBtn>
                        :
                        <InventoryBtn
                            handleClick={() => {
                                setIsLoading(true)
                                editSeasonPlan()
                            }}
                        >
                            Save
                        </InventoryBtn>
                }
            </div>
        </>
    )
}

export default EditSeason



