import { useDispatch, useSelector } from "react-redux";
import { CancelConfirmationPrompt } from "../Redux/actions/PromptAction";
import { RootState } from "../Redux/store";

import Button from "../Views/buttons/Button";

const ConfirmationPrompt = () => {
  const dispatch = useDispatch();

  const { isOpen, message, action, onAccept, buttons } = useSelector((state: RootState) => state.prompt);

  const fadeIn = "animate__fadeIn";
  const fadeOut = "animate__fadeOut";
  const bounceUp = "animate__bounceOutUp"
  const bounceDown = "animate__bounceInDown"

  const onClose = () => {
      let overlayEl = document.getElementById("page-overlay");
      let promptEl = document.getElementById("prompt-content");
      promptEl?.classList.remove(bounceDown);
      promptEl?.classList.add(bounceUp);
      overlayEl?.classList.remove(fadeIn);
      overlayEl?.classList.add(fadeOut);

      // dispatch(CancelConfirmationPrompt());

      setTimeout(() => {
          dispatch(CancelConfirmationPrompt());
      }, 1000);
  }

  return (
      <>
          {isOpen && (action === "DELETE" || action === "YES" || action === "UPDATE") ?
              <div id="page-overlay" className={`standard__confirmation__prompt__wrapper animate__animated ${fadeIn}`}>
                  <div className="prompt__wrapper">
                      <div id="prompt-content" className={`prompt__content__${action === "DELETE" || action === "YES" ? "delete" : "update"} animate__animated ${bounceDown}`}>
                          <div className="prompt__text">
                              <i className={`bi bi-${action === "DELETE" || action === "YES" ? "question-circle" : "check2"} me-2`}></i>
                              <div>{message}</div>
                          </div>
                          <div className="prompt__action mt-3">
                              <Button className="prompt__cancel me-3" handleClick={onClose}>
                                  {action === "YES" ? "NO" :
                                      buttons ? buttons[0] : "Cancel"
                                  }
                              </Button>
                              <Button id="confirm-btn" className={`prompt__${action === "DELETE" || action === "YES" ? "delete" : "update"}`}
                                  handleClick={onAccept}
                              >
                                  {buttons ? buttons[1] : (action === "YES" ? "YES" : action === "DELETE" ? "Delete" : "Update")}
                              </Button>
                          </div>
                      </div>
                  </div>
              </div> : null
          }
      </>
  )
}

export default ConfirmationPrompt;
