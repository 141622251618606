import React, { useState, useEffect } from "react";
import CustomBreadcrumbs from "../../components/breadcrumbs/CustomBreadcrumbs";
import Button from "../../Views/buttons/Button";
import SlidingPane from "react-sliding-pane";
import NewSeason from "./NewSeason";
import e from "express";
import EditSeason from "./EditSeason";
import { toast } from "react-toastify";
import { Capitalize, CATCH_MESSAGE } from "../../UtilityFunctions";
import beApi from "../../API/beApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import moment from "moment";
import InputSelectField from "../../Views/inputtextfield/InputSelectField";
import Loader from "../../Views/loader/Loader";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  CancelConfirmationPrompt,
  ShowConfirmationPrompt,
} from "../../Redux/actions/PromptAction";

function Season() {
  // state
  const [openSlideNewSeason, setOpenSlideNewSeason] = useState<boolean>(false);
  const [openSlideEditSeason, setOpenSlideEditSeason] =
    useState<boolean>(false);
  const [allSeason, setAllSeason] = useState<any>([]);
  const [selectPageSize, setSelectPageSize] = useState(10);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [planForEdit, setPlanForEdit] = useState(null);
  const [loader, setLoader] = useState(true);
  const [defaultMsg, setDefaultMsg] = useState("No Data Found");
  const [customPageRange, setCustomPageRange] = useState<any[]>([]);

  // Handler function to construct custom Page Range-- Pagination
  useEffect(() => {
    // setIsLoading(true);
    if (noOfPages < 7) {
      let arr: number[] = [];
      for (let i = 0; i < noOfPages; i++) {
        arr.push(i + 1);
      }
      setCustomPageRange(arr);
    } else {
      if (currentPageNo < 4) {
        let arr: any[] = [1, 2, 3, 4, 5, "z", noOfPages];
        setCustomPageRange(arr);
      } else if (currentPageNo > noOfPages - 3) {
        let arr: any[] = [
          1,
          "y",
          noOfPages - 4,
          noOfPages - 3,
          noOfPages - 2,
          noOfPages - 1,
          noOfPages,
        ];
        setCustomPageRange(arr);
      } else {
        let arr: any[] = [
          1,
          "y",
          currentPageNo - 1,
          currentPageNo,
          currentPageNo + 1,
          "z",
          noOfPages,
        ];
        setCustomPageRange(arr);
      }
    }
    // setIsLoading(false);
  }, [currentPageNo, noOfPages]);

  // Current page from custom page range
  const currentPageNoFromCustom = (item: any) => {
    if (item === "y") {
      setCurrentPageNo(currentPageNo - 3);
    } else if (item === "z") {
      setCurrentPageNo(currentPageNo + 3);
    } else {
      setCurrentPageNo(item);
    }
  };

  // state from redux
  const { admin_id, auth_token, hotel_id } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch();
  // const param = useParams()
  // console.log(param)
  // Pagination Section
  const pageSize = [10, 15, 20, 25, 30, 35, 40];
  // const pageSize = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const rightArrowClick = () => {
    if (currentPageNo < noOfPages) {
      setCurrentPageNo(currentPageNo + 1);
    }
  };

  const leftArrowClick = () => {
    if (currentPageNo > 1) {
      setCurrentPageNo(currentPageNo - 1);
    }
  };

  // Api call to retrieve all the season data
  const get_plan = async () => {
    setLoader(true);
    try {
      let response = await beApi.get(`/select-all-season/${hotel_id}`, {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      if (response.data.status === 1) {
        setLoader(false);
        setAllSeason(response.data.data);
        setNoOfPages(Math.ceil(response.data.data.length / selectPageSize));
      } else {
        setLoader(false);
        setDefaultMsg(response.data.message);
      }
    } catch {
      setLoader(false);
      toast.error(CATCH_MESSAGE);
    }
  };

  useEffect(() => {
    get_plan();
  }, []);

  // Handler function to edit data
  const edit_plan = (e: any, item: any) => {
    e.preventDefault();
    setPlanForEdit(item);
    setOpenSlideEditSeason(true);
  };

  // Handler function to activate
  const activate_plan = async (seasonId: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure to activate?`,
        "UPDATE",
        async () => {
          try {
            let res = await beApi.get(`/active-season/${seasonId}`, {
              headers: {
                Authorization: `Bearer ${auth_token}`,
              },
            });
            if (res.data.status === 1) {
              toast.success(res.data.message);
              get_plan();
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  // Handler function to deactivate
  const deactivate_plan = async (seasonId: any) => {
    dispatch(
      ShowConfirmationPrompt(
        `Are you sure to deactivate?`,
        "DELETE",
        async () => {
          try {
            let res = await beApi.get(`/deactive-season/${seasonId}`, {
              headers: {
                Authorization: `Bearer ${auth_token}`,
              },
            });
            if (res.data.status === 1) {
              toast.error(res.data.message);
              get_plan();
            }
          } catch {
            toast.error(CATCH_MESSAGE);
          }
          dispatch(CancelConfirmationPrompt());
        },
        ["No", "Yes"]
      )
    );
  };

  return (
    <>
      <div className="property__breadcrumbs">
        <CustomBreadcrumbs content={[{ label: "Season" }]} />
        <div className="manage__bookings__action__right">
          <div className="new__booking__btn__wrapper">
            <Button
              className="new__bookings"
              icon="bi bi-plus-lg"
              handleClick={() => setOpenSlideNewSeason(true)}
              // isAccessible={access}
            >
              New
            </Button>
          </div>
        </div>
      </div>

      <div className="hr--line"></div>

      {/* List View  */}
      {loader ? (
        <div style={emptyDivStyles}>
          <Loader />
        </div>
      ) : allSeason.length ? (
        <div className="season-list-view-table-wrapper">
          <table className="table table-borderless">
            <thead>
              <tr className="listview__table__heading">
                <th>Sl.no</th>
                <th>Plan Name</th>
                <th>Date Range</th>
                <th>Edit</th>
                <th style={{textAlign:"center"}}>Status</th>
              </tr>
            </thead>
            <tbody>
              {allSeason
                ?.slice(
                  selectPageSize * (currentPageNo - 1),
                  selectPageSize * currentPageNo
                )
                .reverse()
                .map((obj: any, i: number) => (
                  <tr className="season__listview__table__data" key={i}>
                    <td>
                      <div className="season-details ms-3">{i + 1}</div>
                    </td>
                    <td>
                      <div className="season-details">
                        <Tooltip title={obj?.season_type} placement="top" arrow>
                          <span>
                            {obj?.season_type.length <= 15
                              ? Capitalize(obj?.season_type)
                              : obj?.season_type.slice(0, 15) + "..."}
                          </span>
                        </Tooltip>
                      </div>
                    </td>
                    <td>
                      <div className="season-details">{`${moment(
                        obj?.validate_from
                      ).format("DD MMMM YYYY")} - ${moment(
                        obj?.validate_to
                      ).format("DD MMMM YYYY")}`}</div>
                    </td>
                    <td>
                      <div className="season-details">
                        <div
                          className="season-edit-button"
                          onClick={(e) => edit_plan(e, obj)}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="season-details">
                        <div className="toogle__switch">
                          <div style={{display:"flex",justifyContent:"center"}}>
                            {/* <input type="checkbox" className="form-check-input"
                                                            checked={obj?.is_active}
                                                            onChange={() => { obj?.is_active ? deactivate_plan(obj.season_id) : activate_plan(obj.season_id) }}
                                                        /> */}
                            <p
                             className= {obj.is_active?"Activestatus":"InActivestatus"}
                            >
                              {obj?.is_active ? "Active" : "Expired"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/* Pagination Section */}
          <div className="season-list--view--pagination">
            <div className="pagination__left">
              <div className="pagination__dropdown">
                <InputSelectField
                  label="Select"
                  value={selectPageSize}
                  options={pageSize}
                  handleChange={(e) => {
                    setSelectPageSize(e.target.value);
                    setNoOfPages(Math.ceil(allSeason.length / e.target.value));
                    setCurrentPageNo(1);
                  }}
                />
              </div>
              <ul style={{ alignItems: "center" }}>
                {/* {Array(noOfPages)
                                        .fill(0)
                                        .map((p, i) => {
                                            const num = i + 1
                                            return (
                                                <React.Fragment key={i}>
                                                    <li
                                                        key={i}
                                                        onClick={() => setCurrentPageNo(num)}
                                                        className={`${num === currentPageNo
                                                            ? "active__page"
                                                            : "showing__page__number"
                                                            }`}
                                                        style={(num > currentPageNo + 1 && num < (Array(noOfPages).length - 1)) || num < currentPageNo ? { display: 'none' } : {}}
                                                    >
                                                        {num}
                                                    </li>
                                                    <div style={num === currentPageNo + 1 && num < (Array(noOfPages).length - 1) ? { marginLeft: "0.9rem" } : { display: "none" }}>.....</div>
                                                </React.Fragment>
                                            );
                                        })} */}
                {customPageRange.map((item, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => currentPageNoFromCustom(item)}
                      className={`${
                        item === currentPageNo
                          ? "active__page"
                          : "showing__page__number"
                      }`}
                    >
                      {typeof item === "string" ? "..." : item}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="pagination__right">
              <div className="arrow__container me-3" onClick={leftArrowClick}>
                <i className="bi bi-chevron-left font--weight"></i>
              </div>
              <div className="arrow__container" onClick={rightArrowClick}>
                <i className="bi bi-chevron-right font--weight"></i>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={emptyDivStyles}>{defaultMsg}</div>
      )}

      {/* New Season add Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="42%"
        isOpen={openSlideNewSeason}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideNewSeason(false)}
      >
        <NewSeason
          onClose={() => setOpenSlideNewSeason(false)}
          callTheApi={() => get_plan()}
        />
      </SlidingPane>

      {/* Edit Season Slider */}
      <SlidingPane
        hideHeader
        from="right"
        width="42%"
        isOpen={openSlideEditSeason}
        className="sliding-pane-custom"
        closeIcon={<i className="bi bi-x-lg"></i>}
        onRequestClose={() => setOpenSlideEditSeason(false)}
      >
        <EditSeason
          onClose={() => setOpenSlideEditSeason(false)}
          callTheApi={() => get_plan()}
          planForEdit={planForEdit}
        />
      </SlidingPane>
    </>
  );
}

const emptyDivStyles = {
  width: "100%",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "lightgray",
};

export default Season;
