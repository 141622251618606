import React from "react";

// importing mui components
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";

export interface IInputTextFieldProps {
  label: string;
  value: string | undefined | number;
  className?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  settingState?: any;
  runningTheFunction?: any;
  helperText?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: string | number;
  name?: any;
  type?: string;
  placeholder?: any;
  hideIcon?: any;
  readonly?: boolean;
  adoreMentIcon?: string;
  id?: string;
  newIcon?: string;
  stylings?: any;
  inputProps?: object;
}

const InputTextField: React.FC<IInputTextFieldProps> = ({
  label,
  value,
  className,
  handleChange,
  settingState,
  runningTheFunction,
  helperText,
  handleFocus,
  onKeyPress,
  disabled,
  multiline,
  rows,
  name,
  type,
  placeholder,
  handleBlur,
  hideIcon,
  readonly,
  adoreMentIcon,
  id,
  newIcon,
  stylings,
  inputProps,
}) => {
  return (
    <FormControl
      style={{ marginTop: "6px", marginBottom: "1px" }}
      variant="outlined"
      fullWidth
    >
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <OutlinedInput
        name={name}
        id={label}
        placeholder={placeholder}
        className={`${className} ${disabled ? "disable__cursor" : ""}`}
        type={type}
        style={{ backgroundColor: "white" }}
        label={label}
        error={helperText?.trim() ? true : false}
        // value={value || ""}
        value={value}
        disabled={disabled}
        readOnly={readonly}
        inputProps={inputProps}
        tabIndex={1}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        endAdornment={
          !helperText?.trim() && value && !hideIcon ? (
            <InputAdornment position="end">
              <IconButton tabIndex={-1} edge="end">
                {adoreMentIcon ? (
                  <i
                    className={`${adoreMentIcon}`}
                    style={{ fontSize: "15px" }}
                  ></i>
                ) : newIcon ? (
                  <i
                    className={`bi ${newIcon}`}
                    style={stylings ? stylings : {}}
                    onClick={() => {
                      settingState();
                      runningTheFunction();
                    }}
                  ></i>
                ) : (
                  <i
                    className={`bi bi-check-lg text-success`}
                    style={stylings ? stylings : {}}
                  ></i>
                )}
              </IconButton>
            </InputAdornment>
          ) : (
            false
          )
        }
        onKeyPress={onKeyPress}
        multiline={multiline}
        rows={rows}
      />
      {id ? (
        helperText ? (
          <FormHelperText className="text-danger">
            {helperText ? helperText : " "}
          </FormHelperText>
        ) : (
          <></>
        )
      ) : (
        <FormHelperText className="text-danger">
          {helperText ? helperText : " "}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputTextField;
